import { odataCoreGetSamples } from "@app/core/components/common/utils";
import { colTemperature } from "@app/core/temperature/config";
import { ITemperature } from "@app/core/temperature/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";
interface ITemperatureProps {
  id?: number | string;
  recordType: RECORDTYPE;
}

const nameOf = nameOfFactory<ITemperature>();
const Temperature: React.FC<ITemperatureProps> = ({ id, recordType }) => {
  return (
    <CCGrid
      columnFields={colTemperature}
      primaryField={nameOf("ID")}
      dataUrl={
        id === undefined ? undefined : odataCoreGetSamples(id, recordType)
      }
    />
  );
};

export default Temperature;
