import { useLookupDocumentDetailsStore } from "@common/pages/settings/lookups/documents/_id/components/reference-sidebar/detail/store";
import { useLookupDocumentStore } from "@common/pages/settings/lookups/documents/_id/store";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

export const LookupDocumentTabDetails = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { document } = useLookupDocumentStore();
  const {
    lookupDocumentDetails,
    loadLookupDocumentDetails,
    isLoading,
    responseLoadError,
  } = useLookupDocumentDetailsStore();

  useEffect(() => {
    const documentID = document?.Document_ID ?? lastSelectedRow?.ID;
    if (documentID) {
      loadLookupDocumentDetails(documentID);
    }
    //eslint-disable-next-line
  }, [lastSelectedRow, document]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() =>
          loadLookupDocumentDetails(
            document?.Document_ID ?? lastSelectedRow?.ID
          )
        }
      />
    );
  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Document Name:"}
          value={lookupDocumentDetails?.Name}
        />
        <ProductReferenceRow
          title={"Usage:"}
          value={lookupDocumentDetails?.DocumentUsageName}
        />
        <ProductReferenceRow
          title={"Save as Format:"}
          value={lookupDocumentDetails?.SaveFormat}
        />
        <ProductReferenceRow
          title={"Availability:"}
          value={lookupDocumentDetails?.Availability}
        />
        <ProductReferenceRow
          title={"Get Data From:"}
          value={lookupDocumentDetails?.GetDataFrom}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
