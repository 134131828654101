import { isSuccessResponse } from "@common/apis/util";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { colActionTypePicker } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/config";
import { IRespActionType } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/model";
import { productLogic } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/until";
import { getActionsTypes } from "@common/pages/actions/api";
import { nameOfFactory } from "@common/utils/common";
import { ICCGridProps } from "@components/cc-grid/_index";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<IRespActionType>();

export interface IActionTypePickerProps extends ICCInputPickerProps {
  recordType?: RECORDTYPE;
  onPickActionType: (value: IRespActionType) => void;
  selectableMode?: "single" | "multiple" | "none";
  grid?: ICCGridProps;
  userSelection?: boolean | null;
  showRegulatory?: boolean;
  selectedItemIDs?: number[];
}
export const ActionTypePicker = ({
  recordType,
  selectableMode,
  textProduce,
  onChange,
  onError,
  onPickActionType,
  grid,
  userSelection = null,
  showRegulatory = false,
  selectedItemIDs = [],
  ...others
}: IActionTypePickerProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actionTypeList, setActionTypeList] = useState<IRespActionType[]>();
  const [configSelectedItems, setConfigSelectedItems] = useState<
    IRespActionType[]
  >([]);

  const handleOnChangeActionType = (event: ICCInputPickerChangeEvent) => {
    const value: IRespActionType = event.value;
    if (onPickActionType) onPickActionType(value);
  };

  useEffectOnce(() => {
    setIsLoading(true);
    const productType: PRODUCT_TYPE = productLogic(recordType);
    getActionsTypes(productType, userSelection, showRegulatory).then(
      (response) => {
        if (isSuccessResponse(response)) {
          if (response?.data) {
            setActionTypeList(response.data);
          } else {
            const error =
              response?.error ?? "Load action type data picker failed";
            if (onError) onError(error);
          }
        }
      }
    );
    setIsLoading(false);
  });

  useEffect(() => {
    if (
      selectedItemIDs.length > 0 &&
      (isNil(grid?.selectedRows) || grid?.selectedRows?.length === 0)
    ) {
      const selectedItemsList = actionTypeList?.filter((item) =>
        selectedItemIDs.includes(item.ID)
      );
      setConfigSelectedItems(selectedItemsList ?? []);
    } else if (!isNil(grid?.selectedRows) && grid?.selectedRows?.length > 0) {
      setConfigSelectedItems(grid.selectedRows);
    }
  }, [actionTypeList, selectedItemIDs, grid]);

  return (
    <CCInputPicker
      {...others}
      isLoading={isLoading}
      dialog={{
        titleHeader: "Pick Action Type",
        maxWidth: "60%",
      }}
      grid={{
        ...grid,
        columnFields: colActionTypePicker,
        primaryField: nameOf("ID"),
        selectableMode: selectableMode ?? "single",
        data: actionTypeList,
        selectedRows: configSelectedItems,
      }}
      onChange={handleOnChangeActionType}
      textProduce={textProduce}
    />
  );
};
