import { CONTACT_ROUTE } from "@app/core/contacts/_id/constant";
import { FURTHER_INFO_ROUTE } from "@app/core/further-info/[id]/constant";
import { INSPECTIONS_MANAGE_ROUTE } from "@app/core/inspections/[id]/constant";
import { AmsRoute } from "@app/products/ams/constants/ams.route";
import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import { ANIMALS_KENNEL_MANAGE_PAGE } from "@app/products/animals/kennels/[id]/constant";
import { ANIMAL_TYPE_ROUTE } from "@app/products/animals/system-admin/animal-types/[id]/constant";
import { BUILDING_ROUTE } from "@app/products/building/constant";
import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import { CSL_ROUTE } from "@app/products/csl/[id]/constant";
import { PREMISES_MANAGE_ROUTE } from "@app/products/hm/premises/[id]/constant";
import {
  LOCAL_LAWS_MANAGE_ROUTE,
  LOCAL_LAWS_PERMIT_ROUTE,
} from "@app/products/local-laws/[id]/constant";
import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { PROPERTY_ASSESSMENT_COMPLIANCE_ROUTE } from "@app/products/property/assessments/compliance/[id]/constant";
import { ASSESSMENTS_DEBT_RECOVERY_ROUTE } from "@app/products/property/assessments/debt-recovery/[id]/constant";
import { PROPERTY_ASSESSMENT_FINANCIAL_SUMMARIES_ROUTE } from "@app/products/property/assessments/financial-summaries/[id]/constant";
import { ASSESSMENT_MASTER_PROPERTIES_ROUTE } from "@app/products/property/assessments/master-properties/[id]/constant";
import { ASSESSMENTS_PENSIONER_OR_REBATES_REBATE_CLAIMS_ROUTE } from "@app/products/property/assessments/rebates/rebate-claims/[id]/constant";
import { CENTRELINK_CUSTOMER_CONFIRMATION_VIEW_RESPONSE_ROUTE } from "@app/products/property/centrelink-customer-confirmation/view-responses/[id]/constant";
import { PROPERTY_CERTIFICATE_ROUTE } from "@app/products/property/certificates/[id]/constant";
import { CERTIFICATE_E_CERTIFICATE_REQUEST_ROUTE } from "@app/products/property/certificates/e-certificate-requests/[id]/constant";
import { PROPERTY_CHARGE_RUNS_ROUTE } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/constant";
import { PROPERTY_NOTICE_RUNS_ROUTE } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/constant";
import { PROPERTY_ENTITY_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import { PROPERTY_DEFERRED_DUTY_ROUTE } from "@app/products/property/deferred-duty/[id]/constant";
import { FIRE_PREVENTION_ROUTE } from "@app/products/property/fire-prevention/hazld-case/[id]/constant";
import { PROPERTY_JOURNALS_ROUTE } from "@app/products/property/journals/[id]/constant";
import { JOURNALS_SESSION_LIST_ROUTE } from "@app/products/property/journals/session-list/[id]/constant";
import { PROPERTY_METERS_ROUTE } from "@app/products/property/meters/[id]/constant";
import { PROPERTY_PARCEL_ROUTE } from "@app/products/property/parcels/[id]/constant";
import { PROPERTY_PIC_ROUTE } from "@app/products/property/pic/[id]/constant";
import { PROPERTY_REGISTERS_ROUTE } from "@app/products/property/registers/[id]/constant";
import { PROPERTY_SCHEMES_ROUTE } from "@app/products/property/schemes/[id]/constant";
import { PROPERTY_SUPPLEMENTARY_RATES_ROUTE } from "@app/products/property/supplementary-rates/[id]/constant";
import { PROPERTY_TITLES_ROUTE } from "@app/products/property/titles/[id]/constant";
import { TOWN_PLANNING_PPR_ROUTE } from "@app/products/town-planning/ppr/[id]/constant";
import { TOWN_PLANNING_PPR_ENQUIRIES_ROUTE } from "@app/products/town-planning/ppr/enquiries/_id/constant";
import { TOWN_PLANNING_PSAR_ROUTE } from "@app/products/town-planning/ppr/psa-referrals/_id/constant";
import { WASTE_WATER_ROUTE } from "@app/products/waste-water/[id]/constant";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ACTIONS_ROUTE } from "@common/pages/actions/[id]/constant";
import { APPEAL_ROUTE } from "@common/pages/appeal/_id/constant";
import { SETTINGS_COMMUNICATION_TEMPLATE_ROUTE } from "@common/pages/settings/communication/template/_id/constant";
import { SETTINGS_LOOKUPS_ACTION_TYPES_ROUTE } from "@common/pages/settings/lookups/action-types/_id/constant";
import { SETTINGS_LOOKUPS_KEYWORDS_ROUTE } from "@common/pages/settings/lookups/keywords/_id/constant";
import { SETTINGS_LOOKUPS_STATE_ROADS_ROUTE } from "@common/pages/settings/lookups/state-roads/_id/constant";
export const getPageLinkByRecordType = (recordType: RECORDTYPE, id: number) => {
  //@TODO: add more tye
  switch (recordType) {
    // Core
    case RECORDTYPE.CORE_Contact:
      return `${CONTACT_ROUTE}/${id}`;
    case RECORDTYPE.CORE_Action:
      return `${ACTIONS_ROUTE}/${id}`;
    case RECORDTYPE.CORE_Appeal:
      return `${APPEAL_ROUTE}/${id}`;
    case RECORDTYPE.CORE_Register:
      return `${TOWN_PLANNING_PPR_ENQUIRIES_ROUTE}/${id}`;
    case RECORDTYPE.CORE_FurtherInformationRequest:
      return `${FURTHER_INFO_ROUTE}/${id}`;
    case RECORDTYPE.CORE_Keyword:
      return `${SETTINGS_LOOKUPS_KEYWORDS_ROUTE}/${id}`;
    case RECORDTYPE.CORE_ActionType:
      return `${SETTINGS_LOOKUPS_ACTION_TYPES_ROUTE}/${id}`;
    case RECORDTYPE.CORE_StateRoad:
      return `${SETTINGS_LOOKUPS_STATE_ROADS_ROUTE}/${id}`;
    case RECORDTYPE.CORE_Inspection:
      return `${INSPECTIONS_MANAGE_ROUTE}/${id}`;
    // PPR
    case RECORDTYPE.TP_PPRApplication:
    case RECORDTYPE.TP_Application:
    case RECORDTYPE.CommunityProperty_CommunityCentral_Town_Planning_Application:
      return `${TOWN_PLANNING_PPR_ROUTE}/${id}`;
    case RECORDTYPE.TP_PSA_Application:
      return `${TOWN_PLANNING_PSAR_ROUTE}/${id}`;
    // CRMS
    case RECORDTYPE.CUSTOMERSERVICE_Event:
      return `${CRMS_ROUTE}/${id}`;
    // Property
    case RECORDTYPE.CommunityProperty_Assessment:
      return `${PROPERTY_ASSESSMENT_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Parcel:
      return `${PROPERTY_PARCEL_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Title:
      return `${PROPERTY_TITLES_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Certificate:
      return `${PROPERTY_CERTIFICATE_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Register:
      return `${PROPERTY_REGISTERS_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Supplementary:
      return `${PROPERTY_SUPPLEMENTARY_RATES_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Meter:
      return `${PROPERTY_METERS_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Scheme_Account:
      return `${PROPERTY_SCHEMES_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Certificate_Request:
      return `${CERTIFICATE_E_CERTIFICATE_REQUEST_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Master_Property:
      return `${ASSESSMENT_MASTER_PROPERTIES_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Scheme:
      return `${PROPERTY_SCHEMES_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Deferred_Duty_Account:
      return `${PROPERTY_DEFERRED_DUTY_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Entity:
      return `${PROPERTY_ENTITY_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Journal:
      return `${PROPERTY_JOURNALS_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Debt_Recovery:
      return `${ASSESSMENTS_DEBT_RECOVERY_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Assessment_Rebate_Entitlement:
      return `${ASSESSMENTS_PENSIONER_OR_REBATES_REBATE_CLAIMS_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_HazardId_Fire_Prevention:
      return `${FIRE_PREVENTION_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Charge_Run:
      return `${PROPERTY_CHARGE_RUNS_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Notice_Run_Assessment:
      return `${PROPERTY_NOTICE_RUNS_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_CommunityCentral_Other:
      return `${CENTRELINK_CUSTOMER_CONFIRMATION_VIEW_RESPONSE_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Compliance:
      return `${PROPERTY_ASSESSMENT_COMPLIANCE_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_CommunicationTemplate:
      return `${SETTINGS_COMMUNICATION_TEMPLATE_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_PIC:
      return `${PROPERTY_PIC_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Financial_Summary:
      return `${PROPERTY_ASSESSMENT_FINANCIAL_SUMMARIES_ROUTE}/${id}`;
    case RECORDTYPE.CommunityProperty_Session:
      return `${JOURNALS_SESSION_LIST_ROUTE}/${id}`;
    // Animal
    case RECORDTYPE.Animals_Registration:
      return `${ANIMALS_ROUTE}/${id}`;
    case RECORDTYPE.Animals_AnimalType:
      return `${ANIMAL_TYPE_ROUTE}/${id}`;
    //@TODO additional route from UX when implement func
    // case RECORDTYPE.Animals_PoundRegister:
    // // return `${ANIMALS_ROUTE}/${id}`;
    case RECORDTYPE.Animals_Kennel:
      return `${ANIMALS_KENNEL_MANAGE_PAGE}/${id}`;
    // Waste water
    case RECORDTYPE.WW_System:
      return `${WASTE_WATER_ROUTE}/${id}`;
    // Health manager
    case RECORDTYPE.HealthManager_Premises:
      return `${PREMISES_MANAGE_ROUTE}/${id}`;
    //  CSL
    case RECORDTYPE.CSM_Licence:
      return `${CSL_ROUTE}/${id}`;
    // Building
    //@TODO additional enum from API
    case RECORDTYPE.BUILDING:
      return `${BUILDING_ROUTE}/${id}`;
    // Local law
    case RECORDTYPE.CommunityProperty_CommunityCentral_Local_Laws_Permit:
      return `${LOCAL_LAWS_MANAGE_ROUTE}/${id}`;
    case RECORDTYPE.LLP_Permit:
      return `${LOCAL_LAWS_PERMIT_ROUTE}/${id}`;
    // Parking permits
    //@TODO additional enum from API
    // Asset Manager
    case RECORDTYPE.AssetManager_AssetRegister:
      return `${AmsRoute.ASSET_MANAGE_PATH}/${id}`;
    default:
      return undefined;
  }
};
