import { DATE_FORMAT } from "@common/constants/common-format";
import {
  DateFromDateTo,
  DateToFromDialogPlaceID,
  LIFE_TIME,
} from "@common/dialog/date-to-from/model";
import { clearHoursOfDate, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { capitalizeFirstLetter } from "@common/utils/formatting";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { addYears } from "date-fns";
import { cloneDeep, isEmpty, isNil } from "lodash";
import React, { useMemo, useRef } from "react";

interface IDateToFromDialogProps {
  onClose: () => void;
  onSubmit: (
    value: DateFromDateTo,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => void;
  isLoading?: boolean;
  data?: DateFromDateTo;
}

const nameOf = nameOfFactory<DateFromDateTo>();

export const DateToFromDialog = ({
  onClose,
  onSubmit,
  isLoading,
  data,
}: IDateToFromDialogProps) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    const dateFromDateTo = cloneDeep(event.values) as DateFromDateTo;
    dateFromDateTo.DateFrom = clearHoursOfDate(dateFromDateTo.DateFrom);
    dateFromDateTo.DateTo = clearHoursOfDate(dateFromDateTo.DateTo);
    onSubmit(dateFromDateTo, notificationRef);
  };

  const initialData = useMemo(() => {
    var processInitialData: DateFromDateTo | undefined = cloneDeep(data);
    if (data && isNil(data?.DateFrom)) {
      processInitialData = { ...data, DateFrom: new Date() };
    }
    if (isNil(data?.DateTo)) {
      if (data?.Flag_ShowDateToDefaultValue)
        processInitialData = { ...data, DateTo: addYears(new Date(), 1) };
    }

    return processInitialData;
  }, [data]);

  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      key={JSON.stringify(initialData)}
      initialValues={initialData}
      render={(formRenderProps: FormRenderProps) => {
        const { valueGetter } = formRenderProps;
        return (
          <FormElement>
            <CCDialog
              yieldNotificationPortal={DateToFromDialogPlaceID}
              height="auto"
              maxWidth="30%"
              titleHeader={
                !isEmpty(initialData?.Title_Text) && initialData?.Title_Text
                  ? initialData?.Title_Text
                  : "Enter the dates"
              }
              onClose={onClose}
              disabled={isLoading}
              bodyElement={
                <div className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <CCLabel
                          title={
                            capitalizeFirstLetter(
                              initialData?.DateLabelFrom_Text
                            ) ?? "Date from"
                          }
                          isMandatory={initialData?.Flag_DateFromIsMandatory}
                        />
                        <Field
                          name={nameOf("DateFrom")}
                          format={DATE_FORMAT.DATE_CONTROL}
                          component={CCDatePicker}
                          validator={
                            initialData?.Flag_DateFromIsMandatory
                              ? requiredValidator
                              : undefined
                          }
                        />
                      </div>
                      {initialData?.DateLabelFrom_To !== LIFE_TIME && (
                        <div className="cc-field">
                          <CCLabel
                            title={
                              capitalizeFirstLetter(
                                initialData?.DateLabelFrom_To
                              ) ?? "Date to"
                            }
                            isMandatory={initialData?.Flag_DateToIsMandatory}
                          />
                          <Field
                            name={nameOf("DateTo")}
                            format={DATE_FORMAT.DATE_CONTROL}
                            component={CCDatePicker}
                            validator={
                              initialData?.Flag_DateToIsMandatory
                                ? requiredValidator
                                : undefined
                            }
                          />
                        </div>
                      )}
                      {initialData?.ShowNotification && (
                        <div className="cc-field">
                          <CCLabel
                            title={
                              !isEmpty(initialData?.NotifyText) &&
                              initialData?.NotifyText
                                ? initialData?.NotifyText
                                : "Send notification"
                            }
                          />
                          <Field
                            name={nameOf("SendNotification")}
                            component={CCSwitch}
                            checked={valueGetter(nameOf("SendNotification"))}
                          />
                        </div>
                      )}
                    </div>
                  </section>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    disabled={!formRenderProps.valid}
                    onClick={formRenderProps.onSubmit}
                    iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
