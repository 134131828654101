import { TEMPORARY_FIELDS } from "@common/stores/flexible-form/constant";
import {
  TDataFormField,
  TFormName,
  TFormRef,
  TMiddlewareSubmitFormOptions,
  TUpdateFormFields,
} from "@common/stores/flexible-form/model";
import { FormSubmitClickEvent } from "@progress/kendo-react-form";
import {
  cloneDeep,
  isArray,
  isEmpty,
  isNil,
  isString,
  mergeWith,
} from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

type TState = any;
class FlexibleFormStore {
  private _dataForms: Record<string, TState> = {};
  private _defaultDataForm: Record<string, TState> = {};
  private _isSubmit: boolean = false;
  private _isCancelSubmit: boolean = false;
  private _isLoadingForm: boolean = false;
  private _skipCheckInternal: boolean = false;
  private _initialEvent: any = undefined;
  private _middlewareSubmitFormOptions: TMiddlewareSubmitFormOptions = {
    skipCheckModified: false,
    skipCheckValidate: false,
  };
  private _formProps: Partial<Record<TFormName, TState>> = {};

  constructor() {
    makeAutoObservable(this);
    const fragment = document
      .createRange()
      .createContextualFragment("<button/>");
    const button = fragment.querySelector("button");
    const getInitialEvent = (event: Event) => this.setInitialEvent(event);
    button?.addEventListener("click", getInitialEvent);
    button?.click();
    button?.remove();
    button?.removeEventListener("click", getInitialEvent);
  }

  getFormProps = (formName: TFormName) => {
    return toJS(this._formProps[formName]);
  };
  setFormProps = ({ formName, formProps }: TFormRef) => {
    runInAction(() => {
      this._formProps[formName] = formProps;
    });
  };

  get middlewareSubmitFormOptions() {
    return toJS(this._middlewareSubmitFormOptions);
  }
  setMiddlewareSubmitFormOptions = (options: TMiddlewareSubmitFormOptions) => {
    runInAction(() => {
      this._middlewareSubmitFormOptions = options;
    });
  };

  setSkipCheckInternal = (status: boolean) => {
    runInAction(() => {
      this._skipCheckInternal = status;
    });
  };

  get isSubmit() {
    return toJS(this._isSubmit);
  }
  setIsSubmit = (status: boolean) => {
    runInAction(() => {
      this._isSubmit = status;
    });
  };

  get isCancelSubmit() {
    return toJS(this._isCancelSubmit);
  }
  setIsCancelSubmit = (status: boolean) => {
    runInAction(() => {
      this._isCancelSubmit = status;
    });
  };

  setInitialEvent = (event: Event) => {
    runInAction(() => {
      this._initialEvent = event;
    });
  };

  get isLoadingForm() {
    return toJS(this._isLoadingForm);
  }
  setIsLoadingForm = (status: boolean) => {
    runInAction(() => {
      this._isLoadingForm = status;
    });
  };

  get dataForms() {
    return toJS(this._dataForms);
  }
  setDataForms = (dataForms: TDataFormField) => {
    runInAction(() => {
      this._dataForms = { ...this._dataForms, ...dataForms };
    });
  };

  get defaultDataForm() {
    return toJS(this._defaultDataForm);
  }
  setDefaultDataForms = (defaultDataForm: TDataFormField) => {
    runInAction(() => {
      this._defaultDataForm = {
        ...this._defaultDataForm,
        ...defaultDataForm,
      };
      this.setDataForms(defaultDataForm);
    });
  };

  syncFormWithStore = (formName: TFormName) => {
    runInAction(() => {
      const valueGetter = this.getFormProps(formName)?.valueGetter;
      this.setDefaultDataForms({
        [formName]: valueGetter
          ? valueGetter("")
          : this._defaultDataForm?.[formName],
      });
    });
  };

  resetDataForms = () => {
    runInAction(() => {
      this._defaultDataForm = {};
      this._dataForms = {};
    });
  };

  //#region Listen submit event ========/
  waitForSubmit() {
    return new Promise((resolve, reject) => {
      if (this.isCancelSubmit) {
        reject("Cancelled Submit");
        return;
      }
      if (this.isSubmit) resolve("Submit");
    });
  }
  //#endregion Listen submit event =====/

  //#region Submit Form ========/
  submitFormGetData = async (
    formName: TFormName,
    options?: TMiddlewareSubmitFormOptions
  ) => {
    if (options) {
      this.setMiddlewareSubmitFormOptions(options);
    }
    try {
      if (!isEmpty(this._formProps) && this._initialEvent) {
        const submitAction = this.getFormProps(formName)?.onSubmit;
        if (submitAction) submitAction(this._initialEvent);
      }

      await this.waitForSubmit();
      this.setIsSubmit(false);
      this.setMiddlewareSubmitFormOptions({
        skipCheckModified: false,
        skipCheckValidate: false,
      });
      this.setSkipCheckInternal(false);
      if (this.dataForms[formName]) {
        const payload = cloneDeep(this.dataForms[formName]);
        delete payload?.[TEMPORARY_FIELDS];
        return payload;
      }
      return undefined;
    } catch (e) {}
  };
  //#endregion Submit Form =====/

  //#region Handle Submit Form ========/
  middlewareSubmitForm = (
    event: FormSubmitClickEvent,
    formName?: TFormName,
    otherCondition?: () => boolean
  ) => {
    this.setIsSubmit(false);
    if (
      this._skipCheckInternal ||
      (otherCondition && otherCondition()) ||
      this.middlewareSubmitFormOptions?.skipCheckValidate ||
      (event.isValid &&
        (event.isModified ||
          this.middlewareSubmitFormOptions?.skipCheckModified))
    ) {
      this.setIsCancelSubmit(false);
      this.setInitialDataForms({
        [formName ? formName : "GeneralForm"]: event.values,
      });
    } else {
      this.setIsCancelSubmit(true);
    }
    return {
      whenNotValid: (callBack: Function) =>
        !event.isValid && !this.middlewareSubmitFormOptions?.skipCheckValidate
          ? callBack()
          : null,
      whenNotModified: (callBack: Function) =>
        event.isValid &&
        !event.isModified &&
        !this.middlewareSubmitFormOptions?.skipCheckModified
          ? callBack()
          : null,
    };
  };
  //#endregion Handle Submit Form =====/

  //#region Get Set Data for form when submit ========/
  setInitialDataForms = (dataFormParam: TDataFormField) => {
    this.setDefaultDataForms(dataFormParam);
    this.setIsSubmit(true);
  };

  updateFormFields = async (
    formName: TFormName,
    dataUpdate: TUpdateFormFields
    // isForcedUnmodified?: boolean // TODO: need to implement this feature
  ) => {
    const onChange = this.getFormProps(formName)?.onChange;
    if (onChange) {
      for (const [key, value] of Object.entries(dataUpdate)) {
        onChange(key, {
          value: value,
        });
      }
    }

    //#region Update Store ========/
    const data = this._dataForms?.[formName];
    if (data)
      this.setDataForms({
        [formName]: mergeWith(
          data,
          dataUpdate,
          (objValue, srcValue, key, obj) => {
            if (objValue !== srcValue && typeof srcValue === "undefined") {
              obj[key] = srcValue;
            }
          }
        ),
      });
    //#endregion Update Store =====/
  };

  getFormFields = (formName: TFormName, fields?: string | TState[]) => {
    const valueGetter = this.getFormProps(formName)?.valueGetter;
    if (valueGetter) {
      if (isString(fields)) {
        return valueGetter(fields);
      }
      if (isArray(fields)) {
        const result: Record<string, TState> = {};
        fields.forEach((field) => (result[field] = valueGetter(field)));
        return result;
      }
      if (isNil(fields)) {
        return valueGetter("") ?? this._dataForms?.[formName];
      }
    }
  };
  //#endregion Get Set Data for form when submit =====/
}
export const flexibleFormStoreInstance = new FlexibleFormStore();
const flexibleFormStoreContext = createContext(flexibleFormStoreInstance);
export const useFlexibleFormStore = () => {
  return useContext(flexibleFormStoreContext);
};
