import { CRMSSystemAminSubCategoryForm } from "@app/products/crms/system-admin/categories/[id]/subcategory/[id]/components/forms/child-screens/general/_index";
import { NewServiceStandardButton } from "@app/products/crms/system-admin/categories/[id]/subcategory/[id]/components/forms/child-screens/general/components/action-bar/buttons/new-service-standard/_index";
import { useCRMSSytemAdminSubCategoryStore } from "@app/products/crms/system-admin/categories/[id]/subcategory/[id]/store";
import { useIsNewSubcategory } from "@app/products/crms/system-admin/categories/[id]/subcategory/hooks/useIsNewSubcategory";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewCRMSSystemAminSubCategory = observer(() => {
  const {
    crmsSubCategory,
    isLoading,
    responseLoadError,
    loadSubCategory,
    onSubmit,
  } = useCRMSSytemAdminSubCategoryStore();

  const isNew = useIsNewSubcategory();

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadSubCategory(0, isNew)}
        />
      ) : (
        <>
          <FormNavigation title="CRMS Subcategory" />
          <FormTitle title={"New Service Standard Subcategory"} />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
              />,
              <NewServiceStandardButton isDisabled={true} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {crmsSubCategory && <CRMSSystemAminSubCategoryForm />}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
