import Actions from "@app/core/actions/_index";
import { Comments } from "@app/core/comments/_index";
import { Documents } from "@app/core/documents/_index";
import Fees from "@app/core/fees/_index";
import { Journal } from "@app/core/journal/_index";
import Samples from "@app/core/samples/_index";
import { BuildingRegistersPOPEForm } from "@app/products/building/registers/pope/[id]/components/child-screens/general/_index";
import { POPEContactsTab } from "@app/products/building/registers/pope/[id]/components/reference-sidebar/contacts/_index";
import { POPEDetailTab } from "@app/products/building/registers/pope/[id]/components/reference-sidebar/detail/_index";
import { IBuildingRegistersPOPERecordsState } from "@app/products/building/registers/pope/[id]/model";
import { useBuildingRegistersPOPEStore } from "@app/products/building/registers/pope/[id]/store";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import Contacts from "@common/pages/contacts/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import { default as React, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedBuildingRegistersPOPE = observer(() => {
  const { id } = useParams<{ id: string }>(); // TODO: The id variable is only use in case reloading.
  const {
    buildingRegistersPOPE,
    buildingRegistersPOPEId,
    loadBuildingRegistersPOPE,
    responseLoadError,
    isLoading,
  } = useBuildingRegistersPOPEStore();
  const { currentUserInfo } = useGlobalStore();

  const formTitle = useMemo(() => {
    const registerNumber = buildingRegistersPOPE?.RegisterNumber;
    const contact = buildingRegistersPOPE?.Contact;
    // If these two are not falsy, return both, otherwise return one or default
    if (registerNumber && contact) {
      return `${registerNumber} - ${contact}`;
    }
    return registerNumber || contact || "N/A";
  }, [buildingRegistersPOPE]);

  const [showSlideBar, setShowSlideBar] = useState(true);
  const managePageUrl = window.location.pathname;

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Building - Registers - POPE - ${buildingRegistersPOPEId ?? 0}`,
      Text:
        `${getDisplayTextWithDashes([
          buildingRegistersPOPE?.RegisterNumber,
          buildingRegistersPOPE?.Contact,
        ])}` || "N/A",
      LinkUrl: managePageUrl,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Building,
      RecordType_ENUM: RECORDTYPE.BUILDING,
      Record_ID: buildingRegistersPOPEId ? +buildingRegistersPOPEId : 0,
    },
  ];

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <POPEDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts", component: <POPEContactsTab /> },
      {
        title: "History",
        component: (
          <Journal id={0} recordType={RECORDTYPE.TP_BuildingApplication} />
        ),
      },
    ],
  });
  const [buildingRegistersPOPERecords] = useState<
    IBuildingRegistersPOPERecordsState | undefined
  >(undefined);

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Addresses",
      totalRecord: buildingRegistersPOPERecords?.addresses,
      component: (
        <Actions
          id={buildingRegistersPOPEId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Actions",
      totalRecord: buildingRegistersPOPERecords?.actions,
      component: (
        <Actions
          id={buildingRegistersPOPEId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Comments",
      totalRecord: buildingRegistersPOPERecords?.comments,
      component: (
        <Comments
          id={buildingRegistersPOPEId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Contacts",
      totalRecord: buildingRegistersPOPERecords?.contacts,
      component: (
        <Contacts
          id={buildingRegistersPOPEId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Documents",
      totalRecord: buildingRegistersPOPERecords?.documents,
      component: (
        <Documents
          id={parseInt(buildingRegistersPOPEId + "")}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Fees",
      totalRecord: buildingRegistersPOPERecords?.fees,
      component: (
        <Fees id={buildingRegistersPOPEId} recordType={RECORDTYPE.BUILDING} />
      ),
    },
    {
      title: "Inspections",
      totalRecord: buildingRegistersPOPERecords?.inspections,
      component: (
        <Fees id={buildingRegistersPOPEId} recordType={RECORDTYPE.BUILDING} />
      ),
    },
    {
      title: "Related",
      totalRecord: buildingRegistersPOPERecords?.samples,
      component: (
        <Samples
          id={buildingRegistersPOPEId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="POPE" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadBuildingRegistersPOPE(+id);
          }}
        />
      ) : (
        <>
          <FormTitle title={formTitle} badge={buildingRegistersPOPE?.Status} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton title={"Accept"} />
                <CCNavButton title={"Reject"} />
              </CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
              <CCNavButton title={"Add"} type="sub">
                <AddActionButton />
                <CCNavButton title={"Additional address"} />
                <CCNavButton title={"Contact"} />
                <CCNavButton title={"Attachment"} />
                <CCNavButton title={"Comment"} />
                <CCNavButton title={"Complaint"} />
                <CCNavButton title={"Document"} />
                <CCNavButton title={"Fee"} />
                <CCNavButton title={"Inspection"} />
                <CCNavButton title={"Meeting"} />
                <CCNavButton title={"Notice"} />
                <CCNavButton title={"Referral"} />
              </CCNavButton>,

              <CCNavButton title={"View"} type="sub">
                <CCNavButton title={"Documents"} />
                <CCNavButton title={"Journal"} />
                <CCNavButton title={"Project"} />
              </CCNavButton>,
              <CCNavButton title={"Print"} />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {buildingRegistersPOPE && (
                  <CCGeneralPanel component={<BuildingRegistersPOPEForm />} />
                )}
                <CustomPanelBar listPanelBar={listPanelBar} />
              </div>
            </div>

            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
