import { odataCoreGetSamples } from "@app/core/components/common/utils";
import { colNCItems } from "@app/core/nc-items/config";
import { INCItem } from "@app/core/nc-items/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface INCItemsProps {
  id?: number | string;
  recordType: RECORDTYPE;
}

const nameOf = nameOfFactory<INCItem>();
const NCItems: React.FC<INCItemsProps> = ({ id, recordType }) => {
  return (
    <div>
      <CCGrid
        columnFields={colNCItems}
        primaryField={nameOf("ID")}
        dataUrl={
          id === undefined ? undefined : odataCoreGetSamples(id, recordType)
        }
      />
    </div>
  );
};

export default NCItems;
