//#region FSR
import { HMFieldMapping } from "@app/products/hm/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { INSPECTIONS_MANAGE_ROUTE } from "../[id]/constant";

export const colHMInspectionsByDate = [
  {
    field: HMFieldMapping.DateInspected,
    title: "Date",
    locked: true,
    linkTo: (dataItem: { ID: string }) => {
      return `${INSPECTIONS_MANAGE_ROUTE}/` + dataItem.ID;
    },
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: HMFieldMapping.InspectionType,
    title: "Inspection Type",
    width: 150,
  },
  {
    field: HMFieldMapping.Reason,
    title: "Reason",
    width: 150,
  },
  {
    field: HMFieldMapping.Compliance,
    title: "Compliance",
    width: 150,
  },
  {
    field: HMFieldMapping.ReferenceNumber,
    title: "Registration Number",
    width: 150,
  },
  {
    field: HMFieldMapping.TradingName,
    title: "Name",
    width: 150,
  },
  {
    field: HMFieldMapping.PremisesClassification,
    title: "Classification",
    width: 150,
  },
  {
    field: HMFieldMapping.RiskClassification,
    title: "Risk",
    width: 150,
  },
  {
    field: HMFieldMapping.PremisesType,
    title: "Premises Type",
    width: 150,
  },
  {
    field: HMFieldMapping.Officer,
    title: "Officer",
    width: 150,
  },
  {
    field: HMFieldMapping.Address,
    title: "Address",
    width: 150,
  },
  {
    field: HMFieldMapping.Area,
    title: "Area",
    width: 150,
  },
];
