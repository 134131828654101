import { ISample } from "@app/core/samples/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<ISample>();
export const colSamples: IColumnFields[] = [
  {
    field: nameOf("ID"),
    title: "ID",
  },
  {
    field: nameOf("DateSampled"),
    title: "Sampled",
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Officer"),
    title: "Officer",
  },
  {
    field: nameOf("Reason"),
    title: "Reason",
  },
];
