import { ICCRoute } from "@common/constants/ICCRoute";

export const recycleBinRoute: ICCRoute = {
  path: "recycle-bin",
  name: "Rycycle Bin",
  children: [
    {
      path: "premises",
      name: "Premises",
      component: require("./premises/_index").default,
    },
    {
      path: "premises-types",
      name: "Premises Types",
      component: require("./premises-types/_index").default,
    },
    {
      path: "fees",
      name: "Fees",
      component: require("./fees/_index").default,
    },
    {
      path: "inspections",
      name: "Inspections",
      component: require("./inspections/_index").default,
    },
    {
      path: "samples",
      name: "Samples",
      component: require("./samples/_index").default,
    },
  ],
};
