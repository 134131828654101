import Actions from "@app/core/actions/_index";
import { Comments } from "@app/core/comments/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { Documents } from "@app/core/documents/_index";
import Fees from "@app/core/fees/_index";
import { Journal } from "@app/core/journal/_index";
import Samples from "@app/core/samples/_index";
import { BuildingRegisterEasementForm } from "@app/products/building/registers/easements/[id]/components/child-screens/general/_index";
import { EasementContactsTab } from "@app/products/building/registers/easements/[id]/components/reference-sidebar/contacts/_index";
import { EasementDetailTab } from "@app/products/building/registers/easements/[id]/components/reference-sidebar/detail/_index";
import { IBuildingRegisterEasementRecordsState } from "@app/products/building/registers/easements/[id]/model";
import { useBuildingRegistersEasementsStore } from "@app/products/building/registers/easements/[id]/store";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import Contacts from "@common/pages/contacts/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const ExistedBuildingRegisterEasement = observer(() => {
  const { id } = useParams<{ id: string }>();
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const managePageUrl = window.location.pathname;
  const {
    easements,
    easementId,
    loadEasements,
    resetStore,
    isLoading,
    responseLoadError,
  } = useBuildingRegistersEasementsStore();

  const { currentUserInfo } = useGlobalStore();
  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Building - Registers - Easements - ${easementId ?? 0}`,
      Text:
        `${getDisplayTextWithDashes([
          easements?.RegisterNo,
          easements?.Contact,
        ])}` || "N/A",
      LinkUrl: managePageUrl,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Building,
      RecordType_ENUM: RECORDTYPE.BUILDING,
      Record_ID: easementId ? +easementId : 0,
    },
  ];

  useEffectOnce(() => {
    loadEasements(parseInt(id));
    return () => {
      resetStore();
    };
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <EasementDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts", component: <EasementContactsTab /> },
      {
        title: "History",
        component: (
          <Journal id={0} recordType={RECORDTYPE.TP_BuildingApplication} />
        ),
      },
    ],
  });
  const [buildingRegisterEasementRecords] = useState<
    IBuildingRegisterEasementRecordsState | undefined
  >();

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Addresses",
      totalRecord: buildingRegisterEasementRecords?.addresses,
      component: <Samples id={easementId} recordType={RECORDTYPE.BUILDING} />,
    },
    {
      title: "Actions",
      totalRecord: buildingRegisterEasementRecords?.actions,
      component: <Actions id={easementId} recordType={RECORDTYPE.BUILDING} />,
    },
    {
      title: "Comments",
      totalRecord: buildingRegisterEasementRecords?.comments,
      component: <Comments id={easementId} recordType={RECORDTYPE.BUILDING} />,
    },
    {
      title: "Contacts",
      totalRecord: buildingRegisterEasementRecords?.contacts,
      component: <Contacts id={easementId} recordType={RECORDTYPE.BUILDING} />,
    },
    {
      title: "Documents",
      totalRecord: buildingRegisterEasementRecords?.documents,
      component: <Documents id={easementId} recordType={RECORDTYPE.BUILDING} />,
    },
    {
      title: "Fees",
      totalRecord: buildingRegisterEasementRecords?.fees,
      component: <Fees id={easementId} recordType={RECORDTYPE.BUILDING} />,
    },
    {
      title: "Inspections",
      totalRecord: buildingRegisterEasementRecords?.inspections,
      component: <Samples id={easementId} recordType={RECORDTYPE.BUILDING} />,
    },
  ];

  const formTitle = useMemo(() => {
    const registerNumber = easements?.RegisterNo;
    const contact = easements?.Contact;

    // If these two are not falsy, return both, otherwise return one or default
    if (registerNumber && contact) {
      return `${registerNumber} - ${contact}`;
    }

    return registerNumber || contact || "N/A";
  }, [easements]);

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Easement" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadEasements(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={formTitle} badge={easements?.Type} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              ></CCNavButton>,

              <CCNavButton title={"Save & exit"} htmlFor={"btn-submit"} />,
              <CCNavButton title={"Cancel"} />,
              <CCNavButton title={"Add"} type="sub">
                <AddActionButton />
                <CCNavButton title={"Additional address"} />
                <CCNavButton title={"Contact"} />
                <CCNavButton title={"Appeal"} />
                <CCNavButton title={"Attachment"} />
                <CCNavButton title={"Comment"} />
                {/* TODO: enhance later */}
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.Building}
                  recordType={RECORDTYPE.TP_BuildingApplication}
                  recordId={parseInt(id)}
                />
                <CCNavButton title={"Complaint"} />
                <CCNavButton title={"Document"} />
                <CCNavButton title={"Fee"} />
                <CCNavButton title={"Inspection"} />
                <CCNavButton title={"Notice"} />
                <CCNavButton title={"Prosecution"} />
              </CCNavButton>,

              <CCNavButton title={"View"} type="sub">
                <CCNavButton title={"Documents"} />
                <CCNavButton title={"Journal"} />
                <CCNavButton title={"Project"} />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {easements && (
                  <CCGeneralPanel
                    component={<BuildingRegisterEasementForm />}
                  />
                )}
                <CustomPanelBar listPanelBar={listPanelBar} />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
