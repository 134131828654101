import { ConditionReference } from "@app/core/condition/components/dialogs/list-condition-references/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const nameOfConditionReference = nameOfFactory<ConditionReference>();

export const conditionReferenceColumnsWithoutGroup: IColumnFields[] = [
  { field: nameOfConditionReference("Title"), title: "Title" },
  { field: nameOfConditionReference("Condition"), title: "Condition" },
];

export const conditionReferenceColumns: IColumnFields[] = [
  ...conditionReferenceColumnsWithoutGroup,
  { field: nameOfConditionReference("Group"), title: "Group", isGrow: true },
];

export const YieldNotificationPortal_CONDITION_REFERENCE_DIALOG =
  "YieldNotificationPortal_CONDITION_REFERENCE_DIALOG";
