import { CoreAPIService } from "@common/apis/coreAPIService";
import { globalStoreInstance } from "@common/stores/global/store";

const getProprietorData = async (query: string) => {
  return await CoreAPIService.getClient().get(
    `${globalStoreInstance.allSetting.baseAPIUrl}/api/core/internal/getcontacts/${query}`
  );
};

export default {
  getProprietorData,
};
