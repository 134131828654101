import { AddCommentButton } from "@app/products/hm/components/action-bar/buttons/add-comment/_index";
import { PremiseForm } from "@app/products/hm/premises/[id]/components/child-screens/general/_index";
import { usePremisesStore } from "@app/products/hm/premises/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewManagePremise = observer(() => {
  const history = useHistory();
  const { premises, loadPremisesLOVs, responseLoadError, isLoading } =
    usePremisesStore();

  return (
    <>
      <FormNavigation title="PREMISE" />
      <Loading isLoading={isLoading} isFullScreen />
      <FormTitle title={"New Premises"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadPremisesLOVs();
          }}
        />
      ) : (
        <>
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton title={"Transfer premise"} />
                <CCNavButton title={"Close premise"} />
              </CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
              <CCNavButton title={"Add"} type="sub">
                <CCNavButton
                  title={"Action"}
                  onClick={() => {
                    history.push(
                      "/newaction/" +
                        premises?.Premises_ID +
                        "/" +
                        RECORDTYPE.HealthManager_Premises
                    );
                  }}
                />
                <AddCommentButton
                  premiseInfo={premises}
                  recordType={RECORDTYPE.HealthManager_Premises}
                />
                <CCNavButton title={"Contacts"} />
                <CCNavButton title={"Documents"} />
                <CCNavButton title={"Fees"} />
                <CCNavButton title={"Samples"} />
                <CCNavButton
                  title={"Inspection"}
                  onClick={() => {
                    history.push("/hm/manageinspection/new", {
                      RecordTypeId: RECORDTYPE.HealthManager_Premises, // Parent record type Id
                      RecordId: premises?.Premises_ID, // Parent Id
                    });
                  }}
                />
              </CCNavButton>,
              <CCNavButton title={"View"} />,
              <CCNavButton title={"Print"} />,
              <CCNavButton title={"More Options"} type="more">
                <CCNavButton title={"Alert"} />
                <CCNavButton title={"Additional details"} />
                <CCNavButton title={"Application details"} />
                <CCNavButton title={"Portal"} />
                <CCNavButton title={"Portal links"} />
              </CCNavButton>,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                <CCGeneralPanel component={<PremiseForm />} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
