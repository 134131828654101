import { PermitAddressSection } from "@app/products/local-laws/[id]/components/general/components/form-element/components/address-section/_index";
import { PermitContactSection } from "@app/products/local-laws/[id]/components/general/components/form-element/components/contact-section/_index";
import { PermitDatesSection } from "@app/products/local-laws/[id]/components/general/components/form-element/components/dates-section/_index";
import { PermitDebtorSection } from "@app/products/local-laws/[id]/components/general/components/form-element/components/debtor-section/_index";
import { PermitTypeSection } from "@app/products/local-laws/[id]/components/general/components/form-element/components/permit-type-section/_index";
import {
  Permit,
  PermitRegisterLovs,
  Svc_Permit,
} from "@app/products/local-laws/[id]/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { nameOfFactory } from "@common/utils/common";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCLabel } from "@components/cc-label/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export interface IPermitFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOfSvcPermit = nameOfFactory<Svc_Permit>();
const nameOfPermit = nameOfFactory<Permit>();
const getNameOf = (permitPropsName: keyof Permit) => {
  return `${nameOfSvcPermit("Permit")}.${nameOfPermit(permitPropsName)}`;
};
const nameOfKeyValuePacket = nameOfFactory<IKeyValuePacket>();

export const PermitFormElement = observer(
  ({ formRenderProps }: IPermitFormElementProps) => {
    const { dataForms } = useFlexibleFormStore();
    const permitLOVsStore = dataForms?.GeneralFormLovs as PermitRegisterLovs;
    return (
      <FormElement>
        <PermitTypeSection formRenderProps={formRenderProps} />
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Area of council" />
              <Field
                component={CCDropDownList}
                name={getNameOf("PermitArea_KWD")}
                textField={nameOfKeyValuePacket("Value")}
                dataItemKey={nameOfKeyValuePacket("Key")}
                data={permitLOVsStore?.AreaofCouncil ?? []}
                isKeyValueDropdown
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        <PermitContactSection formRenderProps={formRenderProps} />
        <hr className="cc-divider" />
        <PermitAddressSection formRenderProps={formRenderProps} />
        <hr className="cc-divider" />
        <PermitDatesSection formRenderProps={formRenderProps} />
        <hr className="cc-divider" />
        <PermitDebtorSection formRenderProps={formRenderProps} />
      </FormElement>
    );
  }
);
