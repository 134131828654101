import { IComment } from "@app/products/hm/components/action-bar/buttons/add-comment/model";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React from "react";

export interface IConfirmDelete {
  onClosePopup: () => void;
  onAddCommentWithTitlePopup: (dataItem: IComment) => void;
}

export const AddCommentWithTitlePopup = ({
  onClosePopup,
  onAddCommentWithTitlePopup,
}: IConfirmDelete) => {
  const handleSubmit = (dataItem: any) => {
    onClosePopup();
    onAddCommentWithTitlePopup(dataItem as IComment);
  };

  return (
    <Form
      validator={(data: any): any => {
        if (!data?.Comment) {
          return {
            Comment: "border-danger ",
          };
        }
        return {};
      }}
      onSubmit={handleSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <CCDialog
            maxWidth="50%"
            maxHeight="40%"
            titleHeader={"Add Comment"}
            onClose={onClosePopup}
            bodyElement={
              <React.Fragment>
                <div>
                  <div className="form-group col-md-12">
                    <div className="d-flex">
                      <label className="label">Title</label>
                      <CCTooltip type="validator" position="right" />
                    </div>
                    <Field
                      name="Title"
                      rows={8}
                      component={CCTextArea}
                      validator={requiredValidator}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <div className="d-flex">
                      <label className="label">Comment</label>
                      <CCTooltip type="validator" position="right" />
                    </div>
                    <Field
                      name="Comment"
                      rows={8}
                      component={CCTextArea}
                      validator={requiredValidator}
                    />
                  </div>
                </div>
              </React.Fragment>
            }
            footerElement={
              <React.Fragment>
                <div className={"d-flex flex-row-reverse "}>
                  <Button
                    className={"pt-1 ml-2 text-size-lg font-weight-bold"}
                    onClick={() => {
                      onClosePopup();
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    themeColor="primary"
                    type={"submit"}
                    className={"pt-1 ml-2 text-size-lg font-weight-bold"}
                  >
                    Save Comment
                  </Button>
                </div>
              </React.Fragment>
            }
          />
        </FormElement>
      )}
    />
  );
};
