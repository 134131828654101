import { DATE_FORMAT, DECIMAL_FORMAT } from "@common/constants/common-format";
import { Svc_RecordsView } from "@common/pages/settings/records/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_RecordsView>();

export const colRecords: IColumnFields[] = [
  {
    title: "Document Number",
    field: nameOf("DocumentNumber"),
  },
  {
    title: "Parent",
    field: nameOf("Parent"),
  },
  {
    title: "File Number",
    field: nameOf("FileNumber"),
  },
  {
    title: "Title",
    field: nameOf("Title"),
  },
  { title: "File Name", field: nameOf("FileName") },
  {
    title: "Size (MB)",
    field: nameOf("Size"),
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    title: "Created Date",
    field: nameOf("CreatedDate"),
    format: DATE_FORMAT.DATE,
  },
  { title: "Created By", field: nameOf("CreatedBy") },
];
