import { NewManagePremise } from "@app/products/hm/premises/[id]/components/forms/new/_index";
import { usePremisesStore } from "@app/products/hm/premises/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { ExistManagePremise } from "./components/forms/exist/_index";
import "./_index.scss";

const ManagePremise = observer(() => {
  // get id from params
  const { id } = useParams<{ id: string }>();
  const { loadPremisesInfo, resetStore } = usePremisesStore();
  const isNew = useIsNew();

  useEffect(() => {
    loadPremisesInfo(id, isNew);
  }, [id, isNew, loadPremisesInfo, resetStore]);

  useEffectOnce(() => {
    return () => resetStore();
  });

  if (isNew) {
    return <NewManagePremise />;
  }

  return <ExistManagePremise />;
});

export default ManagePremise;
