import { Svc_InvoiceToFinance } from "@app/core/invoice/[id]/model";
import { SettingsMap } from "@app/products/property/system-admin/settings/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const nameOfPermitFees = nameOfFactory<PermitFees>();
export const nameOfPermitFinances = nameOfFactory<PermitFinances>();
export const nameOfInvoiceToFinance = nameOfFactory<Svc_InvoiceToFinance>();

export interface PermitFees {
  ID: number;
  AmountIssued: number;
  AmountOutstanding: number | null;
  ContactName: string;
  ReferenceNumber: string;
  FeeType: string;
  CreatedDate: Date;
  DebtorNumber: string;
  InvoiceNumber: string;
  CostCode: string;
  Type: string;
}

export interface PermitFinances {
  ID: number;
  PermitNumber: string;
  Applicant: string;
  SiteAddress: string;
  Locality: string;
  Owner: string;
  Type: string;
  StatusDueStatus: string;
  Status: string;
  DueStatus: string;
  EffectiveFrom: Date | null;
  EffectiveTo: Date | null;
  UsePermitEventName: string;
  Officer: string;
  AmountOS: number | null;
  DebtorNumber: string;
  AssessmentNo: string;
  NARID: string;
  CONTACTID: string;
  /**
   * Need to confirm and implement later
   * Reason: Radium UX has this column, query select column does not have this column
   */
  AppBalance: number | null;
  FileNumber: string | null;
}

export interface IGenerateFinancesColumnsProps {
  defaultConfig: IColumnFields[];
  settings?: SettingsMap;
}

export enum PermitDebtorSummaryTypes {
  DebtorSummaryAll,
  DebtorSummaryOutstanding,
}

export enum InvoiceFinanceViewType {
  InvoiceSentToFinance,
  InvoiceNotSentToFinance,
}
