import { BubbleUpType } from "@app/core/inspections/[id]/model";
import { SettingsMap } from "@app/products/property/system-admin/settings/model";
import {
  BubbleUpIdentifier,
  BubbleUpType as BubbleUpTypeString,
} from "@app/products/waste-water/[id]/model";
import { FINANCEMETHOD } from "@common/constants/enumerations";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { isNil } from "lodash";

export const productTypeNumberLogic = (recordType?: RECORDTYPE) => {
  switch (recordType) {
    case RECORDTYPE.CUSTOMERSERVICE_Event:
      return PRODUCT_TYPE_NUMBER.CustomerService;
    case RECORDTYPE.DisRes_Permit:
      return PRODUCT_TYPE_NUMBER.DISRES;
    case RECORDTYPE.HealthManager_Premises:
      return PRODUCT_TYPE_NUMBER.HealthManager;
    case RECORDTYPE.WW_System:
      return PRODUCT_TYPE_NUMBER.WasteWater;
    case RECORDTYPE.WW_Installation:
    case RECORDTYPE.WW_Scheme:
    case RECORDTYPE.WW_Product:
      return PRODUCT_TYPE_NUMBER.WasteWaterSPI;
    case RECORDTYPE.CRS_Registration:
    case RECORDTYPE.CRS_FourYOKinder:
    case RECORDTYPE.CRS_HomeBasedCare:
    case RECORDTYPE.CRS_ThreeYOKinder:
    case RECORDTYPE.CRS_CentreBasedCare:
      return PRODUCT_TYPE_NUMBER.CRS;
    case RECORDTYPE.Animals_Registration:
    case RECORDTYPE.Animals_Kennel:
    case RECORDTYPE.Animals_Notices:
    case RECORDTYPE.Animals_PoundRegister:
      return PRODUCT_TYPE_NUMBER.Animals;
    case RECORDTYPE.CORE_Register_StreetProtection:
      return PRODUCT_TYPE_NUMBER.StreetProtection;
    case RECORDTYPE.LLP_Permit:
      return PRODUCT_TYPE_NUMBER.LLPermits;
    case RECORDTYPE.TP_Application:
    case RECORDTYPE.TP_Subdivision:
    case RECORDTYPE.TP_PSA_Application:
    case RECORDTYPE.TP_OtherApplication:
    case RECORDTYPE.TP_PPRApplication:
      return PRODUCT_TYPE_NUMBER.TownPlanning;
    case RECORDTYPE.TP_BuildingApplication:
      return PRODUCT_TYPE_NUMBER.Building;
    case RECORDTYPE.TP_DevAppsApplication:
      return PRODUCT_TYPE_NUMBER.DevApps;
    case RECORDTYPE.CSM_Licence:
      return PRODUCT_TYPE_NUMBER.CSM;
    case RECORDTYPE.NSP_Register:
      return PRODUCT_TYPE_NUMBER.NSPR;
    case RECORDTYPE.EMS_Facility:
    case RECORDTYPE.EMS_FacilityFeature:
    case RECORDTYPE.EMS_EventBooking:
      return PRODUCT_TYPE_NUMBER.EventManagementSystem;
    case RECORDTYPE.RAD_Register_Licence:
    case RECORDTYPE.RAD_Register_Place:
    case RECORDTYPE.RAD_Register_Source:
    case RECORDTYPE.RAD_Register_Accreditation:
      return PRODUCT_TYPE_NUMBER.Radiation;
    case RECORDTYPE.DWCMS_Application:
    case RECORDTYPE.DWCMS_Worker:
    case RECORDTYPE.DWCMS_Registration:
      return PRODUCT_TYPE_NUMBER.DWCMS;
    case RECORDTYPE.SRU_Application:
    case RECORDTYPE.SRU_Organisation:
    case RECORDTYPE.SRU_Registration:
    case RECORDTYPE.SRU_Facility:
    case RECORDTYPE.SRU_RegistrationAdministration:
    case RECORDTYPE.SRU_RegistrationStatement:
    case RECORDTYPE.SRU_Infringement:
    case RECORDTYPE.SRU_SuspendAdmission:
    case RECORDTYPE.SRU_RegistrationSanction:
      return PRODUCT_TYPE_NUMBER.StandardsAndRegulation;
    case RECORDTYPE.INFRINGEMENTS_InfringementTicket:
    case RECORDTYPE.INFRINGEMENTS_InfringementOffence:
    case RECORDTYPE.INFRINGEMENTS_InfringementCategory:
    case RECORDTYPE.INFRINGEMENTS_InfringementAnimals:
      return PRODUCT_TYPE_NUMBER.Infringements;
    case RECORDTYPE.TP_LMApplication:
      return PRODUCT_TYPE_NUMBER.LandManagement;
    case RECORDTYPE.CEM_Entry:
    case RECORDTYPE.CEM_Registration:
      return PRODUCT_TYPE_NUMBER.Cemeteries;
    default:
      return PRODUCT_TYPE_NUMBER.Core;
  }
};

export const mapEnum = <T>(
  enumItem: string | number | undefined,
  enumList: any
) => {
  if (enumItem) {
    if (typeof enumItem === "number") {
      return enumItem;
    } else {
      return enumList?.[enumItem as keyof T];
    }
  }
};

export const getBubbleUpDetailsByType = (
  bubbleUpList: BubbleUpIdentifier[],
  bubbleUpType: BubbleUpType
) => {
  return bubbleUpList?.find(
    (bubbleUpItem: BubbleUpIdentifier) =>
      mapEnum<BubbleUpType>(bubbleUpItem.BubbleUpType_ENUM, BubbleUpType) ===
      bubbleUpType
  );
};

export const renderBubbleUps = (
  ancestorId: number,
  ancestorType: string | number | undefined,
  parentId: number,
  parentType: string | number | undefined
): BubbleUpIdentifier[] => {
  return [
    {
      PKID: 0,
      SourceIdentifier: {
        _RecordSource_ID: ancestorId,
        _RecordSourceType_ENUM: mapEnum(ancestorType, RECORDTYPE),
        _RecordSource_ColumnName: "",
        _RecordSource_StringID: "",
      },
      BubbleUpType_ENUM: BubbleUpTypeString.Ancestor,
      BubbleUpType_Name: BubbleUpTypeString.Ancestor.toString(),
      Sys_CreatedDate: new Date(),
      Sys_DeactivationDate: null,
    },
    {
      PKID: 0,
      SourceIdentifier: {
        _RecordSource_ID: parentId,
        _RecordSourceType_ENUM: mapEnum(parentType, RECORDTYPE),
        _RecordSource_ColumnName: "",
        _RecordSource_StringID: "",
      },
      BubbleUpType_ENUM: BubbleUpTypeString.Parent,
      BubbleUpType_Name: BubbleUpTypeString.Parent.toString(),
      Sys_CreatedDate: new Date(),
      Sys_DeactivationDate: null,
    },
  ];
};

export const returnAmount = (amount: number, isNegative: boolean) => {
  let feeAmount: number = 0;
  if (amount) feeAmount = Math.abs(amount);
  if (isNegative && feeAmount !== 0) feeAmount *= -1;

  return feeAmount;
};

export const generateInvoiceItemTitle = (
  settings: SettingsMap,
  recordType: RECORDTYPE,
  isFormTitle?: boolean
) => {
  const isEnableMultiLineInvoice = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableMultiLineInvoice]
  );
  const isEnableUpToGlassFinance = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableUpToGlassFinance]
  );
  const financeMethod =
    getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_FinanceMethod]
    ) ?? FINANCEMETHOD.None;
  if (isEnableMultiLineInvoice) {
    return "Invoice Item";
  }
  if (isFormTitle) {
    return "Fee";
  } else {
    return recordType !== RECORDTYPE.INFRINGEMENTS_InfringementTicket &&
      isEnableUpToGlassFinance &&
      financeMethod === FINANCEMETHOD.Direct
      ? "Fee History"
      : "Fees";
  }
};

export const stripHTML = (input: string): string => {
  return isNil(input) ? "" : input.replace(/<.*?>/g, "");
};

export const generateInvoiceDescription = (
  description: string | undefined | null,
  parentLit: string
): string => {
  let invoiceDescription = "";
  const parentLitStripHTML = stripHTML(parentLit) + " - ";
  if (!description) {
    invoiceDescription = parentLitStripHTML;
  } else if (description && !description.includes(parentLitStripHTML)) {
    invoiceDescription = parentLitStripHTML + description;
  }
  return invoiceDescription;
};
