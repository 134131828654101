import { isSuccessResponse } from "@common/apis/util";
import {
  ACCESSCONTROLENTITYTYPE,
  ACCESSRIGHTS,
} from "@common/constants/enumerations";
import { getShareReport } from "@common/pages/report/integrated-reports/component/dialogs/share-report/api";
import { PickUserGroupOrgButton } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/buttons/pick-user-group-org/_index";
import { IUsers } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/users-grid/model";
import { TYPE_GRID } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/config";
import { IDataUserGroupOrgForm } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/model";
import {
  colReportInfo,
  colShareReport,
} from "@common/pages/report/integrated-reports/component/dialogs/share-report/config";
import {
  AccessControl,
  IReportInfo,
} from "@common/pages/report/integrated-reports/component/dialogs/share-report/model";
import { nameOfFactory } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCNote } from "@components/cc-note/_index";
import { OrgUnit } from "@components/cc-pick-org-units/model";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { CCSwitch } from "@components/cc-switch/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { isNil, uniqBy } from "lodash";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IShareReportProps {
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  reportInfoFromGrid?: IReportInfo[];
}
const nameOf = nameOfFactory<IReportInfo>();
const nameOfReporters = nameOfFactory<AccessControl>();
export const ShareReportDialog = observer(
  ({ isLoading, onClose, onSubmit, reportInfoFromGrid }: IShareReportProps) => {
    const { pushNotification } = useCCAppNotificationStore();
    const [isLoadingShared, setIsLoadingShared] = useState<boolean>(false);

    const onChangeRef = useRef<any>();
    const { gridSelectedIds, gridSelectedRows } = useCCProductListViewStore();
    const getSharedReport = async () => {
      setIsLoadingShared(true);
      const response = await getShareReport(gridSelectedIds?.[0]);
      if (isSuccessResponse(response) && response.data) {
        setIsLoadingShared(false);
        if (onChangeRef?.current) {
          onChangeRef.current("ReportViewers", {
            value: response.data?.Readers ?? [],
          });
          onChangeRef.current("ReportWriters", {
            value: response.data?.Writers ?? [],
          });
        }
      } else {
        setIsLoadingShared(false);
        pushNotification({
          title: "Load shared report failed",
          type: "error",
        });
      }
    };
    useEffectOnce(() => {
      if (gridSelectedRows?.length === 1) {
        getSharedReport();
      }
    });
    return (
      <Form
        initialValues={{ ReplaceExisting: false }}
        onSubmitClick={onSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { onChange, valueGetter } = formRenderProps;
          onChangeRef.current = onChange;

          /**
           * Handle Add User, Groups, Org Structure
           * @param data IDataUserGroupOrgForm
           * @param field using check TYPE_GRID
           */
          const handleAdd = (data: IDataUserGroupOrgForm, field: string) => {
            let reportItem: AccessControl[] = valueGetter(field) ?? [];
            if (data) {
              if (data?.Users?.length) {
                //Get list User from Dialog
                const users = [...data.Users];
                //Loop through list User and process data with DTO AccessControl
                users.forEach((item: IUsers, index: number) => {
                  reportItem.push({
                    Contact_DisplayName: item?.Name,
                    AccessRights_ENUM:
                      field === TYPE_GRID.REPORT_WRITERS
                        ? ACCESSRIGHTS.CanWrite
                        : ACCESSRIGHTS.CanRead,
                    EntityType_ENUM: ACCESSCONTROLENTITYTYPE.SiteUser,
                    OrgUnit_ID: null,
                    Contact_ID: item?.ID,
                    ACID: new Date().getUTCMilliseconds() * 100000 + index,
                  });
                });

                const cloneUser = [...reportItem] as AccessControl[];
                //Remove duplicate item in list User and list Parent
                const listAfterFiltering = uniqBy(cloneUser, (e) => {
                  if (!isNil(e?.Contact_ID)) return e.Contact_ID;
                  return e.ACID;
                });
                reportItem = listAfterFiltering;
              }

              const dataGroup = data?.Groups;
              const dataOrgStructure = data?.OrgStructure;
              //Data Group and OrgStructure the same will be processed once time
              if (dataGroup?.length || dataOrgStructure?.length) {
                let dataOrg: any = [];
                if (dataGroup?.length && dataOrgStructure?.length) {
                  dataOrg = [...dataGroup, ...dataOrgStructure];
                } else if (!dataGroup?.length && dataOrgStructure?.length) {
                  dataOrg = [...dataOrgStructure];
                } else if (dataGroup?.length && !dataOrgStructure?.length) {
                  dataOrg = [...dataGroup];
                }
                //Loop through list User and process data with DTO AccessControl
                dataOrg?.forEach((item: OrgUnit, index: number) => {
                  reportItem.push({
                    Contact_DisplayName: item?.Hierarchy,
                    AccessRights_ENUM:
                      field === TYPE_GRID.REPORT_WRITERS
                        ? ACCESSRIGHTS.CanWrite
                        : ACCESSRIGHTS.CanRead,
                    EntityType_ENUM: ACCESSCONTROLENTITYTYPE.OrgUnit,
                    OrgUnit_ID: item?.ID,
                    Contact_ID: null,
                    ACID: new Date().getUTCMilliseconds() * 100000 + index,
                  });
                });
                const cloneOrg = [...reportItem] as AccessControl[];

                //Remove duplicate item in list User and list Parent
                const listAfterFiltering = uniqBy(cloneOrg, (e) => {
                  if (!isNil(e?.OrgUnit_ID)) return e.OrgUnit_ID;
                  return e.ACID;
                });
                reportItem = listAfterFiltering;
              }

              //On change data file to form
              onChange(field, {
                value: [...reportItem],
              });
            }
          };
          const handleRemoveViewers = (field: string, selected: string) => {
            const selectedItem = valueGetter(selected) ?? [];
            if (selectedItem && selectedItem.length > 0) {
              const reportViewers = valueGetter(field) ?? [];
              selectedItem.forEach((item: any) => {
                const index = reportViewers.findIndex(
                  (viewer: AccessControl) =>
                    viewer.Contact_ID === item.Contact_ID
                );
                if (index > -1) {
                  reportViewers.splice(index, 1);
                }
              });
              onChange(field, {
                value: [...reportViewers],
              });
              onChange(selected, {
                value: [],
              });
            }
          };
          return (
            <FormElement>
              <CCDialog
                height="auto"
                maxWidth="45%"
                titleHeader={`Share ${pluralize(
                  "Report",
                  gridSelectedRows?.length
                )}`}
                onClose={onClose}
                disabled={isLoading}
                bodyElement={
                  <>
                    <CCAppNotification />
                    <div className="cc-form">
                      <div className="cc-field-group">
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <label className="cc-label">
                              Selected report(s)
                            </label>
                            <CCGrid
                              data={reportInfoFromGrid}
                              columnFields={colReportInfo}
                              primaryField={nameOf("ReportName")}
                            />
                          </div>
                        </div>
                        {reportInfoFromGrid &&
                          reportInfoFromGrid?.length > 1 && (
                            <div className="cc-form-cols-2">
                              <div className="cc-field">
                                <label className="cc-label">
                                  Replace existing security
                                </label>
                                <Field
                                  name={"ReplaceExisting"}
                                  component={CCSwitch}
                                  checked={valueGetter("ReplaceExisting")}
                                />
                              </div>
                              <div className="cc-field">
                                <CCNote
                                  message={
                                    "Warning: If Yes is selected, all existing Viewers and Writers on the selected reports will be overwritten."
                                  }
                                />
                              </div>
                            </div>
                          )}
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <label className="cc-label">
                              Report viewers
                              {valueGetter("ReportViewers") &&
                              valueGetter("ReportViewers")?.length > 0
                                ? " (" +
                                  valueGetter("ReportViewers")?.length +
                                  ")"
                                : ""}
                            </label>
                            <CCGrid
                              data={valueGetter("ReportViewers")}
                              selectableMode={"multiple"}
                              columnFields={colShareReport}
                              isLoading={isLoadingShared}
                              selectedRows={valueGetter(
                                "SelectedReportViewers"
                              )}
                              primaryField={nameOfReporters("ACID")}
                              onSelectionChange={(dataItem: any[]) => {
                                if (dataItem) {
                                  onChange("SelectedReportViewers", {
                                    value: [...dataItem],
                                  });
                                }
                              }}
                              toolbar={
                                <>
                                  <div className="cc-grid-tools-bar">
                                    <PickUserGroupOrgButton
                                      onSubmit={(
                                        dataItem: IDataUserGroupOrgForm
                                      ) => {
                                        if (dataItem) {
                                          handleAdd(
                                            dataItem,
                                            TYPE_GRID.REPORT_VIEWERS
                                          );
                                        }
                                      }}
                                    />
                                    <Button
                                      type="button"
                                      iconClass="fas fa-minus"
                                      disabled={
                                        valueGetter("SelectedReportViewers")
                                          ? valueGetter("SelectedReportViewers")
                                              ?.length === 0
                                          : true
                                      }
                                      onClick={() =>
                                        handleRemoveViewers(
                                          "ReportViewers",
                                          "SelectedReportViewers"
                                        )
                                      }
                                    />
                                  </div>
                                </>
                              }
                            />
                          </div>
                        </div>
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <label className="cc-label">
                              Report writers
                              {valueGetter("ReportWriters") &&
                              valueGetter("ReportWriters")?.length > 0
                                ? " (" +
                                  valueGetter("ReportWriters")?.length +
                                  ")"
                                : ""}
                            </label>
                            <CCGrid
                              data={valueGetter("ReportWriters")}
                              selectableMode={"multiple"}
                              columnFields={colShareReport}
                              isLoading={isLoadingShared}
                              selectedRows={valueGetter(
                                "SelectedReportWriters"
                              )}
                              primaryField={nameOfReporters("ACID")}
                              onSelectionChange={(dataItem: any[]) => {
                                if (dataItem) {
                                  onChange("SelectedReportWriters", {
                                    value: [...dataItem],
                                  });
                                }
                              }}
                              toolbar={
                                <>
                                  <div className="cc-grid-tools-bar">
                                    <PickUserGroupOrgButton
                                      onSubmit={(
                                        dataItem: IDataUserGroupOrgForm
                                      ) => {
                                        if (dataItem) {
                                          handleAdd(
                                            dataItem,
                                            TYPE_GRID.REPORT_WRITERS
                                          );
                                        }
                                      }}
                                    />
                                    <Button
                                      type="button"
                                      iconClass="fas fa-minus"
                                      disabled={
                                        valueGetter("SelectedReportWriters")
                                          ? valueGetter("SelectedReportWriters")
                                              ?.length === 0
                                          : true
                                      }
                                      onClick={() =>
                                        handleRemoveViewers(
                                          "ReportWriters",
                                          "SelectedReportWriters"
                                        )
                                      }
                                    />
                                  </div>
                                </>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button className={"cc-dialog-button"} onClick={onClose}>
                      Cancel
                    </Button>

                    <Button
                      className={"cc-dialog-button"}
                      onClick={onSubmit}
                      themeColor="primary"
                      type="submit"
                      iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                    >
                      Share
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
