import { FieldSelectionComboBox } from "@app/core/communication/dialogs/components/cells/field-selection/_index";
import { EmailPreview } from "@app/core/communication/dialogs/components/email-preview/_index";
import {
  getCommunicationTemplates,
  getFieldSelection,
} from "@app/core/communication/dialogs/components/form-elememts/message-template/api";
import { CCEditHTMLTool } from "@app/core/communication/dialogs/components/form-elememts/message-template/components/cc-edit-html-tool";
import { encryptionHtmlForEditor } from "@app/core/communication/dialogs/components/form-elememts/message-template/components/cc-edit-html-tool/util";
import { editorSubjectStyle } from "@app/core/communication/dialogs/components/form-elememts/message-template/config";
import {
  ICommunicationMethod,
  ICommunicationTemplate,
} from "@app/core/communication/dialogs/components/form-elememts/message-template/model";
import { validatorMessageTemplateStep } from "@app/core/communication/dialogs/components/form-elememts/message-template/util";
import { COMMUNICATION_TO_STEP } from "@app/core/communication/dialogs/components/form-elememts/to/_index";
import { MobilePreview } from "@app/core/communication/dialogs/components/mobile-preview/_index";
import { MAIL_MERGE_TEMPLATE_FORM_STEP } from "@app/core/mail-merge/form-steps/components/template/_index";
import { IResponseData } from "@common/models/service";
import { CommunicationTemplate } from "@common/pages/settings/communication/template/_id/model";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { ForeColorCustom } from "@components/cc-custom-editor/components/fore-color-custom/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Editor,
  EditorChangeEvent,
  EditorTools,
  EditorUtils,
  ProseMirror,
} from "@progress/kendo-react-editor";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { cloneDeep } from "lodash";
import { NodeType } from "prosemirror-model";
import React, { useLayoutEffect, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";
export const MESSAGE_TEMPLATE_STEP = "MessageTemplate";
const { Schema, EditorView, EditorState } = ProseMirror;

const nonEditable = {
  name: "nonEditable",
  inline: true,
  group: "inline",
  content: "inline+",
  marks: "",
  attrs: {
    contenteditable: { default: null },
    class: { default: null },
    style: { default: null },
  },
  atom: true,
  parseDOM: [{ tag: "span.uneditable", priority: 51 }],
  // The styles can be added via the class as well
  toDOM: () => [
    "span",
    {
      contenteditable: false,
      class: "uneditable",
      style: "user-select: none;",
    },
    0,
  ],
};
export const MessageTemplateStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={validatorMessageTemplateStep}
    />
  );
};

const FormStepElement = ({ nameOf, formRenderProps }: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const emailEditorRef = useRef<any>();
  const subjectEditorRef = useRef<any>();
  const SMSEditorRef = useRef<any>();
  const [isEmailFocus, setIsEmailFocus] = useState(false);
  const [isSMSFocus, setIsSMSFocus] = useState(false);
  const [isSubjectFocus, setIsSubjectFocus] = useState(false);
  const [textEditorColor, setTextEditorColor] = useState("#000000");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [communicationTemplates, setCommunicationTemplates] = useState<
    CommunicationTemplate[]
  >([]);
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  useEffectOnce(() => {
    let theme = localStorage.getItem("OOTheme");
    if (theme === "Dark") {
      setTextEditorColor("#ffffff");
    }
  });
  const editorStyles = `
  // * {
  //   color: ${textEditorColor};
  // }
`;
  useEffectOnce(() => {
    if (
      (valueGetter(`${COMMUNICATION_TO_STEP}.oSetting`)?.Dataset_Filters[0] &&
        !getFieldValue("CommunicationTemplates")) ||
      !getFieldValue("FieldSelection")
    ) {
      setIsLoading(true);
      getCommunicationTemplates(
        valueGetter(`${COMMUNICATION_TO_STEP}.oSetting`)?.Dataset_Filters[0]
      )
        .then((responseCommunicationTemplate: IResponseData) => {
          const dataShowInDialog = responseCommunicationTemplate?.data?.filter(
            (item: CommunicationTemplate) => item?.ShowInCommunicationDialog
          );
          onChange(nameOf("CommunicationTemplates"), {
            value: dataShowInDialog,
          });
          setCommunicationTemplates(dataShowInDialog);
          return setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          notificationRef.current?.pushNotification({
            autoClose: false,
            title: "Get message template failed.",
            type: "error",
          });
        });

      getFieldSelection(valueGetter(`${COMMUNICATION_TO_STEP}.oSetting`)).then(
        (data) => {
          const formattedFieldSelection: any = [];
          if (data.data?.length > 0) {
            const orderedFieldSelection = Object.keys(data?.data[0])
              .sort()
              .reduce((obj: any, key: any) => {
                obj[key] = data?.data[0][key];
                return obj;
              }, {});
            Object.entries(orderedFieldSelection).forEach(
              ([key, value], index) => {
                formattedFieldSelection.push({
                  text: key,
                  code: `[${key}]`,
                  value: value,
                  id: index,
                });
              }
            );
          }
          onChange(nameOf("FieldSelection"), {
            value: formattedFieldSelection,
          });
          onChange(nameOf("FieldSelectionFilter"), {
            value: formattedFieldSelection.slice(),
          });
        }
      );
    }
  });

  // return Editor ref's field base on last focus field
  const checkWhichEditorFocus = isEmailFocus
    ? getFieldValue("Key") === ICommunicationMethod.Email
      ? emailEditorRef.current
      : {}
    : isSubjectFocus
    ? subjectEditorRef.current
    : isSMSFocus
    ? getFieldValue("Key") === ICommunicationMethod.SMS
      ? SMSEditorRef.current
      : {}
    : {};
  const checkPreviewLeft = getFieldValue("Preview")
    ? "cc-message-template-left-preview"
    : "cc-message-template-left-un-preview";
  const checkPreviewRight = getFieldValue("Preview")
    ? "cc-message-template-right"
    : "cc-message-template-right-un-preview";
  const onMountEmail = (event: any) => {
    //event interface in the newer kendo version
    const iframeDocument = event.dom.ownerDocument;
    const style = iframeDocument.createElement("style");
    style.appendChild(iframeDocument.createTextNode(editorStyles));
    iframeDocument.head.appendChild(style);
    const { viewProps } = event;
    const { plugins, schema } = viewProps.state;
    let nodes = schema.spec.nodes.addToEnd("nonEditable", nonEditable);
    const mySchema = new Schema({ nodes: nodes, marks: schema.spec.marks });

    const doc = EditorUtils.createDocument(
      mySchema,
      getFieldValue("EmailContent")
    );
    return new EditorView(
      { mount: event.dom },
      {
        ...event.viewProps,
        state: EditorState.create({ doc, plugins }),
      }
    );
  };

  /// As new confirm from Derin, the subject is suppose to be plain text only

  const onMountSubject = (event: any) => {
    //event interface in the newer kendo version
    const iframeDocument = event.dom.ownerDocument;
    const style: Element = iframeDocument.createElement("style");
    style.appendChild(iframeDocument.createTextNode(editorSubjectStyle));
    iframeDocument.head.appendChild(style);
    const { viewProps } = event;
    const { plugins, schema } = viewProps.state;

    const cloneSchema = cloneDeep(schema);
    let nodes = cloneSchema.spec.nodes.addToEnd("nonEditable", nonEditable);

    ///modify the doc node to be accept only text not paragraph as default (block+)
    for (const nodeName in cloneSchema.nodes) {
      if (nodeName === "doc") {
        (cloneSchema.nodes[nodeName] as NodeType).spec.content = "text*";
      }
    }
    const mySchema = new Schema({
      nodes: nodes,
      marks: cloneSchema.spec.marks,
    });
    const doc = EditorUtils.createDocument(
      mySchema,
      getFieldValue("SubjectContent")
    );
    const newState = EditorState.create({ doc, plugins });
    return new EditorView(
      { mount: event.dom },
      {
        ...event.viewProps,
        state: newState,
        handleKeyDown: (view: typeof EditorView, event: KeyboardEvent) => {
          if (
            event.ctrlKey &&
            (event.key === "u" || event.key === "b" || event.key === "i")
          ) {
            return true;
          }
        },
      }
    );
  };

  const onMountSMS = (event: any) => {
    //event interface in the newer kendo version
    const { viewProps } = event;
    const { plugins, schema } = viewProps.state;
    let nodes = schema.spec.nodes.addToEnd("nonEditable", nonEditable);
    const mySchema = new Schema({ nodes: nodes, marks: schema.spec.marks });
    const doc = EditorUtils.createDocument(
      mySchema,
      getFieldValue("SMSContent")
    );
    return new EditorView(
      { mount: event.dom },
      {
        ...event.viewProps,
        state: EditorState.create({ doc, plugins }),
      }
    );
  };
  const onChangeMessageTemplate = (e: ComboBoxChangeEvent) => {
    onChange(nameOf("MessageTemplateDropdown"), {
      value: e.target.value,
    });
    const selectedTemplate = (
      getFieldValue("CommunicationTemplates") ?? []
    ).find(
      (item: ICommunicationTemplate) =>
        item?.CommunicationTemplate_ID ===
        e.target?.value?.CommunicationTemplate_ID
    );

    const selectedMailMergeId = (
      getFieldValue("CommunicationTemplates") ?? []
    ).find(
      (item: ICommunicationTemplate) =>
        item?.MailMergeDocument_ID === e.target.value?.MailMergeDocument_ID
    );
    onChange(`${MAIL_MERGE_TEMPLATE_FORM_STEP}.SelectedTemplateMailMerge`, {
      value: selectedMailMergeId,
    });

    // set content for email
    if (selectedTemplate.EmailBody) {
      onChange(nameOf("EmailContent"), {
        value: selectedTemplate.EmailBody,
      });
    }

    if (emailEditorRef.current && selectedTemplate.EmailBody) {
      EditorUtils.setHtml(
        emailEditorRef.current.view,
        encryptionHtmlForEditor(selectedTemplate.EmailBody)
      );
    }

    // set content for SMS
    if (SMSEditorRef.current && selectedTemplate.SMSBody) {
      EditorUtils.setHtml(SMSEditorRef.current.view, selectedTemplate.SMSBody);
    }
    if (selectedTemplate.SMSBody) {
      onChange(nameOf("SMSContent"), {
        value: selectedTemplate.SMSBody,
      });
    }

    if (subjectEditorRef.current) {
      EditorUtils.setHtml(
        subjectEditorRef.current.view,
        selectedTemplate.Subject ?? ""
      );
    }
    onChange(nameOf("SubjectContent"), {
      value: selectedTemplate.Subject,
    });
  };

  useLayoutEffect(() => {
    const defaultCommunicationTemplate = valueGetter(
      "_defaultCommunicationTemplate"
    );

    const defaultCommunicationTemplateExist = communicationTemplates?.find(
      (item: CommunicationTemplate) =>
        item.CommunicationTemplate_ID ===
        defaultCommunicationTemplate?.CommunicationTemplate_ID
    );

    if (
      communicationTemplates &&
      communicationTemplates?.length > 0 &&
      defaultCommunicationTemplate &&
      defaultCommunicationTemplateExist
    ) {
      onChange(nameOf("MessageTemplateDropdown"), {
        value: defaultCommunicationTemplateExist,
      });

      // set content for email
      if (defaultCommunicationTemplateExist.EmailBody) {
        onChange(nameOf("EmailContent"), {
          value: defaultCommunicationTemplateExist.EmailBody,
        });
      }

      if (
        emailEditorRef.current &&
        defaultCommunicationTemplateExist.EmailBody
      ) {
        EditorUtils.setHtml(
          emailEditorRef.current.view,
          encryptionHtmlForEditor(defaultCommunicationTemplateExist.EmailBody)
        );
      }

      // set content for SMS
      if (defaultCommunicationTemplateExist.SMSBody) {
        onChange(nameOf("SMSContent"), {
          value: defaultCommunicationTemplateExist.SMSBody,
        });
      }

      if (SMSEditorRef.current && defaultCommunicationTemplateExist.SMSBody) {
        EditorUtils.setHtml(
          SMSEditorRef.current.view,
          defaultCommunicationTemplateExist.SMSBody
        );
      }

      // set subject
      onChange(nameOf("SubjectContent"), {
        value: defaultCommunicationTemplateExist.Subject,
      });

      if (subjectEditorRef.current) {
        EditorUtils.setHtml(
          subjectEditorRef.current.view,
          defaultCommunicationTemplateExist.Subject ?? ""
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communicationTemplates]);

  return (
    <section className="cc-field-group">
      <div className="cc-manage-communication-body">
        <div className="cc-form">
          <CCLocalNotification ref={notificationRef} />
          <div className="cc-message-template-container">
            <div className={`cc-message-template-left ${checkPreviewLeft}`}>
              <div className="cc-field-group">
                <div className="cc-form-cols-2">
                  <div className=" cc-checkbox-group-message">
                    <div className="cc-field">
                      <Field
                        component={Checkbox}
                        name={nameOf("IncludeMe")}
                        checked={getFieldValue("IncludeMe")}
                        label={"Include me"}
                      />
                    </div>
                    <div className="cc-field">
                      <Field
                        component={Checkbox}
                        name={nameOf("SendCopyToMeBCC")}
                        checked={getFieldValue("SendCopyToMeBCC")}
                        label={"BCC me"}
                      />
                    </div>
                    <div className="cc-field-preview cc-field">
                      <Field
                        component={Checkbox}
                        name={nameOf("Preview")}
                        checked={getFieldValue("Preview")}
                        label={"Preview"}
                      />
                    </div>
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Add field</label>
                    <FieldSelectionComboBox
                      editor={checkWhichEditorFocus}
                      editorUtils={EditorUtils}
                      formRenderProps={formRenderProps}
                      nameOf={nameOf}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-2">
                  <div className="cc-field">
                    <label className="cc-label">Message template</label>
                    <Field
                      name={nameOf("MessageTemplateDropdown")}
                      component={CCSearchComboBox}
                      isLoading={
                        !getFieldValue("CommunicationTemplates") || isLoading
                      }
                      data={getFieldValue("CommunicationTemplates") ?? []}
                      className="cc-form-control"
                      textField="CommunicationTemplate_Name"
                      dataItemKey="CommunicationTemplate_ID"
                      onChange={onChangeMessageTemplate}
                      disabled={isLoading}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Send From</label>
                    <Field
                      name={"SendFrom"}
                      component={CCSearchComboBox}
                      className="cc-form-control"
                      data={valueGetter("_senderEmails")}
                      textField="Value"
                      dataItemKey="Key"
                    />
                  </div>
                </div>
                <div className="cc-form-cols-1">
                  <div className="cc-field">
                    <label className="cc-label">
                      Subject
                      <CCTooltip type="validator" position="right" />
                    </label>

                    {/* / As new confirm from Derin, the subject is suppose to be plain text only, I will comment the subject editor but not remove it (in case of change requirement in the future) / */}

                    <Editor
                      className="cc-form-control cc-editor-subject"
                      onMount={onMountSubject}
                      contentStyle={{ height: "31px" }}
                      defaultContent={getFieldValue("SubjectContent")}
                      ref={subjectEditorRef}
                      onFocus={() => {
                        setIsEmailFocus(false);
                        setIsSubjectFocus(true);
                        setIsSMSFocus(false);
                      }}
                      onChange={(editorValue: any) => {
                        onChange(nameOf("SubjectContent"), {
                          value: editorValue.value.textContent,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-1">
                  <TabStrip
                    selected={getFieldValue("Key")}
                    onSelect={(event: TabStripSelectEventArguments) => {
                      onChange(nameOf("Key"), { value: event.selected });
                    }}
                  >
                    <TabStripTab title="Email">
                      <Editor
                        tools={[
                          [CCEditHTMLTool],
                          [Bold, Italic, Underline, ForeColorCustom],
                          [Undo, Redo],
                          [FontSize, FontName],
                          [Link, Unlink],
                          [AlignLeft, AlignCenter, AlignRight],
                          [OrderedList, UnorderedList, Indent, Outdent],
                        ]}
                        contentStyle={{
                          minHeight: "200px",
                          maxHeight: "500px",
                          height: "350px",
                        }}
                        className="cc-text-email"
                        defaultContent={getFieldValue("EmailContent")}
                        onMount={onMountEmail}
                        onFocus={() => {
                          setIsEmailFocus(true);
                          setIsSubjectFocus(false);
                          setIsSMSFocus(false);
                        }}
                        ref={emailEditorRef}
                        onChange={(editorValue: EditorChangeEvent) => {
                          onChange(nameOf("EmailContent"), {
                            value: editorValue.html,
                          });
                        }}
                      />
                    </TabStripTab>

                    <TabStripTab title="SMS">
                      <Editor
                        tools={[[Undo, Redo]]}
                        className="cc-form-control"
                        contentStyle={{
                          height: "350px",
                        }}
                        onMount={onMountSMS}
                        defaultContent={getFieldValue("SMSContent")}
                        ref={SMSEditorRef}
                        onFocus={() => {
                          setIsEmailFocus(false);
                          setIsSMSFocus(true);
                          setIsSubjectFocus(false);
                        }}
                        onChange={(editorValue: EditorChangeEvent) => {
                          onChange(nameOf("SMSContent"), {
                            value: editorValue.html,
                          });
                        }}
                      />
                    </TabStripTab>
                  </TabStrip>
                </div>
              </div>
            </div>
            <div className={`cc-message-template-right ${checkPreviewRight}`}>
              {getFieldValue("Preview") &&
                getFieldValue("Key") === ICommunicationMethod.SMS && (
                  <MobilePreview SMSContent={getFieldValue("SMSContent")} />
                )}
              {getFieldValue("Preview") &&
                getFieldValue("Key") === ICommunicationMethod.Email && (
                  <EmailPreview EmailContent={getFieldValue("EmailContent")} />
                )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const {
  Bold,
  Italic,
  Underline,
  AlignLeft,
  AlignRight,
  AlignCenter,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  Link,
  Unlink,
} = EditorTools;
