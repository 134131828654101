import { postComment } from "@app/products/hm/api";
import { AddCommentWithTitlePopup } from "@app/products/hm/components/action-bar/buttons/add-comment/components/_index";
import { IComment } from "@app/products/hm/components/action-bar/buttons/add-comment/model";
import { PremiseRegisterForm } from "@app/products/hm/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

interface IAddCommentButtonProps {
  premiseInfo?: PremiseRegisterForm;
  recordType: RECORDTYPE;
}

export const AddCommentButton = ({
  premiseInfo,
  recordType,
}: IAddCommentButtonProps) => {
  const [showAddCommentDialog, setShowAddCommentDialog] = useState(false);
  const handleAddComment = (dataItem: IComment) => {
    if (dataItem.Title && dataItem.Comment) {
      dataItem.ID = String(premiseInfo?.Premises_ID);
      postComment(dataItem);
    }
  };

  return (
    <>
      <CCNavButton
        title={"Comments"}
        onClick={() => {
          setShowAddCommentDialog(true);
        }}
      />
      {showAddCommentDialog && (
        <AddCommentWithTitlePopup
          onClosePopup={() => setShowAddCommentDialog(false)}
          onAddCommentWithTitlePopup={(arg: any) => handleAddComment(arg)}
        />
      )}
    </>
  );
};
