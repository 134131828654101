import { ICCRoute } from "@common/constants/ICCRoute";

export const systemAdminRoute: ICCRoute = {
  path: "system-admin",
  name: "System Admin",
  children: [
    {
      path: "premises-types",
      name: "Premises Types",
      component: require("./premises-types/_index").default,
    },
    {
      path: "classifications",
      name: "Classifications",
      component: require("./classifications/_index").default,
    },
    {
      path: "risks",
      name: "Risks",
      component: require("./risks/_index").default,
    },
    {
      path: "nc-items",
      name: "NC Items",
      component: require("./nc-items/_index").default,
    },
    {
      path: "insp-comments",
      name: "Insp Comments",
      component: require("./insp-comments/_index").default,
    },
    {
      path: "settings",
      name: "Settings",
      component: require("./settings/_index").default,
    },
  ],
};
