import { ICCRoute } from "@common/constants/ICCRoute";

export const recallsRoute: ICCRoute = {
  path: "recalls",
  name: "Recalls",
  children: [
    {
      path: "by-trading-name",
      name: "By Trading Name",
      component: require("./by-trading-name/_index").default,
    },
    {
      path: "by-month",
      name: "By Month",
      component: require("./by-month/_index").default,
    },
    {
      path: "by-number",
      name: "By Number",
      component: require("./by-number/_index").default,
    },
  ],
};
