import { ICCRoute } from "@common/constants/ICCRoute";

export const inspectionsRoute: ICCRoute = {
  path: "inspections",
  name: "Inspections",
  children: [
    {
      path: "by-date",
      name: "By Date",
      component: require("./by-date/_index").default,
    },
    {
      path: "by-type",
      name: "By Type",
      component: require("./by-type/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      component: require("./by-officer/_index").default,
    },
    {
      path: "by-month",
      name: "By Month",
      component: require("./by-month/_index").default,
    },
    {
      path: "by-area",
      name: "By Area",
      component: require("./by-area/_index").default,
    },
    {
      path: "assessments",
      name: "Assessments",
      component: require("./assessments/_index").default,
    },
    {
      path: "audits",
      name: "Audits",
      component: require("./audits/_index").default,
    },
    {
      path: "os-nc-items",
      name: "OS NC Items",
      component: require("./os-nc-items/_index").default,
    },
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
