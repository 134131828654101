import { GISReferenceAmalgamateAssessmentDialog } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/components/form-elements/assessment-details/components/dialogs/gis-reference-amalgamate-assessment/_index";
import { colGISReferenceAmalgamteAssessment } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/components/form-elements/assessment-details/config";
import { VO_Assessment_GIS } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/model";
import { useAmalgamateAssessmentStore } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/store";
import { assessmentGISValidator } from "@app/products/property/assessments/components/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import { nameOfLov } from "@app/products/property/model";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { DECIMAL_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { getDropdownValue, getUUID, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import "./_index.scss";

const nameOfGIS = nameOfFactory<VO_Assessment_GIS>();
export const AssessmentDetailsFormStep = (props: IFormStepElement) => {
  const formValidator = useCallback((value) => {
    return assessmentGISValidator(
      value?.Assessment_Gis_Interface ?? [],
      nameOfGIS("AG_Effective_From"),
      nameOfGIS("AG_Effective_To")
    );
  }, []);

  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? formValidator : undefined}
    />
  );
};

const FormStepElement = observer(
  ({
    nameOf,
    options = { isReadOnly: false, isLLS: false },
    formRenderProps,
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    //@Store
    const { lovsAmalgamateAssessment } = useAmalgamateAssessmentStore();

    const [showDialog, setShowDialog] = useState<boolean>();

    const gis = getFieldValue("Assessment_Gis_Interface") ?? [];
    const selectedGIS = getFieldValue("_option.GISSelected") ?? [];
    const lovAssessmentDetail = getFieldValue("lovAssessmentDetail");

    const statusLOVs =
      convertValueLOVToNumber(
        lovsAmalgamateAssessment?.Assessment_Status ?? [],
        "Code"
      ) ?? [];

    //Get labels
    const [assessmentNumberLabel, sectorLabel, categoryLabel] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.AssessmentNumber,
        ECustomColNameProperty.Sector,
        ECustomColNameProperty.Category,
      ]);
    /**
     * handle add item for (GIS Reference)
     * @param data
     */
    const handleAddItem = (data: VO_Assessment_GIS) => {
      //get previous data GIS
      const previousGIS = gis?.length ? [...gis] : [];
      //add more temporary ID for new record
      const newGIS = { ...data, Id: `new_${getUUID()}` };
      //merge previous data and new one
      const gisData = [...previousGIS, newGIS];
      onChange(nameOf("Assessment_Gis_Interface"), {
        value: gisData,
      });
    };

    /**
     * handle delete item (GIS Reference)
     */
    const handleDeleteItem = () => {
      const gisId = selectedGIS?.[0]?.Id;
      //filter new list GIS without selected record
      const newGIS = gis.filter((item: VO_Assessment_GIS) => item.Id !== gisId);
      onChange(nameOf("Assessment_Gis_Interface"), {
        value: newGIS,
      });
      onChange(nameOf("_option.GISSelected"), {
        value: [],
      });
    };

    const handleDataChangeGrid = (
      dataRow: VO_Assessment_GIS,
      fieldChange: string
    ) => {
      //GIS Reference
      const gisId = dataRow?.Id;
      let newGIS = [...gis];
      // update value Effective_To and Effective_From
      newGIS = newGIS.map((item: VO_Assessment_GIS) => {
        if (
          item.Id === gisId &&
          (fieldChange === nameOfGIS("AG_Effective_To") ||
            fieldChange === nameOfGIS("AG_Effective_From"))
        ) {
          return {
            ...item,
            AG_Effective_To: dataRow[nameOfGIS("AG_Effective_To")] ?? null,
            AG_Effective_From: dataRow[nameOfGIS("AG_Effective_From")] ?? null,
          };
        }
        return item;
      });
      onChange(nameOf("Assessment_Gis_Interface"), { value: newGIS });
    };

    return (
      // Default_Assessment_Group_Id => for the default value for dropdown list
      // Selected_Assessment_Group_Id => is the final value for user choose
      <section className="cc-field-group cc-assessment-detail-step">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title={assessmentNumberLabel} />
            <Field
              name={nameOf("Assessment_Number")}
              component={CCInput}
              readOnly={true}
              value={getFieldValue("Assessment_Number")}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Status" />
            <Field
              name={nameOf("Status")}
              component={CCInput}
              readOnly={true}
              value={
                getDropdownValue(
                  getFieldValue("Status_Id"),
                  statusLOVs ?? [],
                  "Code"
                )?.Name
              }
            />
          </div>
          {options?.isLLS && (
            <div className="cc-field">
              <CCLabel title="Holding name" isMandatory />
              <Field
                name={nameOf("AssessmentPropertyName")}
                component={CCInput}
                placeholder={"Holding name"}
                readOnly={options?.isReadOnly}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
              />
            </div>
          )}
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Type" />
            <Field
              name={nameOf("Type")}
              component={CCInput}
              readOnly={true}
              value={lovAssessmentDetail?.Assessment_Types?.[0].Name}
            />
          </div>
          <div className="cc-field">
            <CCLabel title={sectorLabel} />
            <Field
              name={nameOf("Default_Assessment_Group_Id")}
              component={CCSearchComboBox}
              disabled
              data={lovAssessmentDetail?.Assessment_Groups ?? []}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
            />
          </div>
          <div className="cc-field">
            <CCLabel title={categoryLabel} />
            <Field
              name={nameOf("Default_Differential_Rate_Classification_Id")}
              component={CCSearchComboBox}
              disabled
              data={
                lovAssessmentDetail?.Differential_Rate_Classifications ?? []
              }
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="LGA" />
            <Field
              name={nameOf("Default_LGA_Id")}
              component={CCSearchComboBox}
              disabled
              data={lovAssessmentDetail?.LGAs ?? []}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Primary land use" />
            <Field
              name={nameOf("Default_Primary_Land_Use_Id")}
              component={CCSearchComboBox}
              disabled={options?.isReadOnly}
              data={lovAssessmentDetail?.Primary_Land_Uses ?? []}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("Selected_Primary_Land_Use_Id"), {
                  value: event.value?.Code,
                });
                onChange(nameOf("Default_Primary_Land_Use_Id"), {
                  value: event.value?.Code,
                });
              }}
            />
          </div>

          <div className="cc-field">
            <CCLabel title="Secondary land use" />
            <Field
              name={nameOf("Default_Secondary_Land_Use_Id")}
              component={CCSearchComboBox}
              disabled={options?.isReadOnly}
              data={lovAssessmentDetail?.Secondary_Land_Uses ?? []}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("Selected_Secondary_Land_Use_Id"), {
                  value: event.value?.Code,
                });
                onChange(nameOf("Default_Secondary_Land_Use_Id"), {
                  value: event.value?.Code,
                });
              }}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <CCLabel title="Actual land area" />
              <Field
                name={nameOf("Actual_Land_Area")}
                placeholder={"Actual area"}
                component={CCInput}
                readOnly={true}
                value={getFieldValue("Actual_Land_Area")}
                format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Unit" />
              <Field
                name={nameOf("Actual_Land_Area_Unit_Name")}
                readOnly={true}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-field">
            <CCLabel title="SAP ID" />
            <Field
              name={nameOf("SAP_Id")}
              placeholder={"SAP ID"}
              component={CCInput}
              readOnly={
                options?.isReadOnly || !getFieldValue("OfficerIsAdministrator")
              }
            />
          </div>
        </div>
        <div className="cc-field">
          <CCLabel title="GIS references" errorMessage={errors?.[nameOf("")]} />
          <CCGrid
            readOnly={options?.isReadOnly}
            toolbar={
              !options?.isReadOnly ? (
                <div className="cc-grid-tools-bar">
                  <Button
                    type="button"
                    iconClass="fas fa-plus"
                    title="Add"
                    onClick={(event) => {
                      event.preventDefault();
                      setShowDialog(true);
                    }}
                  />
                  <Button
                    type="button"
                    iconClass="fas fa-minus"
                    title="Remove"
                    onClick={(event) => {
                      event.preventDefault();
                      handleDeleteItem();
                    }}
                    disabled={selectedGIS?.length !== 1}
                  />
                </div>
              ) : null
            }
            onDataRowChange={(
              dataRow: VO_Assessment_GIS,
              fieldChange: string
            ) => {
              handleDataChangeGrid(dataRow, fieldChange);
            }}
            editableMode={!options?.isReadOnly ? "cell" : undefined}
            className="cc-gis-reference-grid-data"
            data={gis ?? []}
            columnFields={colGISReferenceAmalgamteAssessment}
            primaryField={nameOfGIS("Id")}
            selectableMode="single"
            onSelectionChange={(dataItem: VO_Assessment_GIS[]) => {
              onChange(nameOf("_option.GISSelected"), {
                value: dataItem,
              });
            }}
            selectedRows={selectedGIS}
          />
        </div>
        <br />
        {showDialog && (
          <GISReferenceAmalgamateAssessmentDialog
            onClose={() => {
              setShowDialog(false);
            }}
            onSubmit={(data: VO_Assessment_GIS) => {
              const newData = {
                ...data,
                Assessment_Id: +data?.Id,
              };
              handleAddItem(newData);
              setShowDialog(false);
            }}
            gisReferenceData={gis ?? []}
            gisTypes={lovsAmalgamateAssessment?.GISType ?? []}
          />
        )}
      </section>
    );
  }
);
