import { nameOfLov } from "@app/products/property/model";
import {
  colFields,
  radio,
} from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/balance-transfer/config";
import { IAssessment } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/balance-transfer/dialogs/select-assessment/model";
import { SelectAssessmentDialog } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/balance-transfer/dialogs/select-assessment/_index";
import { mockDropdown } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/balance-transfer/mock";
import { getDropdownValue } from "@common/utils/common";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import {
  RadioButtonChangeEvent,
  RadioGroup,
} from "@progress/kendo-react-inputs";
import React, { useState } from "react";

export const BalanceTransferFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  formRenderProps,
  nameOf,
  options = {
    isReadOnly: false,
  },
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));

  const [selectedValue, setSelectedValue] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [assessment, setAssessments] = useState<IAssessment[]>([]);

  const onChangeRadio = (e: RadioButtonChangeEvent) => {
    setSelectedValue(e.value);
  };

  const onClose = () => setIsOpenDialog(false);

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field cc-col-span-2">
            <label className="cc-label">Pre-supplementary balance</label>
            <Field
              name={nameOf("PreSuppBalance")}
              component={CCCurrencyInput}
              placeholder="Pre-supplementary balance"
              disabled
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Supplementary</label>
            <Field
              name={nameOf("Supplementary")}
              component={CCCurrencyInput}
              placeholder="Supplementary"
              disabled
              rows={3}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Balance</label>
            <Field
              name={nameOf("Balance")}
              component={CCCurrencyInput}
              placeholder="Balance"
              disabled
            />
          </div>

          <div className="cc-col-span-2">
            <label className="cc-label">
              Is any remaining balance to be transeferred to associated
              assessments?
            </label>
            <Field
              name={nameOf("group1")}
              component={RadioGroup}
              data={radio}
              defaultValue={false}
              onChange={onChangeRadio}
              disabled={options?.isReadOnly}
            />
          </div>

          <div className="cc-field cc-col-span-2">
            <Field
              name={nameOf("Dropdown")}
              component={CCSearchComboBox}
              data={mockDropdown}
              disabled={!selectedValue || options?.isReadOnly}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              value={getDropdownValue(
                getFieldValue(nameOf("Dropdown")),
                mockDropdown,
                nameOfLov("Code")
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("Dropdown"), {
                  value: event.target.value?.Code ?? null,
                });
              }}
            />
          </div>

          {isOpenDialog && (
            <SelectAssessmentDialog
              onClose={onClose}
              setAssessments={setAssessments}
            />
          )}

          {selectedValue && (
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">
                Assessments to receive the balance
              </label>
              <CCGrid
                data={assessment}
                columnFields={colFields}
                primaryField="AssessmentId"
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <Button
                      iconClass="fas fa-plus"
                      title="Add Levy"
                      onClick={() => {
                        setIsOpenDialog(true);
                      }}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                }
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
};
