import { PremiseFormElement } from "@app/products/hm/premises/[id]/components/child-screens/general/components/premise-form-element/_index";
import { usePremisesStore } from "@app/products/hm/premises/[id]/store";
import { premiseFormValidator } from "@app/products/hm/util";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const PremiseForm = observer(() => {
  const { premises, createPremises, updatePremises } = usePremisesStore();
  const isNew = useIsNew();
  const handleSubmitForm = (data: any) => {
    if (isNew) {
      createPremises(data);
    } else {
      updatePremises(data);
    }
  };

  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmitForm}
        initialValues={premises}
        validator={premiseFormValidator}
        render={(formRenderProps: FormRenderProps) => (
          <PremiseFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
