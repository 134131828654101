import {
  Condition,
  IParentConditionSection,
} from "@app/core/condition/[id]/model";
import {
  BubbleUpType,
  Svc_BubbleUpIdentifier,
} from "@app/core/inspections/[id]/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { TEMPORARY_FIELDS } from "@common/stores/flexible-form/constant";
import { flexibleFormStoreInstance } from "@common/stores/flexible-form/store";

//#region SET-GET GeneralForm ========/
type TCondition = Condition & {
  [TEMPORARY_FIELDS]?: {
    parentState?: IParentConditionSection;
  };
};
export const changeConditionGeneralForm = (nameOfField: Partial<TCondition>) =>
  flexibleFormStoreInstance.updateFormFields("GeneralForm", nameOfField);

export const getConditionGeneralForm = (
  nameOfField?: keyof TCondition | Array<keyof TCondition>
) => flexibleFormStoreInstance.getFormFields("GeneralForm", nameOfField);
//#endregion SET-GET GeneralForm =====/

export const renderCoreConditionBubbleUps = (
  parentId: number,
  parentType: RECORDTYPE,
  ancestorId?: number,
  ancestorType?: RECORDTYPE
): Svc_BubbleUpIdentifier[] => {
  return [
    {
      PKID: 0,
      SourceIdentifier: {
        _RecordSource_ID: ancestorId ?? 0,
        _RecordSourceType_ENUM: ancestorType ?? RECORDTYPE.SystemInitialise,
        _RecordSource_ColumnName: "",
        _RecordSource_StringID: "",
        _RecordSourceType_Name: "",
        _RecordSource_FileNumber: "",
        _RecordSource_Description: "",
      },
      BubbleUpType_ENUM: BubbleUpType.Ancestor,
      BubbleUpType_Name: BubbleUpType[BubbleUpType.Ancestor],
      Sys_CreatedDate: new Date(),
      Sys_DeactivationDate: null,
      RecordSource_Description: "",
    },
    {
      PKID: 0,
      SourceIdentifier: {
        _RecordSource_ID: parentId,
        _RecordSourceType_ENUM: parentType,
        _RecordSource_ColumnName: "",
        _RecordSource_StringID: "",
        _RecordSourceType_Name: "",
        _RecordSource_FileNumber: "",
        _RecordSource_Description: "",
      },
      BubbleUpType_ENUM: BubbleUpType.Parent,
      BubbleUpType_Name: BubbleUpType[BubbleUpType.Parent],
      Sys_CreatedDate: new Date(),
      Sys_DeactivationDate: null,
      RecordSource_Description: "",
    },
  ];
};
