import { IEvent } from "@app/core/events/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IEvent>();
export const colEvents: IColumnFields[] = [
  {
    field: nameOf("RefNo"),
    title: "Reference Number",
    locked: true,
  },
  {
    field: nameOf("ActionOfficer"),
    title: "Action Officer",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("FileNo"),
    title: "File Number",
  },
  {
    field: nameOf("Recorded"),
    title: "Recorded",
  },
  {
    field: nameOf("Target"),
    title: "Target",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
];
