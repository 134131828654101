import { IdentityPacket } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/model";
import { Contact_PickContactFilters } from "@app/products/crms/[id]/model";
import { mockCRMS } from "@app/products/crms/mock";
import {
  ICRMSServiceStandardRequest,
  ServiceStandard,
  ServiceStandardHandlerRequest,
  ServiceStandardHandlerResponse,
  ServiceStandardLOVs,
  ServiceStandardPickListPacket,
} from "@app/products/crms/service-standards/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IIdentityPacket } from "@common/models/identityPacket";
import { IResponseData } from "@common/models/service";
import { sleep } from "@common/utils/common";
import { appNotificationStore } from "@components/cc-app-notification/store";

export const getCRMSServiceStandardSummaryById = async (
  id: number,
  isClone?: boolean
): Promise<APIResponse<IIdentityPacket<ServiceStandard | undefined>>> => {
  try {
    return await CoreAPIService.getClient().get<
      IIdentityPacket<ServiceStandard>
    >(`api/crms/internal/servicestandard/${id}`, {
      params: {
        isClone: isClone ?? false,
      },
    });
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getCRMSServiceStandardRecords = async (
  id: number,
  recordType: number
) => {
  try {
    const response: IResponseData = JSON.parse(JSON.stringify(mockCRMS));
    return response.data;
  } catch (error) {
    appNotificationStore.pushNotification({
      title: "The CRMS service standard could not be loaded.",
      autoClose: false,
      type: "error",
    });
  }
};

export const postCRMSServiceStandard = async (
  serviceStandard: ServiceStandard
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "/api/crms/internal/servicestandard",
      serviceStandard
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putCRMSServiceStandard = async (
  requestBody: ICRMSServiceStandardRequest
) => {
  await sleep(2000);
};

export const getServiceStandardLovs = async (): Promise<
  APIResponse<ServiceStandardLOVs | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<ServiceStandardLOVs>(
      "/api/crms/internal/servicestandard-lovs"
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const serviceStandardHandler = async (
  params: ServiceStandardHandlerRequest
): Promise<
  APIResponse<IIdentityPacket<ServiceStandardHandlerResponse> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<ServiceStandardHandlerResponse>
    >(`/api/crms/internal/service-standard/form-event-handler`, params);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSetupPickActionOfficer = async (
  serviceStandard: ServiceStandard
): Promise<APIResponse<Contact_PickContactFilters | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<Contact_PickContactFilters>(
      "/api/crms/internal/service-standard/setup-pick-action-officer",
      serviceStandard
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getSetupPickServiceStandard = async (): Promise<
  APIResponse<ServiceStandardPickListPacket | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<ServiceStandardPickListPacket>(
      "api/crms/internal/service-standard/setup-pick-parent-service-standard"
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
