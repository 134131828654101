import { eventEmitter } from "@/App";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { ContactCentralNameDetailTabEventType } from "@app/products/property/contacts-central-names/[id]/components/reference-sidebar/detail/util";
import { useContactsCentralNamesStore } from "@app/products/property/contacts-central-names/[id]/store";
import { DTO_Workflow_ModifyEntity } from "@app/products/property/contacts-central-names/list/components/dialogs/update-contact/model";
import {
  ResponseMessageWorkflow,
  WorkflowTypes,
} from "@app/products/property/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

interface IUpdateContactManagePageButton {
  isDisabled?: boolean;
}
export const UpdateContactManagePageButton = observer(
  ({ isDisabled = false }: IUpdateContactManagePageButton) => {
    const { id } = useParams<{ id: string }>();
    const { setListDialog } = usePropertyWorkflowStore();
    const { reloadPage } = useContactsCentralNamesStore();

    return (
      <>
        <CCNavButton
          title="Modify contact"
          disabled={isDisabled}
          onClick={() => {
            setListDialog([
              {
                type: WorkflowTypes.Modify_Entity,
                data: {},
                props: {
                  handleSubmitDialog: (
                    _data: DTO_Workflow_ModifyEntity,
                    res: ResponseMessageWorkflow<DTO_Workflow_ModifyEntity>
                  ) => {
                    const notification = {
                      title:
                        res?.Notification ??
                        "Modify contact was updated successfully.",
                      type: "success",
                    } as IAppNotificationItemAddProps;
                    reloadPage(+id, notification);
                    eventEmitter.emit(
                      ContactCentralNameDetailTabEventType.RefreshData
                    );
                  },
                  isShowNotificationFinish: false,
                },
              },
            ]);
          }}
        />
      </>
    );
  }
);
