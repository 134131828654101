import { ICCRoute } from "@common/constants/ICCRoute";

export const fsrRoute: ICCRoute = {
  path: "fsr",
  name: "FSR",
  children: [
    {
      path: "log",
      name: "Log",
      component: require("./log/_index").default,
    },
    {
      path: "premises-report",
      name: "Premises Report",
      component: require("./premises-report/_index").default,
    },
    {
      path: "premises-types",
      name: "Premises Types",
      component: require("./premises-types/_index").default,
    },
    {
      path: "inspections-types",
      name: "Inspections Types",
      component: require("./inspections-types/_index").default,
    },
    {
      path: "notice-types",
      name: "Notice Types",
      component: require("./notice-types/_index").default,
    },
    {
      path: "complaint-types",
      name: "Complaint Types",
      component: require("./complaint-types/_index").default,
    },
    {
      path: "keywords",
      name: "Keywords",
      component: require("./keywords/_index").default,
    },
  ],
};
