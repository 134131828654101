import { InspectionRespone, PremiseResponse } from "@app/products/hm/model";
import { cloneDeep } from "lodash";

export const convertPremiseResponseToForm = (response: PremiseResponse) => {
  // @TODO: remove any later
  let premiseForm: any = cloneDeep(response);
  if (response.Proprietor && response.Proprietor.Contact) {
    premiseForm.ProprietorContactDisplayName =
      response.Proprietor.Contact.DisplayName;

    premiseForm.PrimaryContactContactDisplayName =
      response.PrimaryContact.Contact.DisplayName;
    premiseForm.PrimaryContactContactPreferredMethod_ENUM =
      response.PrimaryContact.Contact.DisplayName;
    premiseForm.PrimaryContactContactPreferredMethod_Name =
      response.PrimaryContact.Contact.DisplayName;
  }

  if (response.Proprietor) {
    premiseForm.SiteAddressStreetNo = response.SiteAddress.StreetNo;
    premiseForm.SiteAddressStreetName = response.SiteAddress.StreetName;
    premiseForm.SiteAddressSuburb = response.SiteAddress.Suburb;
    premiseForm.SiteAddressState = response.SiteAddress.State;
    premiseForm.SiteAddressPostcode = response.SiteAddress.Postcode;
  }
  premiseForm.DateInspected = response.Officer?.DateInspected;
  premiseForm.TimeStarted = response.Officer?.TimeStarted;
  premiseForm.TimeCompleted = response.Officer?.TimeCompleted;
  premiseForm.PostalAddressStreetNo = response.PostalAddress?.StreetNo;
  premiseForm.PostalAddressStreetName = response.PostalAddress?.StreetName;
  premiseForm.PostalAddressSuburb = response.PostalAddress?.Suburb;
  premiseForm.PostalAddressState = response.PostalAddress?.State;
  premiseForm.PostalAddressPostcode = response.PostalAddress?.Postcode;

  premiseForm.OfficerHealthArea_KWD = response.Officer?.HealthArea_KWD;
  premiseForm.OfficerDisplayName = response.Officer?.DisplayName;
  premiseForm.OfficerHealthArea_Name = response.Officer?.HealthArea_Name;

  premiseForm.PremisesTypePremisesType_ID =
    response.PremisesType.PremisesType_ID;
  premiseForm.PremisesTypeName = response.PremisesType.Name;
  return premiseForm;
};

export const convertInspectionResponseToForm = (
  response: InspectionRespone
) => {
  // @TODO: remove any later
  let inspectionForm: any = cloneDeep(response);

  inspectionForm.TradingName = `${response.parent?.TradingName} ${response.parent?.RegistrationNumber}`;
  inspectionForm.Address = `${response.parent?.SiteAddress.StreetNo} ${response.parent?.SiteAddress.StreetName}`;
  inspectionForm.Type = `${response.parent?.PremisesType.Name}`;
  inspectionForm.InspectionType = response.InspectionType;
  inspectionForm.OfficerDisplayName = response.Officer?.DisplayName;
  inspectionForm.DateInspected = response.DateInspected;
  inspectionForm.TimeStarted = response.TimeStarted;
  inspectionForm.TimeCompleted = response.TimeCompleted;
  inspectionForm.Reason = response.Reason;
  inspectionForm.ReferenceNumber = response.ReferenceNumber;
  inspectionForm.Compliance = response.Compliance;
  inspectionForm.AffectPlannedSchedule = response.AffectPlannedSchedule;
  inspectionForm.SecondaryOfficerDisplayName =
    response.SecondaryOfficer?.DisplayName;
  inspectionForm.FoodSafeScore = response.FoodSafeScore;
  inspectionForm.RelatedInspection = `${response.DateInspected} ${response.InspectionType}`;
  inspectionForm.Position = response.Position;
  return inspectionForm;
};

export const premiseFormValidator: any = (values: any): any => {
  if (Object.keys(values).length > 0) {
    return {};
  }
  return {};
};
