import {
  ICRMSKnowledgeBaseRequest,
  ICRMSKnowledgeBaseSummary,
} from "@app/products/crms/knowledge-base/[id]/model";
import { mockCRMS } from "@app/products/crms/mock";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { IResponseData } from "@common/models/service";
import { sleep } from "@common/utils/common";
import { appNotificationStore } from "@components/cc-app-notification/store";

export const getCRMSKnowledgeBaseSummaryById = async (
  id: number
): Promise<APIResponse<ICRMSKnowledgeBaseSummary | undefined>> => {
  await sleep(1000);
  const dataResponse = mockCRMS.find((record) => record.ID === id);
  if (dataResponse) {
    return {
      data: dataResponse as ICRMSKnowledgeBaseSummary,
      status: APIResponseStatus.SUCCESS,
      statusText: "Ok",
      headers: {},
      config: {},
    };
  } else {
    return {
      data: undefined,
      status: APIResponseStatus.NOT_FOUND,
      statusText: "Not Found",
      headers: {},
      config: {},
    };
  }
};
export const getCRMSKnowledgeBaseRecords = async (
  id: number,
  recordType: number
) => {
  try {
    const response: IResponseData = JSON.parse(JSON.stringify(mockCRMS));
    return response.data;
  } catch (error) {
    appNotificationStore.pushNotification({
      title: "The CRMS knowledge base could not be loaded.",
      autoClose: false,
      type: "error",
    });
  }
};
export const postCRMSKnowledgeBase = async (
  requestBody: ICRMSKnowledgeBaseRequest
) => {
  await sleep(2000);
};
export const putCRMSKnowledgeBase = async (
  requestBody: ICRMSKnowledgeBaseRequest
) => {
  await sleep(2000);
};
