import { eventEmitter } from "@/App";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { ServiceStandardAccordion } from "@app/products/crms/system-admin/categories/[id]/model";
import { deleteServiceStandard } from "@app/products/crms/system-admin/categories/[id]/subcategory/[id]/api";
import { useCRMSSytemAdminSubCategoryStore } from "@app/products/crms/system-admin/categories/[id]/subcategory/[id]/store";
import { colServiceStandardsAccordion } from "@app/products/crms/system-admin/categories/config";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

const nameOfServiceStandardAccordion =
  nameOfFactory<ServiceStandardAccordion>();

export const CrmsServiceStandardAccordion = observer(() => {
  const { isDisabled } = useCRMSSytemAdminSubCategoryStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { subCategoryId } = useParams<{ subCategoryId: string }>();

  const [isShowDialog, setIsShowDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [gridSelectedRows, setGridSelectedRows] = useState<
    ServiceStandardAccordion[]
  >([]);

  const handleOnDelete = async () => {
    setIsDeleting(true);

    if (!gridSelectedRows[0]) return;
    const response = await deleteServiceStandard(gridSelectedRows[0].ID);
    setIsDeleting(false);
    setIsShowDialog(false);

    if (isSuccessIdentityPacket(response)) {
      pushNotification({
        type: "success",
        title: "Service standard deleted successfully",
      });

      eventEmitter.emit(CCGridEventType.RefreshOData);

      setGridSelectedRows([]);
    } else {
      pushNotification({
        type: "error",
        title: "Delete service standard was failed.",
        description: response.data?.Errors ?? response.error,
      });
    }
  };
  return (
    <>
      {isShowDialog && (
        <ConfirmDelete
          onClose={() => setIsShowDialog(false)}
          handleSubmit={handleOnDelete}
          isDeleting={isDeleting}
          contentDelete={"Service standard"}
          header={"Confirm Deletion"}
        />
      )}

      <CCGrid
        dataUrl={`odata/crms/internal/systemadmin/GetServiceStandardAccordion(serviceStandardSubCategoryId=${subCategoryId})?$count=true&`}
        primaryField={nameOfServiceStandardAccordion("ID")}
        columnFields={colServiceStandardsAccordion}
        selectableMode="single"
        selectedRows={gridSelectedRows}
        onSelectionChange={(dataItem: ServiceStandardAccordion[]) => {
          setGridSelectedRows([...dataItem]);
        }}
        readOnly={isDisabled}
        toolbar={
          <div className="cc-grid-tools-bar">
            <Button
              type="button"
              iconClass="fas fa-minus"
              title="Remove"
              disabled={gridSelectedRows.length === 0}
              onClick={() => {
                setIsShowDialog(true);
              }}
            />
          </div>
        }
      />
    </>
  );
});
