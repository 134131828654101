import { PREMISES_MANAGE_ROUTE } from "@app/products/hm/premises/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewPremiseButton = () => {
  const history = useHistory();

  return (
    <React.Fragment>
      <CCNavButton
        title="New Premise"
        onClick={() => {
          history.push(`${PREMISES_MANAGE_ROUTE}/new`);
        }}
      />
    </React.Fragment>
  );
};
