import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import { EventView } from "@app/products/crms/all-events/by-refno/model";
import {
  IGenerateEventsColumnsBySettingProps,
  columnViewConfigLocation,
  iconColumnViewConfigAlert,
  iconColumnViewConfigCallReturn,
  iconColumnViewConfigUrgent,
} from "@app/products/crms/config";
import { DATE_FORMAT } from "@common/constants/common-format";
import { RECORDSSYSTEM } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { getNumberValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep } from "lodash";

const nameOf = nameOfFactory<EventView>();

export const colByAsset: IColumnFields[] = [
  {
    field: nameOf("RefNo"),
    title: "Reference Number",
    locked: true,
    linkTo: (dataItem: EventView) => `${CRMS_ROUTE}/${dataItem.ID}`,
  },
  ...iconColumnViewConfigAlert,
  ...iconColumnViewConfigUrgent,
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("Flag_AssetHasAsbestos"),
    title: "Has asbestos",
  },
  {
    field: nameOf("Flag_AssetIsLeased"),
    title: "Leased",
  },
  {
    field: nameOf("DateTarget"),
    title: "Target",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Source"),
    title: "Source",
  },
  {
    field: nameOf("Category"),
    title: "Category",
  },
  columnViewConfigLocation,
  ...iconColumnViewConfigCallReturn,
  {
    field: nameOf("RequestedBy"),
    title: "Contact",
  },
  {
    field: nameOf("ContactDetails"),
    title: "Contact Number",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("DueStatus"),
    title: "Due Status",
  },
  {
    field: nameOf("DateRecorded"),
    title: "Recorded",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("RecordedBy"),
    title: "Recorded By",
  },
  {
    field: nameOf("OrgStructure"),
    title: "Org Structure",
  },
  {
    field: nameOf("ActionOfficer"),
    title: "Action Officer",
  },
  {
    field: nameOf("Coordinator"),
    title: "Coordinator",
  },
  // Still to be reviewed - taskID: 3269
  // {
  //   field: nameOf("DateWork"),
  //   title: "Work Date",
  //   format: DATE_FORMAT.DATE,
  // },
  {
    field: nameOf("FileNumber"),
    title: "File Number",
  },
  // Still to be reviewed - taskID: 3269
  // {
  //   field: nameOf("EnquiryRefNo"),
  //   title: "Assets Reference Number",
  // },
  // {
  //   field: nameOf("Event_ReferenceNoAdditional"),
  //   title: "Additional Reference Number",
  // },
  {
    field: nameOf("AssetSiteName"),
    title: "Asset Site Name",
  },
];

export const generateUnresolvedEventsByAssetBySetting = ({
  settings,
  defaultConfig = [],
}: IGenerateEventsColumnsBySettingProps) => {
  if (!settings) return defaultConfig;

  const recordsSystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]
  );

  let newConfig: IColumnFields[] = cloneDeep(defaultConfig);
  return newConfig.filter((col) => {
    switch (col.field) {
      case nameOf("FileNumber"):
        if (recordsSystem === RECORDSSYSTEM.None) {
          return false;
        }
        return true;
      default:
        return true;
    }
  });
};
