import { HMFieldMapping } from "@app/products/hm/model";

export const colHMInspScheduleInspectionsDue = [
  {
    field: HMFieldMapping.TradingName,
    title: "Trading Name",
    locked: true,
    width: 150,
  },
  {
    field: HMFieldMapping.RegNo,
    title: "Registration Number",
    width: 150,
  },
  {
    field: HMFieldMapping.Proprietor,
    title: "Proprietor",
    width: 150,
  },
  {
    field: HMFieldMapping.Address,
    title: "Address",
    width: 150,
  },
  {
    field: HMFieldMapping.Suburb,
    title: "Suburb",
    width: 150,
  },
  {
    field: HMFieldMapping.LastInspection,
    title: "Last Inspection",
    width: 150,
  },
  {
    field: HMFieldMapping.NextInspection,
    title: "Next Inspection",
    width: 150,
  },
  {
    field: HMFieldMapping.LastCompliance,
    title: "Last Compliance",
    width: 150,
  },
  {
    field: HMFieldMapping.PremisesClassification,
    title: "Classification",
    width: 150,
  },
  {
    field: HMFieldMapping.PremisesType,
    title: "Type",
    width: 150,
  },
  {
    field: HMFieldMapping.Risk,
    title: "Risk",
    width: 150,
  },
  {
    field: HMFieldMapping.Officer,
    title: "Officer",
    width: 150,
  },
  {
    field: HMFieldMapping.FSP,
    title: "FSP Type",
    width: 150,
  },
  {
    field: HMFieldMapping.FSPContact,
    title: "FSS Contact",
    width: 150,
  },
  {
    field: HMFieldMapping.Area,
    title: "Area",
    width: 150,
  },
];
