import {
  IGroupSettingField,
  ILookupItem,
  ISettingManagerForm,
  SettingEditGroup,
  SettingField,
  SettingFolder,
  SettingsDataType,
} from "@app/products/property/system-admin/settings/model";
import { getDropdownValue } from "@common/utils/common";
import { FormRenderProps } from "@progress/kendo-react-form";
import { cloneDeep, isNil } from "lodash";

/**
 * Set the first group to expand default
 * @param resultData
 * @returns
 */
export const getIdFirstGroup = (resultData: IGroupSettingField[]): string => {
  if (!resultData) {
    return "";
  }
  const createCollapseState = resultData
    .filter((item: IGroupSettingField) => item.groupId !== -1)
    .map((item: IGroupSettingField) => {
      return item.groupId;
    });
  if (createCollapseState.length === 0) {
    return "";
  }
  return createCollapseState[0].toString();
};

/**
 * set state disable fields in one group
 * @param id
 * @returns
 */
export const setDisableField = (
  id: number,
  groupName: string,
  data: IGroupSettingField[]
): boolean => {
  if (!id || !data || !groupName) return false;
  const group: IGroupSettingField | undefined = data.find(
    (itemDisable: IGroupSettingField) => {
      if (isNoneGroup(id)) {
        return itemDisable.name === groupName;
      } else {
        return itemDisable.groupId === id;
      }
    }
  );
  if (!group) return false;
  return group.isEditing;
};

/**
 * Check None Group meaning it is editable by itself
 * @param groupId
 * @returns
 */
interface INoneGroup {
  groupId: number;
  isChild: boolean;
  isNew: boolean;
}
export const isNoneGroupEnabledButton = ({
  groupId = 1,
  isChild = false,
  isNew = false,
}: INoneGroup): boolean => groupId === -1 && !isChild && !isNew;

export const isNoneGroup = (groupId: number): boolean => groupId === -1;

/**
 * reset data a group
 * return origin data of a group
 * @param groupId
 * @param groupName
 * @param originData
 * @returns
 */
export const resetDataAGroup = (
  groupId: number,
  groupName: string,
  originData: IGroupSettingField[]
): IGroupSettingField | undefined => {
  if (!groupId || !originData || !groupName) return;
  let resetGroup: IGroupSettingField | undefined;
  resetGroup = getChangedGroup(groupId, groupName, originData);
  if (!resetGroup) return;
  // const resetDataListOption: SettingField[] | undefined =
  //   changeDataListOption(resetGroup);
  // if (!resetDataListOption) return;
  // resetGroup.settingFields = resetDataListOption;
  return resetGroup;
};

/**
 * Change data List option
 * return data is empty when clicking save or cancel button
 * Because data will load again when clicking edit button
 * @param group
 * @returns
 */
// const changeDataListOption = (group: IGroupSettingField): SettingField[] => {
//   if (!group) return [];
//   return group.settingFields.map((elementGroup: SettingField) => {
//     if (elementGroup.DataType === DATA_TYPE.LIST) elementGroup.Data = [];
//     return elementGroup;
//   });
// };

/**
 * reset data a group
 * return data of a group which are changed
 * @param groupId
 * @param groupName
 * @param data
 * @returns
 */
export const saveDataAGroup = (
  groupId: number,
  groupName: string,
  data: IGroupSettingField[]
): IGroupSettingField | undefined => {
  if (!groupId || !data || !groupName) return;
  let savedGroup: IGroupSettingField | undefined;
  savedGroup = getChangedGroup(groupId, groupName, data);
  if (!savedGroup) return;
  // const savedDataListOption: SettingField[] | undefined =
  //   changeDataListOption(savedGroup);
  // if (!savedDataListOption) return;
  // savedGroup.settingFields = savedDataListOption;
  return savedGroup;
};

/**
 * get Changed Group
 * Find a group which are change by user
 * @param groupId
 * @param groupName
 * @param data
 * @returns
 */
export const getChangedGroup = (
  groupId: number,
  groupName: string,
  data: IGroupSettingField[]
): IGroupSettingField | undefined => {
  if (!groupId || !data || !groupName) return;
  return data.find((itemData: IGroupSettingField) => {
    if (isNoneGroup(groupId)) {
      return itemData.name === groupName;
    } else {
      return itemData.groupId === groupId;
    }
  });
};

/**
 * Assign Changed Group
 * Set group are change into data
 * @param groupId
 * @param groupName
 * @param data
 * @param changedDataGroup
 * @returns
 */
export const assignChangedGroup = (
  groupId: number,
  groupName: string,
  data: IGroupSettingField[],
  changedDataGroup: IGroupSettingField
): IGroupSettingField[] | undefined => {
  if (!groupId || !groupName || !data || !changedDataGroup) return undefined;
  changedDataGroup.isEditing = false;
  return data.map((item: IGroupSettingField) => {
    if (isNoneGroup(groupId)) {
      return item.name === groupName ? changedDataGroup : item;
    } else {
      return item.groupId === groupId ? changedDataGroup : item;
    }
  });
};

/**
 * Get Initial Value for form
 * @param groupId
 * @returns
 */
export const getFormInitialValue = (
  groupId: number,
  groupName: string,
  data: IGroupSettingField[]
) => {
  if (!groupId || !groupName || !data) return;
  const result: any = {};
  data
    .find((itemInitialData: IGroupSettingField) => {
      if (isNoneGroup(groupId)) {
        return itemInitialData.name === groupName;
      } else {
        return itemInitialData.groupId === groupId;
      }
    })
    ?.settingFields.forEach((itemInitialField: SettingField) => {
      result[itemInitialField.FieldName] = itemInitialField.Value;
    });
  result.GroupId = groupId;
  return result;
};

export const getFormInitialValueNew = (data: SettingField[]) => {
  if (!data) return;
  const result: any = {};
  data.forEach((itemInitialField: SettingField) => {
    result[itemInitialField.FieldName] = null;
  });
  return result;
};

export const setNullValueNew = (data: SettingField[]) => {
  if (!data) return;
  data.forEach((itemField: SettingField) => {
    itemField.Value = null;
  });
  return data;
};

export const getFormInitialValueChildForm = (data: SettingField[]) => {
  if (!data) return;
  const result: any = {};
  data.forEach((itemInitialField: SettingField) => {
    result[itemInitialField.FieldName] = itemInitialField.Value;
  });
  return result;
};

export const dataFieldValidator = (
  data: any,
  name: string
): SettingField | undefined => {
  if (!data || !name) return;
  let settingFields: any = [];
  const newData = cloneDeep(data);
  if (Array.isArray(newData) && newData.length === 1) {
    settingFields = newData[0].settingFields;
  } else if (Array.isArray(newData) && newData.length > 1) {
    settingFields = newData.map((item: any) => item.settingFields);
    settingFields = [].concat.apply([], settingFields);
  } else return undefined;
  return settingFields.find((itemField: any) => itemField.FieldName === name);
};

/**
 * set State Edit Change
 * @param groupId
 * @param stateEdit
 * @param name
 * @param data
 * @returns
 */
export const setStateEditChange = (
  groupId: number,
  stateEdit: boolean,
  name: string,
  data: IGroupSettingField[]
): IGroupSettingField[] => {
  if (!groupId || !stateEdit || !name || !data) return [];
  return data.map((itemEditChange: IGroupSettingField) => {
    if (
      (isNoneGroup(groupId) && itemEditChange.name === name) ||
      (itemEditChange.groupId === groupId && !isNoneGroup(groupId))
    ) {
      itemEditChange.isEditing = stateEdit;
    }
    return itemEditChange;
  });
};

/**
 * assign Value On change
 * set value in field when user is typing
 * @param data
 * @param name
 * @param value
 * @param groupId
 * @returns
 */
export const assignValueOnchange = (
  data: IGroupSettingField[],
  name: string,
  value: any,
  groupId: number
): IGroupSettingField[] => {
  if (isNil(data) || isNil(name) || isNil(groupId)) return [];
  return data.map((group: IGroupSettingField) => {
    if (group.groupId === groupId) {
      group.settingFields.forEach((field: SettingField) => {
        if (field.FieldName === name) {
          if (field.DataType === SettingsDataType.Lookup) {
            field.Value = value?.Id;
          } else {
            field.Value = value;
          }
        }
      });
    }
    return group;
  });
};

/**
 * group Validator
 * return group which is not valid
 * @param groupId
 * @param data
 * @param fieldProps
 * @returns
 */
export const groupValidator = (
  groupId: number,
  data: IGroupSettingField[],
  fieldProps: any
): SettingField[] | undefined => {
  if (!groupId || !data || !fieldProps) return undefined;
  return data.find((itemDataList: IGroupSettingField) => {
    if (isNoneGroup(groupId)) {
      return itemDataList.name === fieldProps.name;
    } else {
      return itemDataList.groupId === groupId;
    }
  })?.settingFields;
};

/**
 * message Input Validator
 * return message if field is not correct else return undefined
 * @param getGroupSettingField
 * @param fieldProps
 * @param value
 * @returns
 */
export const messageInputValidator = (
  settingField: SettingField,
  value: any
): string | undefined => {
  if (!settingField) return;
  if (
    (settingField.IsMandatory && !value) ||
    (settingField.IsMandatory && value && value.length === 0)
  ) {
    return `${settingField.Title} required`;
  }
  if (
    settingField?.MaxLength &&
    value &&
    settingField.MaxLength < value.length
  ) {
    return `${settingField.Title} cannot exceed ${settingField.MaxLength}`;
  }
  return undefined;
};
/**
 * message Numeric Validator
 * return message if field is not correct else return undefined
 * @param getGroupSettingField
 * @param fieldProps
 * @param value
 * @returns
 */
export const messageNumericValidator = (
  settingField: SettingField,
  value: any
): string | undefined => {
  if (!settingField) return;
  if (settingField.IsMandatory && (value === null || value === undefined)) {
    return `${settingField.Title} required`;
  } else if (
    settingField.IsMandatory &&
    (value < 0 || typeof value !== "number")
  ) {
    return `${settingField.Title} is a positive number`;
  }
  return undefined;
};

/**
 * message Numeric Validator
 * return message if field is not correct else return undefined
 * @param getGroupSettingField
 * @param fieldProps
 * @param value
 * @returns
 */
export const messagePercentValidator = (
  settingField: SettingField,
  value: any
): string | undefined => {
  if (!settingField) return;
  if (settingField.IsMandatory) {
    if (value === null || value === undefined) {
      return `${settingField.Title} required`;
    } else if (!isNil(value) && (value < 0 || value > 100))
      return `${settingField.Title} must be greater than 0 and less than 100`;
  } else if (!isNil(value) && (value < 0 || value > 100))
    return `${settingField.Title} must be greater than 0 and less than 100`;
  return undefined;
};
/**
 * message DropDown Validator
 * return message if field is not correct else return undefined
 * @param getGroupSettingField
 * @param fieldProps
 * @param value
 * @returns
 */
export const messageDropDownValidator = (
  settingField: SettingField,
  value: ILookupItem
): string | undefined => {
  if (!settingField) return;
  if (
    (settingField.IsMandatory && (value === null || value === undefined)) ||
    (settingField.IsMandatory && value && Object.keys(value).length === 0) ||
    (settingField.IsMandatory &&
      value &&
      Object.keys(value).length !== 0 &&
      value.Id === null)
  )
    return `${settingField.Title} required`;
  return undefined;
};

export const messageCurrencyValidator = (
  settingField: SettingField,
  value: number
): string | undefined => {
  if (!settingField) return;
  if (settingField.IsMandatory && (value === null || value === undefined))
    return `${settingField.Title} required`;
  return undefined;
};

export const messageDateValidator = (
  settingField: SettingField,
  value: number
): string | undefined => {
  if (!settingField) return;
  if (settingField.IsMandatory && (value === null || value === undefined))
    return `${settingField.Title} required`;
  return undefined;
};

/**
 * message List Validator
 * return message if field is not correct else return undefined
 * @param getGroupSettingField
 * @param fieldProps
 * @param value
 * @returns
 */
export const messageListValidator = (
  settingField: SettingField,
  value: SettingFolder[]
): string | undefined => {
  let checkSelectedValue = value;
  if (value && Array.isArray(value))
    checkSelectedValue = value.filter(
      (item: SettingFolder) => item.IsSelected === true
    );
  else checkSelectedValue = [];
  if (!settingField) return;
  if (
    settingField.IsMandatory &&
    Array.isArray(checkSelectedValue) &&
    value.length === 0
  )
    return `${settingField.Title} required`;
  return undefined;
};

/**
 * filter None Group Fields
 * filtering to get fields is not belong any group
 * @param result
 * @returns
 */
export const filterNoneGroupFields = (
  result: SettingField[]
): IGroupSettingField[] => {
  if (!result) return [];
  const noneGroup = result.filter(
    (elementNoGroup: SettingField) =>
      elementNoGroup["EditGroupId"] === undefined
  );
  return noneGroup.map((item: any) => {
    return {
      groupId: -1,
      name: item.FieldName,
      settingFields: [item],
      isEditing: false,
    };
  });
};

/**
 * process Data After Saving
 * After saving function will process
 * return the default format when getting from API in the first
 * @param savedData
 * @param props
 */
export const processDataAfterSaving = (
  savedData: IGroupSettingField[],
  props: ISettingManagerForm,
  groupId: number,
  groupName: string
): SettingFolder | null => {
  const fieldsOfGroup: IGroupSettingField | undefined = savedData.find(
    (itemField: any) => {
      if (
        (itemField.groupId === -1 && itemField.name === groupName) ||
        itemField.groupId === groupId
      )
        return itemField;
      return undefined;
    }
  );
  if (!fieldsOfGroup) return null;
  const settingFields: SettingField[] = fieldsOfGroup.settingFields;
  const fields: SettingField[] | undefined = assignValueField(settingFields);
  if (!fields) return null;
  const originData: SettingFolder = props.data;
  if (!fieldsOfGroup || !originData) return null;
  return {
    Name: originData.Name,
    Title: originData.Title,
    ItemId: originData.ItemId,
    Mode: originData.Mode,
    Description: originData.Description,
    ProductType_Enum: originData.ProductType_Enum,
    SettingCategoryName: originData.SettingCategoryName,
    UseIsActive: originData.UseIsActive,
    IsEditable: originData.IsEditable,
    SettingFields: fields,
    EditGroups: originData.EditGroups,
    ParentFolders: originData.ParentFolders,
  };
};

export const processDataChildAfterSaving = (
  savedData: IGroupSettingField[],
  props: ISettingManagerForm
): SettingFolder | null => {
  const fieldsOfGroup = savedData.map((itemField: any) => {
    return itemField["settingFields"];
  });
  const mergedFields = [].concat.apply([], fieldsOfGroup);
  const settingFieldsUpdatedValueLookup: SettingField[] | undefined =
    assignValueField(mergedFields);
  let result: SettingFolder = props.data;
  if (!settingFieldsUpdatedValueLookup) return null;
  result.SettingFields = settingFieldsUpdatedValueLookup;

  return {
    Name: result?.Name,
    Title: result?.Title,
    ItemId: result?.ItemId,
    Mode: result?.Mode,
    Description: result?.Description,
    ProductType_Enum: result?.ProductType_Enum,
    SettingCategoryName: result?.SettingCategoryName,
    UseIsActive: result?.UseIsActive,
    IsEditable: result?.IsEditable,
    SettingFields: result?.SettingFields,
    EditGroups: result?.EditGroups,
    ParentFolders: result?.ParentFolders,
  };
};

/**
 * Change data List option
 * return data is empty when clicking save or cancel button
 * Because data will load again when clicking edit button
 * @param group
 * @returns
 */
const assignValueField = (
  listField: SettingField[]
): SettingField[] | undefined => {
  if (!listField) return;
  listField.forEach((elementField: SettingField) => {
    let value = elementField?.Value;
    switch (elementField.DataType) {
      case SettingsDataType.Lookup:
        if (value === null || value === undefined) elementField.Value = null;
        else if (typeof value === "object") elementField.Value = value?.Id;
        break;
      case SettingsDataType.Boolean:
        if (value === null || value === undefined) elementField.Value = false;
        break;
      case SettingsDataType.Decimal:
      case SettingsDataType.Int:
      case SettingsDataType.String:
        if (value === null || value === undefined) elementField.Value = null;
        break;
    }
  });
  return listField;
};

/**
 * process Data After Saving
 * After saving function will process
 * return the default format when getting from API in the first
 * @param savedData
 * @param props
 */
export const processDataAfterAdd = (
  savedData: IGroupSettingField[],
  props: ISettingManagerForm
): SettingFolder | null => {
  const fieldsOfGroup = savedData.map((itemField: any) => {
    return itemField["settingFields"];
  });

  const mergedFields = [].concat.apply([], fieldsOfGroup);
  const settingFieldsUpdatedValueLookup: SettingField[] | undefined =
    assignValueField(mergedFields);

  let result: SettingFolder = props.data;
  if (!settingFieldsUpdatedValueLookup) return null;
  result.SettingFields = settingFieldsUpdatedValueLookup;
  return {
    Name: result.Name,
    Title: result.Title,
    ItemId: result.ItemId,
    ProductType_Enum: result.ProductType_Enum,
    SettingCategoryName: result.SettingCategoryName,
    UseIsActive: result.UseIsActive,
    IsEditable: result.IsEditable,
    SettingFields: result.SettingFields,
  };
};

/**
 * process Data After Saving
 * After saving function will process
 * return the default format when getting from API in the first
 * @param savedData
 * @param props
 */
export const processDataChildAfterAdd = (
  savedData: IGroupSettingField[],
  props: ISettingManagerForm
): SettingFolder | null => {
  const fieldsOfGroup = savedData.map((itemField: any) => {
    return itemField["settingFields"];
  });

  const mergedFields = [].concat.apply([], fieldsOfGroup);
  let preID: any = props.lastID ?? 0;
  preID = parseInt(preID);
  let ItemId = -1;
  if (preID !== 0) ItemId = preID - 1;
  mergedFields.forEach((item: any) => {
    if (item.Title === "Id") {
      item.Value = ItemId + "";
    }
  });
  const settingFieldsUpdatedValueLookup: SettingField[] | undefined =
    assignValueField(mergedFields);

  let result: SettingFolder = props.data;

  if (!settingFieldsUpdatedValueLookup) return null;
  result.SettingFields = settingFieldsUpdatedValueLookup;
  return {
    Name: result.Name,
    Title: result.Title,
    ItemId: ItemId + "",
    IsNew: result?.IsNew,
    ProductType_Enum: result.ProductType_Enum,
    SettingCategoryName: result.SettingCategoryName,
    UseIsActive: result.UseIsActive,
    IsEditable: result.IsEditable,
    SettingFields: result.SettingFields,
  };
};

const createDefaultDropdownItem = (fieldName: string) => {
  return { Id: null, Name: `Select ${fieldName}...` };
};

export interface IPropsValueField {
  item: SettingField;
  groupId: number;
  formRenderProps: FormRenderProps;
  data: IGroupSettingField[];
  isNew?: boolean;
  isChild?: boolean;
  isLoading?: boolean;
}
/**
 * props Values Drop
 * return a object consist of values passing to generate field
 * @param item
 * @param groupId
 * @param formRenderProps
 * @param data
 * @returns
 */
export const propsValuesDrop = ({
  item,
  groupId,
  formRenderProps,
  data = [],
  isNew = false,
  isChild = false,
}: IPropsValueField) => {
  const valueSelected = getDropdownValue(
    item?.Value,
    item?.LookupItems ?? [],
    "Id"
  );
  const propsCheckDisabledField: ICheckDisabledField = {
    item: item,
    isNew: isNew,
    groupId: groupId,
    data: data,
    isChild: isChild,
  };
  return {
    name: item.FieldName,
    label: item.Title,
    data: item.LookupItems,
    textField: "Name",
    dataItemKey: "Id",
    value: valueSelected,
    disabled: checkDisabledField(propsCheckDisabledField),
    defaultItem: createDefaultDropdownItem(item.Title),
    isMandatory: item.IsMandatory,
    description: item.Description,
    isEnabledEditButton: isNoneGroupEnabledButton({ groupId, isChild, isNew }),
    formRenderProps: formRenderProps,
  };
};

export const propsValuesCurrency = ({
  item,
  groupId,
  formRenderProps,
  data = [],
  isNew = false,
  isChild = false,
}: IPropsValueField) => {
  let value = item.Value;
  if (value === null || value === undefined) value = null;

  const propsCheckDisabledField: ICheckDisabledField = {
    item: item,
    isNew: isNew,
    groupId: groupId,
    data: data,
    isChild: isChild,
  };
  return {
    maxDecimalPlaces: item.MaxDecimalPlaces,
    name: item.FieldName,
    label: item.Title,
    value: value,
    description: item.Description,
    disabled: checkDisabledField(propsCheckDisabledField),
    isMandatory: item.IsMandatory,
    isEnabledEditButton: isNoneGroupEnabledButton({ groupId, isChild, isNew }),
    formRenderProps: formRenderProps,
  };
};

/**
 * props Values Switch
 * return a object consist of values passing to generate field
 * @param item
 * @param groupId
 * @param formRenderProps
 * @param data
 * @returns
 */
export const propsValuesSwitch = ({
  item,
  groupId,
  formRenderProps,
  data = [],
  isNew = false,
  isChild = false,
}: IPropsValueField) => {
  const propsCheckDisabledField: ICheckDisabledField = {
    item: item,
    isNew: isNew,
    groupId: groupId,
    data: data,
    isChild: isChild,
  };
  return {
    name: item.FieldName,
    label: item.Title,
    checked: item.Value,
    disabled: checkDisabledField(propsCheckDisabledField),
    description: item.Description,
    isEnabledEditButton: isNoneGroupEnabledButton({ groupId, isChild, isNew }),
    formRenderProps: formRenderProps,
  };
};

/**
 * props Values Input
 * return a object consist of values passing to generate field
 * @param item
 * @param groupId
 * @param formRenderProps
 * @param data
 * @returns
 */
export const propsValuesInput = ({
  item,
  groupId,
  formRenderProps,
  data = [],
  isNew = false,
  isChild = false,
  isLoading = false,
}: IPropsValueField) => {
  const propsCheckDisabledField: ICheckDisabledField = {
    item: item,
    isNew: isNew,
    groupId: groupId,
    data: data,
    isChild: isChild,
  };

  return {
    name: item.FieldName,
    label: item.Title,
    value: item.Value,
    isMultiline: false,
    isLoading: isLoading,
    description: item.Description,
    disabled: checkDisabledField(propsCheckDisabledField),
    isMandatory: item.IsMandatory,
    isEnabledEditButton: isNoneGroupEnabledButton({ groupId, isChild, isNew }),
    formRenderProps: formRenderProps,
  };
};
interface ICheckDisabledField {
  item: SettingField;
  isNew?: boolean;
  groupId: number;
  data: IGroupSettingField[];
  isChild?: boolean;
}

const checkDisabledField = ({
  item,
  isNew = false,
  groupId,
  data,
  isChild,
}: ICheckDisabledField): boolean => {
  if (item.IsReadOnly) return item.IsReadOnly;
  else if (item.IsReadOnlyOnEdit && !isNew) return item.IsReadOnlyOnEdit;
  else if (isChild && !isNew) return isNew;
  else if (isNew) return !isNew;
  return !setDisableField(groupId, item.FieldName, data);
};

/**
 * props Values Numeric
 * return a object consist of values passing to generate field
 * @param item
 * @param groupId
 * @param formRenderProps
 * @param data
 * @returns
 */
export const propsValuesNumeric = ({
  item,
  groupId,
  formRenderProps,
  data = [],
  isNew = false,
  isChild = false,
  isLoading = false,
}: IPropsValueField) => {
  let value = item.Value;
  if (value === null || value === undefined) value = null;

  const propsCheckDisabledField: ICheckDisabledField = {
    item: item,
    isNew: isNew,
    groupId: groupId,
    data: data,
    isChild: isChild,
  };
  return {
    maxDecimalPlaces: item.MaxDecimalPlaces,
    name: item.FieldName,
    label: item.Title,
    value: value,
    isLoading: isLoading,
    description: item.Description,
    disabled: checkDisabledField(propsCheckDisabledField),
    isMandatory: item.IsMandatory,
    isEnabledEditButton: isNoneGroupEnabledButton({ groupId, isChild, isNew }),
    formRenderProps: formRenderProps,
    isRemoveComma: item.IsRemoveComma,
  };
};

/**
 * props Values Date
 * return a object consist of values passing to generate field
 * @param item
 * @param groupId
 * @param formRenderProps
 * @param data
 * @returns
 */
export const propsValuesDate = ({
  item,
  groupId,
  formRenderProps,
  data = [],
  isNew = false,
  isChild = false,
  isLoading = false,
}: IPropsValueField) => {
  let value = item.Value;
  if (value === null || value === undefined) value = null;
  else value = new Date(item.Value);

  const propsCheckDisabledField: ICheckDisabledField = {
    item: item,
    isNew: isNew,
    groupId: groupId,
    data: data,
    isChild: isChild,
  };
  return {
    name: item.FieldName,
    label: item.Title,
    value: value,
    isLoading: isLoading,
    description: item.Description,
    disabled: checkDisabledField(propsCheckDisabledField),
    isMandatory: item.IsMandatory,
    isEnabledEditButton: isNoneGroupEnabledButton({ groupId, isChild, isNew }),
    formRenderProps: formRenderProps,
  };
};
/**
 * props Values List
 * return a object consist of values passing to generate field
 * @param item
 * @param groupId
 * @param formRenderProps
 * @param data
 * @returns
 */
export const propsValuesList = ({
  item,
  groupId,
  formRenderProps,
  data = [],
  isNew = false,
  isChild = false,
  isLoading = false,
}: IPropsValueField) => {
  return {
    name: item.FieldName,
    label: item.Title,
    data: item.ListItems,
    isNew: isNew,
    isChild: isChild,
    isLoading: isLoading,
    isEditing: setDisableField(groupId, item.FieldName, data),
    description: item.Description,
    isMandatory: item.IsMandatory,
    isEnabledEditButton: isNoneGroupEnabledButton({ groupId, isChild, isNew }),
    formRenderProps: formRenderProps,
  };
};

const setValueNewFormNull = (data: SettingField[]): SettingField[] => {
  data.forEach((itemField: SettingField) => {
    if (!itemField.Value) itemField.Value = null;
  });
  return data;
};
/**
 * result Preprocess Data
 * concat fields have group and none group
 * return final result
 * @param data
 * @param editGroups
 * @returns
 */
export const resultPreprocessData = (
  data: SettingField[],
  editGroups: SettingEditGroup[],
  isNew?: boolean
): IGroupSettingField[] => {
  let result: any = null;
  result = sortFields(data);
  if (isNew) result = setValueNewFormNull(result);
  const noneGroupFields = filterNoneGroupFields(result);
  result = groupFieldByGroupId(editGroups, result);
  result = removeEmptySettingFieldGroup(result);
  result = result.concat(noneGroupFields);
  result = sortGroups(result);
  return result;
};

export const removeEmptySettingFieldGroup = (
  data: IGroupSettingField[]
): IGroupSettingField[] => {
  return data.filter(
    (group: IGroupSettingField) => group.settingFields.length !== 0
  );
};
/**
 * sortFields
 * sorting Fields is increasing by Display Order
 * @param data
 * @returns
 */
const sortFields = (data: SettingField[]): SettingField[] => {
  if (!data) return [];
  return data.sort((preData: SettingField, currData: SettingField) => {
    if (!isNil(currData?.DisplayOrder) && !isNil(preData?.DisplayOrder)) {
      if (preData.DisplayOrder > currData?.DisplayOrder) return 1;
      if (preData.DisplayOrder < currData?.DisplayOrder) return -1;
    }
    return 0;
  });
};

/**
 * groupFieldByGroupId
 * custom fields and manage by group Id and group Name
 * @param editGroups
 * @param result
 * @returns
 */
const groupFieldByGroupId = (
  editGroups: SettingEditGroup[],
  result: SettingField[]
): IGroupSettingField[] => {
  if (!editGroups || !result) return [];
  return editGroups.map((group: any) => {
    const fieldsGroup: SettingField[] = result.filter(
      (element: SettingField) => group.GroupId === element.EditGroupId
    );
    return {
      groupId: group.GroupId,
      name: group.Name,
      settingFields: fieldsGroup,
      isEditing: false,
    };
  });
};

/**
 * sortGroups
 * sorting group is increasing Display Order
 * @param result
 * @returns
 */
const sortGroups = (result: IGroupSettingField[]): IGroupSettingField[] => {
  if (!result) return [];
  return result.sort(
    (preData: IGroupSettingField, currData: IGroupSettingField) => {
      if (
        !isNil(preData.settingFields[0].DisplayOrder) &&
        !isNil(currData.settingFields[0].DisplayOrder)
      ) {
        if (
          preData.settingFields[0].DisplayOrder >
          currData.settingFields[0].DisplayOrder
        )
          return 1;
        if (
          preData.settingFields[0].DisplayOrder <
          currData.settingFields[0].DisplayOrder
        )
          return -1;
      }
      return 0;
    }
  );
};

export const isModifiedForm = (data: IGroupSettingField[]): boolean => {
  return data.some((item: IGroupSettingField) => item.isEditing === true);
};

export const handleConfirmDelete = (productData: any) => {
  if (!productData) return;
  const recordsIsDeleted = productData.map((record: SettingFolder) => {
    return {
      Name: record.Name,
      Title: record.Title,
      ItemId: record.ItemId,
      ProductType_Enum: record.ProductType_Enum,
      SettingCategoryName: record.SettingCategoryName,
    };
  });
  return {
    Name: recordsIsDeleted[0].Name,
    Title: recordsIsDeleted[0].Title,
    ProductType_Enum: recordsIsDeleted[0].ProductType_Enum,
    SettingCategoryName: recordsIsDeleted[0].SettingCategoryName,
    SettingsFolders: recordsIsDeleted,
  } as SettingFolder;
};
