import { ICCRoute } from "@common/constants/ICCRoute";

export const noticesRoute: ICCRoute = {
  path: "notices",
  name: "Notices",
  children: [
    {
      path: "by-date",
      name: "By Date",
      component: require("./by-date/_index").default,
    },
    {
      path: "by-due-status",
      name: "By Due Status",
      component: require("./by-due-status/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      component: require("./by-officer/_index").default,
    },
  ],
};
