import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { Comments } from "@app/core/comments/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { AddComplaintEvent } from "@app/core/complaints/_index";
import { AdditionalContactButton } from "@app/core/contacts/components/buttons/additional-contact/_index";
import { ContactAccordion } from "@app/core/contacts/components/contact-accordion/_index";
import { Documents } from "@app/core/documents/_index";
import { AddDocumentButton } from "@app/core/documents/components/buttons/add-document/_index";
import { AddFeeWithParentButton } from "@app/core/fees/fee-button/_index";
import Inspections from "@app/core/inspections/inspections-accordion/_index";
import { AddInspectionsWithParentButton } from "@app/core/inspections/inspections-button/_index";
import { InvoicesAccordion } from "@app/core/invoice/invoice-accordion/_index";
import { AddInvoiceWithParentButton } from "@app/core/invoice/invoice-button/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { RelatedAccordion } from "@app/core/related/related-accordion/_index";
import { ICoreRelatedParams } from "@app/core/related/related-accordion/model";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { FinanceTransactions } from "@app/core/transaction/finance-transaction-accordion/_index";
import { isVisibleFinanceTransaction } from "@app/core/transaction/finance-transaction-accordion/util";
import { AnimalsKennelForm } from "@app/products/animals/kennels/[id]/component/child-screens/general/_index";
import { KennelDeregisterButton } from "@app/products/animals/kennels/[id]/component/forms/components/deregister/_index";
import { KennelIssueRegistrationButton } from "@app/products/animals/kennels/[id]/component/forms/components/issue-registration/_index";
import { KennelReactivateButton } from "@app/products/animals/kennels/[id]/component/forms/components/reactivate/_index";
import { KennelRenewButton } from "@app/products/animals/kennels/[id]/component/forms/components/renew/_index";
import { AnimalsKennelContactsTab } from "@app/products/animals/kennels/[id]/component/reference-sidebar/contacts/_index";
import { AnimalsKennelDetailsTab } from "@app/products/animals/kennels/[id]/component/reference-sidebar/details/_index";
import { AnimalsKennelHistoryTab } from "@app/products/animals/kennels/[id]/component/reference-sidebar/history/_index";
import { useAnimalsKennelStore } from "@app/products/animals/kennels/[id]/store";
import { EventsAccordion } from "@app/products/crms/components/event-accordion/_index";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { checkCRMSProductionIsActive } from "@app/products/crms/util";
import { PPRActions } from "@app/products/town-planning/ppr/[id]/components/child-screens/actions/_index";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  COMPLAINTSMENU,
  FINANCEMETHOD,
  TypeSubmitActions,
} from "@common/constants/enumerations";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { splitCamelCase } from "@common/utils/formatting";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistManageKennel = observer(() => {
  const {
    animalsKennel,
    isLoading,
    responseLoadError,
    loadAnimalsKennelById,
    animalKennelId,
    onSubmit,
    initData,
    isDeleted,
  } = useAnimalsKennelStore();
  const { settings } = useCommonCoreStore();
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { id } = useParams<{ id: string }>();
  const { currentUserInfo, productsActive } = useGlobalStore();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Animals_Form_Kennel,
    productType: PRODUCT_TYPE_NUMBER.Animals,
  });

  const managePageUrl = window.location.pathname;
  const isEnableMultiLineInvoice = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableMultiLineInvoice]
  );
  const isEnableUpToGlassFinance = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableUpToGlassFinance]
  );
  const financeMethod =
    getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_FinanceMethod]
    ) ?? FINANCEMETHOD.None;

  const isShowFinanceTransactionAccordion = useMemo(() => {
    //Condition to show finance transaction:
    //1. isVisibleFinanceTransaction = true
    //2. Fee/Invoice is enabled (This Form is always enabled Invoice)
    return isVisibleFinanceTransaction(settings);
  }, [settings]);

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Animals Kennel - ${id ?? 0}`,
      Text: `${getDisplayTextWithDashes([
        animalsKennel?.KennelNo ? animalsKennel?.KennelNo : "No Number",
        animalsKennel?.Owner,
      ])}`,
      LinkUrl: managePageUrl,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Animals,
      RecordType_ENUM: RECORDTYPE.Animals_Kennel,
      Record_ID: parseInt(id) ?? 0,
    },
  ];

  const getTitle = useMemo(() => {
    return `${animalsKennel?.KennelNo ? animalsKennel?.KennelNo : "No Name"}`;
  }, [animalsKennel]);

  const isShowCUSTEventAccordion = useMemo(() => {
    const complaintsMenu = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_ComplaintsMenu]
    );
    const checkIsActiveCRMS = checkCRMSProductionIsActive(productsActive);
    return checkIsActiveCRMS && complaintsMenu === COMPLAINTSMENU.CRMS;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsActive]);

  const relatedAccordionParams = useMemo(() => {
    if (animalsKennel && animalKennelId) {
      const data: ICoreRelatedParams = {
        RecordType: RECORDTYPE.Animals_Kennel,
        RecordID: animalKennelId,
        InvoiceNumber: null,
        AssessmentNumberKey: null,
        AddressLine1: null,
        Suburb: null,
        PersonKey: null,
        WasteWaterSchemeKey: null,
        AncestorRecordType: null,
        AncestorRecordId: null,
      };

      if (initData?.Address) {
        data.AssessmentNumberKey = initData?.Address.AssessmentNo;
        data.AddressLine1 = initData?.Address.Formatted_AddressLine1;
        data.Suburb = initData?.Address.Suburb;
      }

      return data;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initData?.Address, animalsKennel, animalKennelId]);

  const listPanelBar: ITabProps[] = [
    {
      title: "Actions",
      tabType: TabTableType.Actions,
      component: (
        <PPRActions
          id={animalKennelId}
          recordType={RECORDTYPE.Animals_Kennel}
        />
      ),
    },
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      component: (
        <Comments id={animalKennelId} recordType={RECORDTYPE.Animals_Kennel} />
      ),
    },
    {
      title: "Contacts",
      tabType: TabTableType.Contacts,
      component: (
        <ContactAccordion
          id={animalKennelId}
          recordType={RECORDTYPE.Animals_Kennel}
        />
      ),
    },
    {
      title: "Documents",
      tabType: TabTableType.Documents,
      component: (
        <Documents id={animalKennelId} recordType={RECORDTYPE.Animals_Kennel} />
      ),
    },
    {
      title: "Transactions",
      tabType: TabTableType.FinanceTransaction,
      isVisible: isShowFinanceTransactionAccordion,
      component: (
        <FinanceTransactions
          id={animalKennelId}
          recordType={RECORDTYPE.Animals_Kennel}
        />
      ),
    },
    {
      title: "Invoices",
      tabType: TabTableType.Invoices,
      component: (
        <InvoicesAccordion
          id={animalKennelId}
          recordType={RECORDTYPE.Animals_Kennel}
        />
      ),
    },
    {
      title: "Inspections",
      tabType: TabTableType.Inspections,
      component: (
        <Inspections
          id={animalKennelId}
          recordType={RECORDTYPE.Animals_Kennel}
        />
      ),
    },
    {
      title: "Related",
      tabType: TabTableType.Related,
      component: <RelatedAccordion relatedParam={relatedAccordionParams} />,
    },
    {
      title: "Events",
      isVisible: isShowCUSTEventAccordion,
      tabType: TabTableType.Events,
      component: (
        <EventsAccordion
          id={animalKennelId}
          recordType={RECORDTYPE.Animals_Kennel}
        />
      ),
    },
  ];
  return (
    <>
      <Loading isLoading={isLoading || isLoadingPermission} isFullScreen />
      <FormNavigation title={"Kennel Registration"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadAnimalsKennelById(+id, false)}
        />
      ) : (
        <>
          <FormTitle
            title={getTitle}
            badge={splitCamelCase(animalsKennel?.Status_Name?.toString() || "")}
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <KennelIssueRegistrationButton />
                <KennelRenewButton />
                <KennelDeregisterButton />
                <KennelReactivateButton />
              </CCNavButton>,
              <CCNavButton
                title={"Save"}
                name={TypeSubmitActions.Save}
                onClick={onSubmit}
                disabled={
                  isDeleted ||
                  !checkPermissions([
                    FormAction.CORE_ALLOW_SAVE,
                    FormAction.CORE_ALLOW_EDIT,
                  ])
                }
              />,
              <CCNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={animalKennelId}
                  recordType={RECORDTYPE.Animals_Kennel}
                  disabled={
                    isDeleted ||
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ACTION)
                  }
                />
                <AdditionalContactButton
                  id={animalKennelId}
                  recordType={RECORDTYPE.Animals_Kennel}
                  disabled={
                    isDeleted ||
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_CONTACT)
                  }
                />
                <AddAttachmentButton
                  id={animalKennelId}
                  recordType={RECORDTYPE.Animals_Kennel}
                  disabled={
                    isDeleted ||
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ATTACHMENT)
                  }
                />
                <AddCommentButton
                  id={animalKennelId}
                  recordType={RECORDTYPE.Animals_Kennel}
                  isDisabled={
                    isDeleted ||
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMENT)
                  }
                />
                {/* TODO: enhance later */}
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.Animals}
                  recordType={RECORDTYPE.Animals_Kennel}
                  recordId={animalKennelId}
                  isDisabled={isDeleted}
                />
                <AddComplaintEvent
                  recordType={RECORDTYPE.Animals_Kennel}
                  parentId={animalKennelId}
                  disabled={
                    isDeleted ||
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMPLAINT)
                  }
                />
                <AddDocumentButton
                  dataSetFilter={MAILMERGEDATASET.Animals_Kennel}
                  recordType={RECORDTYPE.Animals_Kennel}
                  productType={PRODUCT_TYPE_NUMBER.Animals}
                  id={animalKennelId}
                  isDisabled={
                    isDeleted ||
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_DOCUMENT)
                  }
                />
                {isEnableMultiLineInvoice &&
                isEnableUpToGlassFinance &&
                financeMethod === FINANCEMETHOD.Direct ? (
                  <AddInvoiceWithParentButton
                    id={animalKennelId}
                    recordType={RECORDTYPE.Animals_Kennel}
                    bubbleUps={animalsKennel?.BubbleUp}
                    productType={PRODUCT_TYPE_NUMBER.Animals}
                    isDisabled={isDeleted}
                  />
                ) : (
                  <AddFeeWithParentButton
                    id={parseInt(id)}
                    recordType={RECORDTYPE.Animals_Kennel}
                    productType={PRODUCT_TYPE_NUMBER.Animals}
                    titleButton="Fee"
                    isDisabled={
                      isDeleted ||
                      !checkPermissions(FormAction.CORE_TOOLBAR_ADD_FEE)
                    }
                  />
                )}

                <AddInspectionsWithParentButton
                  id={animalKennelId}
                  recordType={RECORDTYPE.Animals_Kennel}
                  bubbleUps={animalsKennel?.BubbleUp}
                  productType={PRODUCT_TYPE_NUMBER.Animals}
                  isDisabled={
                    isDeleted ||
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_INSPECTION)
                  }
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {animalsKennel && <AnimalsKennelForm />}
                <TabTable
                  id={animalKennelId}
                  recordType={RECORDTYPE.Animals_Kennel}
                  initialPanels={listPanelBar}
                  options={{
                    invoiceNumber: relatedAccordionParams?.InvoiceNumber,
                    propertyAssessmentNoKey:
                      relatedAccordionParams?.AssessmentNumberKey,
                    addressLine1: relatedAccordionParams?.AddressLine1,
                    suburb: relatedAccordionParams?.Suburb,
                    personKey: relatedAccordionParams?.PersonKey,
                    schemeKey: relatedAccordionParams?.WasteWaterSchemeKey,
                    refNoKey: relatedAccordionParams?.CsmRefNoKey
                      ? relatedAccordionParams.CsmRefNoKey.toString()
                      : undefined,
                    ancestorRecordId: relatedAccordionParams?.AncestorRecordId,
                    ancestorRecordType:
                      relatedAccordionParams?.AncestorRecordType,
                  }}
                />
              </div>
            </div>

            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: (
                        <AnimalsKennelDetailsTab isTabDetail={false} />
                      ),
                    },
                    {
                      title: "Map",
                      component: (
                        <MapTab
                          address={animalsKennel?.Address?.Formatted_SingleLine}
                          zoom={18}
                        />
                      ),
                    },
                    {
                      title: "Contacts",
                      component: <AnimalsKennelContactsTab />,
                    },
                    {
                      title: "History",
                      component: <AnimalsKennelHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
