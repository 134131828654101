import { EntitledToRebateDetails } from "@app/products/property/assessments/[id]/components/child-screens/rebates/components/entitled-to-rebate/components/detail/_index";
import { colEntitledToRebate } from "@app/products/property/assessments/[id]/components/child-screens/rebates/components/entitled-to-rebate/config";
import { useEntitledToRebateStore } from "@app/products/property/assessments/[id]/components/child-screens/rebates/components/entitled-to-rebate/store";
import {
  DTO_AssessmentRebateEntitlement,
  DTO_Assessment_Rebates,
} from "@app/products/property/assessments/[id]/components/child-screens/rebates/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { Checkbox } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import "./_index.scss";
export interface IRebatesEntitledToRebate {
  assessmentId: number;
  handleHistoricalEntitlements: (event: any) => void;
  showHistoricalEntitlements: boolean;
  dataRebatesGrid?: DTO_Assessment_Rebates;
}
const nameOf = nameOfFactory<DTO_AssessmentRebateEntitlement>();
export const RebatesEntitledToRebate = observer(
  ({
    assessmentId,
    handleHistoricalEntitlements,
    showHistoricalEntitlements,
    dataRebatesGrid,
  }: IRebatesEntitledToRebate) => {
    const { setSelectedEntitledToRebate } = useEntitledToRebateStore();

    const dataEntitledToRebate = useMemo(() => {
      return (
        dataRebatesGrid?.EntitledToRebate?.map(
          (item: DTO_AssessmentRebateEntitlement) => ({
            ...item,
            assessmentId: assessmentId,
          })
        ) ?? []
      );
    }, [dataRebatesGrid?.EntitledToRebate, assessmentId]);

    return (
      <div className="cc-field cc-rebates-entitled-to-rebate">
        <label className="cc-label">Entitled to rebate</label>
        <div className="cc-grid-control-left">
          <Checkbox
            title="Show Historical Entitlements"
            label="Show historical entitlements"
            className="cc-checkbox"
            onChange={handleHistoricalEntitlements}
            checked={showHistoricalEntitlements}
          />
        </div>
        <CCGrid
          className="cc-rebates-grid"
          data={dataEntitledToRebate}
          columnFields={colEntitledToRebate}
          primaryField={nameOf("Id")}
          detail={EntitledToRebateDetails}
          selectableMode="multiple"
          onSelectionChange={(dataItem: DTO_AssessmentRebateEntitlement[]) => {
            setSelectedEntitledToRebate(dataItem ?? []);
          }}
        />
      </div>
    );
  }
);
