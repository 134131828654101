import { postBatchUpdateEvent } from "@app/products/crms/batch-update-event/api";
import { BatchUpdateEventFormElement } from "@app/products/crms/batch-update-event/components/forms/form-element/_index";
import {
  BatchUpdateEventLocationState,
  EventBatchUpdate,
} from "@app/products/crms/batch-update-event/model";
import { useCRMSBatchUpdateEventStore } from "@app/products/crms/batch-update-event/store";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { isSuccessResponse } from "@common/apis/util";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { IPermissionsWrapperProps } from "@common/hocs/permissions/model";
import { renderWithPermissionsWrapper } from "@common/hocs/permissions/render-with-permission-wrapper";
import { useGlobalStore } from "@common/stores/global/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const BatchUpdateEventComponent = observer(() => {
  const {
    setOnSubmit,
    onSubmit,
    resetStore,
    firstLoadPage,
    isLoading,
    setIsLoading,
    eventBatchUpdate,
  } = useCRMSBatchUpdateEventStore();
  const history = useHistory();
  const location = useLocation();
  const { pushNotification } = useCCAppNotificationStore();
  const { currentUserInfo } = useGlobalStore();

  const locationState = location.state as BatchUpdateEventLocationState;
  const gridSelectedIds = locationState?.gridSelectedIds;

  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current]);

  useEffectOnce(() => {
    if (!gridSelectedIds || (gridSelectedIds && gridSelectedIds.length < 1)) {
      pushNotification({
        autoClose: false,
        title: "Please select at least one event to batch update.",
        type: "error",
      });
    }
    firstLoadPage(currentUserInfo?.UserPKID);
    return () => {
      resetStore();
      window.history.replaceState({}, "");
    };
  });

  const handleOnSubmit = async (event: FormSubmitClickEvent) => {
    const { values, isValid } = event;

    if (
      !isValid ||
      !gridSelectedIds ||
      (gridSelectedIds && gridSelectedIds?.length < 1)
    )
      return;

    setIsLoading(true);
    const response = await postBatchUpdateEvent({
      ...values?.EventBatchUpdate,
      Coordinator_DisplayName: eventBatchUpdate?.Coordinator_DisplayName ?? "",
      ActionOfficer_DisplayName:
        eventBatchUpdate?.ActionOfficer_DisplayName ?? "",
    } as EventBatchUpdate);
    setIsLoading(false);

    if (isSuccessResponse(response)) {
      history.goBack();
    } else {
      pushNotification({
        autoClose: false,
        title: "Batch update event failed.",
        type: "error",
        description: response.data?.Errors ?? response.error,
      });
    }
  };

  const initialValue = useMemo(() => {
    return {
      EventBatchUpdate: { ...eventBatchUpdate, SelectedRows: gridSelectedIds },
    };
  }, [gridSelectedIds, eventBatchUpdate]);

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="CRMS" />
      <FormTitle title={"Batch update"} />
      <CCManagePageActionBar
        leftActions={[
          <CCNavButton title={"Update"} onClick={onSubmit} />,
          <CCNavButton title={"Cancel"} onClick={() => history.goBack()} />,
        ]}
      />

      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <div className="cc-manage-form-body">
            <div className="cc-form">
              <Form
                onSubmitClick={handleOnSubmit}
                initialValues={initialValue}
                render={(formRenderProps: FormRenderProps) => {
                  submitRef.current = formRenderProps.onSubmit;

                  return (
                    <CCGeneralPanel
                      isInvalid={!formRenderProps.valid}
                      component={
                        <BatchUpdateEventFormElement
                          formRenderProps={formRenderProps}
                        />
                      }
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

const renderProps: IPermissionsWrapperProps = {
  component: BatchUpdateEventComponent,
  permissions: { productType: PRODUCT_TYPE_NUMBER.CustomerService },
  useNewPermissionDataCreator: (props: IPermissionsWrapperProps) => {
    return {
      formIdentifier: FormIdentifier.CUSTOMERSERVICE_Form_Event,
      productType: props.permissions.productType,
      formAction: FormAction.CRMS_TOOLS_BATCHUPDATE,
    };
  },
};

const BatchUpdateEvent = renderWithPermissionsWrapper(renderProps);
export default BatchUpdateEvent;
