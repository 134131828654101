import { odataGetDebtorNumbers } from "@app/core/components/common/utils";
import { RECORDTYPE } from "@common/constants/recordtype";
import { colPickDebtorNumber } from "@common/input-pickers/debtor-number/config";
import { RefNumberUsage } from "@common/input-pickers/debtor-number/model";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";

export interface IPickDebtorNumberDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  selectableMode?: "single" | "multiple" | "none";
  recordId?: number;
  contactId?: number;
  recordType?: RECORDTYPE;
}

const nameOf = nameOfFactory<RefNumberUsage>();
export const PickDebtorNumberDialog = ({
  onClose,
  onSubmit,
  selectableMode,
  recordId,
  contactId,
  recordType,
}: IPickDebtorNumberDialogProps) => {
  const [gridSelectedRows, setGridSelectedRows] = useState<RefNumberUsage[]>(
    []
  );

  return (
    <CCDialog
      titleHeader={"Pick Debtor Number"}
      maxWidth="60%"
      maxHeight="80%"
      onClose={onClose}
      bodyElement={
        <CCGrid
          columnFields={colPickDebtorNumber}
          primaryField={nameOf("Record_Id")}
          selectableMode={selectableMode ?? "single"}
          dataUrl={odataGetDebtorNumbers(contactId, recordId, recordType)}
          onSelectionChange={(dataItems) => {
            setGridSelectedRows(dataItems);
          }}
          selectedRows={gridSelectedRows}
        />
      }
      footerElement={
        <div className="cc-dialog-footer-actions-right">
          <Button className="cc-dialog-button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="cc-dialog-button"
            themeColor="primary"
            disabled={gridSelectedRows.length === 0}
            onClick={() => onSubmit(gridSelectedRows)}
          >
            Select
          </Button>
        </div>
      }
    />
  );
};
