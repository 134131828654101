import { CRMSKnowledgeBaseForm } from "@app/products/crms/knowledge-base/[id]/components/forms/components/child-screens/general/_index";
import { useCRMSKnowledgeBaseStore } from "@app/products/crms/knowledge-base/[id]/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewCRMSKnowledgeBase = observer(() => {
  const { isLoading } = useCRMSKnowledgeBaseStore();
  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="CRMS Knowledge Base" />
      <FormTitle title={"Knowledge Base Page"} />
      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Workflow"}
            type="sub-workflow"
            className="cc-workflow-button"
          >
            <CCNavButton title={"Start review"} />
          </CCNavButton>,
          <CCNavButton title={"Send"} htmlFor={"btn-submit"} />,
          <CCNavButton title={"View"} />,
          <CCNavButton title={"Print"} />,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <div className="cc-manage-form-body">
            <CRMSKnowledgeBaseForm />
          </div>
        </div>
      </div>
    </>
  );
});
