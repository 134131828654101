import { ICRMSServiceStandard } from "@app/products/crms/components/crms-service-standards/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<ICRMSServiceStandard>();
export const colCrmsServiceStandard: IColumnFields[] = [
  {
    field: nameOf("Category"),
    title: "Category",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Precis"),
    title: "Precis",
  },
  {
    field: nameOf("Org2"),
    title: "Org2",
  },
  {
    field: nameOf("FileNo"),
    title: "File Number",
  },
  {
    field: nameOf("CRMSChoice"),
    title: "CRMS Choice",
  },
];
