import { ExistedManageNotice } from "@app/core/notices/[id]/components/forms/existed/_index";
import { NewManageNotice } from "@app/core/notices/[id]/components/forms/new/_index";
import { IParentNoticesSection } from "@app/core/notices/[id]/model";
import { useNoticesStore } from "@app/core/notices/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useGlobalStore } from "@common/stores/global/store";
import { parseInt } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

const ManageNotices = observer(() => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const { currentUserInfo } = useGlobalStore();
  const { state } = useLocation<{
    parent?: IParentNoticesSection;
  }>();
  const { loadNotices, resetStore, setParent, checkIsSuperAdmin } =
    useNoticesStore();

  useEffect(() => {
    if (currentUserInfo?.UserPKID)
      checkIsSuperAdmin(parseInt(currentUserInfo.UserPKID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserInfo?.UserPKID]);

  useEffect(() => {
    if (state?.parent) {
      setParent(state.parent);
    }
    loadNotices(isNew ? 0 : parseInt(id), isNew);
    return () => {
      resetStore();
      //Cleanup State parent
      window.history.replaceState({}, "");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isNew, state]);

  if (isNew) {
    return <NewManageNotice />;
  }

  return <ExistedManageNotice />;
});

export default ManageNotices;
