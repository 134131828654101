import {
  MAILMERGEDATASET,
  MAILMERGESAVEFORMAT,
  MailMerge_SelectMergeDocument,
} from "@app/core/new-mail-merge/dialogs/model";
import { RECORDTYPE } from "@common/constants/recordtype";

export const setUpMailMergeFromView = () => {
  let mailMerge = new MailMerge_SelectMergeDocument();

  //append settings
  mailMerge.AppendToRecordSource = true;
  mailMerge.AllowAppendOptionEditing = true;
  mailMerge.ShowAppendToRecordSourceOption = true;

  //collate settings
  mailMerge.Collate = true;
  mailMerge.AllowCollateOptionEdititng = true;
  mailMerge.ShowCollateOption = true;

  //batch print settings
  mailMerge.BatchPrint = false;
  mailMerge.AllowBatchPrintOptionEditing = true;
  mailMerge.ShowBatchPrintOption = true;

  //save format settings
  mailMerge.SaveFormat = MAILMERGESAVEFORMAT.Default;
  mailMerge.AllowSaveFormatOptionEditing = true;
  mailMerge.ShowSaveFormatOption = true;

  return mailMerge;
};

export const setUpMailMergeFromForm = () => {
  let mailMerge = new MailMerge_SelectMergeDocument();

  //append settings
  mailMerge.AppendToRecordSource = true;
  mailMerge.AllowAppendOptionEditing = true;
  mailMerge.ShowAppendToRecordSourceOption = true;

  //collate settings
  mailMerge.Collate = true;
  mailMerge.AllowCollateOptionEdititng = false;
  mailMerge.ShowCollateOption = false;

  //batch print settings
  mailMerge.BatchPrint = false;
  mailMerge.AllowBatchPrintOptionEditing = true;
  mailMerge.ShowBatchPrintOption = true;

  //save format settings
  mailMerge.SaveFormat = MAILMERGESAVEFORMAT.Default;
  mailMerge.AllowSaveFormatOptionEditing = true;
  mailMerge.ShowSaveFormatOption = true;

  return mailMerge;
};

export const getActionMailMergeDataSetEnum = (ancestorType?: RECORDTYPE) => {
  switch (ancestorType) {
    case RECORDTYPE.HealthManager_Premises:
      return MAILMERGEDATASET.HealthManager_Action;
    case RECORDTYPE.TP_Application:
    case RECORDTYPE.TP_Subdivision:
    case RECORDTYPE.TP_OtherApplication:
    case RECORDTYPE.TP_DevAppsApplication:
    case RECORDTYPE.TP_PSA_Application:
    case RECORDTYPE.TP_PPRApplication:
      return MAILMERGEDATASET.TOWNPLANNING_Application_Actions;
    case RECORDTYPE.TP_BuildingApplication:
      return MAILMERGEDATASET.TOWNPLANNING_Building_Actions;
    case RECORDTYPE.CORE_Register_StreetProtection:
    case RECORDTYPE.CORE_Register:
      return MAILMERGEDATASET.Register_Action;
    case RECORDTYPE.CUSTOMERSERVICE_Event:
      return MAILMERGEDATASET.CUSTOMERSERVICE_Event_Actions;
    case RECORDTYPE.CUSTOMERSERVICE_BulletinBoard:
      return MAILMERGEDATASET.CUSTOMERSERVICE_BulletinBoard_Actions;
    default:
      return MAILMERGEDATASET.CORE_Action;
  }
};

export const getFIRequestMailMergeDataSetEnum = (
  ancestorType?: RECORDTYPE | string
) => {
  switch (ancestorType) {
    case RECORDTYPE.TP_Application:
    case RECORDTYPE.TP_Subdivision:
    case RECORDTYPE.TP_OtherApplication:
    case RECORDTYPE.TP_DevAppsApplication:
    case RECORDTYPE.TP_PSA_Application:
      return MAILMERGEDATASET.TOWNPLANNING_Application_FurtherInformationRequests;
    case RECORDTYPE.TP_PPRApplication:
      return MAILMERGEDATASET.TOWNPLANNING_PPR_FurtherInformationRequests;
    case RECORDTYPE.TP_LMRegister:
      return MAILMERGEDATASET.LANDMANAGEMENT_FurtherInformationRequests;
    case RECORDTYPE.CORE_Register:
      return MAILMERGEDATASET.Register_FurtherInformationRequests;
    case RECORDTYPE.TP_BuildingApplication:
      return MAILMERGEDATASET.BUILDING_Application_FurtherInformationRequests;
    case RECORDTYPE.WW_System:
      return MAILMERGEDATASET.WasteWater_System_FurtherInformationRequests;
    default:
      return null;
  }
};

export const getAppealMailMergeDataSetEnum = (ancestorType?: RECORDTYPE) => {
  switch (ancestorType) {
    case RECORDTYPE.TP_Application:
    case RECORDTYPE.TP_BuildingApplication:
    case RECORDTYPE.TP_Subdivision:
    case RECORDTYPE.TP_DevAppsApplication:
    case RECORDTYPE.TP_OtherApplication:
    case RECORDTYPE.TP_PSA_Application:
      return MAILMERGEDATASET.TOWNPLANNING_Application_Appeals;
    case RECORDTYPE.CORE_Register:
    case RECORDTYPE.CORE_Register_StreetProtection:
      return MAILMERGEDATASET.Register_Appeals;
    case RECORDTYPE.TP_LMRegister:
      return MAILMERGEDATASET.LANDMANAGEMENT_FurtherInformationRequests;
    case RECORDTYPE.TP_PPRApplication:
      return MAILMERGEDATASET.TOWNPLANNING_PPR_Appeals;
    default:
      return null;
  }
};

export const getFeeMailMergeDataSetENUM = (ancestorType?: RECORDTYPE) => {
  switch (ancestorType) {
    case RECORDTYPE.HealthManager_Premises:
      return MAILMERGEDATASET.HealthManager_Fees;
    case RECORDTYPE.CRS_Registration:
      return MAILMERGEDATASET.CRS_Registration_LateCollection;
    case RECORDTYPE.TP_Application:
    case RECORDTYPE.TP_BuildingApplication:
    case RECORDTYPE.TP_Subdivision:
    case RECORDTYPE.TP_OtherApplication:
    case RECORDTYPE.TP_DevAppsApplication:
    case RECORDTYPE.TP_PSA_Application:
    case RECORDTYPE.TP_PPRApplication:
      return MAILMERGEDATASET.TOWNPLANNING_Application_Fees;
    case RECORDTYPE.LLP_Permit:
      return MAILMERGEDATASET.LLP_Application_Fees;
    case RECORDTYPE.Animals_Registration:
      return MAILMERGEDATASET.Animals_Fees;
    case RECORDTYPE.Animals_Kennel:
      return MAILMERGEDATASET.Animals_Kennel_Fees;
    case RECORDTYPE.WW_System:
      return MAILMERGEDATASET.WasteWater_Fees;
    case RECORDTYPE.WW_Installation:
      return MAILMERGEDATASET.WasteWater_Installation_Fees;
    case RECORDTYPE.WW_Product:
      return MAILMERGEDATASET.WasteWater_Product_Fees;
    case RECORDTYPE.WW_Scheme:
      return MAILMERGEDATASET.WasteWater_Scheme_Fees;
    case RECORDTYPE.CSM_Licence:
      return MAILMERGEDATASET.CSM_Fees;
    case RECORDTYPE.CORE_Register:
      return MAILMERGEDATASET.Register_Fees;
    case RECORDTYPE.CORE_Register_StreetProtection:
      return MAILMERGEDATASET.Register_Fees;
    case RECORDTYPE.DisRes_Permit:
      return MAILMERGEDATASET.DISRES_Fees;
    case RECORDTYPE.EMS_EventBooking:
      return MAILMERGEDATASET.EMS_EventBooking;
    case RECORDTYPE.EMS_GroupEventBooking:
      return MAILMERGEDATASET.EMS_GroupEventBooking;
    case RECORDTYPE.RAD_Register_UseLicence:
    case RECORDTYPE.RAD_Register_ApprovedTester:
    case RECORDTYPE.RAD_Register_ApprovedAssessor:
    case RECORDTYPE.RAD_Register_ManagementLicence:
    case RECORDTYPE.RAD_Register_FacilityConstructionLicence:
    case RECORDTYPE.RAD_Register_Licence:
    case RECORDTYPE.RAD_Register_Source:
      return MAILMERGEDATASET.RAD_Register;
    default:
      return null;
  }
};
