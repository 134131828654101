import { eventEmitter } from "@/App";
import { deleteRefUsageByIdAndRecordType } from "@app/core/delete/buttons/api";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { colCrmsStandardDocument } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/child-screens/standard-documents/config";
import { StandardDocuments } from "@app/products/crms/service-standards/[id]/model";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface ICRMSStandardDocumentsProps {
  id?: number | string;
}

const nameOf = nameOfFactory<StandardDocuments>();

export const CRMSStandardDocuments: React.FC<ICRMSStandardDocumentsProps> =
  observer(({ id }) => {
    const [isShowDialog, setIsShowDialog] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const { pushNotification } = useCCAppNotificationStore();
    const [gridSelectedRows, setGridSelectedRows] = useState<
      StandardDocuments[]
    >([]);

    const handleOnDelete = async (value: IDeleteConfirmation) => {
      setIsDeleting(true);
      if (gridSelectedRows.length === 0) return;

      const ids = gridSelectedRows?.map((item: StandardDocuments) => item.ID);

      const response = await deleteRefUsageByIdAndRecordType(
        ids,
        RECORDTYPE.CUSTOMERSERVICE_StandardDocument,
        value.Reason
      );

      setIsDeleting(false);
      setIsShowDialog(false);
      if (isSuccessIdentityPacket(response)) {
        pushNotification({
          type: "success",
          title: "Document deleted successfully",
        });

        eventEmitter.emit(CCGridEventType.RefreshOData);

        setGridSelectedRows([]);
      } else {
        pushNotification({
          type: "error",
          title: "The document could not be deleted.",
          description: response.data?.Errors ?? response.error,
        });
      }
    };

    return (
      <>
        {isShowDialog && (
          <ConfirmDelete
            onClose={() => setIsShowDialog(false)}
            handleSubmit={handleOnDelete}
            isDeleting={isDeleting}
            message={
              "Are you sure you want to delete Standard Document(s) (click Yes to Delete, or No to cancel)?"
            }
            header={"Confirm Deletion"}
          />
        )}

        <CCGrid
          dataUrl={`odata/crms/internal/servicestandard/StandardDocument(serviceStandardId=${id})?$count=true&`}
          primaryField={nameOf("ID")}
          columnFields={colCrmsStandardDocument}
          selectableMode="multiple"
          selectedRows={gridSelectedRows}
          onSelectionChange={(dataItem: StandardDocuments[]) => {
            setGridSelectedRows([...dataItem]);
          }}
          toolbar={
            <div className="cc-grid-tools-bar">
              <Button
                type="button"
                iconClass="fas fa-minus"
                title="Remove"
                disabled={gridSelectedRows.length === 0}
                onClick={() => {
                  setIsShowDialog(true);
                }}
              />
            </div>
          }
        />
      </>
    );
  });
