import {
  getPropertiesAddressList,
  getPropertyAddress,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/api";
import {
  getCandidateAddress,
  getOriginalAddress,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/api";
import { MapshareSuggestion } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/model";
import { SimplePropertyDetail } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/_index";
import { searchAddress } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/form-elements/property-address-picker/api";
import {
  colSearchAddresses,
  colSearchPropertyAddresses,
  searchAddressesConfig,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/form-elements/property-address-picker/config";
import { getKeywords } from "@common/apis/coreKeyword";
import { isSuccessResponse } from "@common/apis/util";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import {
  Address,
  Address_BuildAddress,
} from "@common/input-pickers/address/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonProductStore } from "@common/stores/products/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import {
  getDisplayBuildingUnitNumber,
  getDisplayFloorNumber,
  getDisplayHouseNumber,
} from "@common/utils/formatting";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { IColumnFields } from "@components/cc-grid/model";
import { isHTML } from "@components/cc-input-picker/util";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { FormRenderProps } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { isArray, isEmpty, isNil } from "lodash";
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./_index.scss";

export interface IApplicationDescriptionProps extends ComboBoxProps {
  initialData: any;
  visited?: boolean;
  onButtonClick?: (
    setIsShowDialog?: (value: React.SetStateAction<boolean>) => void
  ) => void;
  disabledButton?: boolean;
  onError?: (value: any) => void;
  isLoadDetail?: boolean;
  textField?: string;
  textProduce?: (value: any) => string | undefined;
  formRenderProps: FormRenderProps;
  notificationRef: any;
  onSubmit: (value: any) => void;
  name: string;
  onChangeEventHandler?: (buildAddress: Address_BuildAddress | null) => void;
  isCRMS?: boolean;
  isLoadingDialog?: boolean;
  isHaveStreetLookup?: boolean;
  uniqueKey?: string;
  removeDisplayValue?: () => void;
  restoreDisplayValue?: () => void;
  isAutoShowDialog?: boolean;
  onBeforeOpenDialog?: (
    setIsShowDialog?: (value: React.SetStateAction<boolean>) => void
  ) => void;
  onClose?: () => void;
  isShowFullResultSearch?: boolean;
}

export const AddressPickerSearch = (props: IApplicationDescriptionProps) => {
  const {
    initialData,
    className,
    visited,
    validationMessage,
    formRenderProps,
    onButtonClick = () => {},
    value,
    textProduce,
    textField,
    onError,
    onChange,
    onFocus,
    onSubmit,
    name,
    onChangeEventHandler,
    isCRMS,
    isLoadingDialog,
    isHaveStreetLookup = false,
    removeDisplayValue,
    restoreDisplayValue,
    uniqueKey,
    isAutoShowDialog = false,
    onBeforeOpenDialog = () => {},
    onClose = () => {},
    isShowFullResultSearch,
    ...others
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const refTimeOut = useRef<NodeJS.Timeout | null>(null);
  const [data, setData] = useState<MapshareSuggestion[]>();
  const [showDialog, setShowDialog] = useState(isAutoShowDialog);
  const { settings } = useCommonProductStore();
  const { pushNotification } = useCCAppNotificationStore();

  const { valueGetter, onChange: onChangeForm } = formRenderProps;
  const address = valueGetter(name);
  const propertyAssessment = valueGetter(`${name}.PropertyAssessment`);

  const inputData = useMemo(() => {
    return data;
  }, [data]);

  const isStreetTypeEnabled = getBoolValueSetting(
    settings[ECorporateSettingsField.Global_Contact_SplitAddressName]
  );

  const isUseStreetType = useMemo(() => {
    if (
      !isStreetTypeEnabled &&
      !isNil(valueGetter(`${name}.Flag_ForceUse_StreetType`))
    ) {
      return valueGetter(`${name}.Flag_ForceUse_StreetType`);
    } else {
      return isStreetTypeEnabled;
    }
    // eslint-disable-next-line
  }, [isStreetTypeEnabled, valueGetter]);

  const inputDisplayName = useMemo(() => {
    if (isNil(value)) return "";
    if (textProduce) return textProduce(value);
    if (textField && !isArray(value) && textField in value)
      return value[textField];
    return value ?? "";
  }, [value, textField, textProduce]);

  // handle event
  const handleSearch = (event: ComboBoxFilterChangeEvent) => {
    const searchText = event.filter.value;
    if (searchText.length < searchAddressesConfig.minCharacters) return;

    if (refTimeOut.current) clearTimeout(refTimeOut.current);
    refTimeOut.current = setTimeout(() => {
      setIsLoading(true);
      if (isCRMS) {
        getPropertiesAddressList(searchText).then((response) => {
          if (isSuccessResponse(response)) {
            setData(response.data?.value ?? []);
          } else {
            if (onError) onError(response.error);
          }
          setIsLoading(false);
        });
      } else {
        searchAddress(searchText).then((response) => {
          if (isSuccessResponse(response)) {
            setData(response.data?.suggestions);
          } else {
            if (onError) onError(response.error);
          }
          setIsLoading(false);
        });
      }
    }, searchAddressesConfig.typeSpeed);
  };

  const setStreetType = async (address: Address) => {
    const streetTypeAbbreviation =
      address.PropertyAssessment.Street_TypeAbbreviation;
    // const streetName = address.PropertyAssessment.Street_Name;

    const response = await getKeywords(
      KEYWORD_TYPE.Core_Address_StreetType,
      PRODUCT_TYPE_NUMBER.TownPlanning
    );
    if (!isSuccessResponse(response)) {
      pushNotification({
        autoClose: false,
        title: `Street type list load failed`,
        type: "warning",
      });
    } else {
      if (isUseStreetType && response && response.data) {
        const streetTypeIndex = response.data.findIndex(
          (item) =>
            item.Name.toLowerCase() === streetTypeAbbreviation?.toLowerCase()
        );
        if (!isEmpty(streetTypeAbbreviation) && streetTypeIndex !== -1) {
          address = {
            ...address,
            StreetNamePart_Type: response.data[streetTypeIndex]?.Name,
          };
        } else {
          address = { ...address, StreetNamePart_Type: "" };
        }
      }
      // TODO: Re-check this logic later
      // else {
      //   if (!isEmpty(streetTypeAbbreviation)) {
      //     address = {
      //       ...address,
      //       PropertyAssessment: {
      //         ...address.PropertyAssessment,
      //         Street_Name: `${streetName} ${streetTypeAbbreviation}`,
      //       },
      //     };
      //   }
      // }
    }

    return address;
  };

  const handleOnChange = async (event: ComboBoxChangeEvent) => {
    let newAddress = { ...address };
    let newPropertyAssessment = { ...propertyAssessment };
    const newValue = event.value;
    if (!newValue) {
      onChangeForm(name, { value: undefined });
      if (onChangeEventHandler) onChangeEventHandler(null);
      return;
    }

    setIsLoading(true);

    if (isCRMS) {
      getPropertyAddress(newValue?.ID?.toString() ?? "0").then((response) => {
        if (isSuccessResponse(response) && response.data) {
          newAddress = response.data;
          onChangeForm(`${name}.Flag_StreetName_HasParts`, {
            value: isUseStreetType,
          });
          onChangeForm(name, {
            value: newAddress,
          });

          if (onChangeEventHandler)
            onChangeEventHandler({
              Address: newAddress,
              bAddressChanged: true,
            } as Address_BuildAddress);
        } else {
          if (onError) onError(response.error);
        }
        setIsLoading(false);
      });
    } else {
      //Clear unrelated fields
      newPropertyAssessment = {
        ...newPropertyAssessment,
        PropertyName: "",
        BuildingName: "",
        AddressLocationDescriptor: "",
        NearestCrossStreet: "",
        UnitNumber1: null,
        UnitSuffix1: "",
        UnitNumber2: null,
        UnitSuffix2: "",
        UnitAbbreviation: "",
        HouseNumber2: null,
        HouseSuffix2: "",
        FloorNumber1: null,
        FloorSuffix1: "",
        FloorNumber2: null,
        FloorSuffix2: "",
        FloorTypeAbbreviation: "",
      };

      newAddress = {
        ...newAddress,
        NearestCrossStreet: "",
        PropertyName: "",
        Location_Description: "",
        PropertyAssessment: {
          ...newAddress.PropertyAssessment,
          ...newPropertyAssessment,
        },
        StreetNo: getDisplayHouseNumber(newPropertyAssessment),
        UnitNo:
          (!isEmpty(getDisplayFloorNumber(newPropertyAssessment))
            ? `${getDisplayFloorNumber(newPropertyAssessment)} `
            : "") + getDisplayBuildingUnitNumber(newPropertyAssessment),
      };

      //Get candidate address by magicKey
      const responseCandidateAddress = await getCandidateAddress(
        newValue.magicKey
      );
      if (isSuccessResponse(responseCandidateAddress)) {
        const candidateAddress = responseCandidateAddress?.data;
        if (candidateAddress) {
          if (
            candidateAddress.candidates &&
            candidateAddress.candidates.length > 0
          ) {
            newPropertyAssessment = {
              ...newPropertyAssessment,
              GIS_Latitude: candidateAddress.candidates[0].location.x,
              GIS_Longitude: candidateAddress.candidates[0].location.y,
            };

            newAddress = {
              ...newAddress,
              PropertyAssessment: {
                ...newAddress.PropertyAssessment,
                ...newPropertyAssessment,
              },
            };

            if (
              candidateAddress.candidates[0].attributes &&
              !isEmpty(candidateAddress.candidates[0].attributes.User_fld)
            ) {
              const responseOriginalAddress = await getOriginalAddress(
                candidateAddress.candidates[0].attributes.User_fld
              );
              if (isSuccessResponse(responseOriginalAddress)) {
                const originalAddress = responseOriginalAddress.data;
                if (originalAddress) {
                  //Fill corresponding fields
                  newAddress = {
                    ...newAddress,
                    UnitNo: originalAddress.UnitNo ?? "",
                    StreetNo: originalAddress.StreetNo ?? "",
                  };

                  newPropertyAssessment = {
                    ...newPropertyAssessment,
                    Address_PFI:
                      originalAddress.PropertyAssessment.Address_PFI ?? "",
                    HouseNumber1:
                      originalAddress.PropertyAssessment.HouseNumber1 || null,
                    HouseSuffix1:
                      originalAddress.PropertyAssessment.HouseSuffix1 ?? "",
                    Street_Name:
                      originalAddress.PropertyAssessment.Street_Name ?? "",
                    Street_Suffix:
                      originalAddress.PropertyAssessment.Street_Suffix ?? "",
                    Street_TypeAbbreviation:
                      originalAddress.PropertyAssessment
                        .Street_TypeAbbreviation ?? "",
                    Locality_Name:
                      originalAddress.PropertyAssessment.Locality_Name ?? "",
                    Locality_Postcode:
                      originalAddress.PropertyAssessment.Locality_Postcode ??
                      "",
                    UnitNumber1: originalAddress.UnitNo || null,
                  };

                  newAddress = {
                    ...newAddress,
                    Suburb: originalAddress.Suburb ?? "",
                    Postcode: originalAddress.Postcode ?? "",
                    AddressLine1: originalAddress.AddressLine1 ?? "",
                    VMAS_Verified: true,
                    State: originalAddress.State ?? "",
                    StreetNamePart_Name:
                      originalAddress.StreetNamePart_Name ?? "",
                    StreetName: originalAddress.StreetName ?? "",
                    PropertyAssessment: {
                      ...newAddress.PropertyAssessment,
                      ...newPropertyAssessment,
                    },
                    StreetNo: getDisplayHouseNumber(newPropertyAssessment),
                    UnitNo:
                      (!isEmpty(getDisplayFloorNumber(newPropertyAssessment))
                        ? `${getDisplayFloorNumber(newPropertyAssessment)} `
                        : "") +
                      getDisplayBuildingUnitNumber(newPropertyAssessment),
                  };

                  newAddress = await setStreetType(newAddress);

                  onChangeForm(`${name}.Flag_StreetName_HasParts`, {
                    value: isUseStreetType,
                  });
                  onChangeForm(name, {
                    value: newAddress,
                  });

                  if (onChangeEventHandler)
                    onChangeEventHandler({
                      Address: newAddress,
                      bAddressChanged: true,
                    } as Address_BuildAddress);
                }
              } else {
                if (onError) onError(responseOriginalAddress.error);
              }
            }
          }
        }
      } else {
        if (onError) onError(responseCandidateAddress.error);
      }
      setIsLoading(false);
    }
  };

  // Auto show dialog
  useEffect(() => {
    if (isAutoShowDialog && onBeforeOpenDialog) {
      onBeforeOpenDialog(setShowDialog);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoShowDialog]);

  return (
    <>
      <div
        className={`${className ?? ""} cc-input-picker-new ${
          !others.valid ? "cc-input-picker-invalid" : ""
        }`}
      >
        <div
          style={{
            display: `${!isHTML(inputDisplayName) ? "none" : "block"}`,
          }}
          className={`cc-input-picker-html k-textbox ${
            others.disabled ? "k-state-disabled" : ""
          }`}
          onClick={() => {
            if (removeDisplayValue && uniqueKey && !others.disabled) {
              removeDisplayValue();
              const comboboxElement = document.getElementById(uniqueKey);
              setTimeout(() => {
                comboboxElement?.focus();
              }, 100);
            }
          }}
        >
          {sanitizeHtml(value)}
        </div>
        <ComboBox
          {...others}
          id={uniqueKey}
          filterable
          suggest
          style={{
            display: `${isHTML(inputDisplayName) ? "none" : ""}`,
          }}
          data={inputData}
          loading={isLoading}
          onFilterChange={handleSearch}
          onBlur={restoreDisplayValue}
          itemRender={(
            li: ReactElement<HTMLLIElement>,
            itemProps: ListItemProps
          ) => ItemRender(li, itemProps, isCRMS)}
          value={inputDisplayName}
          header={
            <div className="cc-search-header">
              {(isCRMS ? colSearchPropertyAddresses : colSearchAddresses).map(
                (col: IColumnFields) => (
                  <div key={col.field} style={{ width: col.width }}>
                    {col.title}
                  </div>
                )
              )}
            </div>
          }
          onChange={handleOnChange}
          popupSettings={{
            className: `cc-address-picker-search ${
              isShowFullResultSearch ? "cc-search-view-full" : ""
            }`,
          }}
        />
        <Button
          disabled={
            !isNil(props.disabledButton) ? props.disabledButton : isLoading
          }
          className="cc-input-picker-button"
          iconClass="fa fa-ellipsis-h"
          title="Open address dialogue"
          onClick={(event) => {
            event.preventDefault();
            if (onButtonClick) return onButtonClick(setShowDialog);
          }}
        />
      </div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
      {showDialog && (
        <SimplePropertyDetail
          titleHeader="Address"
          initialData={initialData}
          onClose={() => {
            setShowDialog(false);
            onClose();
          }}
          onSubmit={(value: any) => {
            setShowDialog(false);
            onSubmit(value);
          }}
          isCRMS={isCRMS}
          isLoading={isLoadingDialog}
          isHaveStreetLookup={isHaveStreetLookup}
        />
      )}
    </>
  );
};

const ItemRender = (
  li: ReactElement<HTMLLIElement>,
  itemProps: ListItemProps,
  isCRMS?: boolean
) => {
  const { dataItem } = itemProps;
  const itemChildren = (
    <div className="cc-search-item">
      {(isCRMS ? colSearchPropertyAddresses : colSearchAddresses).map(
        (col: IColumnFields) => (
          <div key={col.field} style={{ width: col.width }}>
            {dataItem?.[col.field] ?? ""}
          </div>
        )
      )}
    </div>
  );
  return React.cloneElement(li, li.props, itemChildren);
};
