import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { VO_Assessment_RebateEntitlement } from "@app/products/property/assessments/rebates/list/model";
import {
  BOOLEAN_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Assessment_RebateEntitlement>();
export const colRebatesList: IColumnFields[] = [
  {
    field: nameOf("Formatted_Address"),
    title: "Property Address",
    locked: true,
    linkTo: (dataItem) =>
      `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.Assessment_Id}`,
  },
  {
    field: nameOf("Assess_NumberX"),
    title: "Assessment Number",
  },
  {
    field: nameOf("Rebate_Type"),
    title: "Rebate Type",
  },
  {
    field: nameOf("Percentage_Of_Full_Rebate"),
    title: "Percentage of Full Rebate",
    format: PERCENTAGE_FORMAT.PERCENTAGE_1,
  },
  {
    field: nameOf("Application_Date"),
    title: "Application Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Effective_Date"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ARE_Entitled_To_AlterRebate_Cap"),
    title: "Alternate Rebate Cap",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: nameOf("Notes"), title: "Notes" },
  { field: nameOf("Entitled_Entities"), title: "Entitled Contacts" },
  {
    field: nameOf("Changed_On"),
    title: "Changed On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Changed_By"),
    title: "Changed By",
    width: 150,
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Assess_Rebate_Entitlement_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
