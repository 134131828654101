import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const InspectionHistoryTab = observer(() => {
  const { lastSelectedId } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const inspectionId = params.id;
  const id: number | string = inspectionId ?? lastSelectedId;
  return <Journal id={id} recordType={RECORDTYPE.CORE_Inspection} />;
});
