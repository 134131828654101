import { apiCoreRegisteredAddress } from "@app/core/components/common/utils";
import { ANIMALS_KENNEL_SAVE } from "@app/products/animals/kennels/[id]/constant";
import {
  AnimalsKennel,
  AnimalsKennelReturnObject,
  KennelFormHandlerRequest,
  KennelHandlerResponse,
  Svc_Kennel_Workflow_DateFromDateTo,
  Svc_Kennel_Workflow_ProvideReason,
} from "@app/products/animals/kennels/[id]/model";
import { getTotalRelatedAnimalRecordsUrl } from "@app/products/animals/[id]/util";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import {
  Address,
  Address_BuildAddress,
} from "@common/input-pickers/address/model";
import { IdentityPacket, IIdentityPacket } from "@common/models/identityPacket";

export const getAnimalsKennelById = async (
  id: number,
  isDetailSidebar?: boolean
): Promise<
  APIResponse<IIdentityPacket<AnimalsKennelReturnObject> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get(
      `${ANIMALS_KENNEL_SAVE}/${id}${
        isDetailSidebar ? "?isDetailSidebar=true" : ""
      }`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const saveAnimalKennel = async (
  kennel: AnimalsKennel
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `${ANIMALS_KENNEL_SAVE}`,
      kennel
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const kennelHandler = async (
  kennel: KennelFormHandlerRequest
): Promise<APIResponse<IIdentityPacket<KennelHandlerResponse> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<KennelHandlerResponse>
    >("api/animals/internal/kennel/form-handler", kennel);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const setupKennelPickSiteAddress = async (
  kennelAddress?: Address
): Promise<APIResponse<IIdentityPacket<Address_BuildAddress> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<Address_BuildAddress>
    >(apiCoreRegisteredAddress(), kennelAddress);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getTotalRelatedAnimalKennelsRecords = async (
  id: number,
  pid?: string
): Promise<APIResponse<number>> => {
  try {
    return await CoreAPIService.getClient().get<number>(
      getTotalRelatedAnimalRecordsUrl(id, pid)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postKennelIssueRegistration = async (
  kennelWorkflowDateFromDateTo: Svc_Kennel_Workflow_DateFromDateTo
): Promise<APIResponse<IIdentityPacket<AnimalsKennel> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<AnimalsKennel>
    >(
      `api/animals/internal/kennel/issue-registration`,
      kennelWorkflowDateFromDateTo
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postKennelRenew = async (
  kennelWorkflowDateFromDateTo: Svc_Kennel_Workflow_DateFromDateTo
): Promise<APIResponse<IIdentityPacket<AnimalsKennel> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<AnimalsKennel>
    >(`api/animals/internal/kennel/renew`, kennelWorkflowDateFromDateTo);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postKennelDeregister = async (
  kennelWorkflowProvideReason: Svc_Kennel_Workflow_ProvideReason
): Promise<APIResponse<IIdentityPacket<AnimalsKennel> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<AnimalsKennel>
    >(`api/animals/internal/kennel/deregister`, kennelWorkflowProvideReason);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postKennelReactivate = async (
  kennelWorkflowProvideReason: Svc_Kennel_Workflow_ProvideReason
): Promise<APIResponse<IIdentityPacket<AnimalsKennel> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<AnimalsKennel>
    >(`api/animals/internal/kennel/reactivate`, kennelWorkflowProvideReason);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
