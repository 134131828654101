import { VO_Valuation_Import } from "@app/products/property/supplementary-rates/import-valuations/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Valuation_Import>();
export const supplementaryImportValuationsColList: IColumnFields[] = [
  {
    field: nameOf("VI_Valuation_NumberX"),
    title: "Imported Valuation Number",
  },
  {
    field: nameOf("VI_Assessment_NumberX"),
    title: "Imported Assessment (10.3)",
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Master_Property_Id"),
    title: "Master Property ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Assess_Number"),
    title: "Assessment (10.3)",
  },
  {
    field: nameOf("Assess_Reference"),
    title: "Assessment (S-S-B-U)",
  },
  {
    field: nameOf("Property_Address"),
    title: "Property Name Address Locality",
  },
  {
    field: nameOf("Ratepayer_Name"),
    title: "Ratepayer Name",
  },
  {
    field: nameOf("VI_Date_of_Valuation"),
    title: "Date of Valuation",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("VI_Issue_Date"),
    title: "Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("VI_Effective_Date"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("VI_Valuation_1"),
    title: "Valuation 1",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("VI_Valuation_2"),
    title: "Valuation 2",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("VI_Valuation_3"),
    title: "Valuation 3",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("VI_Valuation_4"),
    title: "Valuation 3",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("VI_Valuation_5"),
    title: "Valuation 3",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("VI_Comment"),
    title: "Comment",
  },
  {
    field: nameOf("Primary_Land_Use"),
    title: "Primary Land Use",
  },
  {
    field: nameOf("Secondary_Land_Use"),
    title: "Secondary Land Use",
  },
  {
    field: nameOf("Sub_Market_Group"),
    title: "Sub Market Group",
  },
  {
    field: nameOf("VI_Valuation_Basis"),
    title: "Valuation Basis",
  },
  {
    field: nameOf("Valuation_Import_Id"),
    title: "Valuation Import ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
