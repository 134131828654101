import { DTO_Parcel } from "@app/products/property/components/dialogs/add-parcel-lookup/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { DTO_LOV_Number } from "@common/models/odataResponse";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

/**
 *  1 - Address
 *  2 - Name
 *  3 - Legal Description
 *  4 - Parcel Id
 *  5 - Parcel (S-S-B-U)
 *  6 - Assessment Id
 */
export const parcelSearchByList: DTO_LOV_Number[] = [
  {
    Code: 1,
    Name: "Address",
  },
  {
    Code: 3,
    Name: "Legal Description",
  },
];

export enum eOptionSearchParcelLookup {
  Address = 1,
  LegalDescription = 3,
  AssessmentId = 6, // only use for call api not using for option search
}
export const listConfigNumberTypeParcel = [4, 6];
const nameOf = nameOfFactory<DTO_Parcel>();

export const colParcelLookup: IColumnFields[] = [
  {
    field: nameOf("ParcelReference"),
    title: "Parcel (S-S-B-U)",
  },
  {
    field: nameOf("PropertyAddress"),
    title: "Address",
  },
  {
    field: nameOf("Locality"),
    title: "Locality",
  },
  {
    field: nameOf("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOf("CensusDistrict"),
    title: "Census District",
  },
  {
    field: nameOf("ElectoralDivision"),
    title: "Electoral Division",
  },
  {
    field: nameOf("StateCodeElectoralDivision"),
    title: "State Electoral Division",
  },
  {
    field: nameOf("FederalElectoralDivision"),
    title: "Federal Electoral Division",
  },
  {
    field: nameOf("LandArea"),
    title: "Land Area",
  },
  {
    field: nameOf("Zones"),
    title: "Zones",
  },
  {
    field: nameOf("LandUse"),
    title: "Land Use",
  },
  {
    field: nameOf("OwnerName"),
    title: "Owners",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Parcel_Id"),
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
