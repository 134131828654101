import { IGenerateEventsColumnsBySettingProps } from "@app/products/crms/config";
import { ServiceStandardView } from "@app/products/crms/service-standards/model";
import { RECORDSSYSTEM } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import {
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep } from "lodash";

const nameOf = nameOfFactory<ServiceStandardView>();

export const generateServiceStandardViewsBySetting = ({
  settings,
  defaultConfig = [],
}: IGenerateEventsColumnsBySettingProps) => {
  if (!settings) return defaultConfig;

  const recordsSystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]
  );
  const subCategoryLevel3Label = getStringValueSetting(
    settings[
      ECorporateSettingsField
        .CUSTOMERSERVICE_ServiceStandardSubCategoryLevel3Caption
    ]
  );

  const subCategoryLevel4Label = getStringValueSetting(
    settings[
      ECorporateSettingsField
        .CUSTOMERSERVICE_ServiceStandardSubCategoryLevel4Caption
    ]
  );

  const serviceStandardCategoryLevels = getNumberValueSetting(
    settings[
      ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandardCategoryLevels
    ]
  );

  let newConfig: IColumnFields[] = cloneDeep(defaultConfig);

  return newConfig.filter((col) => {
    switch (col.field) {
      case nameOf("SubCategoryLevel3"):
        if (serviceStandardCategoryLevels > 2) {
          if (subCategoryLevel3Label) {
            col.title = subCategoryLevel3Label;
          }
          return true;
        }
        return false;
      case nameOf("SubCategoryLevel4"):
        if (serviceStandardCategoryLevels > 3) {
          if (subCategoryLevel4Label) {
            col.title = subCategoryLevel4Label;
          }
          return true;
        }
        return false;
      case nameOf("FileNumber"):
        if (
          recordsSystem === RECORDSSYSTEM.TRIMContext ||
          recordsSystem === RECORDSSYSTEM.BluePointContentPlus
        ) {
          return true;
        }
        return false;
      case nameOf("Classification"):
        if (recordsSystem === RECORDSSYSTEM.TRIMContext) {
          return true;
        }
        return false;
      case nameOf("CategoryCode"):
        if (recordsSystem === RECORDSSYSTEM.Objective) {
          return true;
        }
        return false;
      default:
        return true;
    }
  });
};
