import { PermitFormElement } from "@app/products/local-laws/[id]/components/general/components/form-element/_index";
import { Svc_Permit } from "@app/products/local-laws/[id]/model";
import { PermitType } from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useFlexibleFormState } from "@common/stores/flexible-form/useFlexibleFormState";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const PermitForm = observer(() => {
  //#region STORE ========/
  const { middlewareSubmitForm } = useFlexibleFormStore();
  const { pushNotificationPortal } = useNotificationPortalStore();
  //#endregion STORE =====/

  //#region HOOK ========/
  const { setFormRef, initialDataForm } = useFlexibleFormState();
  //#endregion HOOK =====/

  //#region METHOD ========/
  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { whenNotValid, whenNotModified } = middlewareSubmitForm(event);
    whenNotValid(() => {
      pushNotificationPortal({
        title: "Please enter all required information.",
        type: "warning",
      });
    });
    whenNotModified(() => {
      pushNotificationPortal({
        title: "The form is not modified.",
        type: "warning",
      });
    });
  };
  //#endregion METHOD =====/

  //#region INITIAL_VALUES ========/
  let permitDataForm = initialDataForm as Svc_Permit;
  if (permitDataForm?.Permit?.PermitType_ID === 0) {
    permitDataForm.Permit.PermitType = null as unknown as PermitType;
  }

  return (
    <div className="cc-form cc-form-submit-manage-page">
      <Form
        ref={setFormRef}
        onSubmitClick={handleOnSubmit}
        initialValues={permitDataForm}
        key={JSON.stringify(permitDataForm)}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <PermitFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
