import { ParkingPermitsForm } from "@app/products/parking-permits/[id]/components/child-screens/general/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";

export const NewManageParkingPermits = () => {
  return (
    <div className="cc-new-parking-permits">
      <FormNavigation title="Parking Permit" />
      <FormTitle title="New Parking Permits" />
      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Workflow"}
            type="sub-workflow"
            className="cc-workflow-button"
          >
            <CCNavButton title={"Create permit"} />
          </CCNavButton>,
          <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
          <CCNavButton title={"View"} />,
          <CCNavButton title={"Print"} />,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <div className="cc-manage-form-body">
            <CCGeneralPanel component={<ParkingPermitsForm />} />
          </div>
        </div>
      </div>
    </div>
  );
};
