import {
  CS_EventStatus,
  CS_RequestorType,
  ElementDisplayStatus,
  Event,
  EventHandlerRequest,
  EventMapObj,
  Svc_EventFormAction,
} from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { isFieldVisible } from "@app/products/crms/util";
import {
  ACCESSRIGHTS,
  ContactRelationshipType,
} from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";

import { renderContact } from "@app/products/animals/kennels/[id]/component/child-screens/general/form-element/components/animal-owner-section/util";
import { renderOptionRequestor } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/config";
import { Svc_ContactDetails } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/model";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { isHTML } from "@components/cc-input-picker/util";
import { useEffectOnce } from "react-use";
import "./_index.scss";

export const DETAIL_STEP = "DetailStep";

const nameOfEventMapObj = nameOfFactory<EventMapObj>();
const nameOfEvent = nameOfFactory<Event>();

export const DetailStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const requiredValidatorCategory = (value: any) => {
  if (value === 0) return "This field is required.";
  return requiredValidator(value);
};

const FormStepElement = observer(
  ({ formRenderProps, localNotificationRef }: IFormStepElement) => {
    //#region Store
    const { settings } = useCommonCoreStore();
    const { currentUserInfo } = useGlobalStore();
    const {
      crmsEvent,
      crmsEventLovs,
      accessRights,
      simpleEventChangeHandler,
      setMeAsRequestorSimpleEvent,
      uiControl,
      //crmsEvent, CCS-86 comment it as customer request
      isLoading,
    } = useCRMSEventStore();
    //#endregion Store

    //#region Hook
    const [requestorTypeEnum, setRequestorTypeEnum] =
      useState<CS_RequestorType>(CS_RequestorType.Councillor);
    const prevRequestorDisplayName = useRef("");
    //#endregion Hook

    //#region Declare
    const { valueGetter, onChange } = formRenderProps;
    const eventFormObj = valueGetter(nameOfEventMapObj("Event"));

    const simpleEventHelpURLValue = getStringValueSetting(
      settings[ECorporateSettingsField.CUSTOMERSERVICE_SimpleEvent_HelpURL]
    );
    const isDisable =
      crmsEvent?.EventStatus_ENUM === CS_EventStatus.Closed ||
      accessRights === ACCESSRIGHTS.CanRead;
    const isAnonymous = useMemo(() => {
      return requestorTypeEnum === CS_RequestorType.Anonymous;
    }, [requestorTypeEnum]);

    const isExternalPerson = useMemo(() => {
      return requestorTypeEnum === CS_RequestorType.ExternalPerson;
    }, [requestorTypeEnum]);

    //#endregion Declare

    //CCS-86 comment it as customer request
    // const isOnbehalfOfRequire = getBoolValueSetting(
    //   settings[
    //     ECorporateSettingsField.CUSTOMERSERVICE_CouncillorRequests_MandatoryOBO
    //   ]
    // );
    // const searchPersonOnly = getBoolValueSetting(
    //   settings[
    //     ECorporateSettingsField.CorporateSettings_NameSearchOnlyReturnPerson
    //   ]
    // );

    // CCS-86 comment it as customer request
    // const { pushNotification } = useCCAppNotificationStore();
    // const prevOnBehalfOfDisplayName = useRef("");

    //#region Methods
    const handleTypeOfRequestChange = (event: ComboBoxChangeEvent) => {
      onChange(`${nameOfEventMapObj("Event")}.${nameOfEvent("Type_ID")}`, {
        value: event.target.value?.CustomerService_LookupTable_Id ?? null,
      });
      onChange(`${nameOfEventMapObj("Event")}.${nameOfEvent("Type_Display")}`, {
        value: event.target.value?.Description ?? null,
      });
    };

    const handleSourceChange = (event: ComboBoxChangeEvent) => {
      onChange(`${nameOfEventMapObj("Event")}.${nameOfEvent("Source_ID")}`, {
        value: event.target.value?.Key ?? null,
      });
      onChange(
        `${nameOfEventMapObj("Event")}.${nameOfEvent("Source_Display")}`,
        {
          value: event.target.value?.Value ?? null,
        }
      );
    };

    const handleCategoryChange = async (event: ComboBoxChangeEvent) => {
      onChange(
        `${nameOfEventMapObj("Event")}.${nameOfEvent("ServiceStandard_Id")}`,
        {
          value: event.target.value?.Key ?? null,
        }
      );
      onChange(
        `${nameOfEventMapObj("Event")}.${nameOfEvent(
          "ServiceStandardType_Name"
        )}`,
        {
          value: event.target.value?.Value ?? null,
        }
      );

      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_CategoryChange,
        Event: eventFormObj,
        EventArgs: {
          ServiceStandard_Id: event.target?.value.Key,
        },
      };

      await simpleEventChangeHandler(
        params,
        "Change category type failed.",
        localNotificationRef
      );
    };

    //#region Requestor Picker
    const handleRequestorTypeChange = async (
      event: DropDownListChangeEvent
    ) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_RequestorTypeChange,
        Event: eventFormObj,
        EventArgs: {
          RequestorType_ID:
            event.target?.value?.CustomerService_LookupTable_Id!,
        },
        isFirstTimeLoad: false,
      };
      await simpleEventChangeHandler(
        params,
        "Change requestor type fail",
        localNotificationRef
      );

      const requestorTypeEnum = getDropdownValue(
        valueGetter(
          `${nameOfEventMapObj("Event")}.${nameOfEvent("RequestorType_ID")}`
        ),
        crmsEventLovs?.RequestorType ?? [],
        "CustomerService_LookupTable_Id"
      )?.LookupTable_EnumeratedValue_ENUM;
      setRequestorTypeEnum(requestorTypeEnum);

      if (uiControl) uiControl.LitRequestedBy.Value = "";
    };

    const setAnonymousHandler = async () => {
      const requestorTypeId = getDropdownValue(
        CS_RequestorType.Anonymous,
        crmsEventLovs?.RequestorType ?? [],
        "LookupTable_EnumeratedValue_ENUM"
      )?.CustomerService_LookupTable_Id;

      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_RequestorTypeChange,
        Event: eventFormObj,
        EventArgs: {
          RequestorType_ID: requestorTypeId,
        },
        isFirstTimeLoad: false,
      };
      await simpleEventChangeHandler(
        params,
        "Change requestor type fail",
        localNotificationRef
      );
      setRequestorTypeEnum(CS_RequestorType.Anonymous);
    };

    const setMeAsRequestorHandler = () => {
      const requestorTypeId = getDropdownValue(
        CS_RequestorType.InternalPerson,
        crmsEventLovs?.RequestorType ?? [],
        "LookupTable_EnumeratedValue_ENUM"
      )?.CustomerService_LookupTable_Id;

      if (currentUserInfo?.UserPKID) {
        setMeAsRequestorSimpleEvent(
          parseInt(currentUserInfo?.UserPKID),
          requestorTypeId,
          eventFormObj,
          localNotificationRef
        );
        setRequestorTypeEnum(CS_RequestorType.InternalPerson);
      }
    };

    const handleSelectRequestor = async (value: Svc_ContactDetails | null) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_RequestorChange,
        Event: eventFormObj,
        EventArgs: {
          RequestedBy_SiteUser_ID: value?.Id,
        },
        isFirstTimeLoad: false,
      };
      await simpleEventChangeHandler(
        params,
        "Change requestor fail",
        localNotificationRef
      );
    };

    const handleSelectRequestorExternal = async (
      event: ComboBoxChangeEvent | null
    ) => {
      let requestedByContactRID = 0;
      let sysTimeStamp = "";
      if (
        eventFormObj?.RequestedBy_Contact_RID &&
        eventFormObj?.RequestedBy?.Contact?.Contact_ID ===
          event?.value?.Contact_ID &&
        eventFormObj?.RequestedBy?.Sys_TimeStamp
      ) {
        requestedByContactRID = eventFormObj.RequestedBy_Contact_RID;
        sysTimeStamp = eventFormObj.RequestedBy.Sys_TimeStamp;
      }

      let params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_RequestorChange,
        Event: eventFormObj,
        EventArgs: {},
        isFirstTimeLoad: false,
      };

      if (event?.value) {
        params = {
          ...params,
          EventArgs: {
            RequestedBy: {
              ContactRelationshipType_ENUM:
                ContactRelationshipType.CUSTOMERSERVICE_RequestedBy,
              Contact: event?.value,
              RID: requestedByContactRID,
              Sys_TimeStamp: sysTimeStamp,
            },
          },
        };
      }

      await simpleEventChangeHandler(
        params,
        "Change requestor fail",
        localNotificationRef
      );
    };
    //#endregion Requestor

    // CCS-86 comment it as customer request
    // const handleSelectOnBehalfOf = (event: ComboBoxChangeEvent | null) => {
    //   let onBehalfOfContactRID = 0;
    //   let sysTimeStamp = "";
    //   if (
    //     eventFormObj?.OnBehalfOf_Contact_RID &&
    //     eventFormObj?.OnBehalfOf?.Contact?.Contact_ID ===
    //       event?.value?.Contact_ID &&
    //     eventFormObj?.OnBehalfOf?.Sys_TimeStamp
    //   ) {
    //     onBehalfOfContactRID = eventFormObj.OnBehalfOf_Contact_RID;
    //     sysTimeStamp = eventFormObj.OnBehalfOf.Sys_TimeStamp;
    //   }
    //   let params: EventHandlerRequest = {
    //     EventFormAction: Svc_EventFormAction.Form_PickOnBehalfOfExternal,
    //     Event: eventFormObj,
    //     EventArgs: {},
    //     isFirstTimeLoad: false,
    //   };

    //   if (event?.value) {
    //     params = {
    //       ...params,
    //       EventArgs: {
    //         OnBehalfOf: {
    //           ContactRelationshipType_ENUM:
    //             ContactRelationshipType.CUSTOMERSERVICE_OnBehalfOf,
    //           Contact: event?.value,
    //           RID: onBehalfOfContactRID,
    //           Sys_TimeStamp: sysTimeStamp,
    //         },
    //       },
    //     };
    //   }

    //   simpleEventChangeHandler(
    //     params,
    //     "Change requestor failed.",
    //     localNotificationRef
    //   );
    // };

    const handleOpenSimpleEventHelpURL = () => {
      if (!isNil(simpleEventHelpURLValue)) {
        window.open(simpleEventHelpURLValue);
      }
    };
    //#endregion Methods

    useEffectOnce(() => {
      const requestorTypeEnum = getDropdownValue(
        valueGetter(
          `${nameOfEventMapObj("Event")}.${nameOfEvent("RequestorType_ID")}`
        ),
        crmsEventLovs?.RequestorType ?? [],
        "CustomerService_LookupTable_Id"
      )?.LookupTable_EnumeratedValue_ENUM;
      setRequestorTypeEnum(requestorTypeEnum);
    });

    if (isLoading) {
      return <Loading isLoading={isLoading} />;
    }

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          {uiControl?.DdlCategory?.DisplayStatus ===
            ElementDisplayStatus.Visible &&
            crmsEventLovs?.SimpleCategories &&
            crmsEventLovs?.SimpleCategories.length > 0 && (
              <div className="cc-field">
                <CCLabel title="Category" isMandatory />
                <Field
                  name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                    "ServiceStandard_Id"
                  )}`}
                  textField="Value"
                  dataItemKey="Key"
                  data={crmsEventLovs?.SimpleCategories}
                  component={CCSearchComboBox}
                  validator={requiredValidatorCategory}
                  placeholder="Select category"
                  value={getDropdownValue(
                    valueGetter(
                      `${nameOfEventMapObj("Event")}.${nameOfEvent(
                        "ServiceStandard_Id"
                      )}`
                    ),
                    crmsEventLovs?.SimpleCategories ?? [],
                    "Key"
                  )}
                  onChange={(event: ComboBoxChangeEvent) => {
                    handleCategoryChange(event);
                  }}
                />
              </div>
            )}

          <div className="cc-field">
            <div className="cc-flex cc-justify-between cc-align-item">
              <CCLabel title="Description" isMandatory />
              {!!simpleEventHelpURLValue && (
                <label className="label" onClick={handleOpenSimpleEventHelpURL}>
                  <span className="btn-link">How to log an event</span>
                </label>
              )}
            </div>

            <Field
              name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                "Event_Description"
              )}`}
              placeholder="Description"
              rows={2}
              value={valueGetter(
                `${nameOfEventMapObj("Event")}.${nameOfEvent(
                  "Event_Description"
                )}`
              )}
              component={CCTextArea}
              validator={requiredValidator}
            />
          </div>
        </div>

        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Type of request" isMandatory />
            <Field
              name={`${nameOfEventMapObj("Event")}.${nameOfEvent("Type_ID")}`}
              textField="Description"
              dataItemKey="CustomerService_LookupTable_Id"
              data={crmsEventLovs?.Type}
              component={CCSearchComboBox}
              validator={requiredValidator}
              placeholder="Select type"
              value={getDropdownValue(
                valueGetter(
                  `${nameOfEventMapObj("Event")}.${nameOfEvent("Type_ID")}`
                ),
                crmsEventLovs?.Type ?? [],
                "CustomerService_LookupTable_Id"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                handleTypeOfRequestChange(event);
              }}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Source" isMandatory />
            <Field
              name={`${nameOfEventMapObj("Event")}.${nameOfEvent("Source_ID")}`}
              textField="Value"
              dataItemKey="Key"
              data={crmsEventLovs?.Source}
              component={CCSearchComboBox}
              validator={requiredValidator}
              placeholder="Select source"
              value={getDropdownValue(
                valueGetter(
                  `${nameOfEventMapObj("Event")}.${nameOfEvent("Source_ID")}`
                ),
                crmsEventLovs?.Source ?? [],
                "Key"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                handleSourceChange(event);
              }}
            />
          </div>
        </div>

        {uiControl?.ChkStandardRequestor?.DisplayStatus ===
        ElementDisplayStatus.Hidden ? (
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCValueField
                label="Requested by"
                value={valueGetter(
                  `${nameOfEventMapObj("Event")}.${nameOfEvent(
                    "RequestedBy_SiteUser_Description"
                  )}`
                )}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <CCLabel title="Requestor type" isMandatory />
                <Field
                  name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                    "RequestorType_ID"
                  )}`}
                  placeholder="Select requestor type"
                  textField="Description"
                  dataItemKey="CustomerService_LookupTable_Id"
                  data={crmsEventLovs?.RequestorType ?? []}
                  component={CCDropDownList}
                  validator={requiredValidator}
                  value={getDropdownValue(
                    valueGetter(
                      `${nameOfEventMapObj("Event")}.${nameOfEvent(
                        "RequestorType_ID"
                      )}`
                    ),
                    crmsEventLovs?.RequestorType ?? [],
                    "CustomerService_LookupTable_Id"
                  )}
                  onChange={handleRequestorTypeChange}
                  disabled={isDisable}
                />
              </div>
              <div className="cc-field">
                <div className="cc-tool-tip-wrapper cc-group-label">
                  <label className="cc-label">Requestor</label>
                  {isFieldVisible(
                    uiControl?.BtnRequestedByAnonymous?.DisplayStatus
                  ) && (
                    <Button
                      fillMode="flat"
                      className="cc-icon-label-button cc-color-button"
                      onClick={(event) => {
                        event.preventDefault();
                        setAnonymousHandler();
                      }}
                      title={"Anonymous requestor"}
                      iconClass="fa fa-question-circle"
                      disabled={isDisable}
                    />
                  )}
                  {isFieldVisible(
                    uiControl?.BtnRequestedByMe?.DisplayStatus
                  ) && (
                    <Button
                      fillMode="flat"
                      className="cc-icon-label-button"
                      onClick={(event) => {
                        event.preventDefault();
                        setMeAsRequestorHandler();
                      }}
                      title={"Set me as requestor"}
                      iconClass="fas fa-user cc-label-field-danger"
                      disabled={isDisable}
                    />
                  )}
                </div>
                {!isExternalPerson && (
                  <Field
                    name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                      "RequestedBy_SiteUser"
                    )}`}
                    nameDisplay="DisplayName"
                    placeholder="Select requestor"
                    component={InputPickerSearch}
                    valueMapGrid={valueGetter(
                      `${nameOfEventMapObj("Event")}.${nameOfEvent(
                        "RequestedBy_SiteUser_ID"
                      )}`
                    )}
                    onChange={handleSelectRequestor}
                    validator={isAnonymous ? undefined : requiredValidator}
                    options={renderOptionRequestor(requestorTypeEnum!)}
                    value={
                      isAnonymous
                        ? "Anonymous"
                        : uiControl?.LitRequestedBy.Value ??
                          valueGetter(
                            `${nameOfEventMapObj("Event")}.${nameOfEvent(
                              "RequestedBy_SiteUser"
                            )}`
                          )
                    }
                    disabled={
                      isDisable ||
                      isAnonymous ||
                      !valueGetter(
                        `${nameOfEventMapObj("Event")}.${nameOfEvent(
                          "RequestorType_ID"
                        )}`
                      )
                    }
                  />
                )}
                {isExternalPerson && (
                  <Field
                    uniqueKey="CRMSRequestedBy"
                    name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                      "RequestedBy"
                    )}.Contact`}
                    component={ContactPicker}
                    onChange={handleSelectRequestorExternal}
                    validator={requiredValidator}
                    onError={(error: any) => {
                      localNotificationRef?.current?.pushNotification({
                        type: "error",
                        title: "Pick contact errors",
                        description: error,
                        autoClose: false,
                      });
                    }}
                    placeholder={"Select requestor"}
                    disabled={isDisable}
                    disabledButton={isDisable}
                    displayValue={uiControl?.LitRequestedBy.Value}
                    removeDisplayValue={() => {
                      if (uiControl) {
                        prevRequestorDisplayName.current =
                          uiControl.LitRequestedBy.Value;
                        uiControl.LitRequestedBy.Value =
                          crmsEvent?.RequestedBy.Contact.DisplayName;
                      }
                    }}
                    restoreDisplayValue={() => {
                      if (
                        uiControl &&
                        isHTML(prevRequestorDisplayName.current) &&
                        valueGetter(
                          `${nameOfEventMapObj("Event")}.${nameOfEvent(
                            "RequestedBy"
                          )}.Contact`
                        )
                      ) {
                        uiControl.LitRequestedBy.Value =
                          prevRequestorDisplayName.current;
                      }
                    }}
                  />
                )}
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCValueField
                  label="Position"
                  value={
                    <span
                      title={uiControl?.LitRequestedByPosition.Value}
                      className="cc-text-ellipsis"
                    >
                      {uiControl?.LitRequestedByPosition.Value}
                    </span>
                  }
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Address"
                  value={
                    isHTML(uiControl?.LblRequestedByAddress.Value)
                      ? sanitizeHtml(uiControl?.LblRequestedByAddress.Value)
                      : uiControl?.LblRequestedByAddress.Value
                  }
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Contact"
                  value={
                    isExternalPerson
                      ? renderContact(crmsEvent?.RequestedBy?.Contact)
                      : renderContact(crmsEvent?.RequestedBy?.Contact)
                  }
                />
              </div>
            </div>
          </>
        )}

        {/* CCS-86 comment it as customer request */}
        {/* <div className="cc-field">
            <CCLabel title="On behalf of" isMandatory={isOnbehalfOfRequire} />
            <Field
              uniqueKey="CRMSOnBehalfOf"
              name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                "OnBehalfOf"
              )}.Contact`}
              component={ContactPicker}
              contactClassification={
                searchPersonOnly
                  ? ContactClassification.Person
                  : ContactClassification.SystemInitialise
              }
              onChange={handleSelectOnBehalfOf}
              onError={(error: any) => {
                pushNotification({
                  type: "error",
                  title: "Pick contact errors",
                  description: error,
                  autoClose: false,
                });
              }}
              placeholder="Select on behalf of"
              displayValue={uiControl?.LitOnBehalfOf.Value}
              removeDisplayValue={() => {
                if (uiControl) {
                  prevOnBehalfOfDisplayName.current =
                    uiControl.LitOnBehalfOf.Value;
                  uiControl.LitOnBehalfOf.Value =
                    crmsEvent?.OnBehalfOf?.Contact?.DisplayName;
                }
              }}
              restoreDisplayValue={() => {
                if (
                  uiControl &&
                  isHTML(prevOnBehalfOfDisplayName.current) &&
                  valueGetter(
                    `${nameOfEventMapObj("Event")}.${nameOfEvent(
                      "OnBehalfOf"
                    )}.Contact`
                  )
                ) {
                  uiControl.LitOnBehalfOf.Value =
                    prevOnBehalfOfDisplayName.current;
                }
              }}
              validator={isOnbehalfOfRequire ? requiredValidator : undefined}
            />
          </div> */}
      </section>
    );
  }
);
