import { MailMergeFile } from "@common/pages/settings/mail-merge/model";
// example string: "attachment; filename=Attachment1MB.doc; size=1080320"
export const extractAttachmentInfo = (
  attachmentString: string
): MailMergeFile => {
  const regex = /attachment; filename="?([^";]+)"?; size=(\d+)/;
  const match = regex.exec(attachmentString);
  if (!match) {
    return {};
  }
  const fileName = match[1];
  const extension = fileName.split(".").pop();
  const size = parseInt(match[2]);
  return {
    fileName,
    extension,
    size,
  };
};
