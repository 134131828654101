import { ICCRoute } from "@common/constants/ICCRoute";

export const tobaccoRoute: ICCRoute = {
  path: "tobacco",
  name: "Tobacco",
  children: [
    {
      path: "register",
      name: "Register",
      component: require("./register/_index").default,
    },
    {
      path: "by-type",
      name: "By Type",
      component: require("./by-type/_index").default,
    },
    {
      path: "by-suburb",
      name: "By Suburb",
      component: require("./by-suburb/_index").default,
    },
    {
      path: "by-area",
      name: "By Area",
      component: require("./by-area/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      component: require("./by-officer/_index").default,
    },
    {
      path: "prelodgements",
      name: "Prelodgements",
      component: require("./prelodgements/_index").default,
    },
    {
      path: "closures",
      name: "Closures",
      component: require("./closures/_index").default,
    },
    {
      path: "pending-transfers",
      name: "Pending Transfers",
      component: require("./pending-transfers/_index").default,
    },
    {
      path: ":id(new|[0-9]+)",
      component: require("../premises/[id]/_index").default,
    },
  ],
};
