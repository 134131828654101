import { CORE_INSPECTION_TYPES_ODATA } from "@app/core/components/common/constant";
import { InspectionTableType } from "@app/products/hm/components/action-bar/buttons/change-inspection-type/config";
import { useGlobalStore } from "@common/stores/global/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { GridSelectionDialog } from "@components/dialog/dialog-grid-selection/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ChangeInspectionTypeButton = observer(() => {
  const [
    isOpenConfirmInspectionTypeChangePopup,
    setIsOpenConfirmInspectionTypeChangePopup,
  ] = useState(false);
  const [isOpenInspectionTypeChangePopup, setIsOpenInspectionTypeChangePopup] =
    useState(false);

  const handleInspectionTypeChangePopup = (item: any) => {};
  const { isEmptyGridSelectedRow } = useCCProductListViewStore();
  const {
    inspectionTypeChangeDialogClicked,
    setInspectionTypeChangeDialogClicked,
  } = useGlobalStore();
  const onClickInspectionTypeChange = () => {
    setInspectionTypeChangeDialogClicked(inspectionTypeChangeDialogClicked + 1);
    setIsOpenConfirmInspectionTypeChangePopup(true);
  };

  return (
    <React.Fragment>
      <CCNavButton
        disabled={isEmptyGridSelectedRow}
        title=" Change Inspection Type"
        onClick={() => onClickInspectionTypeChange()}
      />
      {isOpenConfirmInspectionTypeChangePopup && (
        <ConfirmDialog
          title={"Please Confirm"}
          message={
            "This will change the inspection type on selected records, are you sure?"
          }
          height={"auto"}
          width={"35%"}
          onClosePopup={() => setIsOpenConfirmInspectionTypeChangePopup(false)}
          onConfirmYes={() => setIsOpenInspectionTypeChangePopup(true)}
        />
      )}
      {isOpenInspectionTypeChangePopup && (
        <GridSelectionDialog
          height="60%"
          titleHeader={"Please Select The Category - Subcategory"}
          dataUrl={CORE_INSPECTION_TYPES_ODATA}
          columnFields={InspectionTableType}
          setShowDialog={() => setIsOpenInspectionTypeChangePopup(false)}
          onCloseDialog={() => setIsOpenInspectionTypeChangePopup(false)}
          onSubmit={(arg: any) => handleInspectionTypeChangePopup(arg)}
        />
      )}
    </React.Fragment>
  );
});
