import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const DeleteFinancialSummaryButton = observer(() => {
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const { isEmptyGridSelectedRow } = useCCProductListViewStore();

  //TODO: Waiting API
  const handleSubmit = () => {
    setShowDeleteConfirmDialog(false);
  };
  return (
    <>
      <CCNavButton
        title="Delete financial summary"
        onClick={() => {
          setShowDeleteConfirmDialog(true);
        }}
        disabled={isEmptyGridSelectedRow}
      />
      {showDeleteConfirmDialog && (
        <ConfirmDelete
          onClose={() => {
            setShowDeleteConfirmDialog(false);
          }}
          header={"Delete Confirmation"}
          handleSubmit={handleSubmit}
          message="Are you sure you wish to delete the selected Financial Summary?"
        />
      )}
    </>
  );
});
