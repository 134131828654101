import Actions from "@app/core/actions/_index";
import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { Comments } from "@app/core/comments/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { ContactForm } from "@app/core/contacts/_id/components/child-screens/general/_index";
import { ContactDetailTab } from "@app/core/contacts/_id/components/reference-sidebar/detail/_index";
import { ContactHistoryTab } from "@app/core/contacts/_id/components/reference-sidebar/history/_index";
import {
  ContactClassification,
  ContactSubmitActions,
} from "@app/core/contacts/_id/model";
import { useContactStore } from "@app/core/contacts/_id/store";
import { Alerts } from "@app/core/contacts/components/accordions/alerts/_index";
import { useAlertsAccordionStore } from "@app/core/contacts/components/accordions/alerts/store";
import { AlertsButton } from "@app/core/contacts/components/buttons/alert/_index";
import { Documents } from "@app/core/documents/_index";
import Fees from "@app/core/fees/_index";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { PropertyAssociations } from "@app/products/property/components/associations/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const ExistedContact = observer(() => {
  const { alertSelectedRows } = useAlertsAccordionStore();
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const {
    isLoading,
    responseLoadError,
    onSubmit,
    contactId,
    contact,
    reloadContact,
    isInactive,
  } = useContactStore();
  const { settings } = useCommonCoreStore();
  //#region Settings
  const enableFeesAndGarbage = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableFeesAndGarbage]
  );
  const tenantID = getStringValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_CEP_TenantId]
  );
  const isContactExtensionEnabled = getBoolValueSetting(
    settings[ECorporateSettingsField.Global_ContactManager_ShowContactExtension]
  );
  /*   const propertyEXE = getStringValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_PropertyEXE]
  );
  const isQualificationEnabled = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableQualifications]
  );
  const isQualificationAdvancedMode = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_QualificationMode]
  ); */
  //#endregion
  const classification: ContactClassification = parseInt(
    ContactClassification[
      (contact?.ContactClassification_ENUM as any) ?? "Contact"
    ]
  );

  const isShowWorkflow = useMemo(
    () =>
      isContactExtensionEnabled &&
      classification === ContactClassification.Person,
    [isContactExtensionEnabled, classification]
  );

  const isLockedContact = contact?.Is_Contact_Locked;

  const listPanelBar: ITabProps[] = [
    {
      title: "Actions",
      tabType: TabTableType.Actions,
      component: (
        <Actions id={contactId} recordType={RECORDTYPE.CORE_Contact} />
      ),
    },
    {
      title: "Alerts",
      tabType: TabTableType.Alerts,
      component: (
        <Alerts
          contactID={contactId ?? null}
          recordType={RECORDTYPE.CORE_Contact}
        />
      ),
    },
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      component: (
        <Comments id={contactId} recordType={RECORDTYPE.CORE_Contact} />
      ),
    },
    {
      title: "Related",
      component: (
        <PropertyAssociations
          narID={contactId}
          id={contactId}
          componentNumber={eComponent.CORE_Contact}
        />
      ),
    },
    {
      title: "Documents",
      tabType: TabTableType.Documents,
      component: (
        <Documents id={contactId} recordType={RECORDTYPE.CORE_Contact} />
      ),
    },
    {
      title: "Fees",
      tabType: TabTableType.Fees,
      component: <Fees id={contactId} recordType={RECORDTYPE.CORE_Contact} />,
      isVisible: enableFeesAndGarbage,
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Contact"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            reloadContact();
          }}
        />
      ) : (
        <>
          <FormTitle
            title={contact?.DisplayName}
            badge={contact?.GoldenRecord ? "Golden Contact" : undefined}
          />
          <CCManagePageActionBar
            leftActions={[
              isShowWorkflow ? (
                <CCNavButton
                  title={"Workflow"}
                  className="cc-workflow-button"
                  type="sub-workflow"
                  disabled={isLockedContact || isInactive}
                >
                  <CCNavButton title={"Additional details"} disabled />
                </CCNavButton>
              ) : (
                <></>
              ),
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ContactSubmitActions.Save}
                disabled={isLockedContact || isInactive}
              />,
              <CCNavButton
                title={"Add"}
                type="sub"
                disabled={isLockedContact || isInactive}
              >
                <AddActionWithParentButton
                  id={contact?.Contact_Id}
                  recordType={RECORDTYPE.CORE_Contact}
                />
                <AddAttachmentButton
                  recordType={RECORDTYPE.CORE_Contact}
                  id={contact?.Contact_Id}
                />
                <AddCommentButton
                  id={contact?.Contact_Id}
                  recordType={RECORDTYPE.CORE_Contact}
                />
                <CCNavButton title="Alerts" type="dropdown">
                  <AlertsButton
                    recordType={RECORDTYPE.CORE_Contact}
                    onReload={reloadContact}
                    contactId={contact?.Contact_Id}
                  />
                  <AlertsButton
                    isModifyButton
                    isDisabled={alertSelectedRows?.length !== 1}
                    recordType={RECORDTYPE.CORE_Contact}
                    onReload={reloadContact}
                    contactId={contact?.Contact_Id}
                  />
                </CCNavButton>
              </CCNavButton>,
              tenantID ? <CCNavButton title={"Portal"} disabled /> : <></>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
                disabled={isInactive}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {contact && <ContactForm />}
                <TabTable
                  initialPanels={listPanelBar}
                  recordType={RECORDTYPE.CORE_Contact}
                  id={contact?.Contact_Id}
                  disabled={isInactive}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <ContactDetailTab /> },
                    {
                      title: "History",
                      component: <ContactHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
