import { eventEmitter } from "@/App";
import { postAdditionalAddress } from "@app/core/addresses/components/buttons/add-additional-address/api";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { Address_BuildAddress } from "@app/products/town-planning/ppr/[id]/model";
import { ComplexPropertyDetailDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/property-detail/complex/_index";
import { SimplePropertyDetailDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/property-detail/simple/_index";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IAddAdditionalAddressButton {
  id?: number;
  recordType: RECORDTYPE;
}

export const AddAdditionalAddressButton = observer(
  ({ id, recordType }: IAddAdditionalAddressButton) => {
    const [isShowDialog, setIsShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { pushNotification } = useCCAppNotificationStore();
    const { settings } = useCommonCoreStore();
    const [notification, setNotification] = useState<
      IAppNotificationItemAddProps | undefined
    >();

    const isUseSimpleAddressDialog = getBoolValueSetting(
      settings[ECorporateSettingsField.TP_UseSimpleAddressDialog]
    );

    const handleSubmit = async (data: Address_BuildAddress) => {
      if (!id) return;
      setIsLoading(true);
      const response = await postAdditionalAddress(
        id,
        data.Address,
        recordType
      );
      setIsLoading(false);
      if (isSuccessIdentityPacket(response)) {
        setIsShowDialog(false);
        pushNotification({
          type: "success",
          title: "Additional address added successfully.",
        });
        eventEmitter.emit(CCGridEventType.RefreshOData);
        eventEmitter.emit(TabTableEventType.RefreshData);
      } else {
        setNotification({
          title: "Add additional address failed.",
          type: "error",
          autoClose: false,
          description: response.data?.Errors ?? response?.error,
        });
      }
    };

    return (
      <>
        <CCNavButton
          title={"Additional address"}
          onClick={() => {
            setNotification(undefined);
            setIsShowDialog(true);
          }}
          isLoading={isLoading}
        />
        {isShowDialog &&
          (isUseSimpleAddressDialog ? (
            <SimplePropertyDetailDialog
              onClose={() => setIsShowDialog(false)}
              onSubmit={handleSubmit}
              notification={notification}
              isLoading={isLoading}
            />
          ) : (
            <ComplexPropertyDetailDialog
              onClose={() => setIsShowDialog(false)}
              onSubmit={handleSubmit}
              notification={notification}
              isLoading={isLoading}
            />
          ))}
      </>
    );
  }
);
