import { IAutoFieldParams } from "@components/auto-complete/model";
import { Button } from "@progress/kendo-react-buttons";
import { AutoComplete as AutoCompleteKendo } from "@progress/kendo-react-dropdowns";
import React from "react";
import "./AutoComplete.scss";

const AutoComplete = (props: IAutoFieldParams) => {
  return (
    <div className="cc-auto-complete">
      <AutoCompleteKendo
        className="cc-input-picker-field"
        data={props.data}
        suggest={props.suggest}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        style={{
          flexGrow: 9,
          width: 0,
        }}
        name={props.fieldName}
        disabled={props.disabled}
      />
      <Button
        className="cc-input-picker-button"
        iconClass="fa fa-ellipsis-h"
        onClick={() => {
          props.showPopupEvent();
        }}
      />
    </div>
  );
};

export default AutoComplete;
