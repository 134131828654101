import { ICCRoute } from "@common/constants/ICCRoute";

export const govtReportsRoute: ICCRoute = {
  path: "govt-report",
  name: "Govt. Reports",
  children: [
    {
      path: "tobacco-retailers",
      name: "Tobacco Retailers",
      component: require("./tobacco-retailers/_index").default,
    },
    {
      path: "tobacco-inspections",
      name: "Tobacco Inspections",
      component: require("./tobacco-inspections/_index").default,
    },
    {
      path: "vending-machines",
      name: "Vending Machines",
      component: require("./vending-machines/_index").default,
    },
    {
      path: "eat-in-facilities",
      name: "Eat In Facilities",
      component: require("./eat-in-facilities/_index").default,
    },
    {
      path: "outdoor-dining",
      name: "Outdoor Dining",
      component: require("./outdoor-dining/_index").default,
    },
    {
      path: "licenced",
      name: "Licenced",
      component: require("./licenced/_index").default,
    },
  ],
};
