import { Ancestor } from "@app/core/further-info/[id]/model";
import { getRefNoParent, mapEnum } from "@app/core/inspections/[id]/util";
import { useNoticesStore } from "@app/core/notices/[id]/store";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { RECORDTYPE } from "@common/constants/recordtype";
import { formatDisplayValue } from "@common/utils/formatting";
import { getPageLinkByRecordType } from "@common/utils/getPageLinkByRecordType";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { parseInt } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const getBubbleUpValue = (
  bubbleUpObj: BubbleUpIdentifier,
  ancestor: Ancestor | undefined
) => {
  return (
    <Link
      className="cc-detail-tab-link"
      to={{
        pathname: getPageLinkByRecordType(
          mapEnum(
            bubbleUpObj.SourceIdentifier?._RecordSourceType_ENUM,
            RECORDTYPE
          ),
          bubbleUpObj.SourceIdentifier?._RecordSource_ID
        ),
      }}
    >
      {getRefNoParent(ancestor)}
    </Link>
  );
};

export const NoticeDetailTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();
  const {
    isLoadingDetails,
    loadNoticeDetails,
    notices,
    responseLoadError,
    ancestor,
    parentBubbleUpDetails,
  } = useNoticesStore();

  const noticesID = id ? parseInt(id) : lastSelectedRow?.ID;
  const isEventParent =
    mapEnum(
      parentBubbleUpDetails?.SourceIdentifier?._RecordSourceType_ENUM,
      RECORDTYPE
    ) === RECORDTYPE.CUSTOMERSERVICE_Event;

  useEffect(() => {
    if (noticesID) loadNoticeDetails(noticesID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noticesID]);

  if (isLoadingDetails) return <Loading isLoading={isLoadingDetails} />;

  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          noticesID && loadNoticeDetails(+noticesID);
        }}
      />
    );
  }

  if (!notices) return <NoData />;

  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Notice type:"}
          value={notices?.NoticeType?.Name}
        />
        <ProductReferenceRow
          title={"Date served:"}
          value={formatDisplayValue(notices?.DateServed, DATE_FORMAT.DATE)}
        />
        <ProductReferenceRow
          title={"Compliance due:"}
          value={formatDisplayValue(
            notices?.DateComplianceDue,
            DATE_FORMAT.DATE
          )}
        />
      </ProductReferenceBlock>
      <hr />
      <ProductReferenceBlock>
        {parentBubbleUpDetails ? (
          <ProductReferenceRow
            title={isEventParent ? "Event:" : "Parent:"}
            value={getBubbleUpValue(parentBubbleUpDetails, ancestor)}
          />
        ) : null}
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
