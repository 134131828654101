import { ICCRoute } from "@common/constants/ICCRoute";

export const premisesRoute: ICCRoute = {
  path: "premises",
  name: "Premises",
  children: [
    {
      path: "register",
      name: "Register",
      component: require("./register/_index").default,
    },
    {
      path: "by-type",
      name: "By Type",
      component: require("./by-type/_index").default,
    },
    {
      path: "by-suburb",
      name: "By Suburb",
      component: require("./by-suburb/_index").default,
    },
    {
      path: "by-area",
      name: "By Area",
      component: require("./by-area/_index").default,
    },
    {
      path: "by-risk",
      name: "By Risk",
      component: require("./by-risk/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      component: require("./by-officer/_index").default,
    },
    {
      path: "by-fsp",
      name: "By FSP",
      component: require("./by-fsp/_index").default,
    },
    {
      path: "by-property",
      name: "By Property",
      component: require("./by-property/_index").default,
    },
    {
      path: "by-star-rating",
      name: "By Star Rating",
      component: require("./by-star-rating/_index").default,
    },
    {
      path: "by-category",
      name: "By Category",
      component: require("./by-category/_index").default,
    },
    {
      path: "by-proprietor",
      name: "By Proprietor",
      component: require("./by-proprietor/_index").default,
    },
    {
      path: "pcba",
      name: "PCBA",
      component: require("./pcba/_index").default,
    },
    {
      path: "perscribed-accom",
      name: "Perscribed Accom",
      component: require("./perscribed-accom/_index").default,
    },
    {
      path: "pools-spas",
      name: "Pools / Spas",
      component: require("./pools-spas/_index").default,
    },
    {
      path: "cooling-towers",
      name: "Cooling Towers",
      component: require("./cooling-towers/_index").default,
    },
    {
      path: "drinking-water",
      name: "Drinking Water",
      component: require("./drinking-water/_index").default,
    },
    {
      path: "fast-choices",
      name: "Fast Choices",
      component: require("./fast-choices/_index").default,
    },
    {
      path: "food-stalls",
      name: "Food Stalls",
      component: require("./food-stalls/_index").default,
    },
    {
      path: "food-vehicles",
      name: "Food Vehicles",
      component: require("./food-vehicles/_index").default,
    },
    {
      path: "os-fees",
      name: "OS Fees",
      component: require("./os-fees/_index").default,
    },
    {
      path: "non-renewable",
      name: "Non Renewable",
      component: require("./non-renewable/_index").default,
    },
    {
      path: "streatrader",
      name: "Streatrader",
      component: require("./streatrader/_index").default,
    },
    {
      path: "prelodgements",
      name: "Prelodgements",
      component: require("./prelodgements/_index").default,
    },
    {
      path: "closures",
      name: "Closures",
      component: require("./closures/_index").default,
    },
    {
      path: "pending-transfers",
      name: "Pending Transfers",
      component: require("./pending-transfers/_index").default,
    },
    {
      path: "due-conditions",
      name: "Due Conditions",
      component: require("./due-conditions/_index").default,
    },
    {
      path: "comp-conditions",
      name: "Comp. Conditions",
      component: require("./comp-conditions/_index").default,
    },
    {
      path: "alerts",
      name: "Alerts",
      component: require("./alerts/_index").default,
    },
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
