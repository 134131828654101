import { odataCoreGetConditionReferences } from "@app/core/components/common/utils";
import { DeleteButton } from "@app/core/delete/buttons/_index";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { LocalLawsActionBarNavDropdown } from "@app/products/local-laws/components/toolbar/nav-dropdown/_index";
import { localLawsRoute } from "@app/products/local-laws/route";
import { Svc_ConditionReference } from "@app/products/local-laws/system-admin/conditions/model";
import { systemAdminRoute } from "@app/products/local-laws/system-admin/route";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useCommonCoreStore } from "@common/stores/core/store";
import { nameOfFactory } from "@common/utils/common";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { localLawsSystemAdminConditionsCustom } from "./config";

const nameOf = nameOfFactory<Svc_ConditionReference>();
export default observer(() => {
  //== #region security ==//
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_ConditionReference,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });
  //== #endregion security ==//

  const { settings } = useCommonCoreStore();
  const configGrid = localLawsSystemAdminConditionsCustom(settings);

  // Implement later - Use for bookmark
  // const { listViewDisplayURL, listViewDisplayTitle, recordDisplayURL } =
  //   llSystemAdminConditionsBookmark;

  useCCListViewActionBar({
    title: localLawsRoute.name,
    leftComponents: [
      <LocalLawsActionBarNavDropdown category={systemAdminRoute.path} />,
    ],
    centerComponents: [
      <DeleteButton
        recordType={RECORDTYPE.CORE_ConditionReference}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,

      /**
       * TODO: Implementing later when finishing the manage page of this list
       */
      // <ActionBarInfoIcon />,
      // <ListViewBookmarkIcon
      //   linkUrl={LOCAL_LAWS_MANAGE_ROUTE}
      //   productType={PRODUCT_TYPE.LLPermits}
      //   recordType={RECORDTYPE.CORE_ConditionReference}
      //   detail={listViewDisplayURL}
      //   displayName={recordDisplayURL}
      //   listViewDetail={listViewDisplayTitle}
      //   listViewDisplayName={listViewDisplayURL}
      // />,
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        dataUrl={odataCoreGetConditionReferences(PRODUCT_TYPE_NUMBER.LLPermits)}
        columnFields={configGrid.columns}
        primaryField={nameOf("ID")}
        state={configGrid.state}
      />
    </LoadingPermissionWrapper>
  );
});
