import { getViewConfigurations } from "@app/products/property/assessments/master-properties/api";
import { getMasterPropertiesValuations } from "@app/products/property/assessments/master-properties/[id]/components/child-screens/valuations/api";
import {
  colValuationsByRatingPeriod,
  colValuationsByValuations,
} from "@app/products/property/assessments/master-properties/[id]/components/child-screens/valuations/config";
import { VO_MasterPropertyValuation } from "@app/products/property/assessments/master-properties/[id]/components/child-screens/valuations/model";
import { useMasterPropertyStore } from "@app/products/property/assessments/master-properties/[id]/store";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";

interface MasterPropertyValuationProps {
  isListByValuation?: boolean;
}

const nameOf = nameOfFactory<VO_MasterPropertyValuation>();
export const ValuationsList = observer(
  ({ isListByValuation = true }: MasterPropertyValuationProps) => {
    const { masterPropertyId } = useMasterPropertyStore();
    const [valuationsData, setValuationsData] = useState<
      VO_MasterPropertyValuation[]
    >([]);
    const [viewconfiguration, setViewconfiguration] = useState<ICCViewColumn[]>(
      []
    );
    const colValuationFields = isListByValuation
      ? colValuationsByValuations
      : colValuationsByRatingPeriod;
    const [isGridLoading, setIsGridLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
      if (masterPropertyId) {
        setIsGridLoading(true);
        getMasterPropertiesValuations(masterPropertyId, isListByValuation).then(
          (response) => {
            setIsGridLoading(false);
            if (response?.data?.Valuations) {
              setValuationsData(response.data.Valuations);
            }
          }
        );
      }
    }, [masterPropertyId, isListByValuation]);

    const renderColumnFields = (colField: IColumnFields[]) => {
      const newUpgratedColumnField: IColumnFields[] = [];
      colField.forEach((column: IColumnFields) => {
        const foundIndex: number = viewconfiguration.findIndex(
          (element) => element?.DataColumn === column.field && !element.IsHidden
        );
        if (foundIndex && viewconfiguration[foundIndex]?.ColumnName) {
          column.title = viewconfiguration[foundIndex].ColumnName;
          newUpgratedColumnField.push(column);
        }
      });
      return newUpgratedColumnField;
    };

    useEffectOnce(() => {
      getViewConfigurations(ViewConfiguration.MasterProperty_Valuations).then(
        (response) => {
          if (isSuccessResponse(response) && response?.data) {
            setViewconfiguration(response.data.ColumnDefinitions?.Columns);
          }
          setIsLoading(false);
        }
      );
    });

    if (isLoading) return <Loading isLoading={isLoading} />;

    return (
      <CCGrid
        className="cc-property-valuations-grid"
        data={valuationsData}
        columnFields={renderColumnFields(colValuationFields)}
        primaryField={nameOf("Valuation_Id")}
        isLoading={isGridLoading}
        state={{
          sort: [{ field: nameOf("Valuation_Effective_Date"), dir: "desc" }],
        }}
      />
    );
  }
);
