import { odataCoreGetKeywordsByType } from "@app/core/components/common/utils";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { Keyword } from "@common/models/keyword";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Keyword>();

export const renderOptionInputPickerTheme = (
  isCondition: boolean,
  productType: PRODUCT_TYPE_NUMBER,
  isGroupCategory: boolean
) => {
  const colKeyWordsPicker: IColumnFields[] = isGroupCategory
    ? [
        { title: "Name", field: nameOf("Name") },
        { title: "Description", field: nameOf("Description") },
        { title: "Category", field: nameOf("Category") },
      ]
    : [
        { title: "Name", field: nameOf("Name") },
        { title: "Description", field: nameOf("Description") },
      ];
  const optionInputPickerTheme: IOptionInputPicker = {
    dialog: {
      titleHeader: "Condition Theme",
    },
    boxSearch: {
      colSearch: nameOf("Name"),
      colShowComboboxSearch: [
        {
          title: "Name",
          field: nameOf("Name"),
        },
      ],
    },
    grid: {
      columnFields: colKeyWordsPicker,
      primaryField: "Keyword_ID",
      dataUrl: odataCoreGetKeywordsByType(
        isCondition
          ? KEYWORD_TYPE.Core_ConditionTheme
          : KEYWORD_TYPE.Core_RestrictionTheme,
        productType
      ),
      selectableMode: "single",
      isAutoHiddenPager: false,
      itemPerPage: 15,
      state: {
        sort: [
          {
            field: nameOf("Name"),
            dir: "asc",
          },
        ],
        group: isGroupCategory
          ? [
              {
                field: nameOf("Category"),
              },
            ]
          : undefined,
      },
    },
  };
  return optionInputPickerTheme;
};
