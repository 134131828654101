import { NOTICES_NEW_ROUTE } from "@app/core/notices/[id]/constant";
import { IParentNoticesSection } from "@app/core/notices/[id]/model";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { cloneDeep } from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";

interface IAddNoticeWithParentButtonProps {
  id?: number;
  recordType: RECORDTYPE;
  productType: PRODUCT_TYPE_NUMBER;
  bubbleUps?: BubbleUpIdentifier[];
  isDisabled?: boolean;
}

export const AddNoticeWithParentButton = ({
  id,
  recordType,
  productType,
  bubbleUps,
  isDisabled = false,
}: IAddNoticeWithParentButtonProps) => {
  const history = useHistory();
  return (
    <CCNavButton
      title="Notice"
      onClick={() => {
        history.push(NOTICES_NEW_ROUTE, {
          parent: {
            id,
            recordType,
            productType,
            bubbleUps: cloneDeep(bubbleUps),
          } as IParentNoticesSection,
        });
      }}
      disabled={isDisabled}
    />
  );
};
