import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { LookupTablesForm } from "@app/products/crms/system-admin/lookup-tables/[id]/components/forms/components/child-screens/general/_index";
import { useLookupTableStore } from "@app/products/crms/system-admin/lookup-tables/[id]/store";
import { CRMSSystemAdminLookupTablesDetailsTab } from "@app/products/crms/system-admin/lookup-tables/components/reference-sidebar/details/_index";
import { CRMSSystemAdminLookupTableHistoryTab } from "@app/products/crms/system-admin/lookup-tables/components/reference-sidebar/history/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedLookupTables = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const isNew = useIsNew();
  const {
    lookupTable,
    loadLookupTable,
    isLoading,
    responseLoadError,
    onSubmit,
    isInactive,
  } = useLookupTableStore();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();

  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.CUSTOMERSERVICE_Form_LookupTable,
    productType: PRODUCT_TYPE_NUMBER.CustomerService,
  });

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        lookupTable?.CustomerService_LookupTable_Id,
        lookupTable?.LookupTableType_Name,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Lookup tables - ${
        lookupTable?.CustomerService_LookupTable_Id ?? 0
      }`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CustomerService,
      RecordType_ENUM: RECORDTYPE.CUSTOMERSERVICE_LookupTable,
      Record_ID: lookupTable?.CustomerService_LookupTable_Id ?? 0,
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading || isLoadingPermission} isFullScreen />
      <FormNavigation title={"Lookup Tables"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadLookupTable(parseInt(id), isNew)}
        />
      ) : (
        <>
          <FormTitle title={"Lookup Tables"} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
                disabled={
                  isInactive ||
                  !checkPermissions([
                    FormAction.CORE_ALLOW_SAVE,
                    FormAction.CORE_ALLOW_EDIT,
                  ])
                }
              />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {lookupTable && <LookupTablesForm />}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <CRMSSystemAdminLookupTablesDetailsTab />,
                    },
                    {
                      title: "History",
                      component: <CRMSSystemAdminLookupTableHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
