import { history } from "@/AppRoutes";
import {
  getFinanceFeesById,
  postSaveFinanceFee,
} from "@app/core/fees/[id]/api";
import { FINANCE_FEE_MANAGE_ROUTE } from "@app/core/fees/[id]/constant";
import {
  Fee,
  IFeeParentSection,
  Svc_FinanceFee,
} from "@app/core/fees/[id]/model";
import { replaceBreakLineWithBrTag } from "@app/core/fees/[id]/util";
import { getParentSummary } from "@app/core/further-info/[id]/api";
import { Ancestor } from "@app/core/further-info/[id]/model";
import { BubbleUpType } from "@app/core/inspections/[id]/model";
import { mapEnum } from "@app/core/inspections/[id]/util";
import { postCheckIsSuperAdmin } from "@app/core/notices/[id]/api";
import { getSourceIdentifier } from "@app/core/notices/[id]/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { APIResponseStatus } from "@common/constants/response-status";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { SyntheticEvent, createContext, useContext } from "react";

configure({ enforceActions: "always" });

class FeesStore {
  private _financeFee?: Svc_FinanceFee = undefined;
  private _isLoading: boolean = false;
  private _isLoadingDetails: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _responseDetailLoadError?: APIResponseError = undefined;
  private _onSubmit?: (event: SyntheticEvent<any>) => void = undefined;
  private _isSuperAdmin?: boolean = undefined;
  private _isLoadingSuperAdmin: boolean = false;
  private _parents?: IFeeParentSection = undefined; // Check interface later
  private _isFormModified: boolean = false;
  private _notification?: IAppNotificationItemAddProps = undefined;
  private _ancestor?: Ancestor = undefined;
  private _litDisplayStatus?: string = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isLoadingDetails() {
    return this._isLoadingDetails;
  }
  setIsLoadingDetails = (isLoadingDetails: boolean) => {
    runInAction(() => {
      this._isLoadingDetails = isLoadingDetails;
    });
  };

  get isLoadingSuperAdmin() {
    return this._isLoadingSuperAdmin;
  }
  setIsLoadingSuperAdmin = (isLoadingSuperAdmin: boolean) => {
    runInAction(() => {
      this._isLoadingSuperAdmin = isLoadingSuperAdmin;
    });
  };

  get isSuperAdmin() {
    return this._isSuperAdmin;
  }
  setIsSuperAdmin = (isSuperAdmin: boolean) => {
    runInAction(() => {
      this._isSuperAdmin = isSuperAdmin;
    });
  };

  get litDisplayStatus() {
    return this._litDisplayStatus;
  }
  setLitDisplayStatus = (litDisplayStatus?: string) => {
    runInAction(() => {
      this._litDisplayStatus = litDisplayStatus;
    });
  };

  get parents() {
    return this._parents;
  }
  setParents = (parents: IFeeParentSection) => {
    runInAction(() => {
      this._parents = parents;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get responseDetailLoadError() {
    return toJS(this._responseDetailLoadError);
  }
  setResponseDetailLoadError = (responseDetailLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseDetailLoadError = responseDetailLoadError;
    });
  };

  get financeFee() {
    return toJS(this._financeFee);
  }
  setFinanceFee = (financeFee?: Svc_FinanceFee) => {
    runInAction(() => {
      this._financeFee = financeFee;
    });
  };

  get isFormModified() {
    return this._isFormModified;
  }

  setFormIsModified = (isFormModified: boolean) => {
    runInAction(() => {
      this._isFormModified = isFormModified;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get notification() {
    return this._notification;
  }
  setNotification = (
    notification: IAppNotificationItemAddProps | undefined
  ) => {
    runInAction(() => {
      this._notification = notification;
    });
  };

  get ancestor() {
    return this._ancestor;
  }
  setAncestor = (ancestor?: Ancestor) => {
    runInAction(() => {
      this._ancestor = ancestor;
    });
  };

  resetStore = () => {
    runInAction(() => {
      this._financeFee = undefined;
      this._isLoading = false;
      this._isLoadingDetails = false;
      this._isLoadingSuperAdmin = false;
      this._responseLoadError = undefined;
      this._responseDetailLoadError = undefined;
      this._isSuperAdmin = undefined;
      this._isFormModified = false;
      this._ancestor = undefined;
      this._parents = undefined;
      this._litDisplayStatus = undefined;
    });
  };

  loadParent = async (parent: IFeeParentSection) => {
    this.setParents(parent);
    const response = await getParentSummary(parent.id, parent.recordType);
    if (isSuccessResponse(response) && response.data) {
      this.setAncestor(response.data);
      return true;
    } else {
      appNotificationStore.pushNotification({
        type: "error",
        autoClose: false,
        title: response?.error ?? "Load parent action data failed",
      });
      return false;
    }
  };

  checkIsSuperAdmin = async (userID: number) => {
    this.setIsLoadingSuperAdmin(true);
    const response = await postCheckIsSuperAdmin(userID);
    if (isSuccessResponse(response)) {
      this.setIsSuperAdmin(response.data ?? false);
    } else {
      this.setResponseLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    }
    this.setIsLoadingSuperAdmin(false);
  };

  loadFinanceFees = async (id: number, parentID = "", amount = "") => {
    this.setIsLoading(true);
    let errorResponse = undefined;
    let newData: Svc_FinanceFee | undefined = undefined;

    const response = await getFinanceFeesById(id, parentID, amount);
    if (isSuccessResponse(response) && response.data) {
      newData = response.data?.ReturnObj;
      this.setFinanceFee(newData);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }

    const sourceIdentifier = getSourceIdentifier(
      newData?.FinanceFee?._BubbleUps,
      BubbleUpType.Parent
    );

    if (sourceIdentifier) {
      const newParent = {
        ...this.parents,
        id: sourceIdentifier._RecordSource_ID,
        recordType: mapEnum(
          sourceIdentifier._RecordSourceType_ENUM,
          RECORDTYPE
        ),
      };
      await this.loadParent(newParent as IFeeParentSection);
    } else if (this.parents) {
      await this.loadParent(this.parents);
    }

    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);

    // Push notification
    if (this.notification && !errorResponse) {
      appNotificationStore.pushNotification(this.notification);
      this.setNotification(undefined);
    }
    if (this.parents?.notification && !errorResponse) {
      this.parents?.notification.forEach(
        (notification: IAppNotificationItemAddProps) => {
          appNotificationStore.pushNotification(notification);
        }
      );
      this.setParents({ ...this.parents, notification: [] });
      //Cleanup noti parent - In case: new -> existed finance fee (state only have noti)
      window.history.replaceState({}, "");
    }
  };

  saveFinanceFee = async (fee: Fee, isNew: boolean) => {
    this.setIsLoading(true);
    let newFee = undefined;

    // The fee manage page in the HUB need <br/> tag to display the new line in view mode so when save the fee, we need to replace the new line with <br/> tag
    fee.Description = replaceBreakLineWithBrTag(fee?.Description);

    if (this.parents?.recordType === RECORDTYPE.CORE_Invoice) {
      newFee = { ...fee, Invoice_ID: this.parents.id };
    }

    const response = await postSaveFinanceFee((newFee as Fee) ?? fee);
    this.setIsLoading(false);

    if (isSuccessIdentityPacket(response)) {
      this.setFormIsModified(false);
      if (isNew) {
        history.replace(`${FINANCE_FEE_MANAGE_ROUTE}/${response.data?.ID}`, {
          parent: {
            notification: [
              {
                autoClose: true,
                title: "Record successfully saved.",
                type: "success",
              },
            ],
          },
        });
      } else {
        this.setNotification({
          autoClose: true,
          title: "Record successfully saved.",
          type: "success",
        });
        this.loadFinanceFees(response.data?.ID);
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: response?.error ?? "Finance fee create failed",
        type: "error",
      });
    }
  };

  loadDetails = async (id: number, parentID = "", amount = "") => {
    this.setIsLoadingDetails(true);
    this.setResponseDetailLoadError(undefined);

    const response = await getFinanceFeesById(id, parentID, amount);
    this.setIsLoadingDetails(false);
    if (isSuccessResponse(response) && response.data) {
      this.setFinanceFee(response.data?.ReturnObj);
    } else {
      this.setResponseDetailLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    }
  };
}

const FeesStoreContext = createContext(new FeesStore());
export const useFeesStore = () => {
  return useContext(FeesStoreContext);
};
