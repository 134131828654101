import {
  apiCoreCheckIsSuperAdmin,
  apiCoreRegisteredAddress,
} from "@app/core/components/common/utils";
import {
  LOCAL_LAWS_GET_PERMIT_HANDLER_URL,
  LOCAL_LAWS_GET_PERMIT_LOVS_URL,
  LOCAL_LAWS_PERMIT_API_URL,
} from "@app/products/local-laws/[id]/constant";
import {
  Permit,
  PermitRegisterHandlerRequest,
  TApiIdentity,
  TApiIdentityResponse,
  TApiIsSuperAdmin,
  TApiIsSuperAdminResponse,
  TApiLoadPermitById,
  TApiLoadPermitByIdResponse,
  TApiLoadPermitLovs,
  TApiLoadPermitLovsResponse,
  TApiPermitHandler,
  TApiPermitHandlerResponse,
} from "@app/products/local-laws/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import {
  Address,
  Address_BuildAddress,
} from "@common/input-pickers/address/model";
import { IIdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const getPermitFormById = async (
  permitId: number,
  cancelToken: CancelToken
): Promise<TApiLoadPermitById> => {
  try {
    return await CoreAPIService.getClient().get<TApiLoadPermitByIdResponse>(
      `${LOCAL_LAWS_PERMIT_API_URL}/${permitId}`,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const getPermitFormLOVs = async (
  cancelToken: CancelToken
): Promise<TApiLoadPermitLovs> => {
  try {
    return await CoreAPIService.getClient().get<TApiLoadPermitLovsResponse>(
      LOCAL_LAWS_GET_PERMIT_LOVS_URL,
      {
        cancelToken,
      }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const checkIsSuperAdmin = async (
  userID: number,
  cancelToken: CancelToken
): Promise<TApiIsSuperAdmin> => {
  try {
    return await CoreAPIService.getClient().post<TApiIsSuperAdminResponse>(
      apiCoreCheckIsSuperAdmin(userID),
      {
        cancelToken,
      }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const postPermitFormHandler = async (
  permitRegisterHandlerRequest: PermitRegisterHandlerRequest,
  cancelToken: CancelToken
): Promise<TApiPermitHandler> => {
  try {
    return await CoreAPIService.getClient().post<TApiPermitHandlerResponse>(
      LOCAL_LAWS_GET_PERMIT_HANDLER_URL,
      permitRegisterHandlerRequest,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const setupPermitPickSiteAddress = async (
  address?: Address
): Promise<APIResponse<IIdentityPacket<Address_BuildAddress> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<Address_BuildAddress>
    >(apiCoreRegisteredAddress(), address);
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const postSavePermitForm = async (
  permit: Permit,
  cancelToken: CancelToken
): Promise<TApiIdentity> => {
  try {
    return await CoreAPIService.getClient().post<TApiIdentityResponse>(
      LOCAL_LAWS_PERMIT_API_URL,
      permit,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
