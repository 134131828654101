import { ChangeVIPButton } from "@app/products/crms/[id]/components/forms/components/action-bar/buttons/change-vip/_index";
import { CRMSEventForm } from "@app/products/crms/[id]/components/forms/components/child-screens/general/_index";
import { CRMSEventDetailsTab } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/details/_index";
import { CRMSGuidanceTab } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/guidance/_index";
import {
  CS_EventStatus,
  ElementDisplayStatus,
  IDataStateURLToNewEventPage,
} from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import {
  CS_RequestSection,
  NewEventCloneParams,
} from "@app/products/crms/components/dialogs/clone-new-event/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { IPermissionsWrapperProps } from "@common/hocs/permissions/model";
import { renderWithPermissionsWrapper } from "@common/hocs/permissions/render-with-permission-wrapper";
import { useIsNew } from "@common/hooks/useIsNew";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const NewCRMSEventComponent = observer(() => {
  const location = useLocation();
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const params = new URLSearchParams(location.search);
  const articleId = params.get("article_id");
  const [showSlideBar, setShowSlideBar] = useState(true);

  //Store
  const { settings } = useCommonCoreStore();

  const {
    isLoading,
    isLoadingKB,
    isLoadingSendForAction,
    setShowEventCategorisedAs,
    responseLoadError,
    loadCRMSEvent,
    crmsEvent,
    onSubmit,
    formValid,
    generateBadge,
    uiControl,
    isHasVip,
    setArticleID,
  } = useCRMSEventStore();

  //Memo values
  const newEventCloneState = useMemo(() => {
    const dataStateUrl = location.state as IDataStateURLToNewEventPage;
    return dataStateUrl?.cloneNewEventState as NewEventCloneParams;
  }, [location.state]);

  const isSendForActionDisabled = useMemo(() => {
    return !formValid || !!crmsEvent?.ServiceStandard?.Flag_System;
  }, [formValid, crmsEvent?.ServiceStandard?.Flag_System]);

  //Data settings
  const enableQuickCloseSetting = getBoolValueSetting(
    settings[
      ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandard_EnableQuickClose
    ]
  );
  const actionOfficerLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Common_ActionOfficerLabel]
  );

  //Show pick category
  useEffect(() => {
    const isNotIncludeSameCategory =
      !newEventCloneState?.detailsToCopy?.includes(
        CS_RequestSection.SameCategory
      );
    const isNotCloneEventSameIssue = !newEventCloneState?.sameIssue;
    const isNotHaveArticleId = !articleId;

    if (
      isNew &&
      isNotIncludeSameCategory &&
      isNotCloneEventSameIssue &&
      isNotHaveArticleId // Todo: check and handle this case (articleId and permission) later when having solution for it.
    ) {
      setShowEventCategorisedAs(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, newEventCloneState, articleId]);

  //Load form data
  useEffectOnce(() => {
    if (articleId) {
      setArticleID(parseInt(articleId));
    }
    //Load data when we have permission
    if (newEventCloneState && newEventCloneState?.isClone) {
      loadCRMSEvent(parseInt(id), isNew, newEventCloneState);
      window.history.replaceState({}, "");
    } else {
      loadCRMSEvent(parseInt(id), isNew);
    }
  });

  const isLoadingPage = isLoading || isLoadingKB || isLoadingSendForAction;

  return (
    <>
      <Loading isLoading={isLoadingPage} isFullScreen />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadCRMSEvent(parseInt(id), isNew)}
        />
      ) : (
        <>
          <FormNavigation title="CRMS Event" />
          <FormTitle
            title={"New Event"}
            badge={generateBadge(CS_EventStatus[CS_EventStatus.Draft])}
          />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
                isDefaultActive
              >
                <CCNavButton
                  title={"Send for action"}
                  onClick={onSubmit}
                  disabled={isSendForActionDisabled}
                  name={ActionSubmitActions.SendForAction}
                />
                <CCNavButton
                  title={"Close event"}
                  onClick={onSubmit}
                  name={ActionSubmitActions.CloseEvent}
                  disabled={
                    !formValid ||
                    crmsEvent?.EventStatus_ENUM === CS_EventStatus.Closed ||
                    !(
                      enableQuickCloseSetting &&
                      crmsEvent?.ServiceStandard &&
                      crmsEvent?.ServiceStandard?.EnableQuickClose
                    )
                  }
                />
                <CCNavButton
                  title={"Change category"}
                  onClick={onSubmit}
                  name={ActionSubmitActions.ChangeCategory}
                />
                <CCNavButton
                  title={"Reschedule"}
                  onClick={onSubmit}
                  name={ActionSubmitActions.RescheduleAction}
                  disabled={
                    crmsEvent?.EventStatus_ENUM === CS_EventStatus.Closed ||
                    uiControl?.DivReschedule.DisplayStatus !==
                      ElementDisplayStatus.Visible
                  }
                />
                <CCNavButton
                  title={
                    actionOfficerLabel
                      ? `Change ${actionOfficerLabel}`
                      : "Change action officer"
                  }
                  onClick={onSubmit}
                  name={ActionSubmitActions.ChangeActionOfficer}
                />
                <ChangeVIPButton hidden={!isHasVip} />
                <CCNavButton
                  title="Special interest"
                  onClick={onSubmit}
                  name={ActionSubmitActions.SpecialInterest}
                />
                <CCNavButton
                  title="Security"
                  onClick={onSubmit}
                  name={ActionSubmitActions.AccessControl}
                />
              </CCNavButton>,
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
              />,
              <CCNavButton title={"Add"} type="sub" disabled={isNew}>
                <AddActionButton />
                <CCNavButton title={"Contact"} />
                <CCNavButton title={"Attachment"} />
                <CCNavButton title={"Comment"} />
                <CCNavButton title={"Communication"} />
                {/* TODO: Implement later */}
                {/* <CCNavButton title={"Councillor comment"} /> */}
                <CCNavButton title={"Document"} />
              </CCNavButton>,
              <CCNavButton title={"View"} />,
              <CCNavButton title={"More Options"} type="more">
                <CCNavButton title={"Check map"} />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {crmsEvent && <CRMSEventForm />}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <CRMSEventDetailsTab />,
                    },
                    {
                      title: "Guidance",
                      component: <CRMSGuidanceTab isManagePage />,
                      isVisible: !!crmsEvent?.BulletinBoardID,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});

const renderProps: IPermissionsWrapperProps = {
  component: NewCRMSEventComponent,
  permissions: { productType: PRODUCT_TYPE_NUMBER.CustomerService },
  useNewPermissionDataCreator: (props: IPermissionsWrapperProps) => {
    return {
      formIdentifier: FormIdentifier.CUSTOMERSERVICE_Form_Event,
      productType: props.permissions.productType,
      formAction: FormAction.CORE_ALLOW_NEW,
    };
  },
};

export const NewCRMSEvent = renderWithPermissionsWrapper(renderProps);
