import {
  StatusColumnGroupSystemAdminConditions,
  Svc_ConditionReference,
} from "@app/products/local-laws/system-admin/conditions/model";
import { SettingsMap } from "@app/products/property/system-admin/settings/model";
import { DECIMAL_FORMAT } from "@common/constants/common-format";
import { configGrid, configGridCustom } from "@common/models/configGrid";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { getNumberValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { State } from "@progress/kendo-data-query";
import { cloneDeep } from "lodash";

const nameOf = nameOfFactory<Svc_ConditionReference>();
export const localLawsSystemAdminConditions: configGrid = {
  columns: [
    {
      field: nameOf("SortIndex"),
      title: "Sort",
      format: DECIMAL_FORMAT.DECIMAL1,
    },
    { field: nameOf("Title"), title: "Title" },
    { field: nameOf("Condition"), title: "Condition" },
    { field: nameOf("Group"), title: "Group" },
  ],
  state: {
    sort: [
      {
        field: nameOf("ID"),
        dir: "asc",
      },
    ],
  },
};

export const localLawsSystemAdminConditionsCustom: configGridCustom = (
  settings: SettingsMap
) => {
  const displayConditionsInGroup = getNumberValueSetting(
    settings[
      ECorporateSettingsField.LLP_Views_Conditions_DisplayConditionsInGroup
    ]
  );
  const columns = () => {
    const fieldConditions = {
      [nameOf("Group")]: () =>
        displayConditionsInGroup ===
        StatusColumnGroupSystemAdminConditions.True,
    };

    const newConfigCol: IColumnFields[] = cloneDeep(
      localLawsSystemAdminConditions.columns
    );

    return newConfigCol.filter((col) => {
      const conditionCheck =
        fieldConditions[col.field as keyof typeof fieldConditions];
      return conditionCheck ? conditionCheck() : true;
    });
  };

  const state =
    displayConditionsInGroup === StatusColumnGroupSystemAdminConditions.False
      ? localLawsSystemAdminConditions.state
      : ({
          ...localLawsSystemAdminConditions.state,
          group: [{ field: nameOf("Group"), dir: "asc" }],
        } as State);

  return {
    columns: columns(),
    state,
  };
};
