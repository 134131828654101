import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { VO_Supplementary_Assessment } from "@app/products/property/supplementary-rates/[id]/components/child-screens/assessment/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Supplementary_Assessment>();
export const colSupplementaryRatesAssessment: IColumnFields[] = [
  {
    field: nameOf("Assessment_Number"),
    title: "Assessment Number",
    linkTo: (dataItem: VO_Supplementary_Assessment) => {
      return `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.Assessment_Id}`;
    },
  },
  {
    field: nameOf("Property_Name"),
    title: "Holding Name",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("PrimaryAssessmentNumber"),
    title: "Primary Assessment",
  },
  {
    field: nameOf("Valuation_Number"),
    title: "Valuation Number",
  },
  {
    field: nameOf("Reference"),
    title: "Reference",
  },
  {
    field: nameOf("Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("Effective_Date"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Pre_Supplementary_Balance"),
    title: "Pre-supplementary Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Base_Amount"),
    title: "Base Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Rate_by_Valuation_Amount"),
    title: "Rate by Valuation Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Increment_to_Minimum"),
    title: "Increment to Minimum",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Reduction_to_Maximum"),
    title: "Reduction to Maximum",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Adjustment_for_Capping"),
    title: "Adjustment for Capping",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Tax_Amount"),
    title: "Tax Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Rebate_Amount"),
    title: "Rebate Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SA_Supp_Gross_Amount"),
    title: "Gross Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SA_Supp_Nett_Amount"),
    title: "Nett Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Tax_Included"),
    title: "Tax Included",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Postponed_Value"),
    title: "Postponed Value",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Discount_Amount_1"),
    title: "Discount Total 1",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Discount_Amount_2"),
    title: "Discount Total 2",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Compliance_Penalty"),
    title: "Compliance Penalty",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Compliance_Interest"),
    title: "Compliance Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Balance_Transfer_Method"),
    title: "Balance Transfer Method",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("ID"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
