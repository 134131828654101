import { getViewConfigurations } from "@app/products/property/api";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { VO_ValuationAssessments } from "@app/products/property/supplementary-rates/valuations-by-group/components/detail/model";
import { ODATA_SUPPLEMENTARYS_RATE_VALUATION_BY_GROUP_DETAIL } from "@app/products/property/supplementary-rates/valuations-by-group/constant";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { colDetail } from "./config";

const nameOf = nameOfFactory<VO_ValuationAssessments>();

const DetailComponent = ({ dataItem }: GridDetailRowProps) => {
  const [listConfigurationDetail, setListConfigurationDetail] = useState<
    ICCViewColumn[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await getViewConfigurations(
      ViewConfiguration.OData_SupplementaryValuationAssessments
    );
    if (isSuccessResponse(response) && response?.data) {
      setListConfigurationDetail(response.data.ColumnDefinitions?.Columns);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );
  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <div className="cc-rebates-list-detail">
      <CCGrid
        className="cc-associated-entities-grid"
        dataUrl={ODATA_SUPPLEMENTARYS_RATE_VALUATION_BY_GROUP_DETAIL}
        columnFields={processDynamicColumns(colDetail, listConfigurationDetail)}
        primaryField={nameOf("Assessment_Id")}
        state={{
          filter: {
            logic: "and",
            filters: [
              {
                field: "Valuation_Group_Id",
                value: dataItem.Valuation_Group_Id,
                operator: "eq",
              },
            ],
          },
        }}
      />
    </div>
  );
};

export default DetailComponent;
