import {
  IHandlerEventInitialData,
  Permit,
  PermitRegisterHandlerRequest,
  PermitRegisterUIControl,
  Svc_FormAction_PermitRegister,
  Svc_Permit,
} from "@app/products/local-laws/[id]/model";
import { renderHTML } from "@app/products/local-laws/[id]/util";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { fetchApiByAlias } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { isHTML } from "@components/cc-input-picker/util";
import { CCLabel } from "@components/cc-label/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef } from "react";

interface IContactSectionProps {
  formRenderProps: FormRenderProps;
}

const nameOfSvcPermit = nameOfFactory<Svc_Permit>();
const nameOfPermit = nameOfFactory<Permit>();
const getNameOf = (permitPropsName: keyof Permit) => {
  return `${nameOfSvcPermit("Permit")}.${nameOfPermit(permitPropsName)}`;
};

export const PermitContactSection = observer(
  ({ formRenderProps }: IContactSectionProps) => {
    //#region STORE ========/
    const { setInitialDataForms, dataForms } = useFlexibleFormStore();
    const { pushNotificationPortal } = useNotificationPortalStore();
    const uiControlStore =
      dataForms?.GeneralUIControl as PermitRegisterUIControl;
    //#endregion STORE =====/

    //#region REF ========/
    const prevPermitHolderContactDisplayName = useRef("");
    //#endregion REF =====/

    const { valueGetter, onChange } = formRenderProps;
    const permitObj = valueGetter(nameOfSvcPermit("Permit")) as Permit;

    //#region HANDLER ========/
    const pickPermitHolderHandler = (event: ComboBoxChangeEvent | null) => {
      const contact = event?.value;
      if (contact) {
        let permitHolderContactRID = 0;
        if (
          permitObj?.Contact_RID &&
          permitObj?.Contact?.Contact?.Contact_ID === event?.value?.Contact_ID
        ) {
          permitHolderContactRID = permitObj?.Contact_RID;
        }
        //Convert needed filed from DTO Svc_Contact to base DTO Contact
        contact.PreferredMethod_ENUM =
          contact?.PreferredMethodOfContact_ENUM ??
          contact?.PreferredMethod_ENUM;

        const permitRegisterHandlerRequest: PermitRegisterHandlerRequest = {
          FormAction: Svc_FormAction_PermitRegister.Form_PickContact,
          Permit: permitObj,
          PermitRegisterArgs: {
            Contact: contact ?? null,
            RID: permitHolderContactRID,
          },
          IsFirstTimeLoad: false,
        };

        const handlerInitialData: IHandlerEventInitialData = {
          permitRegisterHandlerRequest,
          errorMsg: "Select permit holder failed.",
        };

        fetchApiByAlias("permitHandlerSlider", {
          initialData: handlerInitialData,
        });
      } else {
        onChange(getNameOf("Contact"), { value: null });
        onChange(getNameOf("Contact_RID"), { value: 0 });
      }
    };
    //#endregion HANDLER =====/

    //#region UI ========/
    const removeDisplayValue = () => {
      if (uiControlStore) {
        prevPermitHolderContactDisplayName.current =
          uiControlStore.LitContact.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitContact: {
              ...uiControlStore.LitContact,
              Value: permitObj?.Contact.Contact.DisplayName,
            },
          },
        });
      }
    };

    const restoreDisplayValue = () => {
      if (
        uiControlStore &&
        isHTML(prevPermitHolderContactDisplayName.current) &&
        valueGetter(`${getNameOf("Contact")}.Contact`)
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitContact: {
              ...uiControlStore.LitContact,
              Value: prevPermitHolderContactDisplayName.current,
            },
          },
        });
      }
    };
    //#endregion UI =====/

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Permit holder" isMandatory />
            <Field
              uniqueKey="PermitHolderContact"
              name={`${getNameOf("Contact")}.Contact`}
              component={ContactPicker}
              onChange={pickPermitHolderHandler}
              onError={(error: any) => {
                pushNotificationPortal({
                  type: "error",
                  title: "Pick contact errors",
                  description: error,
                  autoClose: false,
                });
              }}
              placeholder={"Select contact"}
              validator={requiredValidator}
              displayValue={uiControlStore?.LitContact?.Value}
              removeDisplayValue={removeDisplayValue}
              restoreDisplayValue={restoreDisplayValue}
            />
          </div>
          <div className="cc-field">
            <CCValueField
              label="Address"
              value={renderHTML(uiControlStore?.LitContactAddress?.Value)}
            />
          </div>
          <div className="cc-field">
            <CCValueField
              label="Postal address"
              value={renderHTML(uiControlStore?.LitContactPostalAddress?.Value)}
            />
          </div>
          <div className="cc-field">
            <CCValueField
              label="Phone (H)"
              value={uiControlStore?.LitContactHomePhone?.Value}
            />
          </div>
          <div className="cc-field">
            <CCValueField
              label="Phone (W)"
              value={uiControlStore?.LitContactWorkPhone?.Value}
            />
          </div>
          <div className="cc-field">
            <CCValueField
              label="Preferred contact"
              value={uiControlStore?.LitContactContact?.Value}
            />
          </div>
          <div className="cc-field">
            <CCValueField
              label="Mobile"
              value={uiControlStore?.LitContactMobilePhone?.Value}
            />
          </div>
          <div className="cc-field">
            <CCValueField
              label="Email"
              value={uiControlStore?.LitContactEmail?.Value}
            />
          </div>
        </div>
      </section>
    );
  }
);
