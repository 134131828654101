import Actions from "@app/core/actions/_index";
import { Comments } from "@app/core/comments/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { Documents } from "@app/core/documents/_index";
import Fees from "@app/core/fees/_index";
import { Journal } from "@app/core/journal/_index";
import Samples from "@app/core/samples/_index";
import { BuildingRegisterDemolitionForm } from "@app/products/building/registers/demolitions/[id]/components/child-screens/general/_index";
import { DemolitionsContactsTab } from "@app/products/building/registers/demolitions/[id]/components/reference-sidebar/contacts/_index";
import { DemolitionsDetailTab } from "@app/products/building/registers/demolitions/[id]/components/reference-sidebar/detail/_index";
import { IBuildingDemolitionRecordState } from "@app/products/building/registers/demolitions/[id]/model";
import { useBuildingRegistersDemolitionsStore } from "@app/products/building/registers/demolitions/[id]/store";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import Contacts from "@common/pages/contacts/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const ExistedBuildingRegisterDemolition = observer(() => {
  const { id } = useParams<{ id: string }>();
  const {
    loadDemolitions,
    resetStore,
    isLoading,
    demolitions,
    demolitionId,
    responseLoadError,
  } = useBuildingRegistersDemolitionsStore();
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const managePageUrl = window.location.pathname;

  const { currentUserInfo } = useGlobalStore();
  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Building - Registers - Demolitions - ${demolitionId ?? 0}`,
      Text:
        `${getDisplayTextWithDashes([
          demolitions?.RegisterNumber,
          demolitions?.Contact,
        ])}` || "N/A",
      LinkUrl: managePageUrl,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Building,
      RecordType_ENUM: RECORDTYPE.BUILDING,
      Record_ID: demolitionId ? +demolitionId : 0,
    },
  ];

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <DemolitionsDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts", component: <DemolitionsContactsTab /> },
      {
        title: "History",
        component: <Journal id={3237033} recordType={RECORDTYPE.BUILDING} />,
      },
    ],
  });

  useEffectOnce(() => {
    loadDemolitions(parseInt(id));
    return () => {
      resetStore();
    };
  });

  const [buildingDemolitionRecordState] =
    useState<IBuildingDemolitionRecordState>(
      new IBuildingDemolitionRecordState()
    );

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Addresses",
      totalRecord: buildingDemolitionRecordState.addresses,
      component: <Samples id={demolitionId} recordType={RECORDTYPE.BUILDING} />,
    },
    {
      title: "Actions",
      totalRecord: buildingDemolitionRecordState.actions,
      component: <Actions id={demolitionId} recordType={RECORDTYPE.BUILDING} />,
    },
    {
      title: "Comments",
      totalRecord: buildingDemolitionRecordState.comments,
      component: (
        <Comments id={demolitionId} recordType={RECORDTYPE.BUILDING} />
      ),
    },
    {
      title: "Contacts",
      totalRecord: buildingDemolitionRecordState.contacts,
      component: (
        <Contacts id={demolitionId} recordType={RECORDTYPE.BUILDING} />
      ),
    },
    {
      title: "Documents",
      totalRecord: buildingDemolitionRecordState.documents,
      component: (
        <Documents id={demolitionId} recordType={RECORDTYPE.BUILDING} />
      ),
    },
    {
      title: "Fees",
      totalRecord: buildingDemolitionRecordState.fees,
      component: <Fees id={demolitionId} recordType={RECORDTYPE.BUILDING} />,
    },
    {
      title: "Inspections",
      totalRecord: buildingDemolitionRecordState.inspections,
      component: <Samples id={demolitionId} recordType={RECORDTYPE.BUILDING} />,
    },
    {
      title: "Related",
      totalRecord: buildingDemolitionRecordState.related,
      component: <Samples id={demolitionId} recordType={RECORDTYPE.BUILDING} />,
    },
  ];

  const formTitle = useMemo((): string => {
    const registerNumber = demolitions?.ID;
    const contact = demolitions?.Contact;

    // If these two are not falsy, return both, otherwise return one or default
    if (registerNumber && contact) {
      return `${registerNumber} - ${contact}`;
    }

    return registerNumber?.toString() || contact || "N/A";
  }, [demolitions]);

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />

      <FormNavigation title="Demolition" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadDemolitions(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={formTitle} badge={demolitions?.Status} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              ></CCNavButton>,

              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,

              <CCNavButton title={"Add"} type="sub">
                <CCNavButton title={"Action"} />
                <CCNavButton title={"Additional address"} />
                <CCNavButton title={"Contact"} />
                <CCNavButton title={"Attachment"} />
                <CCNavButton title={"Comment"} />
                {/* TODO: enhance later */}
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.Building}
                  recordType={RECORDTYPE.TP_BuildingApplication}
                  recordId={parseInt(id)}
                />
                <CCNavButton title={"Complaint"} />
                <CCNavButton title={"Document"} />
                <CCNavButton title={"Fee"} />
                <CCNavButton title={"Inspection"} />
                <CCNavButton title={"Notice"} />
                <CCNavButton title={"Referral"} />
              </CCNavButton>,

              <CCNavButton title={"View"} type="sub">
                <CCNavButton title={"Documents"} />
                <CCNavButton title={"Journal"} />
                <CCNavButton title={"Project"} />
              </CCNavButton>,
              <CCNavButton title={"Print"} />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {demolitions && (
                  <CCGeneralPanel
                    component={<BuildingRegisterDemolitionForm />}
                  />
                )}
                <CustomPanelBar listPanelBar={listPanelBar} />
              </div>
            </div>

            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
