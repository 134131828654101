import Actions from "@app/core/actions/_index";
import { Comments } from "@app/core/comments/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { Documents } from "@app/core/documents/_index";
import Events from "@app/core/events/_index";
import Fees from "@app/core/fees/_index";
import Inspections from "@app/core/inspections/inspections-accordion/_index";
import Related from "@app/core/related/_index";
import Samples from "@app/core/samples/_index";
import { getPremiseRecords } from "@app/products/hm/api";
import { AddCommentButton } from "@app/products/hm/components/action-bar/buttons/add-comment/_index";
import { INSPECTIONS_NEW_ROUTE } from "@app/products/hm/inspections/[id]/constant";
import { ITotalRecords } from "@app/products/hm/model";
import { PremiseForm } from "@app/products/hm/premises/[id]/components/child-screens/general/_index";
import { PremiseContactsTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/contacts/_index";
import { PremiseDetailsTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/details/_index";
import { PremiseHistoryTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/history/_index";
import { usePremisesStore } from "@app/products/hm/premises/[id]/store";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import Contacts from "@common/pages/contacts/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import { default as React, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const ExistManagePremise = observer(() => {
  const { id } = useParams<{ id: string }>();
  const {
    isLoading,
    premises,
    premisesLOVs,
    loadPremisesInfo,
    responseLoadError,
    premisesId,
  } = usePremisesStore();

  const history = useHistory();
  const [showSlideBar, setShowSlideBar] = useState(true);
  const [premisesRecord, setPremisesRecord] = useState<ITotalRecords>();
  const { currentUserInfo } = useGlobalStore();
  const managePageUrl = window.location.pathname;
  const bookmarkList: IBookmarkItem[] = [
    {
      Text: premises?.TradingName ?? "",
      LinkUrl: managePageUrl,
      LinkText: `Heath Manager - Premises - ${id ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.HealthManager,
      RecordType_ENUM: RECORDTYPE.HealthManager_Premises,
      Record_ID: premisesId ? +premisesId : 0,
    },
  ];

  useEffectOnce(() => {
    getPremiseRecords(id, RECORDTYPE.HealthManager_Premises).then((res) => {
      setPremisesRecord(res.data);
    });
  });

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Actions",
      totalRecord: premisesRecord?.actions,
      component: (
        <Actions
          id={premisesId}
          recordType={RECORDTYPE.HealthManager_Premises}
        />
      ),
    },
    {
      title: "Contacts",
      totalRecord: premisesRecord?.contacts,
      component: (
        <Contacts
          id={premisesId}
          recordType={RECORDTYPE.HealthManager_Premises}
        />
      ),
    },
    {
      title: "Comments",
      totalRecord: premisesRecord?.comments,
      component: (
        <Comments
          id={premisesId}
          recordType={RECORDTYPE.HealthManager_Premises}
        />
      ),
    },
    {
      title: "Documents",
      totalRecord: premisesRecord?.documents,
      component: (
        <Documents
          id={parseInt(premisesId + "")}
          recordType={RECORDTYPE.HealthManager_Premises}
        />
      ),
    },
    {
      title: "Inspections",
      totalRecord: premisesRecord?.inspections,
      component: (
        <Inspections
          id={premisesId}
          recordType={RECORDTYPE.HealthManager_Premises}
        />
      ),
    },
    {
      title: "Fees",
      totalRecord: premisesRecord?.fees,
      component: (
        <Fees id={premisesId} recordType={RECORDTYPE.HealthManager_Premises} />
      ),
    },
    {
      title: "Samples",
      totalRecord: premisesRecord?.samples,
      component: (
        <Samples
          id={premisesId}
          recordType={RECORDTYPE.HealthManager_Premises}
        />
      ),
    },
    {
      title: "Related",
      totalRecord: premisesRecord?.related,
      component: (
        <Related
          id={premisesId}
          recordType={RECORDTYPE.HealthManager_Premises}
        />
      ),
    },
    {
      title: "Events",
      totalRecord: premisesRecord?.events,
      component: (
        <Events
          id={premisesId}
          recordType={RECORDTYPE.HealthManager_Premises}
        />
      ),
    },
  ];

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <PremiseDetailsTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts", component: <PremiseContactsTab /> },
      {
        title: "History",
        component: <PremiseHistoryTab />,
      },
    ],
  });

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="PREMISE" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadPremisesInfo(id, false);
          }}
        />
      ) : (
        <>
          <FormTitle
            title={premises?.TradingName}
            badge={premises?.Status_ENUM}
          />
          {premisesLOVs && premises?.Premises_ID && (
            <>
              <CCManagePageActionBar
                leftActions={[
                  <CCNavButton
                    title={"Workflow"}
                    type="sub-workflow"
                    className="cc-workflow-button"
                  >
                    <CCNavButton title={"Transfer premise"} />
                    <CCNavButton title={"Close premise"} />
                  </CCNavButton>,
                  <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
                  <CCNavButton title={"Add"} type="sub">
                    <CCNavButton
                      title={"Action"}
                      onClick={() => {
                        history.push(
                          "/newaction/" +
                            premises?.Premises_ID +
                            "/" +
                            RECORDTYPE.HealthManager_Premises
                        );
                      }}
                    />
                    <AddCommentButton
                      premiseInfo={premises}
                      recordType={RECORDTYPE.HealthManager_Premises}
                    />
                    <CommunicationButtonListView
                      productType={PRODUCT_TYPE_NUMBER.HealthManager}
                      recordType={RECORDTYPE.HealthManager_Premises}
                      recordId={parseInt(id)}
                    />
                    <CCNavButton title={"Contacts"} />
                    <CCNavButton title={"Documents"} />
                    <CCNavButton title={"Fees"} />
                    <CCNavButton title={"Samples"} />
                    <CCNavButton
                      title={"Inspection"}
                      onClick={() => {
                        history.push(INSPECTIONS_NEW_ROUTE);
                      }}
                    />
                  </CCNavButton>,
                  <CCNavButton title={"View"} />,
                  <CCNavButton title={"Print"} />,
                  <CCNavButton title={"More Options"} type="more">
                    <CCNavButton title={"Alert"} />
                    <CCNavButton title={"Additional Details"} />
                    <CCNavButton title={"Application Details"} />
                    <CCNavButton title={"Portal"} />
                    <CCNavButton title={"Portal Links"} />
                  </CCNavButton>,
                ]}
                rightActions={[
                  <CCNavButton
                    title={"Slide Bar"}
                    iconClass="fal fa-info-circle"
                    isActive={showSlideBar}
                    onClick={() => {
                      setShowSlideBar(!showSlideBar);
                    }}
                  />,

                  <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
                ]}
              />
            </>
          )}
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {premises && <CCGeneralPanel component={<PremiseForm />} />}
                <CustomPanelBar listPanelBar={listPanelBar} />
              </div>
            </div>

            {premises && showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
