import { PremiseRegisterForm } from "@app/products/hm/model";
import ProprietorService from "@app/products/hm/premises/[id]/components/child-screens/general/components/premise-form-element/api";
import { parentPage } from "@app/products/hm/premises/[id]/components/child-screens/general/components/premise-form-element/mock";
import { usePremisesStore } from "@app/products/hm/premises/[id]/store";
import { APIResponseStatus } from "@common/constants/response-status";
import {
  getDropdownValue,
  nameOfFactory,
  populateValue,
} from "@common/utils/common";
import { validateRequired } from "@common/utils/field-validators";
import AutoComplete from "@components/auto-complete/AutoComplete";
import { IAutoFieldParams } from "@components/auto-complete/model";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Toast } from "@components/toast";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export interface IPremiseFormElementProps {
  formRenderProps: FormRenderProps;
}

export const PremiseFormElement = observer(
  ({ formRenderProps }: IPremiseFormElementProps) => {
    const { premisesLOVs } = usePremisesStore();

    const { valueGetter, onChange } = formRenderProps;
    const nameOf = nameOfFactory<PremiseRegisterForm>();

    const history = useHistory();

    const [valueProprietor, setValueProprietor] = useState(
      valueGetter(nameOf("ProprietorContactDisplayName"))
    );
    const [proprietorData, setProprietorData] = useState([]);
    const [suggestProprietor, setSuggestProprietor] = useState("");

    const propOfAuto: IAutoFieldParams = {
      data: proprietorData,
      suggest: suggestProprietor,
      placeholder: "Proprietor",
      disabled: false,
      fieldName: nameOf("ProprietorContactDisplayName"),
      value: valueProprietor,
      // valueGetter(nameOf("ProprietorContactDisplayName")),
      showPopupEvent: () => {
        history.push(
          `/managecontactrelationship/${valueGetter(nameOf("Proprietor_RID"))}`,
          {
            parentPage,
            recordType: "210",
          }
        );
      },
      onChange: (event: any) => handleChange(event),
    };
    const propOfAutoDis: IAutoFieldParams = {
      data: [],
      suggest: "",
      placeholder: "Disabled",
      fieldName: "DisplayName",
      disabled: true,
      value: "",
      showPopupEvent: () => null,
      onChange: (event: any) => handleChange(event),
    };

    const handleChange = (event: any) => {
      setValueProprietor(event.target.value);

      formRenderProps.onChange(nameOf("ProprietorContactDisplayName"), {
        value:
          valueGetter(nameOf("ProprietorContactDisplayName")) === null
            ? ""
            : event.target.value,
      });

      setSuggestProprietor(event.suggestion ? event.suggestion.value : "");
      if (event.target.value.length > 2) {
        ProprietorService.getProprietorData(event.target.value).then(
          (response) => {
            if (response.status !== APIResponseStatus.SUCCESS) {
              return;
            }
            const temp: any = [];
            response.data.forEach((item: any) => {
              temp.push(item.DisplayName);
            });
            setProprietorData(temp);
          }
        );
      }
    };

    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (formRenderProps.modified) {
        formRenderProps.onSubmit(event);
        return;
      }

      if (!formRenderProps.valid) return;

      Toast.displayToast({
        content: "🚀 The form is not modified !",
        type: "warning",
      });
    };

    return (
      <FormElement onSubmit={formRenderProps.onSubmit} noValidate>
        {/* Reference by a label in ActionBar.tsx */}
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />

        {/* SECTION 1 */}
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Trading name
                <CCTooltip type="validator" position="right" />
              </label>

              <Field
                component={CCInput}
                name={nameOf("TradingName")}
                validator={validateRequired}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Proprietor
                <CCTooltip type="validator" position="right" />
              </label>

              <AutoComplete {...propOfAuto} />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Primary contact
                <CCTooltip type="validator" position="right" />
              </label>

              <AutoComplete
                {...Object.assign({}, propOfAutoDis, {
                  placeholder: "Primary Contact",
                  selectedValue: valueGetter(
                    nameOf("PrimaryContactContactDisplayName")
                  ),
                })}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Premises address
                <CCTooltip type="validator" position="right" />
              </label>

              <AutoComplete
                {...Object.assign({}, propOfAutoDis, {
                  placeholder: "Premises Address",
                  selectedValue: `${
                    (valueGetter(nameOf("SiteAddressStreetNo")),
                    valueGetter(nameOf("SiteAddressStreetName")),
                    valueGetter(nameOf("SiteAddressSuburb")),
                    valueGetter(nameOf("SiteAddressState")))
                  }`,
                })}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Postal address</label>
              <AutoComplete
                {...Object.assign({}, propOfAutoDis, {
                  placeholder: "Premises Address",
                  selectedValue: `${
                    (valueGetter(nameOf("PostalAddressStreetNo")),
                    valueGetter(nameOf("PostalAddressStreetName")),
                    valueGetter(nameOf("PostalAddressSuburb")),
                    valueGetter(nameOf("PostalAddressState")))
                  }`,
                })}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Premise type
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("PremisesType_ID")}
                textField="Value"
                dataItemKey="Key"
                validator={validateRequired}
                data={premisesLOVs?.PremiseTypes}
                component={CCSearchComboBox}
                value={getDropdownValue(
                  valueGetter(nameOf("PremisesType_ID")),
                  premisesLOVs?.PremiseTypes ?? [],
                  "Key"
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOf("PremisesType_ID"), {
                    value: event.target.value?.Key ?? null,
                  });
                }}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Reference number</label>
              <Field name={nameOf("ReferenceNumber")} component={CCInput} />
            </div>
            <div className="cc-field">
              <label className="cc-label">Debtor number</label>
              <Field name={nameOf("DebtorNumber")} component={CCInput} />
            </div>
            <div className="cc-field">
              <label className="cc-label">File number</label>
              <Field name={nameOf("FileNumber")} component={CCInput} />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">BPAY reference</label>
              <div className="cc-label-field">
                {populateValue(valueGetter(nameOf("BPayReference")))}
              </div>
            </div>
            <div className="cc-field">
              <label className="cc-label">Streatrader</label>
              <div>
                <Field
                  component={CCSwitch}
                  name={nameOf("Flag_Streatrader")}
                  checked={valueGetter(nameOf("Flag_Streatrader"))}
                />
              </div>
            </div>
            <div className="cc-field">
              <label className="cc-label">Fast choice</label>
              <div>
                <Field
                  component={CCSwitch}
                  name={nameOf("Flag_FastChoice")}
                  checked={valueGetter(nameOf("Flag_FastChoice"))}
                />
              </div>
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Officer</label>
              <div className="cc-label-field">
                {populateValue(valueGetter(nameOf("OfficerDisplayName")))}
              </div>
            </div>
            <div className="cc-field">
              <label className="cc-label">Health area</label>
              <Field
                name={nameOf("OfficerHealthArea_KWD")}
                textField="Value"
                dataItemKey="Key"
                data={premisesLOVs?.HealthAreas}
                component={CCSearchComboBox}
                value={getDropdownValue(
                  valueGetter(nameOf("OfficerHealthArea_KWD")),
                  premisesLOVs?.HealthAreas ?? [],
                  "Key"
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOf("OfficerHealthArea_KWD"), {
                    value: event.target.value?.Key ?? null,
                  });
                }}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                FSP template
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("FSPTemplate_KWD")}
                textField="Value"
                dataItemKey="Key"
                validator={validateRequired}
                data={premisesLOVs?.FSPTemplates}
                component={CCSearchComboBox}
                value={getDropdownValue(
                  valueGetter(nameOf("FSPTemplate_KWD")),
                  premisesLOVs?.FSPTemplates ?? [],
                  "Key"
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOf("FSPTemplate_KWD"), {
                    value: event.target.value?.Key ?? null,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </FormElement>
    );
  }
);
