import { INVOICE_MANAGE_ROUTE } from "@app/core/invoice/[id]/constant";
import { Svc_InvoiceToFinance } from "@app/core/invoice/[id]/model";
import {
  IGenerateFinancesColumnsProps,
  nameOfInvoiceToFinance,
} from "@app/products/local-laws/finance/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import {
  getBoolValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep } from "lodash";
export const colLocalLawsFinanceInvoicesSentToFinance: IColumnFields[] = [
  {
    field: nameOfInvoiceToFinance("InvoiceNumber"),
    title: "Invoice Number",
    linkTo: (dataItem: Svc_InvoiceToFinance) => ({
      pathname: `${INVOICE_MANAGE_ROUTE}/` + dataItem.ID,
    }),
    locked: true,
  },
  { field: nameOfInvoiceToFinance("DebtorNumber"), title: "Debtor Number" },
  {
    field: nameOfInvoiceToFinance("InvoiceAmount"),
    title: "Invoice Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfInvoiceToFinance("InvoiceDate"),
    title: "Invoice Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfInvoiceToFinance("InvoicePostingDate"),
    title: "Posting Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfInvoiceToFinance("InvoiceDueDate"),
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfInvoiceToFinance("InvoiceActiveDate"),
    title: "Active Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfInvoiceToFinance("FeeCount"),
    title: "Fee Count",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfInvoiceToFinance("InvoiceBalance"),
    title: "Invoice Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfInvoiceToFinance("PaidInFull"),
    title: "Paid In Full",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfInvoiceToFinance("InvoiceDescription"),
    title: "Invoice Description",
  },
];

export const generateInvoiceSentToFinanceColumnsBySetting = ({
  settings,
  defaultConfig = [],
}: IGenerateFinancesColumnsProps) => {
  if (!settings) return defaultConfig;

  const registerDisplayDebtorNumber =
    getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_RegisterDisplayDebtorNumber
      ]
    ) ?? false;
  const debtorNumberLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_DebtorNumberLabel]
  );

  let columnsClone: IColumnFields[] = cloneDeep(defaultConfig);

  return columnsClone.filter((col) => {
    switch (col.field) {
      case nameOfInvoiceToFinance("DebtorNumber"):
        if (debtorNumberLabel) {
          col.title = debtorNumberLabel;
        }
        return registerDisplayDebtorNumber;
      default:
        return true;
    }
  });
};
