import { CRMSSvcStandardBatchUpdateForm } from "@app/products/crms/service-standards/batch-update/components/form/components/general/_index";
import { StateSvcStandardUpdate } from "@app/products/crms/service-standards/batch-update/model";
import { useSvcStandardBatchUpdateStore } from "@app/products/crms/service-standards/batch-update/store";
import { IPermissionsWrapperProps } from "@common/hocs/permissions/model";
import { renderWithPermissionsWrapper } from "@common/hocs/permissions/render-with-permission-wrapper";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useLocation } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";

const BatchUpdateServiceStandardComponent = observer(() => {
  const {
    resetStore,
    isLoading,
    svcStandardBatchUpdate,
    loadSvcStandardBatchUpdate,
    responseLoadError,
    onSubmit,
  } = useSvcStandardBatchUpdateStore();

  const { pushNotification } = useCCAppNotificationStore();

  const location = useLocation();
  const locationState = location.state as StateSvcStandardUpdate;
  const gridSelectedIds: number[] = locationState?.gridSelectedIds;

  useEffectOnce(() => {
    if (!gridSelectedIds || (gridSelectedIds && gridSelectedIds.length < 1)) {
      pushNotification({
        autoClose: false,
        title: "Please select at least one service standard to batch update.",
        type: "error",
      });
    }
    loadSvcStandardBatchUpdate();
    return () => {
      resetStore();
      window.history.replaceState({}, "");
    };
  });

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="CRMS" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadSvcStandardBatchUpdate();
          }}
        />
      ) : (
        <>
          <FormTitle
            title={`Batch update - Service standards selected: ${
              gridSelectedIds?.length ?? 0
            }`}
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Update"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
              />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {svcStandardBatchUpdate && <CRMSSvcStandardBatchUpdateForm />}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});

const renderProps: IPermissionsWrapperProps = {
  component: BatchUpdateServiceStandardComponent,
  permissions: { productType: PRODUCT_TYPE_NUMBER.CustomerService },
  useNewPermissionDataCreator: (props: IPermissionsWrapperProps) => {
    return {
      formIdentifier: FormIdentifier.CUSTOMERSERVICE_Form_ServiceStandard,
      productType: props.permissions.productType,
      formAction: FormAction.CRMS_TOOLS_BATCHUPDATE,
    };
  },
};

const BatchUpdateServiceStandard = renderWithPermissionsWrapper(renderProps);

export default BatchUpdateServiceStandard;
