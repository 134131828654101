import { ICCRoute } from "@common/constants/ICCRoute";

export const renewalsRoute: ICCRoute = {
  path: "renewals",
  name: "Renewals",
  children: [
    {
      path: "all-by-due-status",
      name: "All By Due Status",
      component: require("./all-by-due-status/_index").default,
    },
    {
      path: "all-by-type",
      name: "All By Type",
      component: require("./all-by-type/_index").default,
    },
    {
      path: "requires-renewal-fee",
      name: "Requires Renewal Fee",
      component: require("./requires-renewal-fee/_index").default,
    },
    {
      path: "requires-notice",
      name: "Requires Notice",
      component: require("./requires-notice/_index").default,
    },
    {
      path: "notice-sent",
      name: "Notice Sent",
      component: require("./notice-sent/_index").default,
    },
    {
      path: "notice-received",
      name: "Notice Received",
      component: require("./notice-received/_index").default,
    },
    {
      path: "fees-outstanding",
      name: "Fees Outstanding",
      component: require("./fees-outstanding/_index").default,
    },
    {
      path: "requires-certificate-paid",
      name: "Requires Certificate Paid",
      component: require("./requires-certificate-paid/_index").default,
    },
    {
      path: "requires-certificate",
      name: "Requires Certificate",
      component: require("./requires-certificate/_index").default,
    },
  ],
};
