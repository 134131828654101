import {
  apiCoreRefusageViewOnMap,
  apiCoreRefusageViewOnMapInView,
} from "@app/core/components/common/utils";
import {
  Svc_ListView_ViewOnMap,
  Svc_ViewOnMap,
} from "@app/core/view-on-map/components/buttons/view-map-by-gis/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IIdentityPacket } from "@common/models/identityPacket";

export const postViewOnMap = async (
  objViewOnMapRequest: Svc_ViewOnMap
): Promise<APIResponse<IIdentityPacket<string> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IIdentityPacket<string>>(
      apiCoreRefusageViewOnMap(),
      objViewOnMapRequest
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postViewOnMapInView = async (
  objViewOnMapRequest: Svc_ListView_ViewOnMap
): Promise<APIResponse<IIdentityPacket<string> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IIdentityPacket<string>>(
      apiCoreRefusageViewOnMapInView(),
      objViewOnMapRequest
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
