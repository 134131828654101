import { mapEnum } from "@app/core/inspections/[id]/util";
import { ElementDisplayStatus } from "@app/products/crms/[id]/model";
import { Permit, Permit_Status } from "@app/products/local-laws/[id]/model";
import {
  BubbleUpIdentifier,
  BubbleUpType,
} from "@app/products/waste-water/[id]/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { TEMPORARY_FIELDS } from "@common/stores/flexible-form/constant";
import { flexibleFormStoreInstance } from "@common/stores/flexible-form/store";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { isHTML } from "@components/cc-input-picker/util";
import { INotificationPortalContentExcludeId } from "@components/cc-notification-portal/components/notification-portal-content/model";
import { forEach, isNil, isNumber } from "lodash";

export const getPermitStatusName = (statusEnum?: Permit_Status) => {
  switch (statusEnum) {
    case Permit_Status.Unspecified:
      return "Unspecified";
    case Permit_Status.NewApplication:
      return "New Application";
    case Permit_Status.PermitIssued:
      return "Permit Issued";
    case Permit_Status.PermitRefused:
      return "Permit Refused";
    case Permit_Status.PermitDeactivated:
      return "Permit Deactivated";
    case Permit_Status.PermitExpired:
      return "Permit Expired";
    case Permit_Status.PermitLodged:
      return "Permit Lodged";
    case Permit_Status.PermitWithdrawn:
      return "Permit Withdrawn";
    case Permit_Status.PermitAmended:
      return "Permit Amended";
    case Permit_Status.PermitApproved:
      return "Permit Approved - Action Required";
    case Permit_Status.PermitCancelled:
      return "Permit Canceled";
    case Permit_Status.PermitOnHold:
      return "Permit On Hold";
    case Permit_Status.PermitRevoked:
      return "Permit Revoked";
    case Permit_Status.PermitFinalApproved:
      return "Permit Approved";
    case Permit_Status.NewTender:
      return "New Tender";
    case Permit_Status.Advertising:
      return "Advertising";
    case Permit_Status.ReadyForAssessment:
      return "Ready For Assessment";
    case Permit_Status.AssessmentComplete:
      return "Assessment Complete";
    case Permit_Status.AcknowledgmentSent:
      return "Acknowledgment Sent";
    case Permit_Status.AcknowledgmentReceived:
      return "Acknowledgment Received";
    case Permit_Status.TenderSuccessful:
      return "Tender Successful";
    case Permit_Status.InvoiceGenerated:
      return "Invoice Generated";
  }

  return "";
};

export const renderHTML = (stringHTML: string) => {
  return isHTML(stringHTML) ? sanitizeHtml(stringHTML) : stringHTML;
};

export const isFieldVisible = (visibility?: ElementDisplayStatus) => {
  if (isNil(visibility)) return false;

  return visibility === ElementDisplayStatus.Visible;
};

export const renderBubbleUps = (
  ancestorId: number,
  ancestorType: string | number | undefined,
  parentId?: number,
  parentType?: string | number | undefined
): BubbleUpIdentifier[] => {
  return [
    {
      PKID: 0,
      SourceIdentifier: {
        _RecordSource_ID: ancestorId,
        _RecordSourceType_ENUM: mapEnum(ancestorType, RECORDTYPE),
        _RecordSource_ColumnName: "",
        _RecordSource_StringID: "",
      },
      BubbleUpType_ENUM: BubbleUpType.Ancestor,
      BubbleUpType_Name: BubbleUpType.Ancestor.toString(),
      Sys_CreatedDate: new Date(),
      Sys_DeactivationDate: null,
    },
    {
      PKID: 0,
      SourceIdentifier: {
        _RecordSource_ID: parentId ?? ancestorId,
        _RecordSourceType_ENUM: mapEnum(parentType ?? ancestorType, RECORDTYPE),
        _RecordSource_ColumnName: "",
        _RecordSource_StringID: "",
      },
      BubbleUpType_ENUM: BubbleUpType.Parent,
      BubbleUpType_Name: BubbleUpType.Parent.toString(),
      Sys_CreatedDate: new Date(),
      Sys_DeactivationDate: null,
    },
  ];
};

export const getAlerts = (permit: Permit) => {
  const alerts: INotificationPortalContentExcludeId[] = [];
  if (!permit) return alerts;

  const permitHolderAlert = permit?.Contact?.Contact?.ContactAlert;
  const superVisorAlert = permit?.Supervisor?.Contact?.ContactAlert;
  const preferredContactAlert = permit?.PreferredContact?.Contact?.ContactAlert;
  const siteAddressAlert = permit?.Address?.PropertyAlert;

  if (permit.Contact?.Contact?.ContactAlert) {
    alerts.push({
      title: `Permit Holder Alert: ${permitHolderAlert}`,
      type: "warning",
      autoClose: false,
    });
  }

  if (superVisorAlert) {
    alerts.push({
      title: `Supervisor Alert: ${superVisorAlert}`,
      type: "warning",
      autoClose: false,
    });
  }

  if (preferredContactAlert) {
    alerts.push({
      title: `Preferred Contact Alert: ${preferredContactAlert}`,
      type: "warning",
      autoClose: false,
    });
  }

  if (siteAddressAlert) {
    alerts.push({
      title: `Site Address Alert: ${siteAddressAlert}`,
      type: "warning",
      autoClose: false,
    });
  }
  return alerts;
};

export const formatPermitBalance = (value?: number): React.CSSProperties => {
  let style: React.CSSProperties = {};
  if (isNil(value) || !isNumber(value)) return {};
  if (value > 0) {
    style.color = "var(--currency-red)";
    style.fontWeight = "bold";
  } else if (value <= 0) {
    style.fontWeight = "bold";
  }
  return style;
};

export const processPushNotificationPortal = (
  notifications: INotificationPortalContentExcludeId[],
  pushNotificationPortal: (
    notification: INotificationPortalContentExcludeId,
    isClearAllNotification?: boolean
  ) => void
) => {
  forEach(
    notifications,
    (notification: INotificationPortalContentExcludeId, index: number) => {
      pushNotificationPortal(notification, index === 0);
    }
  );
};

type TPermit = Permit & {
  [TEMPORARY_FIELDS]?: {
    forceModified?: boolean;
  };
};
export const changePermitGeneralForm = (nameOfField: Partial<TPermit>) =>
  flexibleFormStoreInstance.updateFormFields("GeneralForm", nameOfField);

export const getPermitGeneralForm = (
  nameOfField?: keyof TPermit | Array<keyof TPermit>
) => flexibleFormStoreInstance.getFormFields("GeneralForm", nameOfField);
