import { CRMSSystemSettingFormData } from "@app/products/crms/system-admin/[id]/model";
import { NotificationType } from "@common/constants/notificationtype";
import { useGlobalStore } from "@common/stores/global/store";
import { nameOfFactory } from "@common/utils/common";
import { CCInput } from "@components/cc-input/_index";
import Notifications from "@components/cc-notification/product-detail-notification/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import EditableField from "@components/editable-field/EditableField";
import { FormRenderProps } from "@progress/kendo-react-form";
import { default as React, useEffect, useState } from "react";

const nameOf = nameOfFactory<CRMSSystemSettingFormData>();

interface IProps {
  formRenderProps: FormRenderProps;
}

export const GeneralTab = ({ formRenderProps }: IProps) => {
  const [showMessage, setShowMessage] = useState(false);
  const { setIsNotify, setNotificationType } = useGlobalStore();
  const getValue = formRenderProps.valueGetter;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowMessage(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [showMessage]);

  const handleSaveChange = (name: string, value: any) => {
    setIsNotify(true);
    // TODO: Call API here
    formRenderProps.onChange(name, { value });
    setNotificationType(NotificationType.Success);
    setTimeout(() => {
      setIsNotify(false);
    }, 3000);
    return true;
  };

  return (
    <div id={"manage-crmssystemsetting-tab-general"}>
      <div className="pt-4">
        <Notifications></Notifications>
      </div>
      <div className="d-flex justify-content-end">
        <div className="question mb-1 mt-1">
          <i className="fas fa-2x text-primary fa-info-circle mr-1"></i>
          Are your looking for <a href="#!">CRMS Settings?</a>
        </div>
      </div>

      <div className="row">
        <div className="title col-lg-12 text-disabled">General Settings</div>
        <div className="form-group col-lg-4">
          <div className="d-flex">
            <label className="label">CEO</label>
          </div>
          <EditableField
            handleSaveChange={handleSaveChange}
            formRenderProps={formRenderProps}
            name={nameOf("GeneralCEO")}
            value={getValue(nameOf("GeneralCEO"))}
            placeholder={"CEO"}
            component={CCInput}
          />
        </div>
        <div className="form-group col-lg-2">
          <div className="d-flex">
            <label className="label">Prompt user on exit without saving</label>
          </div>
          <div className="ml-2">
            <EditableField
              handleSaveChange={handleSaveChange}
              formRenderProps={formRenderProps}
              name={nameOf("FlagPromptToSave")}
              value={getValue(nameOf("FlagPromptToSave"))}
              component={CCSwitch}
              defaultChecked={true}
            />
          </div>
        </div>
        <div className="form-group col-lg-2">
          <div className="d-flex">
            <label className="label">Show map event page</label>
          </div>
          <div className="ml-2">
            <EditableField
              handleSaveChange={handleSaveChange}
              formRenderProps={formRenderProps}
              name={nameOf("FlagEventShowMap")}
              value={getValue(nameOf("FlagEventShowMap"))}
              component={CCSwitch}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="form-group col-lg-4">
          <div className="d-flex">
            <label className="label">Action Officer Label</label>
          </div>
          <EditableField
            handleSaveChange={handleSaveChange}
            formRenderProps={formRenderProps}
            name={nameOf("ActionOfficerLabel")}
            value={getValue(nameOf("ActionOfficerLabel"))}
            placeholder={"Action officer label"}
            component={CCInput}
          />
        </div>
        <div className="form-group col-lg-4">
          <div className="d-flex">
            <label className="label">Coordinator Label</label>
          </div>
          <EditableField
            handleSaveChange={handleSaveChange}
            formRenderProps={formRenderProps}
            name={nameOf("CoordinatorLabel")}
            value={getValue(nameOf("CoordinatorLabel"))}
            placeholder={"Coordinator label"}
            component={CCInput}
          />
        </div>
      </div>

      <div className="row">
        <div className="form-group col-md-4">
          <div className="d-flex">
            <label className="label">Send to Action Officer Label</label>
          </div>
          <EditableField
            handleSaveChange={handleSaveChange}
            formRenderProps={formRenderProps}
            name={nameOf("SendToActionOfficerLabel")}
            value={getValue(nameOf("SendToActionOfficerLabel"))}
            placeholder={"Send to action officer label"}
            component={CCInput}
          />
        </div>
      </div>

      <div className="row">
        <div className="title col-lg-12 text-disabled">Event Printing</div>
        <div className="form-group col-lg-2">
          <div className="d-flex">
            <label className="label">Include Map</label>
          </div>
          <div className="ml-2">
            <EditableField
              name={nameOf("FlagPrintingIncludeMap")}
              value={getValue(nameOf("FlagPrintingIncludeMap"))}
              component={CCSwitch}
              defaultChecked={true}
              handleSaveChange={handleSaveChange}
              formRenderProps={formRenderProps}
            />
          </div>
        </div>
        <div className="form-group col-lg-2">
          <div className="d-flex">
            <label className="label">Include Lastest Info</label>
          </div>
          <div className="ml-2">
            <EditableField
              handleSaveChange={handleSaveChange}
              formRenderProps={formRenderProps}
              name={nameOf("FlagPrintingIncludeLatestInfo")}
              value={getValue(nameOf("FlagPrintingIncludeLatestInfo"))}
              component={CCSwitch}
            />
          </div>
        </div>
        <div className="form-group col-lg-2">
          <div className="d-flex">
            <label className="label">Include Comments</label>
          </div>
          <div className="ml-2">
            <EditableField
              handleSaveChange={handleSaveChange}
              formRenderProps={formRenderProps}
              name={nameOf("FlagPrintingIncludeComments")}
              value={getValue(nameOf("FlagPrintingIncludeComments"))}
              component={CCSwitch}
            />
          </div>
        </div>
        <div className="form-group col-lg-2">
          <div className="d-flex">
            <label className="label">Include Actions</label>
          </div>
          <div className="ml-2">
            <EditableField
              handleSaveChange={handleSaveChange}
              formRenderProps={formRenderProps}
              name={nameOf("FlagPrintingIncludeActions")}
              value={getValue(nameOf("FlagPrintingIncludeActions"))}
              component={CCSwitch}
              defaultChecked={true}
            />
          </div>
        </div>
        <div className="form-group col-lg-2">
          <div className="d-flex">
            <label className="label">Include Contacts</label>
          </div>
          <div className="ml-2">
            <EditableField
              handleSaveChange={handleSaveChange}
              formRenderProps={formRenderProps}
              name={nameOf("FlagPrintingIncludeContacts")}
              value={getValue(nameOf("FlagPrintingIncludeContacts"))}
              component={CCSwitch}
              defaultChecked={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
