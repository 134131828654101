import Actions from "@app/core/actions/_index";
import { Comments } from "@app/core/comments/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { Documents } from "@app/core/documents/_index";
import Fees from "@app/core/fees/_index";
import { Journal } from "@app/core/journal/_index";
import Samples from "@app/core/samples/_index";
import { IParkingPermitsRecordsState } from "@app/products/parking-permits/model";
import { ParkingPermitsForm } from "@app/products/parking-permits/[id]/components/child-screens/general/_index";
import { ParkingPermitsContactTab } from "@app/products/parking-permits/[id]/components/reference-sidebar/contact/_index";
import { ParkingPermitsDetailTab } from "@app/products/parking-permits/[id]/components/reference-sidebar/details/_index";
import { useParkingPermitsStore } from "@app/products/parking-permits/[id]/store";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { splitCamelCase } from "@common/utils/formatting";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistManageParkingPermits = observer(() => {
  const { id } = useParams<{ id: string }>(); // TODO: The id variable is only use in case reloading.
  const {
    parkingPermits,
    parkingPermitsId,
    loadParkingPermits,
    responseLoadError,
    isLoading,
  } = useParkingPermitsStore();

  const formTitle = useMemo(() => {
    return `${
      parkingPermits?.PermitHolder ? parkingPermits?.PermitHolder : "No Name"
    } ${parkingPermits?.PermitType ? `- ${parkingPermits?.PermitType}` : ""}`;
  }, [parkingPermits]);

  const managePageUrl = window.location.pathname;

  const { currentUserInfo } = useGlobalStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Parking Permits - ${parkingPermitsId ?? 0}`,
      Text: `${getDisplayTextWithDashes([
        parkingPermits?.PermitHolder ?? "No Name",
        parkingPermits?.PermitType,
      ])}`,
      LinkUrl: managePageUrl,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.DISRES,
      //TODO don't have exactly record type, temporary use DisRes_Permit
      RecordType_ENUM: RECORDTYPE.DisRes_Permit,
      Record_ID: parkingPermitsId ? +parkingPermitsId : 0,
    },
  ];

  const [parkingPermitsRecords] = useState<
    IParkingPermitsRecordsState | undefined
  >(undefined);

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <ParkingPermitsDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      {
        title: "Contacts",
        component: <ParkingPermitsContactTab />,
      },

      {
        title: "History",
        component: (
          <Journal id={3235079} recordType={RECORDTYPE.DisRes_Permit} />
        ),
      },
    ],
  });

  const [showSlideBar, setShowSlideBar] = useState(true);
  const listPanelBar: IListPanelBar[] = [
    {
      title: "Actions",
      totalRecord: parkingPermitsRecords?.actions,
      component: (
        <Actions id={parkingPermitsId} recordType={RECORDTYPE.DisRes_Permit} />
      ),
    },
    {
      title: "Comments",
      totalRecord: parkingPermitsRecords?.comments,
      component: (
        <Comments id={parkingPermitsId} recordType={RECORDTYPE.DisRes_Permit} />
      ),
    },
    {
      title: "Documents",
      totalRecord: parkingPermitsRecords?.documents,
      component: (
        <Documents
          id={parkingPermitsId}
          recordType={RECORDTYPE.DisRes_Permit}
        />
      ),
    },
    {
      title: "Fees",
      totalRecord: parkingPermitsRecords?.fees,
      component: (
        <Fees id={parkingPermitsId} recordType={RECORDTYPE.DisRes_Permit} />
      ),
    },
    {
      title: "Related",
      totalRecord: parkingPermitsRecords?.samples,
      component: (
        <Samples id={parkingPermitsId} recordType={RECORDTYPE.DisRes_Permit} />
      ),
    },
    {
      title: "Events",
      totalRecord: parkingPermitsRecords?.samples,
      component: (
        <Samples id={parkingPermitsId} recordType={RECORDTYPE.DisRes_Permit} />
      ),
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Parking Permit" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadParkingPermits(+id);
          }}
        />
      ) : (
        <>
          <FormTitle
            title={formTitle}
            badge={
              parkingPermits &&
              splitCamelCase(parkingPermits.Status_ENUM?.toString() || "")
            }
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton title={"Renew permit"} />
                <CCNavButton title={"Cancel permit"} />
              </CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
              <CCNavButton title={"Add"} type="sub">
                <AddActionButton />
                <CCNavButton title={"Attachment"} />
                <CCNavButton title={"Comment"} />
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.DISRES}
                  recordType={RECORDTYPE.DisRes_Permit}
                  recordId={parseInt(id)}
                />
                <CCNavButton title={"Documents"} />
                <CCNavButton title={"Fees"} />
              </CCNavButton>,
              <CCNavButton title={"View"} />,
              <CCNavButton title={"Print"} />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {parkingPermits && (
                  <CCGeneralPanel component={<ParkingPermitsForm />} />
                )}
                <CustomPanelBar listPanelBar={listPanelBar} />
              </div>
            </div>

            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
