import {
  transformColumnSearch,
  transformKeySearch,
} from "@app/core/communication/dialogs/components/form-elememts/to/components/add-recipient/util";

export const getUrlSearchInputPicker = (
  searchText: string,
  colSearch: string,
  dataUrl: string
): string => {
  const columnSearch = transformColumnSearch(colSearch);
  const keySearch = transformKeySearch(searchText);
  return `${dataUrl}?$count=true&$filter=contains(${columnSearch},${keySearch})&`;
};
