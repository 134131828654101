import Actions from "@app/core/actions/_index";
import { Comments } from "@app/core/comments/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { Documents } from "@app/core/documents/_index";
import Fees from "@app/core/fees/_index";
import { Journal } from "@app/core/journal/_index";
import Samples from "@app/core/samples/_index";
import { BuildingRegisterEssentialSafetyForm } from "@app/products/building/registers/essential-safety/[id]/components/child-screens/general/_index";
import { EssentialSafetyContactsTab } from "@app/products/building/registers/essential-safety/[id]/components/reference-sidebar/contacts/_index";
import { EssentialSafetyDetailTab } from "@app/products/building/registers/essential-safety/[id]/components/reference-sidebar/detail/_index";
import { IBuildingEssentialSafetyRecordState } from "@app/products/building/registers/essential-safety/[id]/model";
import { useBuildingRegistersEssentialSafetyStore } from "@app/products/building/registers/essential-safety/[id]/store";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import Contacts from "@common/pages/contacts/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedBuildingRegisterEssentialSafety = observer(() => {
  const { id } = useParams<{ id: string }>(); // TODO: The id variable is only use in case reloading.
  const {
    buildingRegistersEssentialSafety,
    buildingRegistersEssentialSafetyId,
    loadBuildingRegistersEssentialSafety,
    responseLoadError,
    isLoading,
  } = useBuildingRegistersEssentialSafetyStore();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();

  const formTitle = useMemo(() => {
    const registerNumber = buildingRegistersEssentialSafety?.ID;
    const contact = buildingRegistersEssentialSafety?.Contact;
    // If these two are not falsy, return both, otherwise return one or default
    if (registerNumber && contact) {
      return `${registerNumber} - ${contact}`;
    }
    return String(registerNumber) || contact || "N/A";
  }, [buildingRegistersEssentialSafety]);

  const [showSlideBar, setShowSlideBar] = useState(true);

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Building - Registers - Essential Safety - ${
        buildingRegistersEssentialSafetyId ?? 0
      }`,
      Text:
        `${getDisplayTextWithDashes([
          buildingRegistersEssentialSafety?.RegisterNumber,
          buildingRegistersEssentialSafety?.Contact,
        ])}` || "N/A",
      LinkUrl: managePageUrl,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Building,
      RecordType_ENUM: RECORDTYPE.BUILDING,
      Record_ID: buildingRegistersEssentialSafetyId
        ? +buildingRegistersEssentialSafetyId
        : 0,
    },
  ];

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <EssentialSafetyDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts", component: <EssentialSafetyContactsTab /> },
      {
        title: "History",
        component: <Journal id={3237033} recordType={RECORDTYPE.BUILDING} />,
      },
    ],
  });
  const [buildingEssentialSafetyRecordState] =
    useState<IBuildingEssentialSafetyRecordState>(
      new IBuildingEssentialSafetyRecordState()
    );

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Addresses",
      totalRecord: buildingEssentialSafetyRecordState.addresses,
      component: (
        <Samples
          id={buildingRegistersEssentialSafetyId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Actions",
      totalRecord: buildingEssentialSafetyRecordState.actions,
      component: (
        <Actions
          id={buildingRegistersEssentialSafetyId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Comments",
      totalRecord: buildingEssentialSafetyRecordState.comments,
      component: (
        <Comments
          id={buildingRegistersEssentialSafetyId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Contacts",
      totalRecord: buildingEssentialSafetyRecordState.contacts,
      component: (
        <Contacts
          id={buildingRegistersEssentialSafetyId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Documents",
      totalRecord: buildingEssentialSafetyRecordState.documents,
      component: (
        <Documents
          id={buildingRegistersEssentialSafetyId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Fees",
      totalRecord: buildingEssentialSafetyRecordState.fees,
      component: (
        <Fees
          id={buildingRegistersEssentialSafetyId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Inspections",
      totalRecord: buildingEssentialSafetyRecordState.inspections,
      component: (
        <Samples
          id={buildingRegistersEssentialSafetyId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Essential Safety" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadBuildingRegistersEssentialSafety(+id);
          }}
        />
      ) : (
        <>
          <FormTitle
            title={formTitle}
            badge={buildingRegistersEssentialSafety?.Status}
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              ></CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
              <CCNavButton title={"Add"} type="sub">
                <AddActionButton />
                <CCNavButton title={"Additional address"} />
                <CCNavButton title={"Contact"} />
                <CCNavButton title={"Appeal"} />
                <CCNavButton title={"Attachment"} />
                <CCNavButton title={"Comment"} />
                {/* TODO: enhance later */}
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.Building}
                  recordType={RECORDTYPE.TP_BuildingApplication}
                  recordId={parseInt(id)}
                />
                <CCNavButton title={"Complaint"} />
                <CCNavButton title={"Document"} />
                <CCNavButton title={"Fee"} />
                <CCNavButton title={"Inspection"} />
                <CCNavButton title={"Notice"} />
                <CCNavButton title={"Prosecution"} />
              </CCNavButton>,

              <CCNavButton title={"View"} type="sub">
                <CCNavButton title={"Documents"} />
                <CCNavButton title={"Journal"} />
                <CCNavButton title={"Project"} />
              </CCNavButton>,
              <CCNavButton title={"Print"} />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {buildingRegistersEssentialSafety && (
                  <CCGeneralPanel
                    component={<BuildingRegisterEssentialSafetyForm />}
                  />
                )}
                <CustomPanelBar listPanelBar={listPanelBar} />
              </div>
            </div>

            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
