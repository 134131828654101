import { IconEditCell } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/components/icon-edit-cell/_index";
import { CardNumberEditCell } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/concession-cards/components/card-number-edit-cell/_index";
import { DatePresentedEditCell } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/concession-cards/components/date-presented-edit-cell/_index";
import { DTO_Entity_Concession_Card } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { DATE_FORMAT, ICON_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Entity_Concession_Card>();
export const colConcessionCardsHeld: IColumnFields[] = [
  { field: nameOf("Name"), title: "Concession Card" },
  {
    field: nameOf("Card_Number"),
    title: "Card Number",
    minWidth: 150,
    editCell: CardNumberEditCell,
    editable: true,
    disableEditField: (dataItem) => {
      return !dataItem.Is_Active;
    },
  },
  {
    field: nameOf("Is_Active"),
    title: "Is Active",
    format: ICON_FORMAT.ICON_BOOLEAN,
    editable: true,
    editCell: IconEditCell,
  },
  {
    field: nameOf("Presented_Date"),
    title: "Date Presented",
    format: DATE_FORMAT.DATE,
    minWidth: 210,
    editCell: DatePresentedEditCell,
    editable: true,
  },
  {
    field: nameOf("Confirmation_Date"),
    title: "Confirmation Date",
    format: DATE_FORMAT.DATE,
  },
];
