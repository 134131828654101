import { ICCRoute } from "@common/constants/ICCRoute";

export const financeRoute: ICCRoute = {
  path: "finance",
  name: "Finance",
  children: [
    {
      path: "os-fees",
      name: "OS Fees",
      component: require("./os-fees/_index").default,
    },
    {
      path: "paid-fees",
      name: "Paid Fees",
      component: require("./paid-fees/_index").default,
    },
    {
      path: "fees-all",
      name: "Fees All",
      component: require("./fees-all/_index").default,
    },
    {
      path: "not-send-to-finance",
      name: "Not Send To Finance",
      component: require("./not-send-to-finance/_index").default,
    },
    {
      path: "send-to-finance",
      name: "Send To Finance",
      component: require("./send-to-finance/_index").default,
    },
  ],
};
