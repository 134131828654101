import { eventEmitter } from "@/App";
import { history } from "@/AppRoutes";
import { getParentSummary } from "@app/core/further-info/[id]/api";
import { Ancestor } from "@app/core/further-info/[id]/model";
import { BubbleUpType } from "@app/core/inspections/[id]/model";
import { postInvoiceById, postSaveInvoice } from "@app/core/invoice/[id]/api";
import { INVOICE_MANAGE_ROUTE } from "@app/core/invoice/[id]/constant";
import { IParentInvoiceSection, Invoice } from "@app/core/invoice/[id]/model";
import {
  generateInvoiceDescription,
  getBubbleUpDetailsByType,
  mapEnum,
} from "@app/core/invoice/[id]/util";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import {
  IAppNotificationItem,
  IAppNotificationItemAddProps,
} from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class InvoiceStore {
  private _invoice?: Invoice = undefined;
  private _isLoading: boolean = false;
  private _isLoadingDetails: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _parentBubbleUpDetails?: BubbleUpIdentifier = undefined;
  private _parent?: IParentInvoiceSection = undefined;
  private _isLoadingParent: boolean = false;
  private _ancestor?: Ancestor = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _notification?: IAppNotificationItemAddProps = undefined;
  private _lstExportedFeeIDs?: number[] = [];
  private _isShowInvoiceItemDialog?: boolean = false;
  private _isLoadingSaveInvoiceItem?: boolean = false;
  private _isExpandedInvoiceItems?: boolean = true;
  private _forceModified: boolean = false;
  private _countInvoiceItems: number | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get invoice() {
    return toJS(this._invoice);
  }
  setInvoice = (invoice?: Invoice) => {
    runInAction(() => {
      this._invoice = invoice;
      if (this._invoice?.Invoice_ID === 0) {
        this._invoice.InvoiceActiveDate = new Date();
      }
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get countInvoiceItems() {
    return this._countInvoiceItems;
  }
  setCountInvoiceItems = (total: number | undefined) => {
    runInAction(() => {
      this._countInvoiceItems = total;
    });
  };

  get forceModified() {
    return this._forceModified;
  }
  setForceModified = (status: boolean) => {
    runInAction(() => {
      this._forceModified = status;
    });
  };

  get isLoadingSaveInvoiceItem() {
    return this._isLoadingSaveInvoiceItem;
  }
  setIsLoadingSaveInvoiceItem = (isLoadingSaveInvoiceItem: boolean) => {
    runInAction(() => {
      this._isLoadingSaveInvoiceItem = isLoadingSaveInvoiceItem;
    });
  };

  get isLoadingDetails() {
    return this._isLoadingDetails;
  }
  setIsLoadingDetails = (isLoadingDetails: boolean) => {
    runInAction(() => {
      this._isLoadingDetails = isLoadingDetails;
    });
  };

  get parentBubbleUpDetails() {
    return this._parentBubbleUpDetails;
  }

  setParentBubbleUpDetails = (parentBubbleUpDetails?: BubbleUpIdentifier) => {
    runInAction(() => {
      this._parentBubbleUpDetails = parentBubbleUpDetails;
    });
  };

  get parent() {
    return this._parent;
  }
  setParent = (parent?: IParentInvoiceSection) => {
    runInAction(() => {
      this._parent = parent;
    });
  };

  get isLoadingParent() {
    return this._isLoadingParent;
  }
  setIsLoadingParent = (isLoadingParent: boolean) => {
    runInAction(() => {
      this._isLoadingParent = isLoadingParent;
    });
  };

  get ancestor() {
    return this._ancestor;
  }
  setAncestor = (parentLine?: Ancestor) => {
    runInAction(() => {
      this._ancestor = parentLine;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get notification() {
    return this._notification;
  }
  setNotification = (
    notification: IAppNotificationItemAddProps | undefined
  ) => {
    runInAction(() => {
      this._notification = notification;
    });
  };

  get lstExportedFeeIDs() {
    return this._lstExportedFeeIDs;
  }
  setLstExportedFeeIDs = (lstExportedFeeIDs: number[] | undefined) => {
    runInAction(() => {
      this._lstExportedFeeIDs = lstExportedFeeIDs;
    });
  };

  get isShowInvoiceItemDialog() {
    return this._isShowInvoiceItemDialog;
  }
  setIsShowInvoiceItemDialog = (isShowInvoiceItemDialog: boolean) => {
    runInAction(() => {
      this._isShowInvoiceItemDialog = isShowInvoiceItemDialog;
    });
  };

  get invoiceID() {
    return this.invoice?.Invoice_ID;
  }

  get isExpandedInvoiceItems() {
    return this._isExpandedInvoiceItems;
  }
  setIsExpandedInvoiceItems = (isExpandedInvoiceItems: boolean) => {
    runInAction(() => {
      this._isExpandedInvoiceItems = isExpandedInvoiceItems;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._invoice = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._parentBubbleUpDetails = undefined;
      this._parent = undefined;
      this._isLoadingParent = false;
      this._isLoadingDetails = false;
      this._ancestor = undefined;
      this._onSubmit = undefined;
      this._notification = undefined;
      this._lstExportedFeeIDs = undefined;
      this._isShowInvoiceItemDialog = false;
      this._isLoadingSaveInvoiceItem = false;
      this._isExpandedInvoiceItems = true;
    });
  };

  loadInvoiceById = async (
    recordId: number,
    isNew: boolean,
    deleteMsgSuccess?: IAppNotificationItem
  ) => {
    this.setIsLoading(true);
    let errorResponse = undefined;

    let newInvoice: Invoice | undefined;

    let parentParams = {};
    if (isNew && this.parent?.id && this.parent?.recordType) {
      parentParams = {
        ParentId: this.parent.id,
        RecordType: this.parent.recordType,
      };
    }

    const response = await postInvoiceById(recordId, parentParams);
    if (isSuccessResponse(response) && response.data) {
      newInvoice = response.data.ReturnObj?.Invoice;
      this.setLstExportedFeeIDs(response.data.ReturnObj?.LstExportedFeeIDs);
      if (!isNew) {
        this.setInvoice(newInvoice);
      } else {
        this.setInvoice({
          ...newInvoice,
          _BubbleUps: this.parent?.bubbleUps ?? [],
        } as Invoice);
      }
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }

    const sourceIdentifier = newInvoice?._BubbleUps?.find(
      (bubbleUp: BubbleUpIdentifier) =>
        mapEnum(bubbleUp.BubbleUpType_ENUM, BubbleUpType) ===
        BubbleUpType.Parent
    )?.SourceIdentifier;

    if (sourceIdentifier) {
      const newParent = {
        ...this.parent,
        id: sourceIdentifier._RecordSource_ID,
        recordType: mapEnum(
          sourceIdentifier._RecordSourceType_ENUM,
          RECORDTYPE
        ),
      };
      await this.loadParent(newParent, recordId, newInvoice);
    } else if (this.parent) {
      await this.loadParent(this.parent, recordId, newInvoice);
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);

    // Push notification
    if (this.notification && !errorResponse) {
      appNotificationStore.pushNotification(this.notification);
      this.setNotification(undefined);
    }

    if (deleteMsgSuccess && !errorResponse)
      appNotificationStore.pushNotification(deleteMsgSuccess);

    if (this.parent?.notification && !errorResponse) {
      this.parent?.notification.forEach(
        (notification: IAppNotificationItemAddProps) => {
          appNotificationStore.pushNotification(notification);
        }
      );
      this.setParent({ ...this.parent, notification: [] });
      window.history.replaceState({}, "");
    }
  };

  loadParent = async (
    parent: IParentInvoiceSection,
    recordId?: number,
    newInvoice?: Invoice
  ) => {
    this.setParent(parent);
    const response = await getParentSummary(parent.id, parent.recordType);
    if (isSuccessResponse(response) && response.data) {
      this.setAncestor(response.data);
      // Refactor invoice description
      if (recordId === 0) {
        this.setInvoice({
          ...newInvoice,
          Description: generateInvoiceDescription(
            newInvoice?.Description,
            response.data.litParent1
          ),
        } as Invoice);
      }
    } else {
      appNotificationStore.pushNotification({
        type: "error",
        autoClose: false,
        title: response?.error ?? "Load parent data failed",
      });
    }
  };

  loadInvoiceDetails = async (recordId: number) => {
    this.setIsLoadingDetails(true);
    let errorResponse = undefined;

    let newInvoice: Invoice | undefined;

    const response = await postInvoiceById(recordId);
    if (isSuccessResponse(response) && response.data) {
      newInvoice = response.data.ReturnObj?.Invoice;

      this.setInvoice(newInvoice);
      this.setParentBubbleUpDetails(
        getBubbleUpDetailsByType(newInvoice?._BubbleUps, BubbleUpType.Parent)
      );
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }

    const sourceIdentifier = newInvoice?._BubbleUps?.find(
      (bubbleUp: BubbleUpIdentifier) =>
        mapEnum(bubbleUp.BubbleUpType_ENUM, BubbleUpType) ===
        BubbleUpType.Parent
    )?.SourceIdentifier;
    if (sourceIdentifier) {
      const newParent = {
        ...this.parent,
        id: sourceIdentifier._RecordSource_ID,
        recordType: mapEnum(
          sourceIdentifier._RecordSourceType_ENUM,
          RECORDTYPE
        ),
      };
      await this.loadParent(newParent);
    }
    this.setIsLoadingDetails(false);
    this.setResponseLoadError(errorResponse);
  };

  saveInvoice = async (
    invoice: Invoice,
    isNew: boolean,
    isSaveInvoiceItem?: boolean,
    notificationRef?: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    let dataBubbleUps = undefined;

    isSaveInvoiceItem
      ? this.setIsLoadingSaveInvoiceItem(true)
      : this.setIsLoading(true);

    if (!invoice.InvoiceDate) {
      invoice.InvoiceDate = new Date();
    }

    if (invoice._BubbleUps?.length === 0) {
      dataBubbleUps = { ...invoice, _BubbleUps: this.parent?.bubbleUps };
    }

    const response = await postSaveInvoice(
      (dataBubbleUps as Invoice) ?? invoice
    );

    isSaveInvoiceItem
      ? this.setIsLoadingSaveInvoiceItem(false)
      : this.setIsLoading(false);

    if (isSuccessResponse(response)) {
      if (isSaveInvoiceItem) this.setIsShowInvoiceItemDialog(false);

      if (isNew) {
        history.replace(`${INVOICE_MANAGE_ROUTE}/${response.data?.ID}`, {
          parent: {
            notification: [
              {
                autoClose: true,
                title: "Record successfully saved.",
                type: "success",
              },
            ],
          },
        });
      } else {
        this.setNotification({
          autoClose: true,
          title: "Record successfully saved.",
          type: "success",
        });
        this.loadInvoiceById(response?.data?.ID, false);
      }
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(CCJournalEventType.RefreshData);
      eventEmitter.emit(TabTableEventType.RefreshData);
    } else {
      if (isSaveInvoiceItem && notificationRef) {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: response?.error ?? "Invoice item could not be saved.",
          type: "error",
        });
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: response?.error ?? "Invoice could not be saved.",
          type: "error",
          description: response?.data.Errors,
        });
      }
    }
    this.setCountInvoiceItems(undefined);
    this.setForceModified(false);
  };
}

const InvoiceStoreContext = createContext(new InvoiceStore());
export const useInvoiceStore = () => {
  return useContext(InvoiceStoreContext);
};
