import { getViewConfigurations } from "@app/products/property/api";
import {
  colAssociationParcel,
  colFoliosAssessment,
} from "@app/products/property/assessments/components/form-steps/new-assessment/components/form-elements/associations-parcel/config";
import {
  DTO_CreateAssessment_Parcel,
  DTO_Title,
  EAssociationGridType,
} from "@app/products/property/assessments/components/form-steps/new-assessment/components/form-elements/associations-parcel/model";
import { AddParcelLookupDialog } from "@app/products/property/components/dialogs/add-parcel-lookup/_index";
import { DTO_Parcel } from "@app/products/property/components/dialogs/add-parcel-lookup/model";
import { AddTitleLookupDialog } from "@app/products/property/components/dialogs/add-title-lookup/_index";
import { getSearchTitleLookup } from "@app/products/property/components/dialogs/add-title-lookup/api";
import { eOptionSearchTitleLookup } from "@app/products/property/components/dialogs/add-title-lookup/config";
import {
  fnt_Title_LookupResult,
  folioGridLookupLoading,
} from "@app/products/property/components/dialogs/add-title-lookup/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { processCombineData } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/associations/util";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const AssociationParcelsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const nameOfParcel = nameOfFactory<DTO_CreateAssessment_Parcel>();
const nameOfFolio = nameOfFactory<DTO_Title>();

const FormStepElement = observer(
  ({
    formRenderProps,
    localNotificationRef,
    nameOf,
    options = {
      isReadOnly: false,
      isLLS: false,
      setIsLoadingDialog: () => {},
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const [isShowDialog, setIsShowDialog] = useState<EAssociationGridType>();
    const parcels = getFieldValue("AssociateParcels") ?? [];
    const parcelSelected = getFieldValue("_option.ParcelSelected") ?? [];
    const loadingMode = getFieldValue("_option.Loading") ?? false;
    const folio = getFieldValue("AssociateTitles") ?? [];
    const folioSelected = getFieldValue("_option.FolioSelected") ?? [];
    const [processedColumns, setProcessedColumns] = useState<IColumnFields[]>(
      options?.isLLS ? colFoliosAssessment : colAssociationParcel
    );
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >(undefined);

    //Get label
    const [titleLabel, titlesLabel] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Title,
      ECustomColNameProperty.Titles,
    ]);

    /**
     * handle add item
     * @param data
     */
    const handleAddItemParcel = (data: DTO_Parcel[]) => {
      const newData: DTO_CreateAssessment_Parcel[] =
        data?.map((item: DTO_Parcel) => {
          return {
            ...item,
            AssociationFactorPercentage: 100,
            PropertyName: "",
            LandCategory: "",
            MapReference: "",
            MapNumber: "",
            LocationDescriptor: "",
          };
        }) ?? [];
      const previousParcel = parcels?.length ? [...parcels] : [];
      const newParcels = processCombineData(
        previousParcel,
        newData,
        nameOfParcel("Parcel_Id")
      );
      onChange(nameOf("AssociateParcels"), {
        value: newParcels,
      });
    };

    /**
     * handle delete item
     */
    const handleDeleteItem = () => {
      const parcelId = parcelSelected?.[0]?.Parcel_Id;
      const newParcel = parcels.filter(
        (item: DTO_CreateAssessment_Parcel) => parcelId !== item.Parcel_Id
      );
      onChange(nameOf("AssociateParcels"), {
        value: newParcel,
      });
      onChange(nameOf("_option.ParcelSelected"), {
        value: [],
      });
    };

    const handleAddItemFolio = async (data: fnt_Title_LookupResult[]) => {
      const listTitleId = data.map((item) => item.Title_Id).toString();
      onChange(nameOf("_option.Loading"), {
        value: folioGridLookupLoading,
      });
      options?.setIsLoadingDialog(true);
      const responseTitleLookup = await getSearchTitleLookup({
        LookupKey: eOptionSearchTitleLookup.TitleId,
        LookupValue: listTitleId,
        Statuses: [0], //default task 12866
      });
      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
      if (isSuccessResponse(responseTitleLookup) && responseTitleLookup?.data) {
        const resTitle = responseTitleLookup?.data?.Titles ?? [];
        const newData: DTO_Title[] =
          resTitle?.map((item: DTO_Title) => {
            return {
              ...item,
              AssociationFactorPercentage: 100,
            };
          }) ?? [];
        const previousFolio = folio?.length ? [...folio] : [];
        const newDataCombine = processCombineData(
          previousFolio,
          newData,
          nameOfFolio("TitleId")
        );
        onChange(nameOf("AssociateTitles"), {
          value: newDataCombine,
        });
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Load ${titleLabel}s failed`,
          type: "error",
          autoClose: false,
        });
      }
      options?.setIsLoadingDialog(false);
    };

    const handleDeleteItemFolio = () => {
      const folioId = folioSelected?.[0]?.TitleId;
      const newFolio = folio.filter(
        (item: DTO_Title) => folioId !== item.TitleId
      );
      onChange(nameOf("AssociateTitles"), {
        value: newFolio,
      });
      onChange(nameOf("_option.FolioSelected"), {
        value: [],
      });
    };

    const handleDataChange = (
      dataRow: any,
      gridName: string,
      primaryKey: "TitleId" | "Parcel_Id",
      gridData: DTO_Title[] | DTO_Parcel[]
    ) => {
      const id = dataRow?.[primaryKey];
      let newGridData = [...gridData];
      newGridData = newGridData?.map((item: any) =>
        item?.[primaryKey] === id ? { ...dataRow } : item
      );
      onChange(nameOf(gridName), { value: newGridData });
    };

    const loadViewConfiguration = async () => {
      onChange(nameOf("_option.Loading"), {
        value: true,
      });
      options?.setIsLoadingDialog(true);
      let viewConfigColumn;
      let colViewConfig: IColumnFields[] = [];
      if (options?.isLLS) {
        viewConfigColumn = ViewConfiguration.TitleSearch;
        colViewConfig = [...colFoliosAssessment];
      } else {
        viewConfigColumn = ViewConfiguration.ParcelSearch;
        colViewConfig = [...colAssociationParcel];
      }
      getViewConfigurations(viewConfigColumn).then((response) => {
        if (isSuccessResponse(response)) {
          const viewConfig: ICCViewColumn[] | undefined =
            response?.data?.ColumnDefinitions?.Columns;
          if (!viewConfig || viewConfig?.length === 0) {
            setProcessedColumns([]);
            onChange(nameOf("_option.Loading"), { value: false });
            options?.setIsLoadingDialog(false);

            return;
          }
          setProcessedColumns(processDynamicColumns(colViewConfig, viewConfig));
        } else {
          setResponseLoadError({
            status: response.status,
            error: response.error ?? "Load failed",
          });
        }
        onChange(nameOf("_option.Loading"), { value: false });
        options?.setIsLoadingDialog(false);
      });
    };

    useEffectOnce(() => {
      loadViewConfiguration();
    });

    return (
      <section className="cc-field-group">
        {/* Task [14733]: Only show Title grid with LLS state */}
        {options?.isLLS && (
          <>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title={titlesLabel} />
                {loadingMode ? (
                  <Loading isLoading={loadingMode} />
                ) : responseLoadError ? (
                  <CCLoadFailed
                    responseError={responseLoadError}
                    onReload={() => {
                      loadViewConfiguration();
                    }}
                  />
                ) : (
                  <CCGrid
                    data={folio ?? []}
                    columnFields={processedColumns}
                    selectableMode={options?.isReadOnly ? "none" : "single"}
                    primaryField={nameOfFolio("TitleId")}
                    selectedRows={folioSelected ?? []}
                    editableMode="cell"
                    onSelectionChange={(dataItems: DTO_Title[]) => {
                      onChange(nameOf("_option.FolioSelected"), {
                        value: dataItems ?? [],
                      });
                    }}
                    onDataRowChange={(dataRow) =>
                      handleDataChange(
                        dataRow,
                        "AssociateTitles",
                        "TitleId",
                        folio
                      )
                    }
                    readOnly={options?.isReadOnly}
                    toolbar={
                      <div className="cc-grid-tools-bar">
                        <Button
                          iconClass="fas fa-plus"
                          title={`Add ${titleLabel}`}
                          onClick={() => {
                            setIsShowDialog(EAssociationGridType.Title);
                          }}
                          disabled={options?.isReadOnly}
                        />
                        <Button
                          iconClass="fas fa-minus"
                          title={`Remove ${titleLabel}`}
                          onClick={() => handleDeleteItemFolio()}
                          disabled={
                            options?.isReadOnly || folioSelected.length < 1
                          }
                        />
                      </div>
                    }
                  />
                )}
              </div>
            </div>
            {isShowDialog === EAssociationGridType.Title ? (
              <AddTitleLookupDialog
                onClose={() => setIsShowDialog(undefined)}
                handleAddTitle={(data: fnt_Title_LookupResult[]) => {
                  setIsShowDialog(undefined);
                  handleAddItemFolio(data);
                }}
              />
            ) : null}
          </>
        )}
        {!options?.isLLS && (
          <div className="cc-form-cols-1">
            <div className="cc-field cc-association-parcels">
              <label className="cc-label">Parcels</label>
              {loadingMode ? (
                <Loading isLoading={loadingMode} />
              ) : responseLoadError ? (
                <CCLoadFailed
                  responseError={responseLoadError}
                  onReload={() => {
                    loadViewConfiguration();
                  }}
                />
              ) : (
                <CCGrid
                  toolbar={
                    <div className="cc-grid-tools-bar">
                      <Button
                        iconClass="fas fa-plus"
                        type="button"
                        title="Add parcel"
                        onClick={() => {
                          setIsShowDialog(EAssociationGridType.Parcel);
                        }}
                        disabled={options?.isReadOnly}
                      />
                      <Button
                        type="button"
                        iconClass="fas fa-minus"
                        title="Remove parcel"
                        disabled={
                          options?.isReadOnly || parcelSelected?.length <= 0
                        }
                        onClick={() => {
                          handleDeleteItem();
                        }}
                      />
                    </div>
                  }
                  selectableMode="single"
                  data={parcels ?? []}
                  primaryField={nameOfParcel("Parcel_Id")}
                  columnFields={colAssociationParcel}
                  selectedRows={parcelSelected ?? []}
                  onDataRowChange={(dataRow) =>
                    handleDataChange(
                      dataRow,
                      "AssociateParcels",
                      "Parcel_Id",
                      parcels
                    )
                  }
                  editableMode="cell"
                  onSelectionChange={(
                    dataItem: DTO_CreateAssessment_Parcel[]
                  ) => {
                    onChange(nameOf("_option.ParcelSelected"), {
                      value: dataItem ?? [],
                    });
                  }}
                  readOnly={options?.isReadOnly}
                />
              )}
              {isShowDialog === EAssociationGridType.Parcel ? (
                <AddParcelLookupDialog
                  onClose={() => setIsShowDialog(undefined)}
                  handleAddParcel={(data: DTO_Parcel[]) => {
                    handleAddItemParcel(data);
                    setIsShowDialog(undefined);
                  }}
                  isLoadingFinish={loadingMode}
                />
              ) : null}
            </div>
          </div>
        )}
      </section>
    );
  }
);
