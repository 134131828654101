import {
  IAttachment,
  IWebLink,
} from "@app/core/attachment/components/buttons/add-attachment/model";
import { AddAttachmentDialog } from "@app/core/attachment/components/dialogs/_index";
import {
  IAddAttachmentDialog,
  eUploadMode,
} from "@app/core/attachment/components/dialogs/model";
import { convertToFileStreamEntry } from "@app/core/attachment/components/dialogs/util";
import {
  CORE_GET_CORPORATE_AUTHOR_ODATA,
  CORE_GET_HOMEPAGE_VIEWS_ODATA,
  CORE_GET_VIEW_SECURITY_TEMPLATES_ODATA,
} from "@app/core/components/common/constant";
import { odataCoreGetOrgUnit } from "@app/core/components/common/utils";
import { defaultBuildAddress } from "@app/core/contacts/_id/components/child-screens/general/components/form-element/configs";
import { ContactUpdateTriggers } from "@app/core/contacts/_id/model";
import {
  getDocumentById,
  saveDocument,
  saveDocumentAsWebLink,
} from "@app/core/documents/api";
import { Svc_Attachment } from "@app/core/documents/model";
import { getSiteUser } from "@app/core/inspections/[id]/api";
import { optionOfficer } from "@app/core/inspections/[id]/components/child-screens/general/components/inspection-form-element/config";
import { Officers } from "@app/core/inspections/[id]/model";
import { checkProductActiveAndFlagCommunication } from "@app/products/crms/util";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { IOrgUnit } from "@app/products/town-planning/ppr/[id]/components/input-picker/pick-org-structure/model";
import AvatarDefault from "@assets/img/AvatarDefault.svg";
import SignatureDefault from "@assets/img/SignatureDefault.svg";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { APIResponseStatus } from "@common/constants/response-status";
import { useIsNew } from "@common/hooks/useIsNew";
import { AddressPicker } from "@common/input-pickers/address/_index";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import {
  CRMS_FORM_TYPE,
  EVENT_SIMPLE_METHOD,
} from "@common/pages/settings/constant";
import { colHomepageShortcuts } from "@common/pages/settings/homepage/homepage-shortcuts/config";
import { Svc_HomepageShortcuts } from "@common/pages/settings/homepage/homepage-shortcuts/model";
import { colHomepageViews } from "@common/pages/settings/homepage/homepage-views/config";
import { Svc_HomepageViews } from "@common/pages/settings/homepage/homepage-views/model";
import { CorporateAuth } from "@common/pages/settings/security/corporate-authorisations/_id/model";
import { colCorporateAuthorisations } from "@common/pages/settings/security/corporate-authorisations/config";
import { Svc_CorporateAuthorisations } from "@common/pages/settings/security/corporate-authorisations/model";
import { colSecurityTemplates } from "@common/pages/settings/security/security-templates/config";
import { Svc_SecurityTemplate } from "@common/pages/settings/security/security-templates/model";
import {
  checkAvailability,
  postChangeUserName,
  putChangePassword,
} from "@common/pages/settings/security/site-users/_id/api";
import { UserCredentials } from "@common/pages/settings/security/site-users/_id/components/dialogs/credentials/model";
import { CredentialsPicker } from "@common/pages/settings/security/site-users/_id/components/input-picker/exchange-username/_index";
import {
  colOrgUnits,
  colPickCorpAuthorization,
  colPickHomepageShortcuts,
  colPickHomepageViews,
  colPickSecurityTemplates,
} from "@common/pages/settings/security/site-users/_id/config";
import {
  ChangePassword,
  HomePageFunction,
  HomePageView,
  NewSiteUser,
  SITE_USER_DELETE_ITEMS,
  SiteUser,
} from "@common/pages/settings/security/site-users/_id/model";
import { useSiteUserStore } from "@common/pages/settings/security/site-users/_id/store";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { aesEncrypt } from "@common/utils/cryptography";
import { requiredValidator } from "@common/utils/field-validators";
import { getFullAddressHTML } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmailRequired } from "@components/cc-input-email/util";
import { CCInputPhone } from "@components/cc-input-phone/_index";
import { useIsEnableValidatePhoneNumber } from "@components/cc-input-phone/hook/useIsEnableValidatePhoneNumber";
import { EModePhoneNumber } from "@components/cc-input-phone/model";
import { validatePhoneNumber } from "@components/cc-input-phone/util";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCPickOrgUnits } from "@components/cc-pick-org-units/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { GridSelectionDialog } from "@components/dialog/dialog-grid-selection/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  InputChangeEvent,
  SwitchChangeEvent,
} from "@progress/kendo-react-inputs";
import { filter, isEmpty, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import "./_index.scss";
export interface ISiteUserFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOfOrgUnit = nameOfFactory<IOrgUnit>();
const nameOfSecurityTemplate = nameOfFactory<Svc_SecurityTemplate>();
const nameOfCorporateAuth = nameOfFactory<CorporateAuth>();
const nameOfHomepageShortcuts = nameOfFactory<HomePageFunction>();
const nameOfHomepageViews = nameOfFactory<HomePageView>();

const SiteUserFormElement = ({
  formRenderProps,
}: ISiteUserFormElementProps) => {
  const { productsActive } = useGlobalStore();
  const isCRMSActive = checkProductActiveAndFlagCommunication(
    productsActive,
    PRODUCT_TYPE.CustomerService
  );

  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  const { onChange } = formRenderProps;
  const getValue = formRenderProps.valueGetter;
  const { settings } = useCommonCoreStore();
  const enableVIP = getBoolValueSetting(
    settings[ECorporateSettingsField.EnableVIP]
  );
  const isEnableValidatePhoneNumber = useIsEnableValidatePhoneNumber();
  const isNew = useIsNew();
  const [isOpenPopupPhoto, setOpenPopupPhoto] = useState(false);
  const [isOpenPopupSignature, setOpenPopupSignature] = useState(false);
  const [isShowAddOrgUnitDialog, setIsShowAddOrgUnitDialog] = useState(false);
  const [isShowAddAdHocDialog, setIsShowAddAdHocDialog] = useState(false);
  const [isShowSecurityTemplateDialog, setIsShowSecurityTemplateDialog] =
    useState(false);
  const [isShowCorpAuthorisations, setIsShowCorpAuthorisations] =
    useState(false);
  const [isShowHomepageShortcuts, setIsShowHomepageShortcuts] = useState(false);
  const [isShowHomepageViews, setIsShowHomepageViews] = useState(false);
  const [availability, setAvailability] = useState<boolean>(false);
  const [showAvailability, setshowAvailability] = useState<boolean>(false);
  const [checkingAvailability, setCheckingAvailability] =
    useState<boolean>(false);
  const [loadingChangeUserName, setLoadingChangeUserName] =
    useState<boolean>(false);
  const [loadingSetPassword, setLoadingSetPassword] = useState<boolean>(false);
  const [isLoadingUploadPhoto, setIsLoadingUploadPhoto] = useState(false);
  const [isLoadingSignature, setIsLoadingSignature] = useState(false);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
    useState<SITE_USER_DELETE_ITEMS | null>(null);

  const [orgGridData, setOrgGridData] = useState<IOrgUnit[]>(() => {
    const orgUnits = getValue("Contact_SiteUser.OrgUnits");
    if (orgUnits && orgUnits.length > 0)
      return orgUnits.map((unit: IKeyValuePacket) => {
        return { ID: unit.Key, Hierarchy: unit.Value };
      });
    return [];
  });
  const [selectedOrgUnits, setSelectedOrgUnits] = useState<IOrgUnit[]>([]);

  const [selectedSecurityTemplate, setSelectedSecurityTemplate] = useState<
    Svc_SecurityTemplate[]
  >([]);
  const [securityTemplateGridData, setSecurityTemplateGridData] = useState<
    Svc_SecurityTemplate[]
  >(() => {
    const securityTemplate = getValue(
      "Contact_SiteUser.Special_SecurityTemplates"
    );
    if (securityTemplate && securityTemplate.length > 0)
      return securityTemplate;
    return [];
  });

  //Name
  const handleOnChangeFirstName = (e: InputChangeEvent) => {
    onChange("Contact_SiteUser.FirstName", { value: e.value });
    onChange("Contact_SiteUser.DisplayName", {
      value: e.value + " " + (getValue("Contact_SiteUser.LastName") ?? ""),
    });
  };
  const handleOnChangeLastName = (e: InputChangeEvent) => {
    onChange("Contact_SiteUser.LastName", { value: e.value });
    onChange("Contact_SiteUser.DisplayName", {
      value: (getValue("Contact_SiteUser.FirstName") ?? "") + " " + e.value,
    });
  };

  //Corp Authorisations
  const [selectedCorpAuthorisations, setSelectedCorpAuthorisations] = useState<
    CorporateAuth[]
  >([]);
  const [corpAuthorisationsGridData, setCorpAuthorisationsGridData] = useState<
    CorporateAuth[]
  >(() => {
    const corpAuthorisations = getValue("Contact_SiteUser.CorporateAuths"); //TODO: Change to Contact_SiteUser.CorporateAuths
    if (corpAuthorisations && corpAuthorisations?.length > 0)
      return corpAuthorisations;
    return [];
  });
  const handleOnChangeCorpAuthorisations = (
    data: Svc_CorporateAuthorisations[]
  ) => {
    const newCorpAuthorisations = data?.map(
      (item: Svc_CorporateAuthorisations) => {
        return {
          CorporateAuth_ID: item?.ID,
          AuthorisationName: item?.CorporateAuthorisationName,
          AuthorisationDescription: item?.CorporateAuthorisationDescription,
        } as CorporateAuth;
      }
    );
    setCorpAuthorisationsGridData(newCorpAuthorisations);
    onChange("Contact_SiteUser.CorporateAuths", {
      value: newCorpAuthorisations,
    });
    setIsShowCorpAuthorisations(false);
  };

  //Homepage Shortcuts
  const [selectedHomepageShortcuts, setSelectedHomepageShortcuts] = useState<
    HomePageFunction[]
  >([]);
  const [homepageShortcutsGridData, setHomepageShortcutsGridData] = useState<
    HomePageFunction[]
  >(() => {
    const hpShortcuts = getValue("Contact_SiteUser.Special_HomePageFunctions");
    if (hpShortcuts && hpShortcuts?.length > 0) return hpShortcuts;
    return [];
  });
  const handleOnChangeHomepageShortcuts = (data: Svc_HomepageShortcuts[]) => {
    const newHomepageShortcuts = data?.map((item: Svc_HomepageShortcuts) => {
      return {
        Function_Id: item?.ID,
        FunctionHeading: item?.FunctionHeading,
        FunctionDescription: item?.FunctionDescription,
      } as HomePageFunction;
    });
    setHomepageShortcutsGridData(newHomepageShortcuts);
    onChange("Contact_SiteUser._HomePageFunctions", {
      value: newHomepageShortcuts,
    });
    onChange("Contact_SiteUser.HomePageFunctions", {
      value: newHomepageShortcuts,
    });
    setIsShowHomepageShortcuts(false);
  };

  //Homepage Views
  const [selectedHomepageViews, setSelectedHomepageViews] = useState<
    HomePageView[]
  >([]);
  const [homepageViewsGridData, setHomepageViewsGridData] = useState<
    HomePageView[]
  >(() => {
    const homepageViews = getValue("Contact_SiteUser.Special_HomePageViews");
    if (homepageViews && homepageViews?.length > 0) return homepageViews;
    return [];
  });
  const handleOnChangeHomepageViews = (data: Svc_HomepageViews[]) => {
    const newHomepageViews = data?.map((item: Svc_HomepageViews) => {
      return {
        View_Id: item?.ID,
        ViewHeading: item?.ViewHeading,
      } as HomePageView;
    });
    setHomepageViewsGridData(newHomepageViews);
    onChange("Contact_SiteUser._HomePageViews", {
      value: newHomepageViews,
    });
    onChange("Contact_SiteUser.HomePageViews", {
      value: newHomepageViews,
    });
    setIsShowHomepageViews(false);
  };

  const [selectedAdHoc, setSelectedAdHoc] = useState<IOrgUnit[]>([]);
  const [adHocGridData, setAdHocGridData] = useState<IOrgUnit[]>(() => {
    const orgUnits = getValue("Contact_SiteUser.SecurityOrgUnits");
    if (orgUnits && orgUnits.length > 0)
      return orgUnits.map((unit: IKeyValuePacket) => {
        return { ID: unit.Key, Hierarchy: unit.Value };
      });
    return [];
  });

  const userName = getValue("UserName");
  const nameOf = nameOfFactory<NewSiteUser>();
  const { membership, siteUserLovs, siteUserId, reloadSiteUser } =
    useSiteUserStore();
  const credentialUserName = getValue("siteUserCredential.Username");
  const credentialPassword = getValue("siteUserCredential.Password");
  const contactUserName = getValue("Contact_SiteUser.DocMgmt_Username");
  const contactPassword = getValue("Contact_SiteUser.DocMgmt_Password");
  const contactSaveTrigger = getValue("Contact_SiteUser.SaveTriggers");
  const flagCRMS = getValue("Contact_SiteUser.Flag_CRMS_StandardForm");
  const eventSimpleMethod = getValue(
    "Contact_SiteUser.SiteUser_CRMS_SimpleEventMethod"
  );
  const healthAreaKWD = getValue("Contact_SiteUser.HealthArea_KWD");
  const photoAttachment = getValue("Contact_SiteUser.Photo_Attachment");
  const signAttachment = getValue("Contact_SiteUser.Sign_Attachment");
  const themeData = useMemo(
    () => filter(siteUserLovs?.Theme, { Platform: "Central" }),
    [siteUserLovs?.Theme]
  );
  //mock data for list of product
  const enterpriseProduct = true;

  const handleChangeOrgUnits = (selectedData: IOrgUnit[]) => {
    const convertOrgUnits = selectedData.map((unit) => {
      return { Key: unit.ID, Value: unit.OrgUnit_Name };
    });
    setOrgGridData(selectedData);
    onChange("Contact_SiteUser.OrgUnits", { value: convertOrgUnits });
    setIsShowAddOrgUnitDialog(false);
  };

  const handleChangeAdHoc = (selectedData: IOrgUnit[]) => {
    const convertOrgUnits = selectedData.map((unit) => {
      return { Key: unit.ID, Value: unit.OrgUnit_Name };
    });
    setAdHocGridData(selectedData);
    onChange("Contact_SiteUser.SecurityOrgUnits", { value: convertOrgUnits });
    setIsShowAddAdHocDialog(false);
  };
  const handleChangeSecurityTemplate = (data: Svc_SecurityTemplate[]) => {
    setSecurityTemplateGridData(data);
    onChange("Contact_SiteUser._Special_SecurityTemplates", { value: data });
    onChange("Contact_SiteUser.Special_SecurityTemplates", { value: data });
    setIsShowSecurityTemplateDialog(false);
  };

  const flagCRMSValue = useMemo(() => {
    if (!isNil(flagCRMS)) {
      return CRMS_FORM_TYPE.find((item) => item.Value === flagCRMS);
    }
    return CRMS_FORM_TYPE[0];
  }, [flagCRMS]);

  const eventSimpleMethodValue = useMemo(() => {
    if (!isNil(eventSimpleMethod)) {
      return EVENT_SIMPLE_METHOD.find(
        (item) => item.Value === eventSimpleMethod
      );
    }
    return EVENT_SIMPLE_METHOD[0];
  }, [eventSimpleMethod]);

  const displayHealthArea = useMemo(() => {
    if (!isNil(healthAreaKWD)) {
      return (
        siteUserLovs?.HealthArea.find((item) => item.Key === healthAreaKWD) ||
        []
      );
    }
  }, [healthAreaKWD, siteUserLovs]);

  const handleOnPickOfficer = async (officer: Officers) => {
    if (officer) {
      const response = await getSiteUser(officer?.ID);
      if (isSuccessResponse(response) && response?.data) {
        onChange("Contact_SiteUser.VIPRelationshipOwner", {
          value: response.data?.DisplayName,
        });
        onChange("Contact_SiteUser.VIPRelationshipOwnerID", {
          value: response.data?.Contact_ID,
        });
        onChange("Contact_SiteUser.SaveTriggers", {
          value: { ...contactSaveTrigger, ContactUpdateTriggers },
        });
      } else {
        pushNotification({
          title: "Officer is not found",
          type: "error",
          autoClose: false,
        });
      }
    } else {
      onChange("Contact_SiteUser.VIPRelationshipOwner", {
        value: undefined,
      });
      onChange("Contact_SiteUser.VIPRelationshipOwnerID", {
        value: undefined,
      });
    }
  };

  const initialExchangeUserValues = useMemo(() => {
    const retVal = new UserCredentials();
    if (credentialUserName) {
      const domainUserName: string[] = credentialUserName.split("\\");
      if (domainUserName.length > 1) {
        retVal.Domain = domainUserName[0];
        retVal.UserName = domainUserName[1];
      } else {
        retVal.UserName = credentialUserName;
      }
    }
    return retVal;
  }, [credentialUserName]);

  const handleExchangeUserChange = (data: any) => {
    const newUserInfo: UserCredentials = data.value;
    if (newUserInfo) {
      if (isEmpty(newUserInfo.Domain)) {
        onChange("siteUserCredential.Username", {
          value: newUserInfo.UserName,
        });
      } else {
        onChange("siteUserCredential.Username", {
          value: `${newUserInfo.Domain}\\${newUserInfo.UserName}`,
        });
      }
      onChange("siteUserCredential.Password", {
        value: newUserInfo.Password,
      });
    } else {
      onChange("siteUserCredential.Username", {
        value: "",
      });
      onChange("siteUserCredential.Password", { value: "" });
    }
  };

  const initialRecordUserValues = useMemo(() => {
    const retVal = new UserCredentials();
    if (contactUserName) {
      const domainUserName: string[] = contactUserName.split("\\");
      if (domainUserName.length > 1) {
        retVal.Domain = domainUserName[0];
        retVal.UserName = domainUserName[1];
      } else {
        retVal.UserName = contactUserName;
      }
    }
    retVal.Title = "Records Credentials";
    return retVal;
  }, [contactUserName]);

  const userNameDisplayValue = useMemo(() => {
    return !isNil(userName)
      ? userName
      : getValue("Contact_SiteUser.MemberUserName");
  }, [getValue, userName]);

  const handleRecordsUserChange = (data: any) => {
    const newUserInfo: UserCredentials = data.value;
    if (newUserInfo) {
      if (isEmpty(newUserInfo.Domain)) {
        onChange("Contact_SiteUser.DocMgmt_Username", {
          value: newUserInfo.UserName,
        });
      } else {
        onChange("Contact_SiteUser.DocMgmt_Username", {
          value: `${newUserInfo.Domain}\\${newUserInfo.UserName}`,
        });
      }
      onChange("Contact_SiteUser.DocMgmt_Password", {
        value: newUserInfo.Password,
      });
    } else {
      onChange("Contact_SiteUser.DocMgmt_Username", {
        value: "",
      });
      onChange("Contact_SiteUser.DocMgmt_Password", { value: "" });
    }
  };

  const handleSaveAttachmentPhoto = async (data: IAddAttachmentDialog) => {
    setIsLoadingUploadPhoto(true);
    switch (data?.UploadMode) {
      case eUploadMode.UPLOAD_FILE:
        if (!isNil(data?.File) && isNil(data?.File[0].validationErrors)) {
          const fileEntry = await convertToFileStreamEntry(data?.File[0]);
          const attachments = {
            FileEntry: fileEntry,
            Title: data.Title ?? data?.File[0]?.name,
            Attachment_ID: 0,
            Category_KWD: data?.Category?.Keyword_ID ?? null,
            FileNumber: "",
            TRIMUri: "",
          } as IAttachment;

          const requestBody = {
            ParentID: getValue("Contact_SiteUser.Contact_ID"),
            ParentRecordType: RECORDTYPE.CORE_Contact,
            AttachmentList: [attachments],
            AddDateTimeToFileName: null,
          } as Svc_Attachment;
          const response = await saveDocument(requestBody);
          if (isSuccessIdentityPacket(response)) {
            const responseDocument = await getDocumentById(
              response.data?.ID,
              false
            );
            if (isSuccessResponse(responseDocument)) {
              onChange("Contact_SiteUser.Photo_Attachment", {
                value: responseDocument.data,
              });
              onChange("Contact_SiteUser.Photo_Attachment_ID", {
                value: response.data?.ID,
              });
              clearNotifications();
              pushNotification({
                title: "Photo uploaded successfully",
                type: "success",
              });
            } else {
              pushNotification({
                autoClose: false,
                title: "Upload photo failed",
                type: "error",
              });
            }
          } else {
            if (response?.status === APIResponseStatus.EXCEEDED_SIZE_LIMIT) {
              pushNotification({
                autoClose: false,
                title: "Exceeded the specified size limit",
                type: "error",
              });
            } else {
              pushNotification({
                autoClose: false,
                title: "Upload photo failed",
                description: response.data?.Errors ?? response.statusText,
                type: "error",
              });
            }
          }
          setIsLoadingUploadPhoto(false);
          setOpenPopupPhoto(false);
        }
        break;
      case eUploadMode.UPLOAD_WEB_LINK:
        if (!siteUserId) return;
        const requestBody = {
          WebLinkURI: data.WebLink,
          Tag: data?.Tag ?? "",
          WeblinkCategory_KWD: data?.WebLinkCategory?.Keyword_ID ?? null,
        } as IWebLink;
        const response = await saveDocumentAsWebLink(
          siteUserId,
          RECORDTYPE.CORE_Contact,
          requestBody
        );
        setIsLoadingUploadPhoto(false);
        if (isSuccessIdentityPacket(response)) {
          clearNotifications();
          const responseDocument = await getDocumentById(
            response.data?.ID,
            false
          );
          if (isSuccessResponse(responseDocument)) {
            onChange("Contact_SiteUser.Photo_Attachment", {
              value: responseDocument.data,
            });
            onChange("Contact_SiteUser.Photo_Attachment_ID", {
              value: response.data?.ID,
            });
            clearNotifications();
            pushNotification({
              title: "Photo uploaded successfully",
              type: "success",
            });
          } else {
            pushNotification({
              autoClose: false,
              title: "Upload photo failed",
              type: "error",
            });
          }
        } else {
          pushNotification({
            autoClose: false,
            title: "Upload photo failed",
            type: "error",
            description: response?.data?.Errors ?? response?.statusText,
          });
        }
        setOpenPopupPhoto(false);
        break;
    }
  };

  const handleSaveSignature = async (data: IAddAttachmentDialog) => {
    setIsLoadingSignature(true);
    switch (data?.UploadMode) {
      case eUploadMode.UPLOAD_FILE:
        if (!isNil(data?.File) && isNil(data?.File[0].validationErrors)) {
          const fileEntry = await convertToFileStreamEntry(data?.File[0]);
          const attachments = {
            FileEntry: fileEntry,
            Title: data.Title ?? data?.File[0]?.name,
            Attachment_ID: 0,
            Category_KWD: data?.Category?.Keyword_ID ?? null,
            FileNumber: "",
            TRIMUri: "",
          } as IAttachment;

          const requestBody = {
            ParentID: getValue("Contact_SiteUser.Contact_ID"),
            ParentRecordType: RECORDTYPE.CORE_Contact,
            AttachmentList: [attachments],
            AddDateTimeToFileName: null,
          } as Svc_Attachment;
          const response = await saveDocument(requestBody);
          setIsLoadingSignature(false);
          if (isSuccessIdentityPacket(response)) {
            const responseDocument = await getDocumentById(
              response.data?.ID,
              false
            );
            if (isSuccessResponse(responseDocument)) {
              onChange("Contact_SiteUser.Sign_Attachment", {
                value: responseDocument.data,
              });
              onChange("Contact_SiteUser.Sign_Attachment_ID", {
                value: response.data?.ID,
              });
              clearNotifications();
              pushNotification({
                title: "Signature uploaded successfully",
                type: "success",
              });
            } else {
              pushNotification({
                autoClose: false,
                title: "Upload signature failed",
                type: "error",
              });
            }
          } else {
            if (response?.status === APIResponseStatus.EXCEEDED_SIZE_LIMIT) {
              pushNotification({
                autoClose: false,
                title: "Exceeded the specified size limit",
                type: "error",
              });
            } else {
              pushNotification({
                autoClose: false,
                title: "Upload signature failed",
                description: response.data?.Errors ?? response.statusText,
                type: "error",
              });
            }
          }
          setOpenPopupSignature(false);
        }
        break;
      case eUploadMode.UPLOAD_WEB_LINK:
        if (!siteUserId) return;
        const requestBody = {
          WebLinkURI: data.WebLink,
          Tag: data?.Tag ?? "",
          WeblinkCategory_KWD: data?.WebLinkCategory?.Keyword_ID ?? null,
        } as IWebLink;
        const response = await saveDocumentAsWebLink(
          siteUserId,
          RECORDTYPE.CORE_Contact,
          requestBody
        );
        setIsLoadingSignature(false);
        if (isSuccessIdentityPacket(response)) {
          clearNotifications();
          const responseDocument = await getDocumentById(
            response.data?.ID,
            false
          );
          if (isSuccessResponse(responseDocument)) {
            onChange("Contact_SiteUser.Sign_Attachment", {
              value: responseDocument.data,
            });
            onChange("Contact_SiteUser.Sign_Attachment_ID", {
              value: response.data?.ID,
            });
            clearNotifications();
            pushNotification({
              title: "Signature uploaded successfully",
              type: "success",
            });
          } else {
            pushNotification({
              autoClose: false,
              title: "Upload signature failed",
              type: "error",
            });
          }
        } else {
          pushNotification({
            autoClose: false,
            title: "Upload signature failed",
            type: "error",
            description: response?.data?.Errors ?? response?.statusText,
          });
        }
        setOpenPopupSignature(false);
        break;
    }
  };
  const handleChangeUserName = async () => {
    clearNotifications();
    if (formRenderProps.modified) {
      const newSiteUser: SiteUser = getValue("Contact_SiteUser");
      newSiteUser.MemberUserName = getValue("UserName");
      setLoadingChangeUserName(true);
      const response = await postChangeUserName(newSiteUser);
      setLoadingChangeUserName(false);
      if (isSuccessIdentityPacket(response)) {
        reloadSiteUser().then(() => {
          pushNotification({
            type: "success",
            title: "Username has been updated successfully",
          });
        });
      } else {
        pushNotification({
          type: "error",
          autoClose: false,
          title: "Update user failed",
          description: response.data?.Errors ?? response?.error,
        });
      }
      return;
    }

    if (!formRenderProps.valid) return;
    pushNotification({
      type: "warning",
      title: `A duplicate record was found for Key: ${getValue(
        "Contact_SiteUser.MemberUserName"
      )} in Module: ContactFactory during operation SiteUser_ChangeUserName`,
    });
  };

  const clickAddAttachment = (mode: boolean = false) => {
    if (isNew) {
      let infoMessage = "Please save the site user before attaching a photo";
      if (mode) {
        infoMessage = "Please save the site user before attaching a signature";
      }
      pushNotification({
        type: "info",
        title: infoMessage,
      });
    }
  };

  const onClickCheckAvailability = (event: any) => {
    event.preventDefault();
    const defaultValue = !isEmpty(userName)
      ? userName
      : getValue("Contact_SiteUser.MemberUserName");
    if (defaultValue) {
      setCheckingAvailability(true);
      checkAvailability(defaultValue).then((response: any) => {
        if (response.data) {
          setAvailability(true);
          setCheckingAvailability(false);
        } else {
          setAvailability(false);
          setCheckingAvailability(false);
        }
      });
    } else {
      setAvailability(false);
    }
    setshowAvailability(true);
  };

  const handleChangePassword = async (event: any) => {
    event.preventDefault();
    clearNotifications();
    const dataPut = new ChangePassword();
    dataPut.userName = userNameDisplayValue;

    dataPut.password = getValue("_option.ForceNewPassword");
    if (!dataPut.password || dataPut.password.length < 7) {
      pushNotification({
        type: "error",
        autoClose: false,
        title: "Changed password failed",
        description: "Password needs to be atleast 7 characters in length.",
      });
      return;
    }
    dataPut.password = aesEncrypt(dataPut.password);
    setLoadingSetPassword(true);
    const response = await putChangePassword(dataPut);
    setLoadingSetPassword(false);
    if (isSuccessResponse(response)) {
      pushNotification({
        type: "success",
        title: `Password changed to: ${getValue("_option.ForceNewPassword")}`,
      });
      onChange("_option.ForceNewPassword", { value: "" });
    } else {
      pushNotification({
        type: "error",
        autoClose: false,
        title: "Changed password failed",
        description: response.data?.Errors ?? response?.error,
      });
    }
  };
  const checkAttachmentField = (field: string) => {
    return getValue(field)?.FileEntry?.FileAttachment;
  };

  const requiredStringValidator = useCallback(
    (value: any) => {
      return userNameDisplayValue && !isEmpty(userNameDisplayValue.trim())
        ? ""
        : "This field is required.";
    },
    [userNameDisplayValue]
  );

  const handleOnRemoveItems = () => {
    switch (showConfirmDeleteDialog) {
      case SITE_USER_DELETE_ITEMS.CORP_AUTHORISATIONS:
        const newCorpAuthorisations = corpAuthorisationsGridData.filter(
          (item: CorporateAuth) =>
            !selectedCorpAuthorisations.some(
              (selectedItem: CorporateAuth) =>
                item.CorporateAuth_ID === selectedItem.CorporateAuth_ID
            )
        );
        setCorpAuthorisationsGridData(newCorpAuthorisations);
        onChange("Contact_SiteUser.CorporateAuths", {
          value: newCorpAuthorisations,
        });
        setSelectedCorpAuthorisations([]);
        break;
      case SITE_USER_DELETE_ITEMS.SECURITY_TEMPLATES:
        const newSecurityTemplate = securityTemplateGridData.filter(
          (item: Svc_SecurityTemplate) =>
            !selectedSecurityTemplate.some(
              (selectedItem: Svc_SecurityTemplate) =>
                item.SecurityTemplate_ID === selectedItem.SecurityTemplate_ID
            )
        );
        setSecurityTemplateGridData(newSecurityTemplate);
        onChange("Contact_SiteUser._Special_SecurityTemplates", {
          value: newSecurityTemplate,
        });
        onChange("Contact_SiteUser.Special_SecurityTemplates", {
          value: newSecurityTemplate,
        });
        setSelectedSecurityTemplate([]);
        break;
      case SITE_USER_DELETE_ITEMS.ORG_STRUCTURE:
        const OrgUnitIDs = selectedOrgUnits?.map(
          (orgUnit: IOrgUnit) => orgUnit.ID
        );
        const newOrgUnits = orgGridData.filter(
          (item: IOrgUnit) => !OrgUnitIDs?.includes(item.ID)
        );
        const newOrgUnitsCovered = newOrgUnits?.map((item: IOrgUnit) => ({
          Key: item.ID,
          Value: item.Hierarchy,
        }));
        setOrgGridData(newOrgUnits);
        onChange("Contact_SiteUser.OrgUnits", { value: newOrgUnitsCovered });
        setSelectedOrgUnits([]);
        break;
      case SITE_USER_DELETE_ITEMS.AD_HOC_GROUPS:
        const newAdHoc = adHocGridData.filter(
          (item: IOrgUnit) =>
            !selectedAdHoc.some(
              (selectedItem: IOrgUnit) => item.ID === selectedItem.ID
            )
        );
        setAdHocGridData(newAdHoc);
        onChange("Contact_SiteUser.SecurityOrgUnits", { value: newAdHoc });
        setSelectedAdHoc([]);
        break;
      case SITE_USER_DELETE_ITEMS.HOMEPAGE_SHORTCUTS:
        const newHomepageShortcuts = homepageShortcutsGridData.filter(
          (item: HomePageFunction) =>
            !selectedHomepageShortcuts.some(
              (selectedItem: HomePageFunction) =>
                item.Function_Id === selectedItem.Function_Id
            )
        );
        setHomepageShortcutsGridData(newHomepageShortcuts);
        onChange("Contact_SiteUser._HomePageFunctions", {
          value: newHomepageShortcuts,
        });
        onChange("Contact_SiteUser.HomePageFunctions", {
          value: newHomepageShortcuts,
        });
        setSelectedHomepageShortcuts([]);
        break;
      case SITE_USER_DELETE_ITEMS.HOMEPAGE_VIEWS:
        const newHomepageViews = homepageViewsGridData.filter(
          (item: HomePageView) =>
            !selectedHomepageViews.some(
              (selectedItem: HomePageView) =>
                item.View_Id === selectedItem.View_Id
            )
        );
        setHomepageViewsGridData(newHomepageViews);
        onChange("Contact_SiteUser._HomePageViews", {
          value: newHomepageViews,
        });
        onChange("Contact_SiteUser.HomePageViews", {
          value: newHomepageViews,
        });
        setSelectedHomepageViews([]);
        break;
    }
  };

  return (
    <div className="cc-site-user-form-element">
      <FormElement>
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Login name
                <CCTooltip
                  type="validator"
                  content={formRenderProps.errors.loginName}
                />
              </label>
              <Field
                name={nameOf("UserName")}
                component={CCInput}
                placeholder={"Login name"}
                validator={requiredStringValidator}
                defaultValue={userNameDisplayValue}
                onChange={(event: InputChangeEvent) => {
                  onChange("Contact_SiteUser.MemberUserName", {
                    value: event.value,
                  });
                  onChange("UserName", {
                    value: event.value,
                  });
                }}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">&nbsp;</label>
              <div>
                <Button onClick={onClickCheckAvailability}>
                  {checkingAvailability ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : null}
                  {!checkingAvailability && showAvailability && (
                    <>
                      {availability ? (
                        <i className="fa fa-check" />
                      ) : (
                        <i className="fa fa-times" />
                      )}
                    </>
                  )}{" "}
                  Check Availability
                </Button>
              </div>
            </div>
            {!isNew && (
              <div className="cc-field">
                <label className="cc-label">&nbsp;</label>
                <div>
                  <Button
                    type="button"
                    onClick={handleChangeUserName}
                    disabled={loadingChangeUserName}
                  >
                    {loadingChangeUserName ? (
                      <i className="fas fa-spinner fa-spin" />
                    ) : null}
                    Change Username
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className="cc-form-cols-3">
            {isNew && (
              <div className="cc-field">
                <label className="cc-label">
                  Assign Password
                  <CCTooltip
                    type="info"
                    position="right"
                    content="Autogenerated if left blanks"
                  />
                </label>
                <Field
                  name={nameOf("UserPassword")}
                  component={CCInput}
                  placeholder={"Assign Password"}
                />
              </div>
            )}
            <div className="cc-field">
              <label className="cc-label">Change password</label>

              <Field
                name={"Contact_SiteUser.bResetPasswordOnFirstLogon"}
                checked={getValue(
                  "Contact_SiteUser.bResetPasswordOnFirstLogon"
                )}
                onChange={(event: SwitchChangeEvent) => {
                  onChange("Contact_SiteUser.bResetPasswordOnFirstLogon", {
                    value: event.value,
                  });
                  onChange("bResetPasswordOnFirstLogon", {
                    value: event.value,
                  });
                }}
                component={CCSwitch}
              />
            </div>
            {!isNew && (
              <>
                <div className="cc-field">
                  <label className="cc-label">Force new password</label>

                  <Field
                    name={"_option.ForceNewPassword"}
                    placeholder={"Force new password"}
                    component={CCInput}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <div>
                    <Button
                      type="button"
                      onClick={handleChangePassword}
                      disabled={loadingSetPassword}
                    >
                      {loadingSetPassword ? (
                        <i className="fas fa-spinner fa-spin" />
                      ) : null}
                      Set Password
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Salutation</label>
              <Field
                name={"Contact_SiteUser.Salutation"}
                textField="Value"
                dataItemKey="Key"
                data={siteUserLovs?.Salutation || []}
                value={getDropdownValue(
                  +(getValue("Contact_SiteUser.Salutation") ?? "0"),
                  siteUserLovs?.Salutation ?? [],
                  "Key"
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  formRenderProps.onChange("Contact_SiteUser.Salutation", {
                    value: event.value?.Key ?? null,
                  });
                }}
                component={CCSearchComboBox}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                First name
                <CCTooltip
                  type="validator"
                  content={formRenderProps.errors.firstName}
                />
              </label>
              <Field
                name={"Contact_SiteUser.FirstName"}
                component={CCInput}
                placeholder={"First name"}
                validator={requiredValidator}
                onChange={handleOnChangeFirstName}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">
                Last name
                <CCTooltip
                  type="validator"
                  content={formRenderProps.errors.lastName}
                />
              </label>
              <Field
                name={"Contact_SiteUser.LastName"}
                component={CCInput}
                placeholder={"Last name"}
                validator={requiredValidator}
                onChange={handleOnChangeLastName}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">
                Display name
                <CCTooltip
                  type="validator"
                  content={formRenderProps.errors.displayName}
                />
              </label>

              <Field
                name="Contact_SiteUser.DisplayName"
                component={CCInput}
                placeholder={"Display name"}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Staff number</label>

              <Field
                name="Contact_SiteUser.Staff_ID"
                component={CCInput}
                placeholder={"Staff number"}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-2">
              <Field
                label="Email"
                tooltip={
                  <CCTooltip
                    type="validator"
                    content={formRenderProps.errors.email}
                  />
                }
                name="Contact_SiteUser.Email"
                placeholder={"Email"}
                component={CCInputEmail}
                validator={validateEmailRequired}
                onChangeForm={onChange}
              />
            </div>
            <div className="cc-field">
              <label className="label">Phone</label>
              <Field
                name="Contact_SiteUser.WorkPhone"
                placeholder={"Phone"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Private}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Mobile</label>
              <Field
                name="Contact_SiteUser.Mobile"
                placeholder={"Mobile"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Mobile}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Home phone</label>
              <Field
                name="Contact_SiteUser.HomePhone"
                placeholder={"Home phone"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.HomePhone}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Fax</label>

              <Field
                name="Contact_SiteUser.Fax"
                placeholder={"Fax"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Fax}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Location</label>

              <Field
                name="Contact_SiteUser.LocationName"
                component={CCInput}
                placeholder={"Location"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Position</label>

              <Field
                name="Contact_SiteUser.PositionName"
                component={CCInput}
                placeholder={"Position"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Position number</label>

              <Field
                name="Contact_SiteUser.PositionNumber"
                component={CCInput}
                placeholder={"Position number"}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Manager (escalation)</label>

              <Field
                name="Contact_SiteUser.Manager"
                component={CCInput}
                placeholder={"Manager (escalation)"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Theme</label>

              <Field
                name={"Contact_SiteUser.Theme_LKP"}
                textField="Theme_DisplayName"
                dataItemKey="Theme_LKP_ID"
                data={themeData || []}
                component={CCSearchComboBox}
                isUseDefaultOnchange
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Identity card number</label>

              <Field
                name="Contact_SiteUser.IDCardNumber"
                component={CCInput}
                placeholder={"Identity card number"}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Photo</label>
              <div className="cc-image-upload">
                <div className="cc-image-upload-control">
                  {checkAttachmentField("Contact_SiteUser.Photo_Attachment") ? (
                    <i
                      className="fa fa-times fa-2x"
                      onClick={() => {
                        formRenderProps.onChange(
                          "Contact_SiteUser.Photo_Attachment",
                          {
                            value: "",
                          }
                        );
                        formRenderProps.onChange(
                          "Contact_SiteUser.Photo_Attachment_ID",
                          {
                            value: "",
                          }
                        );
                      }}
                      title="Remove"
                    />
                  ) : (
                    <i
                      className="fa fa-upload fa-2x"
                      onClick={() => {
                        setOpenPopupPhoto(isNew ? false : true);
                        clickAddAttachment();
                      }}
                      title="Upload"
                    />
                  )}
                </div>
                <img
                  src={
                    checkAttachmentField("Contact_SiteUser.Photo_Attachment")
                      ? !photoAttachment?.FileEntry?.FileAttachment.toString().includes(
                          "blob:"
                        )
                        ? `data:${photoAttachment?.FileEntry?.ContentType};base64,` +
                          photoAttachment.FileEntry?.FileAttachment
                        : photoAttachment.FileEntry?.FileAttachment
                      : AvatarDefault
                  }
                  className="cc-image-review"
                  alt="Attachment Photos"
                />
              </div>
            </div>
            <div className="cc-field">
              <label className="cc-label">Signature</label>
              <div className="cc-image-upload">
                <div className="cc-image-upload-control">
                  {checkAttachmentField("Contact_SiteUser.Sign_Attachment") ? (
                    <i
                      className="fa fa-times fa-2x"
                      onClick={() => {
                        formRenderProps.onChange(
                          "Contact_SiteUser.Sign_Attachment",
                          {
                            value: "",
                          }
                        );
                        formRenderProps.onChange(
                          "Contact_SiteUser.Sign_Attachment_ID",
                          {
                            value: "",
                          }
                        );
                      }}
                      title="Remove"
                    />
                  ) : (
                    <i
                      className="fa fa-upload fa-2x"
                      onClick={() => {
                        setOpenPopupSignature(isNew ? false : true);
                        clickAddAttachment(true);
                      }}
                      title="Upload"
                    />
                  )}
                </div>
                <img
                  src={
                    checkAttachmentField("Contact_SiteUser.Sign_Attachment")
                      ? !signAttachment?.FileEntry?.FileAttachment.toString().includes(
                          "blob:"
                        )
                        ? `data:${signAttachment?.FileEntry?.ContentType};base64,` +
                          signAttachment.FileEntry?.FileAttachment
                        : signAttachment.FileEntry?.FileAttachment
                      : SignatureDefault
                  }
                  className="cc-image-review"
                  alt="Attachment Signature"
                />
              </div>
            </div>
          </div>

          {/*end info*/}
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="label">User active</label>
              <Field
                name="_option.Contact_SiteUser.Sys_DBRowState"
                checked={getValue("_option.Contact_SiteUser.Sys_DBRowState")}
                component={CCSwitch}
                onChange={(data: SwitchChangeEvent) => {
                  if (data.value === true) {
                    onChange("_option.Contact_SiteUser.Sys_DBRowState", {
                      value: true,
                    });
                    onChange("Contact_SiteUser.Sys_DBRowState", {
                      value: "Active",
                    });
                    return;
                  }
                  onChange("Contact_SiteUser.Sys_DBRowState", {
                    value: "Inactive",
                  });
                  onChange("_option.Contact_SiteUser.Sys_DBRowState", {
                    value: false,
                  });
                }}
              />
            </div>

            {membership && (
              <div className="cc-field">
                <label className="cc-label">User locked out</label>

                <Field
                  name="IsUserLockedOut"
                  defaultChecked={membership?.IsLockedOut}
                  component={CCSwitch}
                  disabled={!membership?.IsLockedOut}
                />
              </div>
            )}
            <div className="cc-field">
              <label className="cc-label">Authorised control officer</label>

              <Field
                name="Contact_SiteUser.ACO"
                checked={getValue("Contact_SiteUser.ACO")}
                component={CCSwitch}
                disabled
              />
            </div>
            {isCRMSActive && (
              <>
                <div className="cc-field">
                  <label className="cc-label">Councillor</label>

                  <Field
                    name="Contact_SiteUser.Flag_Councillor"
                    checked={getValue("Contact_SiteUser.Flag_Councillor")}
                    component={CCSwitch}
                  />
                </div>

                <div className="cc-field">
                  <label className="cc-label">Ward</label>

                  <Field
                    name="Contact_SiteUser.Ward"
                    component={CCInput}
                    placeholder={"Ward"}
                  />
                </div>
              </>
            )}
            <div className="cc-field">
              <label className="cc-label">External user</label>
              <Field
                name="Contact_SiteUser.Flag_ExternalUser"
                checked={getValue("Contact_SiteUser.Flag_ExternalUser")}
                component={CCSwitch}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">Exchange username</label>
              <Field
                name="siteUserCredential.Username"
                component={CredentialsPicker}
                placeholder="Exchange username"
                onChange={handleExchangeUserChange}
                initialValue={initialExchangeUserValues}
                showClearButton
              />
            </div>
            <CCValueField
              label="Exchange password"
              value={!isEmpty(credentialPassword) ? "**********" : ""}
            />
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">Records username</label>
              <Field
                name="Contact_SiteUser.DocMgmt_Username"
                component={CredentialsPicker}
                placeholder="Records username"
                onChange={handleRecordsUserChange}
                initialValue={initialRecordUserValues}
                showClearButton
              />
            </div>
            <CCValueField
              label="Records password"
              value={!isEmpty(contactPassword) ? "**********" : ""}
            />
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Corporate authorisations</label>
              <CCGrid
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <Button
                      type="button"
                      iconClass="fa fa-plus"
                      onClick={() => {
                        setIsShowCorpAuthorisations(true);
                      }}
                      title="Add Corp. Authorisations"
                    />
                    <Button
                      type="button"
                      iconClass="fas fa-minus"
                      disabled={selectedCorpAuthorisations.length !== 1}
                      onClick={() => {
                        setShowConfirmDeleteDialog(
                          SITE_USER_DELETE_ITEMS.CORP_AUTHORISATIONS
                        );
                      }}
                      title="Remove Corp. Authorisations"
                    />
                  </div>
                }
                columnFields={colPickCorpAuthorization}
                primaryField={nameOfCorporateAuth("CorporateAuth_ID")}
                selectableMode="multiple"
                data={corpAuthorisationsGridData}
                onSelectionChange={(data: CorporateAuth[]) => {
                  setSelectedCorpAuthorisations(data);
                }}
              />
              {isShowCorpAuthorisations && (
                <GridSelectionDialog
                  maxWidth="55%"
                  titleHeader={"Pick Corporate Authorisation(s)"}
                  selectableMode={"multiple"}
                  dataUrl={`${CORE_GET_CORPORATE_AUTHOR_ODATA}()?$count=true&`}
                  columnFields={colCorporateAuthorisations}
                  primaryField={"ID"}
                  setShowDialog={setIsShowCorpAuthorisations}
                  onCloseDialog={() => setIsShowCorpAuthorisations(false)}
                  onSubmit={handleOnChangeCorpAuthorisations}
                  selectedRows={corpAuthorisationsGridData?.map(
                    (item: CorporateAuth) => {
                      return {
                        ID: item?.CorporateAuth_ID,
                        CorporateAuthorisationName: item?.AuthorisationName,
                        CorporateAuthorisationDescription:
                          item?.AuthorisationDescription,
                      } as Svc_CorporateAuthorisations;
                    }
                  )}
                />
              )}
            </div>

            <div className="cc-field">
              <label className="cc-label">Security templates</label>
              <CCGrid
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <Button
                      type="button"
                      iconClass="fa fa-plus"
                      onClick={() => {
                        setIsShowSecurityTemplateDialog(true);
                      }}
                      title="Add Security Templates"
                    />
                    <Button
                      type="button"
                      iconClass="fas fa-minus"
                      disabled={selectedSecurityTemplate.length !== 1}
                      onClick={() => {
                        setShowConfirmDeleteDialog(
                          SITE_USER_DELETE_ITEMS.SECURITY_TEMPLATES
                        );
                      }}
                      title="Remove Security Templates"
                    />
                  </div>
                }
                columnFields={colPickSecurityTemplates}
                primaryField={nameOfSecurityTemplate("SecurityTemplate_ID")}
                selectableMode="single"
                data={securityTemplateGridData}
                onSelectionChange={(data: Svc_SecurityTemplate[]) => {
                  setSelectedSecurityTemplate(data);
                }}
              />
              {isShowSecurityTemplateDialog && (
                <GridSelectionDialog
                  maxWidth="55%"
                  titleHeader={"Pick Security Template(s)"}
                  selectableMode={"multiple"}
                  dataUrl={`${CORE_GET_VIEW_SECURITY_TEMPLATES_ODATA}?$count=true&`}
                  columnFields={colSecurityTemplates}
                  primaryField={nameOfSecurityTemplate("SecurityTemplate_ID")}
                  setShowDialog={setIsShowSecurityTemplateDialog}
                  onCloseDialog={() => setIsShowSecurityTemplateDialog(false)}
                  onSubmit={(data: Svc_SecurityTemplate[]) =>
                    handleChangeSecurityTemplate(data)
                  }
                  selectedRows={securityTemplateGridData}
                />
              )}
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Org structure</label>
              <CCGrid
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <Button
                      type="button"
                      iconClass="fa fa-plus"
                      onClick={() => {
                        setIsShowAddOrgUnitDialog(true);
                      }}
                      title="Add Org structure"
                    />
                    <Button
                      type="button"
                      iconClass="fas fa-minus"
                      disabled={selectedOrgUnits.length <= 0}
                      onClick={() => {
                        setShowConfirmDeleteDialog(
                          SITE_USER_DELETE_ITEMS.ORG_STRUCTURE
                        );
                      }}
                      title="Remove Org structure"
                    />
                  </div>
                }
                columnFields={colOrgUnits}
                primaryField={nameOfOrgUnit("ID")}
                selectableMode="multiple"
                data={orgGridData}
                onSelectionChange={(data: IOrgUnit[]) => {
                  setSelectedOrgUnits(data);
                }}
              />
              {isShowAddOrgUnitDialog && (
                <CCPickOrgUnits
                  dataUrl={odataCoreGetOrgUnit(false, null)}
                  setShowDialog={setIsShowAddOrgUnitDialog}
                  onSubmit={handleChangeOrgUnits}
                  onClose={() => setIsShowAddOrgUnitDialog(false)}
                  isMultiple={true}
                  selectedRows={orgGridData}
                />
              )}
            </div>
            <div className="cc-field">
              <label className="cc-label">Ad hoc groups</label>
              <CCGrid
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <Button
                      type="button"
                      iconClass="fa fa-plus"
                      onClick={() => {
                        setIsShowAddAdHocDialog(true);
                      }}
                      title="Add Ad hoc Groups"
                    />
                    <Button
                      type="button"
                      iconClass="fas fa-minus"
                      disabled={selectedAdHoc.length !== 1}
                      onClick={() => {
                        setShowConfirmDeleteDialog(
                          SITE_USER_DELETE_ITEMS.AD_HOC_GROUPS
                        );
                      }}
                      title="Remove Ad hoc Groups"
                    />
                  </div>
                }
                columnFields={colOrgUnits}
                primaryField={nameOfOrgUnit("ID")}
                selectableMode="multiple"
                data={adHocGridData}
                onSelectionChange={(data: IOrgUnit[]) => {
                  setSelectedAdHoc(data);
                }}
              />
              {isShowAddAdHocDialog && (
                <CCPickOrgUnits
                  dataUrl={odataCoreGetOrgUnit(true, null)}
                  setShowDialog={setIsShowAddAdHocDialog}
                  onSubmit={handleChangeAdHoc}
                  onClose={() => setIsShowAddAdHocDialog(false)}
                  isMultiple={false}
                  selectedRows={adHocGridData}
                />
              )}
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Home page shortcuts</label>
              <CCGrid
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <Button
                      type="button"
                      iconClass="fa fa-plus"
                      onClick={() => {
                        setIsShowHomepageShortcuts(true);
                      }}
                      title="Add Home page shortcuts"
                    />
                    <Button
                      type="button"
                      iconClass="fas fa-minus"
                      disabled={selectedHomepageShortcuts.length !== 1}
                      onClick={() => {
                        setShowConfirmDeleteDialog(
                          SITE_USER_DELETE_ITEMS.HOMEPAGE_SHORTCUTS
                        );
                      }}
                      title="Remove Home Page Shortcuts"
                    />
                  </div>
                }
                columnFields={colPickHomepageShortcuts}
                primaryField={nameOfHomepageShortcuts("Function_Id")}
                selectableMode="multiple"
                data={homepageShortcutsGridData}
                onSelectionChange={(data: HomePageFunction[]) => {
                  setSelectedHomepageShortcuts(data);
                }}
              />
              {isShowHomepageShortcuts && (
                <GridSelectionDialog
                  maxWidth="55%"
                  titleHeader={"Pick Homepage Shortcuts"}
                  selectableMode={"multiple"}
                  dataUrl={
                    "/odata/core/internal/homepageshortcuts/GetHomepageShortcuts()?$count=true&"
                  }
                  columnFields={colHomepageShortcuts}
                  primaryField={"ID"}
                  setShowDialog={setIsShowHomepageShortcuts}
                  onCloseDialog={() => setIsShowHomepageShortcuts(false)}
                  onSubmit={handleOnChangeHomepageShortcuts}
                  selectedRows={homepageShortcutsGridData?.map(
                    (item: HomePageFunction) => {
                      return {
                        ID: item?.Function_Id,
                        FunctionHeading: item?.FunctionHeading,
                      } as Svc_HomepageShortcuts;
                    }
                  )}
                />
              )}
            </div>
            <div className="cc-field">
              <label className="cc-label">Home page views</label>
              <CCGrid
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <Button
                      type="button"
                      iconClass="fa fa-plus"
                      onClick={() => {
                        setIsShowHomepageViews(true);
                      }}
                      title="Add Home page views"
                    />
                    <Button
                      type="button"
                      iconClass="fas fa-minus"
                      disabled={selectedHomepageViews.length !== 1}
                      onClick={() => {
                        setShowConfirmDeleteDialog(
                          SITE_USER_DELETE_ITEMS.HOMEPAGE_VIEWS
                        );
                      }}
                      title="Remove Home Page Views"
                    />
                  </div>
                }
                columnFields={colPickHomepageViews}
                primaryField={nameOfHomepageViews("View_Id")}
                selectableMode="multiple"
                data={homepageViewsGridData}
                onSelectionChange={(data: HomePageView[]) => {
                  setSelectedHomepageViews(data);
                }}
              />
              {isShowHomepageViews && (
                <GridSelectionDialog
                  maxWidth="55%"
                  titleHeader={"Pick Homepage Views"}
                  selectableMode={"multiple"}
                  dataUrl={`${CORE_GET_HOMEPAGE_VIEWS_ODATA}()?$count=true&`}
                  columnFields={colHomepageViews}
                  primaryField={"ID"}
                  setShowDialog={setIsShowHomepageViews}
                  onCloseDialog={() => setIsShowHomepageViews(false)}
                  onSubmit={handleOnChangeHomepageViews}
                  selectedRows={homepageViewsGridData?.map(
                    (item: HomePageView) => {
                      return {
                        ID: item?.View_Id,
                        ViewHeading: item?.ViewHeading,
                      } as Svc_HomepageViews;
                    }
                  )}
                />
              )}
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Postal address</label>
              <Field
                name="Contact_SiteUser.PostalAddressName"
                component={AddressPicker}
                buildAddress={defaultBuildAddress}
                placeholder={"Postal address"}
                value={getValue("Contact_SiteUser.PostalAddress")}
                onChangeDataSearch={(data: any) => {
                  formRenderProps.onChange(
                    "Contact_SiteUser.PostalAddressName",
                    {
                      value: getFullAddressHTML(data),
                    }
                  );
                  formRenderProps.onChange("Contact_SiteUser.PostalAddress", {
                    value: data,
                  });
                }}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Default module</label>
              <Field
                name={"Contact_SiteUser.HomePage_ENUM"}
                textField="Value"
                dataItemKey="Key"
                component={CCSearchComboBox}
                data={siteUserLovs?.DefaultPage || []}
                value={siteUserLovs?.DefaultPage.find(
                  (item: IKeyValuePacket) => {
                    return (
                      item.Key === getValue("Contact_SiteUser.HomePage_ENUM")
                    );
                  }
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange("Contact_SiteUser.HomePage_ENUM", {
                    value: event.value?.Key ?? null,
                  });
                }}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Default actions page</label>
              <Field
                name={"Contact_SiteUser.DefaultActionPage_ENUM"}
                textField="Value"
                dataItemKey="Key"
                component={CCSearchComboBox}
                data={siteUserLovs?.DefaultActionPages || []}
                value={siteUserLovs?.DefaultActionPages.find(
                  (item: IKeyValuePacket) => {
                    return (
                      item.Key ===
                      getValue("Contact_SiteUser.DefaultActionPage_ENUM")
                    );
                  }
                )}
                onChange={(event: ComboBoxChangeEvent) =>
                  onChange("Contact_SiteUser.DefaultActionPage_ENUM", {
                    value: event.value?.Key ?? null,
                  })
                }
              />
            </div>
          </div>
          {/* Can be use if change requirement
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Security Access</label>
              <Field
                name={"123"}
                textField="Value"
                dataItemKey="Key"
                component={CCDropDownList}
                data={siteUserLovs?.Product || []}
              />
            </div>
          </div> */}
        </section>
        {enableVIP && (
          <>
            <br />
            <section className="cc-field-group">
              <label className="cc-label cc-label-approved-detail-part">
                VIP ( Very Important Person ) settings
              </label>
              <div className="cc-custom-sub-panel-bar">
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <label className="cc-label">VIP</label>
                    <Field
                      name={"Contact_SiteUser.VIP"}
                      checked={getValue("Contact_SiteUser.VIP")}
                      component={CCSwitch}
                    />
                  </div>
                  {getValue("Contact_SiteUser.VIP") && (
                    <>
                      <div className="cc-field">
                        <label className="cc-label">VIP type</label>
                        <Field
                          name={"Contact_SiteUser.VIPTypeID"}
                          textField="Value"
                          dataItemKey="Key"
                          component={CCSearchComboBox}
                          data={siteUserLovs?.VIPTypes || []}
                          value={siteUserLovs?.VIPTypes.find(
                            (item: IKeyValuePacket) => {
                              return (
                                item.Key ===
                                getValue("Contact_SiteUser.VIPTypeID")
                              );
                            }
                          )}
                          onChange={(event: ComboBoxChangeEvent) =>
                            onChange("Contact_SiteUser.VIPTypeID", {
                              value: event.value?.Key ?? null,
                            })
                          }
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="Relationship owner" />
                        <Field
                          component={InputPickerSearch}
                          name={"Contact_SiteUser.VIPRelationshipOwner"}
                          nameDisplay="DisplayName"
                          value={getValue(
                            "Contact_SiteUser.VIPRelationshipOwner"
                          )}
                          options={optionOfficer}
                          onChange={handleOnPickOfficer}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </section>
          </>
        )}
        {enterpriseProduct && (
          <>
            <br />
            <section className="cc-field-group">
              <label className="cc-label cc-label-approved-detail-part">
                CRMS settings
              </label>
              <div className="cc-custom-sub-panel-bar">
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <label className="cc-label">Event lodgement page</label>
                    <Field
                      name={"Contact_SiteUser.Flag_CRMS_StandardForm"}
                      textField="Text"
                      dataItemKey="Value"
                      data={CRMS_FORM_TYPE}
                      value={flagCRMSValue}
                      component={CCSearchComboBox}
                      onChange={(event: ComboBoxChangeEvent) => {
                        onChange("Contact_SiteUser.Flag_CRMS_StandardForm", {
                          value: event.value?.Value ?? null,
                        });
                      }}
                    />
                  </div>
                  {flagCRMSValue === CRMS_FORM_TYPE[1] && (
                    <div className="cc-field">
                      <CCLabel title="Event simple method" />
                      <Field
                        name={
                          "Contact_SiteUser.SiteUser_CRMS_SimpleEventMethod"
                        }
                        textField="Text"
                        dataItemKey="Value"
                        data={EVENT_SIMPLE_METHOD}
                        value={eventSimpleMethodValue}
                        component={CCSearchComboBox}
                        onChange={(event: ComboBoxChangeEvent) => {
                          onChange(
                            "Contact_SiteUser.SiteUser_CRMS_SimpleEventMethod",
                            {
                              value: event.value?.Value ?? null,
                            }
                          );
                        }}
                      />
                    </div>
                  )}
                  <div className="cc-field">
                    <label className="cc-label">
                      Receive escalation emails
                    </label>
                    <Field
                      name={
                        "Contact_SiteUser.Flag_CRMS_Escalation_ActionOfficer_OutstandingNotifications"
                      }
                      checked={getValue(
                        "Contact_SiteUser.Flag_CRMS_Escalation_ActionOfficer_OutstandingNotifications"
                      )}
                      defaultChecked={true}
                      component={CCSwitch}
                    />
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
        <br />
        <section className="cc-field-group">
          <label className="cc-label cc-label-approved-detail-part">
            Health manager settings
          </label>
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Area</label>
                <Field
                  name={"Contact_SiteUser.HealthArea_KWD"}
                  textField="Value"
                  dataItemKey="Key"
                  data={siteUserLovs?.HealthArea}
                  value={displayHealthArea}
                  component={CCSearchComboBox}
                  onChange={(event: ComboBoxChangeEvent) =>
                    onChange("Contact_SiteUser.HealthArea_KWD", {
                      value: event.value?.Key ?? null,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </section>
      </FormElement>

      {isOpenPopupPhoto && !isNew && (
        <AddAttachmentDialog
          onClose={() => setOpenPopupPhoto(false)}
          onSubmit={handleSaveAttachmentPhoto}
          isLoadingButton={isLoadingUploadPhoto}
          isDisableWebLink
        />
      )}

      {isOpenPopupSignature && !isNew && (
        <AddAttachmentDialog
          onClose={() => setOpenPopupSignature(false)}
          onSubmit={handleSaveSignature}
          isLoadingButton={isLoadingSignature}
          isDisableWebLink
        />
      )}

      {showConfirmDeleteDialog && (
        <ConfirmDialog
          title={"Confirm Deletion"}
          subMessage={"Are you sure you want to delete this item?"}
          onClosePopup={() => {
            setShowConfirmDeleteDialog(null);
          }}
          onConfirmYes={handleOnRemoveItems}
        />
      )}
    </div>
  );
};

export default observer(SiteUserFormElement);
