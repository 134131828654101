import Actions from "@app/core/actions/_index";
import { Comments } from "@app/core/comments/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { Documents } from "@app/core/documents/_index";
import Fees from "@app/core/fees/_index";
import { Journal } from "@app/core/journal/_index";
import Samples from "@app/core/samples/_index";
import { BuildingPoolsSpasForm } from "@app/products/building/pools-or-spas/[id]/components/child-screens/general/_index";
import { PoolsOrSpasContactsTab } from "@app/products/building/pools-or-spas/[id]/components/reference-sidebar/contacts/_index";
import { PoolsOrSpasDetailTab } from "@app/products/building/pools-or-spas/[id]/components/reference-sidebar/detail/_index";
import { BuildingPoolsSpasRecordsState } from "@app/products/building/pools-or-spas/[id]/model";
import { useBuildingPoolOrSpasStore } from "@app/products/building/pools-or-spas/[id]/store";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import Contacts from "@common/pages/contacts/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import { default as React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedBuildingPoolsSpas = observer(() => {
  const { id } = useParams<{ id: string }>();
  const [showSlideBar, setShowSlideBar] = useState(true);
  const {
    loadPoolOrSpas,
    poolsOrSpas,
    isLoading,
    resetStore,
    responseLoadError,
    poolsOrSpasId,
  } = useBuildingPoolOrSpasStore();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();
  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Building - Pools Or Spas - ${poolsOrSpasId ?? 0}`,
      Text:
        `${getDisplayTextWithDashes([
          poolsOrSpas?.RegisterNumber,
          poolsOrSpas?.Contact,
        ])}` || "N/A",
      LinkUrl: managePageUrl,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Building,
      RecordType_ENUM: RECORDTYPE.BUILDING,
      Record_ID: poolsOrSpasId ? +poolsOrSpasId : 0,
    },
  ];

  useEffect(() => {
    loadPoolOrSpas(parseInt(id));
    return () => {
      resetStore();
    };
  }, [loadPoolOrSpas, id, resetStore]);

  const [buildingPoolsSpasRecords] = useState<
    BuildingPoolsSpasRecordsState | undefined
  >(undefined);

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Addresses",
      totalRecord: buildingPoolsSpasRecords?.addresses,
      component: (
        <Samples id={poolsOrSpasId} recordType={RECORDTYPE.BUILDING} />
      ),
    },
    {
      title: "Actions",
      totalRecord: buildingPoolsSpasRecords?.actions,
      component: (
        <Actions id={poolsOrSpasId} recordType={RECORDTYPE.BUILDING} />
      ),
    },
    {
      title: "Comments",
      totalRecord: buildingPoolsSpasRecords?.comments,
      component: (
        <Comments id={poolsOrSpasId} recordType={RECORDTYPE.BUILDING} />
      ),
    },
    {
      title: "Contacts",
      totalRecord: buildingPoolsSpasRecords?.contacts,
      component: (
        <Contacts id={poolsOrSpasId} recordType={RECORDTYPE.BUILDING} />
      ),
    },
    {
      title: "Documents",
      totalRecord: buildingPoolsSpasRecords?.documents,
      component: (
        <Documents
          id={parseInt(poolsOrSpasId + "")}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Fees",
      totalRecord: buildingPoolsSpasRecords?.fees,
      component: <Fees id={poolsOrSpasId} recordType={RECORDTYPE.BUILDING} />,
    },
    {
      title: "Inspections",
      totalRecord: buildingPoolsSpasRecords?.samples,
      component: (
        <Samples id={poolsOrSpasId} recordType={RECORDTYPE.BUILDING} />
      ),
    },
    {
      title: "Related",
      totalRecord: buildingPoolsSpasRecords?.samples,
      component: (
        <Samples id={poolsOrSpasId} recordType={RECORDTYPE.BUILDING} />
      ),
    },
  ];

  const getTitle = () => {
    const registerNumber = poolsOrSpas?.RegisterNumber;
    const contact = poolsOrSpas?.Contact;

    // If these two are not falsy, return both, otherwise return one or default
    if (registerNumber && contact) {
      return `${registerNumber} - ${contact}`;
    }

    return registerNumber || contact || "N/A";
  };

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <PoolsOrSpasDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts", component: <PoolsOrSpasContactsTab /> },
      {
        title: "History",
        component: <Journal id={0} recordType={RECORDTYPE.BUILDING} />,
      },
    ],
  });

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />

      <FormNavigation title="Pool/Spa" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadPoolOrSpas(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={getTitle()} badge={poolsOrSpas?.Status} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              ></CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
              <CCNavButton title={"Add"} type="sub">
                <CCNavButton title={"Action"} />
                <AddActionButton />
                <CCNavButton title={"Additional address"} />
                <CCNavButton title={"Contact"} />
                <CCNavButton title={"Advertising"} />
                <CCNavButton title={"Appeal"} />
                <CCNavButton title={"Attachment"} />
                <CCNavButton title={"Comment"} />
                {/* TODO: enhance later */}
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.Building}
                  recordType={RECORDTYPE.TP_BuildingApplication}
                  recordId={parseInt(id)}
                />
                <CCNavButton title={"Complaint"} />
                <CCNavButton title={"Document"} />
                <CCNavButton title={"Fee"} />
                <CCNavButton title={"Inspection"} />
                <CCNavButton title={"Notice"} />
                <CCNavButton title={"Prosecution"} />
              </CCNavButton>,

              <CCNavButton title={"View"} type="sub">
                <CCNavButton title={"Documents"} />
                <CCNavButton title={"Journal"} />
                <CCNavButton title={"Project"} />
              </CCNavButton>,
              <CCNavButton title={"Print"} />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {poolsOrSpas && (
                  <CCGeneralPanel component={<BuildingPoolsSpasForm />} />
                )}
                <CustomPanelBar listPanelBar={listPanelBar} />
              </div>
            </div>

            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
