import { RatingStar } from "@app/products/hm/components/rating-star/_index";
import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { loadPremise } from "./api";
import { PremiseResponse } from "./model";
import "./_index.scss";

export const PremiseDetailsTab = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [premiseInfo, setPremiseInfo] = useState<PremiseResponse | undefined>();
  const productListViewStore = useCCProductListViewStore();
  const { lastSelectedRow } = productListViewStore;
  const params: { id: string } = useParams();
  const premiseId = params.id;
  useEffect(() => {
    if (lastSelectedRow?.ID || premiseId) {
      const ID: any = lastSelectedRow?.ID || premiseId;
      setIsLoading(true);
      loadPremise(ID).then((data) => {
        setPremiseInfo(data);
        setIsLoading(false);
      });
    }
  }, [lastSelectedRow, premiseId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!premiseInfo) return <NoData />;
  return (
    <>
      <ProductReferenceBody>
        <ProductReferenceHeading
          title={"Premise Type:"}
          value={premiseInfo.PremiseType}
          primary
        />
        <ProductReferenceBlock>
          <ProductReferenceRow
            customValue={<RatingStar numberOfStar={premiseInfo.RatingStar} />}
          />
          <ProductReferenceRow
            title={"Reference Number:"}
            value={formatDisplayValue(premiseInfo.RefNo, NUMBER_FORMAT.NUMBER2)}
            primary
          />
          <ProductReferenceRow
            customValue={
              <>
                <div className="cc-premise-risk-class">
                  <div className="cc-field">
                    Risk:{" "}
                    <span className="cc-risk-class-label-value">Medium</span>
                  </div>
                  <div className="cc-field">
                    Class: <span className="cc-risk-class-label-value">1</span>
                  </div>
                </div>
              </>
            }
          />
          <ProductReferenceRow
            title={"OS Fees:"}
            value={formatDisplayValue(
              premiseInfo?.OSFees,
              CURRENCY_FORMAT.CURRENCY1
            )}
            format="currency"
          />
          <ProductReferenceRow
            title={"Registered From:"}
            value={premiseInfo.RegisteredFrom}
          />
          <ProductReferenceRow
            title={"Registered To:"}
            value={premiseInfo.RegisteredTo}
          />
          <ProductReferenceRow
            title={"Inspection Frequency:"}
            value={premiseInfo.InspectionFrequency}
          />
          <ProductReferenceRow
            badge
            value={"Requires a new certificate"}
            primary
          />
        </ProductReferenceBlock>
      </ProductReferenceBody>
    </>
  );
});
